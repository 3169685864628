import React from "react";
import { Link } from "react-router-dom";

export interface ISidebarItemProps {
  label: string;
  activeClass: string;
  linkPath: string;
  labelPadding?: string;
}

export default class SidebarItem extends React.Component<ISidebarItemProps> {
  render() {
    let { label, activeClass, linkPath, labelPadding } = this.props;

    return (
      <Link className={"br-menu-link " + activeClass} to={"/ops/employee/" + linkPath}>
        <div className="br-menu-item">
          <span className={"menu-item-label "+labelPadding}>{label}</span>
        </div>
      </Link>
    );
  }
}
