import { FieldRef } from "..";
import { IFieldDefinition, IRecordSchema, Schema } from "../Schema";
import { Lexer } from "./Lexer";
import { TypeChecking } from "./TypeChecking";
import { IExpressionNode, IExpressionToken, KeywordSet, Operator, OperatorSet, SystemFunctionSet } from "./types";

export class Parser {
    private index: number;
    private length: number;
    private tokens: IExpressionToken[];
    private last: IExpressionToken;
    private current: IExpressionToken;
    public typeChecking:TypeChecking;
    public operators:OperatorSet;
    public systemFunctions:SystemFunctionSet;
    public keywords:KeywordSet;

    constructor(
        tokens: IExpressionToken[],
        typeChecking: TypeChecking
    ) {
        this.tokens = tokens;
        this.index = 0;
        this.length = tokens.length;
        this.typeChecking = typeChecking;
    }

    public static ScriptOperators: OperatorSet = {
        "^": { precedence: 120, nodeType: "exp" },
        NEG: { precedence: 110, nodeType: "neg" },
       
        "*": { precedence: 100, nodeType: "mult" },
        "/": { precedence: 100, nodeType: "div" },
        "\\": { precedence: 90, nodeType: "idiv" },
        MOD: { precedence: 80, nodeType: "mod" },
        "+": { precedence: 70, nodeType: "add" },
        "-": { precedence: 70, nodeType: "sub" },
        "&": { precedence: 60, nodeType: "con" },
        EQ: { precedence: 50, nodeType: "eq" },
        "=": { precedence: 50, nodeType: "eq" },
        NE: { precedence: 50, nodeType: "neq" },
        "<>": { precedence: 50, nodeType: "neq" },
        LE: { precedence: 50, nodeType: "lte" },
        "<=": { precedence: 50, nodeType: "lte" },
        LT: { precedence: 50, nodeType: "lt" },
        "<": { precedence: 50, nodeType: "lt" },
        GT: { precedence: 50, nodeType: "gt" },
        ">": { precedence: 50, nodeType: "gt" },
        GTE: { precedence: 50, nodeType: "gte" },
        ">=": { precedence: 50, nodeType: "gte" },
        BEGINS:{precedence:50,nodeType:"begins"},
        CONTAINS:{precedence:50,nodeType:"contains"},
        ENDS:{precedence:50,nodeType:"ends"},
        BETWEEN:{precedence:50,nodeType:"between"},
        "IS EMPTY":{precedence:50,nodeType:"isempty"},
        NOT: { precedence: 40, nodeType: "not" },
       
        AND: { precedence: 30, nodeType: "and" },
        OR: { precedence: 20, nodeType: "or" },
        XOR: { precedence: 15, nodeType: "xor" },
        
       
    };

    public static ScriptFunctions: SystemFunctionSet = {
        TRIM: { args: ["string"] },
        ABS: { args: ["number"] },
        MID: { args: ["string", "number", "number"] },
        WHEN: {args:["any"]},
        AVAILABLE: {args:["any"]},
        "IS-FIRST":{args:["table"]},
        "IS-LAST":{args:["table"]},
        "DISPLAY-NAME": { args: ["pointer"] },
        "@EVENT-VALUE":{asVariable:true},
        "@RETURN-VALUE":{asVariable:true},
        "DIALOG-YES": {asVariable:true},
        "DIALOG-NO": {asVariable:true},
        "DIALOG-CANCEL": {asVariable:true},
        "DIALOG-VALUE": {asVariable:true},
        "THIS-FIELD": {asVariable:true},
        "THIS-FIELD-NAME": {asVariable:true},
        "THIS-FIELD-PTR": {asVariable:true},
        "PREVIOUS-VALUE": {asVariable:true},
        "NAME-OF": { args: ["any"] },
        "THIS-FORM":{asVariable:true},
        "THIS-FORM-NAME":{asVariable:true},
        "DATE-TODAY":{asVariable:true},
        "TIME-OF-DAY-GREETING":{asVariable:true},
        "NEW-ROW":{asVariable:true},
        "ALL-ROWS":{args:["any"]},
        "TABLE-REF":{args:["any"]},
        "MOD-SELECTED-ROWS": {args:["any"]},
        "MOD-SELECTED-KEYS": {args:["any"]},
        "MOD-ALL-ROWS":{args:["any"]}
    };

    public static ScriptKeywords:KeywordSet = {};


    public static parse(
        expression: string,
        typeChecking: TypeChecking
    ): IExpressionNode {
        let keywords = this.ScriptKeywords;
        let tokens = Lexer.getExpression(expression,keywords,this.ScriptFunctions);
        if (!tokens) return null;
        var s = new Parser(tokens, null);
        s.typeChecking = typeChecking;
        s.keywords = keywords;
        s.systemFunctions = this.ScriptFunctions;
        s.operators = this.ScriptOperators;
        return s.getNode(null, null);
    }

    

    public advance(): IExpressionToken {
        this.last = this.current;
        this.current = this.tokens[this.index++];
        return this.current;
    }

    public isMoreTokens(): boolean {
        return this.index < this.length;
    }

    private peek(): IExpressionToken {
        return this.tokens[this.index] || {};
    }

    public getNode(
        pendingNode: IExpressionNode,
        pendingOp: Operator
    ): IExpressionNode {
        var valueNode = this.getValue();

        var endOfBlock: boolean;
        if (!this.isMoreTokens()) {
            endOfBlock = true;
        } else {
            let peek = this.peek();
            if (
                peek.type == "punctuation" &&
                (peek.text == ")" || peek.text == ",")
            ) {
                endOfBlock = true;
            }
        }
        let token:IExpressionToken;
        if (!endOfBlock){
            token = this.advance();
            if (token.type == "op" && token.text == "IS"){
                if (!this.isMoreTokens()){
                    throw "expected value after IS";
                }
                token = this.advance();
                let opText = "IS " + token.text.toUpperCase();
                var op = this.operators[opText];
                if (!op) {
                    throw "Invalid operator: " + opText;
                }
                valueNode = {type:op.nodeType,value:valueNode};
                if (!this.isMoreTokens()){
                    endOfBlock = true;
                }
                token = this.advance();
            }
        }

        if (endOfBlock) {
            if (pendingOp == "BETWEEN" && !pendingNode.right1){
                throw "Expected AND after BETWEEN";
            }
            if (pendingNode) {
                pendingNode.right = valueNode;
                return pendingNode;
            }
            return valueNode;
        }

       

        if (token && token.type == "op") {
            var op = this.operators[token.text];
            if (!op) {
                throw "Invalid operator: " + token.text;
            }
           
            let opNode: IExpressionNode = { type: op.nodeType };
        
            if (!pendingNode) {
                opNode.left = valueNode;
                return this.getNode(opNode, token.text as Operator);
            } 
            else if (pendingOp == "BETWEEN" && !pendingNode.right1){
                if (token.text != "AND"){
                    throw "Expected AND after BETWEEN";
                }
                pendingNode.right1 = valueNode;
                return this.getNode(pendingNode,"BETWEEN");
            } 
            else if (
                this.getPrecedence(token.text as Operator) <=
                this.getPrecedence(pendingOp)
            ) {
                pendingNode.right = valueNode;
                opNode.left = pendingNode;
                return this.getNode(opNode, token.text as Operator);
            } else {
                opNode.left = valueNode;
                pendingNode.right = this.getNode(
                    opNode,
                    token.text as Operator
                );
                return pendingNode;
            }
        } else {
            throw "expected operator";
        }
    }

    getValue(): IExpressionNode {
        var token = this.advance();
        if (!token) {
            throw "Expected value";
        }
        var tokenType = token.type;
        if (tokenType == "op") {
            if (token.text == "-") {
                let peek = this.peek();
                if (peek.type == "string" || peek.type == "boolean" || peek.type == "null") {
                    throw "Expected number, literal or expression after '-'";
                }
                let valueNode = this.getValue();
                return { type: "neg", value: valueNode };
            }
            if (token.text == "NOT") {
                let valueNode = this.getValue();
                return { type: "not", value: valueNode };
            }
        }
        if (tokenType == "punctuation") {
            if (token.text == "(") {
                let valueNode = this.getNode(null, null);
                token = this.advance();

                if (
                    token &&
                    token.type == "punctuation" &&
                    token.text == ")"
                )
                    return valueNode;
                throw "Expected: )";
            }
            throw "Unexpected char: " + token.text;
        }
        if (tokenType == "string") {
            return { type: "str", value: token.text };
        }
        if (tokenType == "number") {
            return { type: "num", value: token.text };
        }
        if (tokenType == "boolean") {
            return { type: "bool", value: token.text };
        }
        if (tokenType == "sysfunc"){
            return this.getSystemFunc(token);
        }
        if (tokenType == "identifier") {
            return this.getIdentifier(token);
        }
        if (tokenType == "keyword" || tokenType == "datatype"){
            return {type:"null"}
        }
        if (tokenType == "null"){
            return {type:"null"};
        }
        throw "Unexpected char: " + token.text;
    }

    getNameof(node: IExpressionNode): IExpressionNode {
        let arg: IExpressionNode = node.args[0];
        if (!arg) {
            throw "Missing argument for name-of";
        }
        if (arg.type == "sdf") {
            if (arg.name == "THIS-FIELD") {
                return { type: "sdf", name: "THIS-FIELD-NAME", args: [] };
            }
            if (arg.name == "THIS-FORM"){
                return {type:"sdf",name:"THIS-FORM-NAME",args:[]}
            }
            throw "Invalid argument for name-of";
        }
        if (arg.type == "loc" || arg.type == "get" || arg.type == "prop") {
            return { type: "str", value: arg.name };
        }
        throw "Invalid argument for name-of";
    }

    createPropNode(target:IExpressionNode,field:IFieldDefinition):IExpressionNode {
        if (field.computed){
            return {
                type:"computed",
                expr:field.computedExpr,
                target:target,
                name:field.name
            }
        }
        return {
            type:"prop",
            target:target,
            name:field.name
        };
    }

    createTargetNode(field:IFieldDefinition,indexer:number):IExpressionNode {
        if (field.type == "collection"){
            if (indexer !== undefined){
                return {type:"row",name:field.name,value:indexer};
            }
            return {type:"current",name:field.name};
        }
        return {type:"get",name:field.name};
    }

    getIdentifier(token: IExpressionToken): IExpressionNode {

       
        var iNode: IExpressionNode;

        var peek = this.peek();
        if (peek.type == "punctuation" && peek.text == "(") {
           return this.getFunction(token,peek);
        } 
        let indexer:number;
        if (peek.type == "punctuation" && peek.text == "["){
            indexer = this.getIndexer();
            peek = this.peek();
        }
        var varName = token.text;
        if (varName[0] != '@' && this.typeChecking.defaultRecord){
            let rec = this.typeChecking.getVariableType(this.typeChecking.defaultRecord);
            if (rec){
                let f = Schema.getFieldDef(rec.recordSchema,varName);
                if (f){
                    return this.createPropNode(this.createTargetNode(rec,indexer),f);
                }
            }
        }

        let isTarget = (peek.type == "punctuation" && peek.text == ".");
       
        let field = this.typeChecking.getLocalType(varName);
        if (field) {
            iNode = { type: "loc", name: field.name };
        } else {
            let i = varName.indexOf(":");
            let modifier:string;
            if (i != -1){
                modifier = varName.substr(i + 1).toUpperCase();
                varName = varName.substr(0,i);
            } 
            field = this.typeChecking.getVariableType(varName);
            if (field) {
                if (field.computed) {
                    iNode = { type: "gcp", name: field.name };
                } 
                else if (modifier){
                    let sysFuncName = "MOD-" + modifier;
                    let sysFunc = this.systemFunctions[sysFuncName];
                    if (!sysFunc){
                        throw "invalid modifier on " + token.text;
                    }
                    iNode = {type: "sdf",name:"MOD-" + modifier,args:[{value:field.name,type:"str"}]};
                }
                else if (isTarget){
                    iNode = this.createTargetNode(field,indexer);
                }
                else {
                    iNode = {type:'get',name:field.name};
                }
            }
            if (!iNode) {
                let extendedName = this.getExtendedName(varName);
                let withStackItem = this.typeChecking.findWith(extendedName);
                if (withStackItem) {   
                    field = Schema.getFieldDef(withStackItem.schema,extendedName);                   
                    if (field) {
                        iNode = this.createPropNode({type:"get",name:withStackItem.name},field);
                        return iNode;
                    }
                }
            }
        }
    
        if (!iNode) {
            throw varName + " is not defined";
        }
        if (peek.type == "punctuation" && peek.text == ".") {
            token = this.advance();
            return this.getProp(iNode, field);
        }
        return iNode;
    }

    getIndexed(target:IExpressionNode):IExpressionNode{
        this.advance(); // consume [
        let token = this.advance();
        if (token.type == "number"){
            let value:IExpressionNode = {type:"num",value:token.text};
            let indexed:IExpressionNode = {type:"ind",value,target};
            token = this.advance();
            if (token.type !== "punctuation" || token.text != "]"){
                throw "missing closing ] on array index";
            }
            return indexed;
        }
        else {
            throw "Expected numeric value for array index";
        }

    }

    getIndexer():number{
        this.advance(); // consume [
        let token = this.advance();
        if (token.type == "number"){
            let indexer = parseInt(token.text,10);
            token = this.advance();
            if (token.type !== "punctuation" || token.text != "]"){
                throw "missing closing ] on array index";
            }
            return indexer;
        }
        else {
            throw "Expected numeric value for array index";
        }

    }
    getSystemFunc(token:IExpressionToken):IExpressionNode{
        let varName = token.text;
        let peek = this.peek();
        let up = varName.toUpperCase();
        let sysFunc = this.systemFunctions[up];
        if (!sysFunc){
            let keyword = this.keywords[up];
            if (!keyword){
                throw "Unknown keyword: " + varName;
            }
            return {type:"sdf",name:up,args:[]};
        }
        if (up =="THIS-FORM" && peek.type == "punctuation" && peek.text == "."){
            this.advance(); // consume .
            token  = this.advance();
            if (token.type != "identifier"){
                throw "Expected identifier after this-form.";
            }
            varName = token.text;
            peek = this.peek();
            let field = this.typeChecking.getVariableType(varName);
            let iNode:IExpressionNode;
            if (!field){
                throw varName + " is not defined";
            }
            iNode = {type:"get",name:field.name};
            if (peek.type == "punctuation" && peek.text == ".") {
                token = this.advance();
                return this.getProp(iNode, field);
            }
            return iNode;
        }
        
        let iNode:IExpressionNode = { type: "sdf", name: up, args: [] };
        if (peek.type == "punctuation" && peek.text == "(") {
            token = this.advance();
            iNode.args = this.getFuncArgs();
        }
        return iNode;  
    }

    getFunction(token:IExpressionToken,peek:IExpressionToken):IExpressionNode{  
        let funcName = token.text;
        let up = funcName.toUpperCase();
        token = this.advance(); // consume (
        
        let systemFunction = this.systemFunctions[up];
        let iNode:IExpressionNode;
        if (systemFunction) {
            iNode = { type: "sdf", name: up };
        } else {
            throw "Unknown function: " + funcName;
        }
        let args = this.getFuncArgs();
        iNode.args = args;
        if (up == "NAME-OF") {
            iNode = this.getNameof(iNode);
        }
        return iNode;
    }


    getProp(
        target: IExpressionNode,
        targetField: IFieldDefinition
    ): IExpressionNode {
        var token = this.advance();
        if (!token || token.type != "identifier") {
            throw "Identifier expected";
        }
        let propName = token.text;
        var pNode: IExpressionNode;
        var peek = this.peek();
        let field: IFieldDefinition;
        if (peek.type == "punctuation" && peek.text == "(") {
            this.advance();
            var args = this.getFuncArgs();
            pNode = { type: "odf", target, name: propName, args };
        } else {
            propName = this.getExtendedName(propName);
            field = this.typeChecking.getPropertyType(
                targetField.recordSchema,
                propName
            );
            if (!field) {
                throw targetField.name + "." + propName + " is not defined";
            }
            pNode = this.createPropNode(target,field);
        }
        /*
        var peek = this.peek();
        if (peek.type == "punctuation" && peek.text == '.') {
            this.advance();
            return this.getProp(pNode, field);
        }
        */
        return pNode;
    }

    getExtendedName(root: string): string {
        var name = root;
        var peek = this.peek();
        while (peek.type == "punctuation" && peek.text == ".") {
            this.advance();
            peek = this.peek();
            if (peek.type == "identifier") {
                this.advance();
                name += "." + peek.text;
            } else {
                throw "Identifier expected after: " + name + ".";
            }
            peek = this.peek();
        }
        return name;
    }

    getIsLocalVar(varName: string): boolean {
        return false;
    }

    getFuncArgs(): IExpressionNode[] {
        let peek = this.peek();
        if (peek.type == "punctuation" && peek.text == ")") {
            this.advance();
            return null;
        }
        var args: IExpressionNode[] = [];
        while (this.isMoreTokens()) {
            let node = this.getNode(null, null);
            args.push(node);
            peek = this.peek();
            if (peek.type == "punctuation" && peek.text == ",") {
                this.advance();
            } else if (peek.type == "punctuation" && peek.text == ")") {
                this.advance();
                return args;
            }
        }
        throw "Expected )";
    }

    private getPrecedence(op: Operator): number {
        var o = this.operators[op];
        if (o) {
            return o.precedence;
        }
        return 5;
    }
}