import { useEffect, useState } from "react";
import { CustomLoading } from "./CustomLoading";
import { Card, Line } from "ktm-ui";
import "../styles/Settings.css";
import EditGeneralSettingModal from "./EditGeneralSettingModal";

const FundingSettings = ({ policyId }) => {
    const [loading, setLoading] = useState(true);
    const [showEditGeneralModal, setShowEditGeneralModal] = useState(false);
    const [enablePad, setEnablePAD] = useState(null);

    useEffect(() => {
        getEnablePAD();
    }, []);

    useEffect(() => {
        getEnablePAD();
    }, [enablePad]);

    const openEditGeneralModal = () => {
        setShowEditGeneralModal(true);
    }

    const getEnablePAD = async () => {
        let response = await fetch("/api/Settings/checkenablepad?policyId=" + policyId, { method: "GET" });
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        setEnablePAD(data.enablePAD.value);
        setLoading(false);
    }

    const updateEnablePad = async (value) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ policyId: policyId, enablePAD: value }),
        };

        let response = await fetch("/api/Settings/updateenablepad", requestOptions);
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        await getEnablePAD();
    }

    let editGeneralModal = null;
    if (showEditGeneralModal) {
        editGeneralModal = (
            <EditGeneralSettingModal
                modalLabel={"Edit General"}
                options={["Yes", "No"]}
                showEditModal={showEditGeneralModal}
                selectedOption={enablePad}
                setSelectedOption={updateEnablePad}
                closeModal={() => setShowEditGeneralModal(false)}
                dropdownLabel={"Enable PAD:"}
            />
        );
    }

    let contents = null;
    if (loading) {
        contents = (<CustomLoading />);
    } else {
        contents = (
        <div className="appearance-setting-container">
            {editGeneralModal}
            <Card
                actions={<><a href="#" className="RT-Link" onClick={openEditGeneralModal}>Edit</a></>}
            >
                <Line>
                    <div className="RT-Static-Field">
                        <div className='RT-Static-Field__label'>Enable PAD:</div>
                        <div className='RT-Static-Field__content'>{enablePad}</div>
                    </div>
                </Line>
            </Card>
        </div>
        );
    }

    return (
        <div className="appearance-settings">
        {contents}
        </div>
    );
}

export default FundingSettings;