import React, {useEffect, useState} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from "moment";
import {PDFDocument} from "pdf-lib";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const CertificateOfInsurance = (props) => {
    const [name, setName] = useState(null);

    useEffect(() => {
        if (props.natPolicy === "NAT1001") {
            setName("CoreHealth+");
        }
        else {
            setName("InsurPak");
        }
    }, []);
    
    const generateCHCoverPage = async () => {
        const docDefinition = {
            pageSize: 'LETTER',
            pageOrientation: 'portrait',
            pageMargins: [60, 40, 60, 60],
            info: {
                title: `Certificate of Insurance - ${props.policyNo} - ${props.companyName}`
            },
            content: [
                {
                    columns: [
                        {
                            width: '*',
                            text: ''
                        },
                        {
                            width: 'auto',
                            text: 'National HealthClaim Corporation\n5320 - 7005 Fairmount Dr. SE\nCalgary, AB\nT2H 0K4',
                            style: 'address'
                        }
                    ],
                    margin: [0, 40, 0, 20]
                },
                { text: 'Certificate of Insurance', style: 'header' },
                {
                    text: `Wawanesa Life Insurance Company of Canada (the "Insurer") has issued a Group Insurance Policy ${props.natPolicy} for Life, Accidental Death and Dismemberment, Critical Illness, Travel Emergency Medical and Excess Medical Expense Insurance (the "Policy") to National HealthClaim Corporation (NHC) on their behalf.`,
                    margin: [0, 20, 0, 10],
                },
                {
                    text: 'The Insurer agrees to provide the Insurance for which a premium has been paid, subject to the terms and conditions of the Group Master Policy.',
                    margin: [0, 0, 0, 20],
                },
                { text: 'Account Information', style: 'subheader' },
                {
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                { text: 'Company Name' },
                                { text: `${props.companyName}` },
                            ],
                            [
                                { text: `${name} Policy Number` },
                                { text: `${props.policyNo}` },
                            ],
                            [
                                { text: 'Group Insurance Policy' },
                                { text: `${props.natPolicy}`, bold: true },
                            ],
                        ],
                    },
                    layout: {
                        paddingLeft: () => 10,
                        paddingTop: () => 10,
                        paddingRight: () => 10,
                        paddingBottom: () => 10,
                    },
                    margin: [0, 0, 0, 20],
                },
                { text: 'Policy Details', style: 'subheader' },
                {
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                { text: 'Effective Date of Insurance' },
                                { text: moment(props.policyEffectiveDate).format("MMMM DD, YYYY") },
                            ],
                            [
                                { text: 'Insurance Plan' },
                                {
                                    text: 'Life\nAccidental Death\nCritical Illness\nTravel Emergency Medical\nExcess Medical Expense',
                                },
                            ],
                        ],
                    },
                    layout: {
                        paddingLeft: () => 10,
                        paddingTop: () => 10,
                        paddingRight: () => 10,
                        paddingBottom: () => 10,
                    },
                    margin: [0, 0, 0, 20],
                },
                {
                    columns: [
                        {
                            width: '*',
                            text: ''
                        },
                        {
                            width: 'auto',
                            text: 'Underwritten by:\n' +
                                'Wawanesa Life Insurance Company\n' +
                                'PO Box 3300\n' +
                                'Winnipeg, MB\n' +
                                'R3C 5S2\n' +
                                'Claims # 1-866-337-0408',
                            style: 'address'
                        }
                    ],
                    margin: [0, 100, 0, 0],
                },
            ],
            styles: {
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                },
                address: {
                    fontSize: 9,
                    alignment: 'right',
                },
            },
            defaultStyle: {
                fontSize: 9,
            }
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        return new Promise((resolve, reject) => {
            pdfDocGenerator.getBlob((blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(blob);
            });
        });
    };

    const mergePDFs = async (existingPdfBytes, newPdfBytes) => {
        const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
        const newPdfDoc = await PDFDocument.load(newPdfBytes);

        const mergedPdfDoc = await PDFDocument.create();

        const newPages = await mergedPdfDoc.copyPages(newPdfDoc, newPdfDoc.getPageIndices());
        newPages.forEach((page) => mergedPdfDoc.addPage(page));

        const existingPages = await mergedPdfDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
        existingPages.forEach((page) => mergedPdfDoc.addPage(page));

        const mergedPdfBytes = await mergedPdfDoc.save();
        return mergedPdfBytes;
    };

    const handleMergeCert = async () => {
        let storageUrl = "https://nhcfilestorage.blob.core.windows.net/docfiles";
        let response;
        if (props.natPolicy === "NAT1001") {
            response = await fetch(storageUrl + "/nat1001.pdf");
        } else if (props.natPolicy === "NAT1002") {
            response = await fetch(storageUrl + "/nat1002.pdf");
        }
        const existingPdfBytes = await response.arrayBuffer();

        const newPdfBytes = await generateCHCoverPage();

        const mergedPdfBytes = await mergePDFs(existingPdfBytes, newPdfBytes);

        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        window.open(url, '_blank');
        setTimeout(() => URL.revokeObjectURL(url), 100);
    };

    return (
        <div className="pd-l-45 mg-b-10">
            <a href="#" onClick={handleMergeCert} style={{color: "#0d6efd"}}>
                <i className="fa fa-file pd-r-15"></i> {name} Certificate of Insurance
            </a>
        </div>
    );
};
