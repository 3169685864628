import React from 'react';
import { DialogResult } from '../../core';
import { DialogContainer } from './DialogContainer';

type DialogStyle = null | 'danger';
export class Confirm extends React.Component<{
    $onClose?:(result:DialogResult) => any;
    $layerZIndex?:number;
    message:string;
    style?:DialogStyle;
    continueLabel?:string;
    cancelLabel?:string;
}> {

    render() {
        let icon: any;
        let style = this.props.style;
        let headerClass = "Message-Box__header";
        let buttonContainerClass = "Message-Box__buttons Message-Box__buttons--right";

        icon = this.renderConfirmIcon(style);
        
        let confirmButtonClass:string;
        if (style == "danger"){
            headerClass += " Message-Box__header--danger";
            buttonContainerClass += " Message-Box__buttons--danger";
            confirmButtonClass = "RT-Button--destructive";
        }
        else {
            headerClass += " Message-Box__header--warning";
            buttonContainerClass += " Message-Box__buttons--warning";
            confirmButtonClass = "RT-Button--warning";
        }

        var header = (
            <div className={headerClass}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {icon}
                </div>
            </div>
        );
        let content;
        if (this.props.children){
            content = <div className="Message-Box__text">
                <div className="Message-Box__text-inner">
                    {this.props.children}
                </div>
            </div>
        }
        let message = this.props.message;
      
        var body = (
            <div className="Message-Box__body">
                <div className="Message-Box__title">{message}</div>
                {content}
            </div>
        );

        let buttons = (<>
          
            <button
                   className="RT-Button"
                   onClick={this.handleCancel}
               >
                   {this.props.cancelLabel || "Cancel"}
               </button>
               <button className={'RT-Button ' + confirmButtonClass + ' RT-Button--large'} onClick={this.handleContinue}>{this.props.continueLabel || "Continue"}</button>
           </>)
        
        let helpButton;
    
        var footer = (
            <div className={buttonContainerClass}>
               {buttons}
               {helpButton}
            </div>
        );

        return (
            <DialogContainer size="small" zIndex={this.props.$layerZIndex} animate>
                {header}
                {body}
                {footer}
            </DialogContainer>
        )
        
    }


    handleContinue = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.$onClose({continue:true}); 
    };

    
    handleCancel = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.$onClose({continue:false});
    };


    renderConfirmIcon(style:DialogStyle) {
        let styleClass = (style == "danger") ? "Message-Box__icon--danger" : "Message-Box__icon--warning";
        return (
            <svg
                className={'Message-Box__icon ' + styleClass}
                viewBox="0 0 24 24"
            >
                <path d="M12,16a1,1,0,1,0,1,1A1,1,0,0,0,12,16Zm10.67,1.47-8.05-14a3,3,0,0,0-5.24,0l-8,14A3,3,0,0,0,3.94,22H20.06a3,3,0,0,0,2.61-4.53Zm-1.73,2a1,1,0,0,1-.88.51H3.94a1,1,0,0,1-.88-.51,1,1,0,0,1,0-1l8-14a1,1,0,0,1,1.78,0l8.05,14A1,1,0,0,1,20.94,19.49ZM12,8a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V9A1,1,0,0,0,12,8Z"/>
            </svg>
        );
    }
}
