import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';

interface IdsCampaign {
    Id:string;
    AllocationIncrement:number;
    AllocationIncrementAmount:number;
};

export default KTMScreen({name:"planadmin.campaign.edit-flex-options"}, class extends React.Component<{campaign_id:string}> {

    data:{
        dsCampaign:IdsCampaign;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsCampaign";
        await KTMApi.start(screen,{
            "@campaign_id":this.props.campaign_id,
        });
        screen.title = "Edit record";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit record"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display field={field("AllocationIncrement")}/>
                    <Display field={field("AllocationIncrementAmount")}/>
                </Line>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsCampaign.AllocationIncrement":this.data.dsCampaign.AllocationIncrement,
            "@dsCampaign.AllocationIncrementAmount":this.data.dsCampaign.AllocationIncrementAmount,
            "@campaign_id":this.props.campaign_id,
        });
        screen.close({"continue":true});
    }

});
