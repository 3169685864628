import React, { Component, Ref } from "react";
import Spinner from "../components/Spinner";
import axios from "axios";
import Dropzone from "react-dropzone";
import "./styles/support-dashboard.css";
import "./styles/progress-circle.css";
import QueuedImages from "./Employee/QueuedImages";
import ClaimSubmittedModal from "./modals/ClaimSubmittedModal";
import ClaimSubmissionFailedModal from "./modals/ClaimSubmissionFailedModal";
import ClaimSubmittedPendingModal from "./modals/ClaimSubmittedPendingModal";
import Joyride from "react-joyride";
import toastr from "toastr";

export default class UploadClaim extends React.Component<any, any> {
  state = {
    loaded: false,
    plan: null,
    files: null,
    empId: null,
    polId: null,
    polBenId: null,
    queuedImages: null,
    isModalOpen: false,
    isFailedModalOpen: false,
    isPendingModalOpen: false,
    modal: null,
    loadedImages: "none",
    loadedSpinner: "",
    load: 4,
    loadedPictures: null,
    buttonText: "Push New Claim",
    steps: [
      {
        target: ".step4",
        content:
          "Use this section to make a claim by uploading your receipts/images",
        disableBeacon: true,
      },
      {
        target: ".step5",
        content:
          "For alternative methods of claiming, review these instructions",
        disableBeacon: true,
      },
    ],
    run: true,
    enableWellness: false,
  };
  private myRef;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{ employeeId: string; policyId: string; type: string }>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      this.props.employeeId != prevProps.employeeId! ||
      this.props.policyId != prevProps.policyId ||
      this.props.type != prevProps.type
    ) {
      this.startLoading();
    }
  }

  async startLoading() {
    let type = this.props.type;
    axios
      .get(
        `/api/supportemployee/getaccount?policyId=${this.props.policyId}&employeeId=${this.props.employeeId}&type=${type}`
      )
      .then((response) => {
        this.setState({
          loaded: true,
          plan: response.data.spendingAccounts,
          empId: response.data.empId,
          polId: response.data.polId,
          polBenId: response.data.polBenId,
          queuedImages: response.data.queuedImages,
          enableWellness: response.data.enableWellness,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    if (localStorage.getItem("intro2") == "completed") {
      this.setState({ run: false });
    }
  }

  loadMore = () => {};

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if (status == "finished") {
      localStorage.setItem("intro2", "completed");
    }
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
    this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  closeFailedModal = () => {
    this.setState({ isFailedModalOpen: false });
  };

  closePendingModal = () => {
    this.setState({ isPendingModalOpen: false });
  };

  displayModal = () => {
    this.setState({ isModalOpen: true });
  };

  displayFailedModal = () => {
    this.setState({ isFailedModalOpen: true });
  };

  displayPendingModal = () => {
    this.setState({ isPendingModalOpen: true });
  };

  renderOpenModal() {
    if (!this.state.isModalOpen) return null;
    return <ClaimSubmittedModal close={this.closeModal}></ClaimSubmittedModal>;
  }

  renderFailedModal() {
    if (!this.state.isFailedModalOpen) return null;
    return (
      <ClaimSubmissionFailedModal
        close={this.closeFailedModal}
      ></ClaimSubmissionFailedModal>
    );
  }

  renderPendingModal() {
    if (!this.state.isPendingModalOpen) return null;
    return (
      <ClaimSubmittedPendingModal
        close={this.closeFailedModal}
      ></ClaimSubmittedPendingModal>
    );
  }

  setLoaded() {
    this.setState({ loadedImages: "", loadedSpinner: "none" });
  }

  setUnloaded() {
    this.setState({ loadedImages: "none", loadedSpinner: "" });
  }

  onDrop = (acceptedFiles) => {
    this.setState({ files: acceptedFiles });
  };

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  uploadPdf = async (pdfArray) => {
    const formData = new FormData();
    pdfArray.forEach((file, i) => {
      formData.append("pdffile" + i, file);
    });

    await axios
      .post(
        "/api/upload/UploadPDFs/" +
          this.state.empId +
          "/" +
          this.state.polBenId +
          "/" +
          this.state.plan.type,
        formData
      )
      .then((response) => {
        this.closePendingModal();
        if (response.data.success) {
          this.setState({ queuedImages: response.data.queuedImages });
          this.displayModal();
          toastr.success("Upload success", "Success");
        } else {
          toastr.error("Failed to upload files", "Error");
          this.displayFailedModal();
        }
      })
      .catch((error) => {
        this.displayFailedModal();
      });
  };

  uploadImage = async (imagesArray) => {
    const formData = new FormData();
    imagesArray.forEach((file, i) => {
      formData.append("imgfile" + i, file);
    });

    await axios
      .post(
        "/api/upload/UploadImages/" +
          this.state.empId +
          "/" +
          this.state.polBenId +
          "/" +
          this.state.plan.type,
        formData
      )
      .then((response) => {
        this.closePendingModal();
        if (response.data.success) {
          this.setState({ queuedImages: response.data.queuedImages });
          this.displayModal();
          toastr.success("Upload success", "Success");
        } else {
          toastr.error("Failed to upload files", "Error");
          this.displayFailedModal();
        }
      })
      .catch((error) => {
        this.displayFailedModal();
      });
  };

  onSubmit = (e) => {
    let self = this;
    this.setUnloaded();
    e.preventDefault();
    this.displayPendingModal();
    let imagesToUpload = [];
    let pdfToUpload = [];
    let hasPdf = false;

    this.state.files.map((file, i) => {
      if (file.type == "application/pdf") {
        pdfToUpload.push(file);
        hasPdf = true;
      } else {
        imagesToUpload.push(file);
      }
    });

    if (hasPdf) {
      self.uploadPdf(pdfToUpload);
    } else {
      self.uploadImage(imagesToUpload);
    }

    this.setState({ files: null });
  };

  removeFile = (index) => (e) => {
    this.setState({ files: this.state.files.filter((_, i) => i !== index) });
  };

  renderContent() {

    if (!this.state.loaded) {
      return <Spinner />;
    }

    let account = null;
    let accountBox = null;

    // Replace type with actual expense name/id
    let plan = this.state.plan;
    let currPercent = Math.round(
      (plan.currYear.available / plan.currYear.limit) * 100
    );
    let prevPercent = null;
    if (plan.prevYear != null) {
      prevPercent = Math.round(
        (plan.prevYear.Available / plan.prevYear.limit) * 100
      );
    }
    if (currPercent > 100) {
      currPercent = 100;
    }
    if (prevPercent > 100) {
      prevPercent = 100;
    }

    let currCircleClass = null;
    let prevCircleClass = null;
    if (plan.type == "HSA") {
      currCircleClass = "progress-circle progress-" + currPercent;
      prevCircleClass = "progress-circle progress-" + prevPercent;
    } else if (plan.type == "LSA") {
      currCircleClass = "progress-circle-green progress-" + currPercent;
      prevCircleClass = "progress-circle-green progress-" + prevPercent;
    } else {
      // other misc account types
    }

    let disableSubmit = true;
    if (this.state.files) {
      disableSubmit = false;
    }

    let runoffInfo =
      "Proper documentation must be submitted for expenses incurred within your previous benefit period. Claim early, as there are no exceptions to submit once the deadline has ended.";
    let deadlineInfo =
      "If you don’t use up your previous year balance, unused credits will be added to your new benefit year for a maximum of one year. This sometimes can take a few weeks to process but you can still claim normally. Our team will take the credits out of the correct benefit period.";

    let prevYearDiv = null;
    if (plan.prevYear != null) {
      prevYearDiv = (
        <div className="text-center">
          <div className={prevCircleClass}>
            <span>
              <div className="tx-available-val card-font-color">
                ${plan.prevYear.available.toFixed(2)}
              </div>
              <div className="tx-available text-uppercase card-font-color">
                available
              </div>
            </span>
          </div>
          <div className="text-center">
            <div style={{ margin: "auto", textAlign: "center" }}>
              <div className="h6 black pd-t-20">
                {plan.prevYear.startDate} to {plan.prevEndDate}
              </div>
              <div className="h6 black">
                Previous year limit: ${plan.prevYear.limit.toFixed(2)}
              </div>
              <div className="h6 black pd-b-15">
                <span style={{ backgroundColor: "yellow" }}>
                  Deadline: {plan.prevYear.runoff} at 11:59 PM MT
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let documentsBox = (
      <div className="mg-b-0 black">
        <div className="black pd-b-15">Additional Resources</div>
        <div className="mg-b-10">
          <a href="#" className="tx-14">
            <i className="fa fa-file pd-r-15"></i> Major Receipt Requirements
          </a>
        </div>
      </div>
    );

    let fileToUpload = null;
    if (this.state.files) {
      fileToUpload = (
        <div className="pd-t-10">
          <div>Files ({this.state.files.length})</div>
          <div className="tx-12 pd-l-5">
            {this.state.files.map((data, i) => {
              return (
                <div key={data.name}>
                  {data.name}{" "}
                  <a
                    onClick={this.removeFile(i)}
                    href="#"
                    className="mg-l-25 tx-18 mg-r-25"
                    style={{ color: "red" }}
                  >
                    <i className="fa fa-remove" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let queuedImageDiv = null;

    if (this.state.queuedImages != null && this.state.queuedImages.length > 0) {
      queuedImageDiv = (
        <div>
          <div
            className="br-pagebody no-padding mg-t-0 mg-x-50"
            style={{ display: this.state.loadedSpinner }}
          >
            <div
              className="br-section-wrapper no-padding"
              style={{ maxWidth: 1250 }}
            >
              <div className="card">
                <div className="card-header tx-medium tx-uppercase bg-white">
                  Submitted Images
                </div>
                {this.renderOpenModal()}
                {this.renderFailedModal()}
                {this.renderPendingModal()}
                <div
                  className="card-body no-padding"
                  style={{ minHeight: "694px" }}
                >
                  <div className="pd-10 clearfix">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        margin: 15,
                        padding: 10,
                        textAlign: "center",
                        border: "1px solid #ffb75d",
                      }}
                    >
                      <div style={{ width: 780, margin: "0 auto" }}>
                        These image(s) have been submitted and will be removed
                        from this page when our team is reviewing them. In the
                        meantime, you may enter notes, delete or add more
                        images. You will receive an email notification once your
                        claim is processed.
                      </div>
                    </div>
                  </div>

                  <div
                    className="container"
                    style={{
                      maxHeight: 550,
                      overflowY: "auto",
                      marginBottom: 30,
                    }}
                  >
                    <div
                      className="row"
                      style={{ display: this.state.loadedSpinner }}
                    >
                      <div className="col-12">
                        <Spinner />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: this.state.loadedImages }} ref={this.myRef}>
            <QueuedImages
              loaded={this.setLoaded.bind(this)}
              unloaded={this.setUnloaded.bind(this)}
              type={this.props.type}
              images={this.state.queuedImages}
              showModal={this.displayModal}
              employeeId={this.props.employeeId}
              policyId={this.props.policyId}
            />
          </div>
          <button className="btn btn-danger" onClick={this.generateTestClaims}>{this.state.buttonText}</button>
        </div>
      );
    }

    let file;
    let link;
    if (plan.type == "LSA") {
      link = "../eligibleexpenses/LSA";
      file = "LSAClaimForm.pdf";
    } else if (plan.type == "HSA") {
      link = "../eligibleexpenses/HSA";
      file = "HSAClaimForm.pdf";
    }

    plan.label =
      this.state.enableWellness && plan.type === "LSA"
        ? "Wellness Spending Account"
        : plan.label;
    return (
      <>
        <Joyride
          steps={this.state.steps}
          callback={this.handleJoyrideCallback}
          locale={{ last: "Close" }}
          continuous={true}
          hideCloseButton={true}
          run={this.state.run}
          showProgress={true}
          styles={{
            options: {
              primaryColor: "#223e7f",
            },
          }}
        />
        <div className="br-mainpanel black" style={{ maxWidth: 1250 }}>
          {this.renderOpenModal()}
          {this.renderFailedModal()}
          {this.renderPendingModal()}
          <div
            className="br-pagebody no-padding mg-t-0 mg-x-50"
            style={{ maxWidth: 1250 }}
          >
            <div className="br-section-wrapper no-padding">
              <div className="step4">
                <div className="card">
                  <div className="card-header tx-medium tx-uppercase bg-white">
                    Make a Claim: Upload your {plan.label} receipts
                  </div>
                  <div className="card-body" style={{ padding: 10 }}>
                    <div className="row-upload">
                      <div className="col-sm-1 col-md-8 col-lg-8">
                        <div
                          className="br-pagebody mg-t-10"
                          style={{ paddingLeft: 15 }}
                        >
                          <div className="pd-b-80" style={{ maxWidth: 1250 }}>
                            <div
                              style={{ position: "fixed", top: 0, left: -9999 }}
                              id="canvas-container"
                            ></div>
                            <div className="card">
                              <div className="text-center">
                                <Dropzone
                                  onDrop={this.onDrop}
                                  accept="image/png, image/jpg, image/jpeg, application/pdf"
                                  minSize={0}
                                  maxSize={15728640}
                                  maxFiles={10}
                                >
                                  {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragReject,
                                    fileRejections,
                                  }) => {
                                    const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > 15728640;
                                    return (
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div
                                          style={{
                                            height: 160,
                                            paddingTop: 40,
                                          }}
                                        >
                                          <div>
                                            {!isDragActive &&
                                              "Drag and drop your files here"}
                                            {isDragActive &&
                                              !isDragReject &&
                                              "Drag and drop your files here"}
                                            {isDragReject &&
                                              "File type not accepted"}
                                            {isFileTooLarge && (
                                              <div className="text-danger mt-2">
                                                Invalid file size
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              fontSize: 10,
                                              color: "grey",
                                            }}
                                          >
                                            Maximum file upload size: 15MB
                                            Upload up to 10 images at a time.
                                          </div>
                                          <div className="mg-t-15">
                                            <a
                                              className="btn btn-primary btn-sm"
                                              href="#"
                                            >
                                              Browse Files
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Dropzone>
                              </div>
                            </div>

                            {fileToUpload}

                            <div className="mg-t-20 float-left">
                              <button
                                className="btn btn-primary btn-submit"
                                onClick={this.onSubmit}
                                disabled={disableSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                          {/* <div>{documentsBox}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {queuedImageDiv}
        </div>
      </>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }

  generateTestClaims = async (e) => {
    e.preventDefault();
    <Spinner />
    await axios
      .post(`/api/upload/GenerateTestClaim?policyId=${this.props.policyId}&employeeId=${this.props.employeeId}`,
        {},
      )
      .then((response) => {
        toastr.success("Claim Submitted", "Success");
        this.setState({queuedImages: null});
      })
      .catch((error) => {
        toastr.error("Failed to upload claim");
      });
  };
}
