import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';

interface IdsBenefit {
    Id:string;
    Policy_Id:string;
    Advisor2_Id:string;
    Advisor2Split:number;
    Advisor3_Id:string;
    Advisor3Split:number;
};

interface IdsAdvisor2 {
    Id:string;
    Name:string;
    Distro:string;
};

interface IdsAdvisor3 {
    Id:string;
    Name:string;
    Distro:string;
};

export default KTMScreen({name:"planadmin.product.edit-commission-splits"}, class extends React.Component<{policyBenefit_id:string}> {

    data:{
        dsBenefit:IdsBenefit;
        dsAdvisor2:IdsAdvisor2;
        dsAdvisor3:IdsAdvisor3;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenefit";
        await KTMApi.start(screen,{
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.title = "Edit product multi-advisor split";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit product multi-advisor split"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display field={field("Advisor2_Id")}/>
                    <Display field={field("Advisor2Split")}/>
                </Line>
                <Line>
                    <Display field={field("Advisor3_Id")}/>
                    <Display field={field("Advisor3Split")}/>
                </Line>
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsBenefit.Advisor2_Id":this.data.dsBenefit.Advisor2_Id,
            "@dsBenefit.Advisor2Split":this.data.dsBenefit.Advisor2Split,
            "@dsBenefit.Advisor3_Id":this.data.dsBenefit.Advisor3_Id,
            "@dsBenefit.Advisor3Split":this.data.dsBenefit.Advisor3Split,
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.close({"continue":true});
    }

});
