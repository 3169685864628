import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import CustomToolTip from "components/CustomToolTip";
import "../../styles/Employee.css";
import CustomIntegerInput from "components/CustomIntegerInput";

interface IdsEmployee {
  Id: string;
  Name: string;
  FirstName: string;
  LastName: string;
  TerminationDate: string;
  AccessAfterTerminationDays: number;
  BenefitEndDate: number;
  TerminationNote: string;
}

export default KTMScreen(
  { name: "planadmin.employee.terminate" },
  class extends React.Component<any,any> {
    data: {
      dsEmployee: IdsEmployee;
    };

    constructor(props: any) {
      super(props);
      this.state = {
        accessDays : this.props.access_days || 0
      }
      this.handleInputChange = this.handleInputChange.bind(this);
    }

    render() {
      return renderScreen(this);
    }

    handleInputChange = (value) => {
      this.setState({
        accessDays : value
      })
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsEmployee";
      await KTMApi.start(screen, {
        "@employee_id": this.props.employee_id,
      });
      screen.title = "Edit employee status";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout dialogSize="medium">
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit employee status" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      let disabled = false;

      let employeeSubmitButton = null;
      if ((this.data.dsEmployee.BenefitEndDate === null ||
        this.data.dsEmployee.BenefitEndDate === undefined)
      ) {
        disabled = true;
      }
      employeeSubmitButton = (
        <Button
          action={action(this.submit)}
          disable={disabled}
          label="Save"
          size="large"
          style="primary"
        />
      );

      return <Dialog.Footer>{employeeSubmitButton}</Dialog.Footer>;
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display
              field={field("BenefitEndDate")}
              toolTip={
                <CustomToolTip
                  header="Benefit End Date"
                  bodyContent="The last day you want the employee to have coverage. Only expenses incurred prior to this date will be processed."
                />
              }
            />
            <CustomIntegerInput
              label="Access After Termination (days)"
              value={this.state.accessDays}
              onChange={this.handleInputChange}
              toolTip={
                <CustomToolTip
                    header="Access After Termination (days)"
                    bodyContent="How long the employee can access their account to log in, make claims, and see their claim history."
                  />
              }
            />
          </Line>
          <Line>
            <Display field={field("TerminationNote")} />
          </Line>
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          employeeId: this.props.employee_id,
          terminationDate: this.data.dsEmployee.TerminationDate,
          benefitEndDate: this.data.dsEmployee.BenefitEndDate,
          accessAfterTermination: this.state.accessDays,
          terminationNote: this.data.dsEmployee.TerminationNote,
        }),
      };

      await fetch("/api/Terminate", requestOptions);
      screen.close({ continue: true });
    }
  }
);