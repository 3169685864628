import DatePicker from "react-date-picker"

export const CustomDateRangePicker = ({ fromDate, toDate, setFromDate, setToDate }) => {
    return (
        <div className="date-range-picker">
            <div style={{marginRight:"20px"}}>
                <label style={{fontSize:"14px", marginRight:"10px"}}>From:</label>
                <DatePicker onChange={(e) => {setFromDate(e)}} value={new Date(fromDate)} format={"y-MM-dd"} clearIcon={null}/>
            </div>
            <div>
                <label style={{fontSize:"14px", marginRight:"10px"}}>To:</label>
                <DatePicker onChange={(e) => {setToDate(e)}} value={new Date(toDate)} format={"y-MM-dd"} clearIcon={null}/>
            </div>
        </div>
    )
}