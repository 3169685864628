import { Application } from "./Application";
import { IScreenLaunchParams } from "./types";

export interface IParsedUrl {
    app:string;
    domain:string;
    screen:string;
    search:{[name:string]:string};
    branch:string;
}


export class Url {

    static parse(pathname:string,search:string): IParsedUrl {
        let domain:string;
        let screen:string;
        let app:string;
        if (pathname){
            pathname = pathname.substr(1);
            let segments = pathname.split('/');
        
            if (segments.length > 0){
                let index = 0;
                app = decodeURIComponent(segments[index]);
                index++;
                let seg = decodeURIComponent(segments[index]);
                if (seg && seg[0] == "@"){
                    domain = seg.substr(1);
                    if (segments.length > 1){
                        screen = decodeURIComponent(segments[index + 1]);
                    }
                }
                else if (segments.length > 0) {
                    screen = decodeURIComponent(segments[index]);
                }
            }
        }

        let searchValues = Url.parseSearch(search);
        let params = {};
        if (search) {
            for (var key in searchValues) {
                if (key[0] != "_"){
                    let v = search[key];
                    params[key] = v;
                }
            }
        }
        let branch = searchValues["_branch"];
        return {
            app,
            domain,
            screen,
            search:searchValues,
            branch
        }
    }
    
    static updateSearch(location:Location,patch:any):string{
        let pathname = location.pathname;
        let search = Url.parseSearch(location.search);
        search = {...search,...patch};
        return pathname + Url.stringifySearch(search);
    }
    
    static parseSearch(search: string): any {

        if (!search) {
            return {};
        }
        if (search[0] == "?") {
            search = search.substr(1);
        }
        var parameters = search.split('&');
        var obj = {};
        for (var i = 0; i < parameters.length; i++) {
            var parameter = parameters[i].split('=');
            var pName = decodeURIComponent(parameter[0]);
            obj[pName] = decodeURIComponent(parameter[1]);
        }
        return obj;

    }

    static stringify(app:Application,launchParams:IScreenLaunchParams):string {
        let screenName = launchParams.screen;
        let formPath:string;

        let screenPath = screenName;

        let prefix = '/';
        if (app.appName){
            prefix = prefix + app.appName + "/";
        }
        if (app.domain){
            prefix = prefix + "@" + app.domain + "/";
        }
        let search = Url.stringifySearch(launchParams.props);
        if (app.branch){
            if (search){
                search += "&_branch=" + app.branch;
            }
            else {
                search = "?_branch=" + app.branch;
            }
        }
        var url = prefix + screenPath + search;
        return url;
    }

    static stringifySearch(searchObj: any): string {
        var segments: string[] = [];

        if (searchObj) {
            for (var key in searchObj) {
                if (key[0] != "_"){
                    let value = searchObj[key];
                    if (Url.isPrimitive(value)) {
                        segments.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
                    }
                }
            }

        }

        if (segments.length) {
            return "?" + segments.join('&');
        }
        return "";
    }

    static stringifyRedirect(domain:string,form?:string,search?:string):string {
        let url =  "/@" + encodeURIComponent(domain);
        if (form){
            url += "/" + encodeURIComponent(form);
        }

        if (search){
            url += "/" + search;
        }
        return url;
    }

    static isPrimitive(value: any): boolean {
        if (value === undefined || value === null) return false;
        if (typeof (value) == "string" || typeof (value) == "number" || typeof (value) == "boolean") {
            return true;
        }
        return (value != Object(value));
    }
}