import axios from "axios";
import React from "react";
import { Card } from "react-bootstrap";
import toastr from "toastr";
import { CustomLoading } from "components/CustomLoading";
import "../../styles/Reports.css";
import HsaBenefitUtilizationTable from "./HsaBenefitUtilizationTable";
import LsaBenefitUtilizationTable from "./LsaBenefitUtilizationTable";
import HsaDisbursementReportTable from "./HsaDisbursementReportTable";
import LsaDisbursementReportTable from "./LsaDisbursementReportTable";
import DivisionsReportTable from "./DivisionReportTable";
import CostCentresReportTable from "./CostCentresReportTable";
import LifestyleTaxableReportTable from "./LifestyleTaxableReportTable";
import FundingActivityAndTransactionsTable from "./FundingActivityAndTransactionsTable";
import MonthlySummaryTable from "./MonthlySummaryTable";
import FlexSelectionReportTable from "./FlexSelectionReport";
import MonthlyInvoice from "./MonthlyInvoice";
import HsaCustomCategoriesReport from "./HsaCustomCategoriesReport";
import LsaCustomCategoriesReport from "./LsaCustomCategoriesReport";
import CustomCatClaimSummaryReport from "./CustomCatClaimSummaryReport";
import CustomCatEmployeeUsageReport from "./CustomCatEmployeeUsageReport";
import TeladocReportTable from "./TeladocReportTable";
import TeladocFundingActivityTable from "./TeladocFundingActivityTable";
import CorehealthReportTable from "./CorehealthReportTable";
import CorehealthFundingActivityTable from "./CorehealthFundingActivityTable";

export default class Reports extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            divisions: [],
            costCentres: [],
            benefitYearOptions: [],
            currentListItem: "Funding Activity and Transactions",
            isMigratedCompany: false,
            pendingandactivebenefitYearOptions: [],
            isSystemAdmin: false
        };

        this.setCurrentItem = this.setCurrentItem.bind(this);
        this.fetchDivisionsData = this.fetchDivisionsData.bind(this);
        this.fetchCostCentresData = this.fetchCostCentresData.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.getBenefitYearOptions();
        this.getPendingAndActiveBenefitYearOptions();

        if (this.props.showDivisions) {
            this.fetchDivisionsData();
        }
        if (this.props.showCostCentres) {
            this.fetchCostCentresData();
        }
        this.checkIsMigratedCompany();
        this.checkIsSystemAdmin();

        if (this.props.teladocOnly) {
            this.setState({ currentListItem: 'Teladoc Invoice' });
        }
    }

    checkIsMigratedCompany() {
        axios.get("/api/PlanAdmin/ismigratedcompany?policyId=" + this.props.policyId)
            .then(res => {
                if (res.data.success) {
                    this.setState({ 
                        isMigratedCompany: res.data.isMigratedCompany,
                        loading: false
                    });
                } else {
                    toastr.error(res.data.message, "Error");
                }
            }
            )
            .catch(error => {
                toastr.error(error, "Error");
            });
    }

    getBenefitYearOptions = () => {
        axios.get("/api/PlanAdmin/benefityearsoptions?policyId=" + this.props.policyId)
            .then(res => {
                if(res.data.success) {
                    this.setState({
                        benefitYearOptions: res.data.benYearOptions,
                    });
                } else {
                    toastr.error(res.data.message, "Error");
                }
            })
            .catch(err => {
                toastr.error(err, "Error");
            });
    }

    getPendingAndActiveBenefitYearOptions = () => {
        axios.get("/api/Reports/pendingandactivebenefityearsoptions?policyId=" + this.props.policyId)
            .then(res => {
                if(res.data.success) {
                    this.setState({
                        pendingandactivebenefitYearOptions: res.data.benYearOptions,
                    });
                } else {
                    toastr.error(res.data.message, "Error");
                }
            })
            .catch(err => {
                toastr.error(err, "Error");
            });
    }

    setCurrentItem = (item) => {
        this.setState({currentListItem: item});
    }

    fetchDivisionsData = () => {
        axios.get(`/api/PlanAdmin/divisions?policyId=${this.props.policyId}`)
            .then(res => {
                if (res.data.success) {
                    this.setState({ 
                        divisions: res.data.divisions,
                    });
                } else {
                    toastr.error(res.data.message, "Error");
                }
            })
            .catch(error => {
                toastr.error(error, "Error");
            });
    }

    fetchCostCentresData = () => {
        axios.get(`/api/PlanAdmin/costcentres?policyId=${this.props.policyId}`)
            .then(res => {
                if (res.data.success) {
                    this.setState({ 
                        costCentres: res.data.costCentres,
                    });
                } else {
                    toastr.error(res.data.message, "Error");
                }
            })
            .catch(error => {
                toastr.error(error, "Error");
            });
    }

    checkIsSystemAdmin = () => {
        axios.get(`/api/PlanAdmin/issystemadmin`)
            .then(res => {
                if (res.data.success) {
                    this.setState({ 
                        isSystemAdmin: res.data.isSystemAdmin,
                    });
                } else {
                    toastr.error(res.data.message, "Error");
                }
        })
        .catch(error => {
            toastr.error(error, "Error");
        });
    };

    render() {
        let contents = null;
        if (this.state.loading) {
            contents = (<CustomLoading />);
        } else
        {
            let listGroup = [];
            if(!this.props.teladocOnly) {
                listGroup.push("Funding Activity and Transactions");
                listGroup.push("Monthly Statement / Invoice");
                listGroup.push("Claim Summary");
            }
            if(this.props.hasTeladoc) {
                listGroup.push("Teladoc Funding Activity");
                listGroup.push("Teladoc Invoice");
            }
            if(this.props.hasCorehealth) {
                listGroup.push("Corehealth Funding Activity");
                listGroup.push("Corehealth Invoice");
            }
            if (this.props.showHSAReports) {
                listGroup.push("HSA Benefit Utilization");
                
                if(this.props.showHSACustomCat)
                {
                    listGroup.push("HSA Enhanced Benefits Report");
                }
            }
            if (this.props.showLSAReports) {
                listGroup.push("LSA Benefit Utilization");
                
                if(this.props.showLSACustomCat)
                {
                    listGroup.push("LSA Enhanced Benefits Report");
                }
            }
            if(this.props.showHSACustomCat)
            {
                listGroup.push("Enhanced Benefits Claim Summary Report");
            }
            if (this.props.showHSAReports) {
                listGroup.push("HSA Disbursement Report");
            }
            if (this.props.showLSAReports) {
                listGroup.push("LSA Disbursement Report");
            }
            if (this.props.showLSAReports) {
                listGroup.push("Lifestyle Taxable Report");
            }
            if (this.props.showDivisions) {
                listGroup.push("Divisions Report");
            }
            if (this.props.showCostCentres) {
                listGroup.push("Cost Centres Report");
            }
            if (this.props.showFlexReports) {
                listGroup.push("Flex Selection Report");
            }
            if(this.state.isSystemAdmin && (this.props.showHSACustomCat || this.props.showLSACustomCat))
            {
                listGroup.push("Enhanced Benefits Employee Usage Report (only visible to Ops users)");
            }

            let tableContents = null;
            if (this.state.currentListItem === "HSA Benefit Utilization") {
                tableContents = <HsaBenefitUtilizationTable 
                                    benefitYearOptions={this.state.benefitYearOptions} 
                                    policyId={this.props.policyId}
                                    isMigratedCompany={this.state.isMigratedCompany}
                                    showCostCentres={this.props.showCostCentres}
                                    costCentres={this.state.costCentres}
                                    showDivisions={this.props.showDivisions}
                                    divisions={this.state.divisions}
                                />

            } else if (this.state.currentListItem === "LSA Benefit Utilization") {
                tableContents = <LsaBenefitUtilizationTable 
                                    benefitYearOptions={this.state.benefitYearOptions} 
                                    policyId={this.props.policyId}
                                    isMigratedCompany={this.state.isMigratedCompany}
                                    showCostCentres={this.props.showCostCentres}
                                    costCentres={this.state.costCentres}
                                    showDivisions={this.props.showDivisions}
                                    divisions={this.state.divisions}
                                />

            } else if (this.state.currentListItem === "HSA Disbursement Report") {
                tableContents = <HsaDisbursementReportTable
                                    policyId={this.props.policyId}
                                />

            } else if (this.state.currentListItem === "LSA Disbursement Report") {
                tableContents = <LsaDisbursementReportTable 
                                    policyId={this.props.policyId}
                                />

            } else if (this.state.currentListItem === "Divisions Report") {
                tableContents = <DivisionsReportTable 
                                    policyId={this.props.policyId} 
                                    divisions={this.state.divisions}
                                />

            } else if (this.state.currentListItem === "Cost Centres Report") {
                tableContents = <CostCentresReportTable 
                                    policyId={this.props.policyId} 
                                    costCentres={this.state.costCentres}
                                />

            } else if (this.state.currentListItem === "Lifestyle Taxable Report") {
                tableContents = <LifestyleTaxableReportTable policyId={this.props.policyId}/>

            } else if (this.state.currentListItem === "Funding Activity and Transactions") {
                tableContents = <FundingActivityAndTransactionsTable policyId={this.props.policyId}/>

            } else if (this.state.currentListItem === "Claim Summary") {
                tableContents = <MonthlySummaryTable policyId={this.props.policyId}/>

            } else if (this.state.currentListItem === "Flex Selection Report") {
                tableContents = <FlexSelectionReportTable 
                                    benefitYearOptions={this.state.pendingandactivebenefitYearOptions} 
                                    policyId={this.props.policyId}
                                />
                                
            } else if (this.state.currentListItem === "Monthly Statement / Invoice") {
                tableContents = <MonthlyInvoice 
                                    showLSAReports={this.props.showLSAReports} 
                                    showHSAReports={this.props.showHSAReports} 
                                    policyId={this.props.policyId} 
                                />
            } else if (this.state.currentListItem === "HSA Enhanced Benefits Report") {
                tableContents = <HsaCustomCategoriesReport
                                    benefitYearOptions={this.state.benefitYearOptions} 
                                    policyId={this.props.policyId}
                                />
            } else if (this.state.currentListItem === "LSA Enhanced Benefits Report") {
                tableContents = <LsaCustomCategoriesReport
                                    benefitYearOptions={this.state.benefitYearOptions} 
                                    policyId={this.props.policyId}
                                />
            } else if (this.state.currentListItem === "Enhanced Benefits Claim Summary Report") {
                tableContents = <CustomCatClaimSummaryReport
                                    benefitYearOptions={this.state.benefitYearOptions} 
                                    policyId={this.props.policyId}
                                />
            } else if (this.state.currentListItem === "Enhanced Benefits Employee Usage Report (only visible to Ops users)") {
                tableContents = <CustomCatEmployeeUsageReport
                    policyId={this.props.policyId}
                    benefitYearOptions={this.state.benefitYearOptions}
                />
            }
            else if (this.state.currentListItem === "Teladoc Funding Activity") {
                tableContents =  <TeladocFundingActivityTable
                    policyId={this.props.policyId}
                />
            }
            else if (this.state.currentListItem === "Corehealth Funding Activity") {
                tableContents =  <CorehealthFundingActivityTable
                    policyId={this.props.policyId}
                />
            }
            else if (this.state.currentListItem === "Corehealth Invoice") {
                tableContents =  <CorehealthReportTable
                    policyId={this.props.policyId}
                />
            }
            else if (this.state.currentListItem === "Teladoc Invoice") {
                    tableContents =  <TeladocReportTable
                        policyId={this.props.policyId}
                    />
            }

            contents = (
                <div className="ktm-reports" style={{overflow: "hidden"}}>
                    <ul className="list-group" style={{width:"15%", marginRight:"10px", maxHeight:"700px", overflowY: "scroll"}}>
                        {listGroup.map((item, index) => (
                            <li key={index} 
                                className="list-group-item" 
                                style={this.state.currentListItem==item? {backgroundColor:"#848f99",color:"white", cursor:"pointer"} :{cursor:"pointer"}}
                                onClick={() => this.setCurrentItem(item)}
                            >
                               {item}
                            </li>
                         ))
                        }
                    </ul>
                    <Card className="ktm-reports-content">{tableContents}</Card>
                </div>
            );
        }
        
        return (
            <>
            {this.state.isMigratedCompany &&
                <span className="ktm-reports-message">
                    Your account information has been migrated to Version 3 of our platform. To see your previous year reports please contact <a style={{"color":"#0d6efd"}} href="mailto:support@nationalhealthclaim.com">support@nationalhealthclaim.com</a>.
                </span>
            }
            {contents}
            </>
        );
    }
}