import { CustomLoading } from "components/CustomLoading";
import React from "react";
import GeneralSettings from "./GeneralSettings";
import EmployeeSettings from "./EmployeeSettings";
import FundingSettings from "./FundingSettings";
import BrandingSettings from "./BrandingSetting";
import FlexTemplates from "./FlexTemplates";
import FlexDatesSettings from "./FlexDatesSettings"
import axios from "axios";
import EmployeeBulkEdits from "./EmployeeBulkEdits";

export default class Settings extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentListItem: "General",
            showFlexDates: false,
        };
    }

    componentDidMount() {
        this.checkFlexDetails();
    }

    setCurrentItem = (item) => {
        this.setState({currentListItem: item});
    }

    checkFlexDetails = async () => {
        await axios.get('/api/Settings/checkflexdetails', {
            params: {
                policyId : this.props.policyId
            }
        }).then((response) => {
            if (response.data.success) {
                this.setState({
                    showFlexDates: response.data.showFlexDates,
                    hasFlex: response.data.hasFlex,
                    loading: false
                });
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }

    render() {
        let contents = null;
        if (this.state.loading) {
            contents = (<CustomLoading />);
        } else
        {
            let listGroup = [];
            listGroup.push("General");
            if (this.props.view === "ops") {
                listGroup.push("Funding");
                listGroup.push("Employee");
                listGroup.push("Employee Terminations");
                listGroup.push("Branding");
            }
            if (this.state.hasFlex) {
                listGroup.push("Flex Templates");

                if (this.state.showFlexDates) {
                    listGroup.push("Flex Employees");
                }
            }

            let tabContent = null;
            if (this.state.currentListItem === "General") {
                tabContent = (<GeneralSettings policyId = {this.props.policyId} />);
            } else if (this.state.currentListItem === "Funding") {
                tabContent = (<FundingSettings policyId = {this.props.policyId} />);
            } else if (this.state.currentListItem === "Employee") {
                tabContent = (<EmployeeSettings policyId = {this.props.policyId} />);
            } else if (this.state.currentListItem === "Branding") {
                tabContent = (<BrandingSettings policyId = {this.props.policyId} />);
            } else if (this.state.currentListItem === "Flex Templates") {
                tabContent = (<FlexTemplates policyId={this.props.policyId} />);
            } else if (this.state.currentListItem === "Flex Employees") {
                tabContent = (<FlexDatesSettings policyId={this.props.policyId} />);
            } else if (this.state.currentListItem === "Employee Terminations") {
                tabContent = (<EmployeeBulkEdits policyId={this.props.policyId} />);
            }

            contents = (
                <div className="ktm-reports">
                    <ul className="list-group" style={{width:"13%", marginRight:"10px"}}>
                        {listGroup.map((item, index) => (
                            <li key={index} 
                                className="list-group-item" 
                                style={this.state.currentListItem==item? {backgroundColor:"#848f99",color:"white"} :null}
                                onClick={() => this.setCurrentItem(item)}
                            >
                               {item}
                            </li>
                         ))
                        }
                    </ul>
                    {tabContent}
                </div>
            );
        }

        return (<>{contents}</>);
    }
}
    