import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Dialog, Button, Display, Line, Card } from "ktm-ui";

interface IdsPolicyHolder {
  Id: string;
  Name: string;
  DBA: string;
  MailingStreet1: string;
  MailingStreet2: string;
  MailingCity: string;
  MailingProv: string;
  MailingCountry: string;
  MailingPostalCode: string;
  Phone: string;
  ContactPerson: string;
  ContactEmail: string;
  LegalForm: string;
}

export default KTMScreen(
  { name: "planadmin.policy.edit-policy-holder" },
  class extends React.Component<{ policyholder_id: string }> {
    data: {
      dsPolicyHolder: IdsPolicyHolder;
    };
    
    state = {
        phone: null,
    }

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsPolicyHolder";
      await KTMApi.start(screen, {
        "@policyholder_id": this.props.policyholder_id,
      });
      screen.title = "Edit account holder";
      this.setState({phone: this.data.dsPolicyHolder.Phone});
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.details)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit account holder" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }
    
    formattedInput(input){
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);
    
      let formatted = "";
      if (input.length > 6) {
          formatted = `(${areaCode})${middle}-${last}`;
      } else if (input.length > 3) {
          formatted = `(${areaCode})${middle}`;
      } else if (input.length > 0) {
          formatted = `(${areaCode}`;
      }
      return formatted;
    }
    
    handleChange(e) {
      let input = e.target.value.replace(/\D/g, '');
      e.target.value =  this.formattedInput(input);
      this.setState({companyPhone: e.target.value});
    }

    details({ field, draw, action }: RenderEvent) {
      return (
          <Card>
              <Display required field={field("Name")}/>
              <Display field={field("DBA")}/>
              <Display required field={field("MailingStreet1")}/>
              <Display field={field("MailingStreet2")}/>
              <Line>
                  <Display required field={field("MailingCity")}/>
                  <Display required field={field("MailingProv")}/>
              </Line>
              <Line>
                  <Display required field={field("MailingCountry")}/>
                  <Display required field={field("MailingPostalCode")}/>
              </Line>
              <div>
                  <label>Phone</label>
                  <input className="form-control" id="phone" name="phone"
                         value={this.state.phone}
                         onChange={(e) => this.handleChange(e)} placeholder="(000)000-0000" maxLength={14}/>
              </div>
              <Display required field={field("LegalForm")}/>
          </Card>
      );
    }

      async submit({screen}: ActionEvent) {
          this.data.dsPolicyHolder.Phone = this.state.phone;
          await KTMApi.action(screen, "submit", {
              "@dsPolicyHolder.Name": this.data.dsPolicyHolder.Name,
              "@dsPolicyHolder.DBA": this.data.dsPolicyHolder.DBA,
              "@dsPolicyHolder.MailingStreet1":
          this.data.dsPolicyHolder.MailingStreet1,
        "@dsPolicyHolder.MailingStreet2":
          this.data.dsPolicyHolder.MailingStreet2,
        "@dsPolicyHolder.MailingCity": this.data.dsPolicyHolder.MailingCity,
        "@dsPolicyHolder.MailingProv": this.data.dsPolicyHolder.MailingProv,
        "@dsPolicyHolder.MailingCountry":
          this.data.dsPolicyHolder.MailingCountry,
        "@dsPolicyHolder.MailingPostalCode":
          this.data.dsPolicyHolder.MailingPostalCode,
        "@dsPolicyHolder.Phone": this.data.dsPolicyHolder.Phone,
        "@dsPolicyHolder.ContactPerson": this.data.dsPolicyHolder.ContactPerson,
        "@dsPolicyHolder.ContactEmail": this.data.dsPolicyHolder.ContactEmail,
        "@dsPolicyHolder.LegalForm": this.data.dsPolicyHolder.LegalForm,
        "@policyholder_id": this.props.policyholder_id,
      });
      screen.close({ continue: true });
    }
  }
);
