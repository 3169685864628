export class FileDownloadManager {
    static downloadFile(filename:string,content:any,autoOpen:boolean){
        let contentType:string;
        let i = filename.lastIndexOf('.');
        let extension:string;
        if (i != -1){
          extension = filename.substr(i + 1);
        }
        if (extension == "pdf"){
          contentType = "application/pdf";
          content = FileDownloadManager.decodeBase64(content);
        }
        else if (extension == "csv"){
           contentType = "text/csv";
        }
        else if (extension == "json"){
          contentType = "text/json";
        }
        else {
          contentType = "text/plain";
        }
        
        let blob = new Blob([content],{type:contentType});
        FileDownloadManager.downloadBlob(blob,filename,autoOpen);
    }

   private static downloadBlob(blob, filename,autoOpen:boolean) {

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        
        a.href = url;
        if (autoOpen){
          a.target = "_blank";
        }
        else {
          a.download = filename || 'download';
        }
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        
        a.addEventListener('click', clickHandler, false);
        a.click();
    
        return a;
    }

    private static decodeBase64(base64):any {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes;
    };

}