import { Canvas } from "./Canvas";
import { Focus } from "./Focus";
import { FieldFormatter } from "./FieldFormatter";
import { FieldError, RecordErrorSet } from "./RecordErrorSet";
import { IFieldMetaProps, IFieldOption, RecordMeta } from "./RecordMeta";
import { IFieldDefinition, IFieldLookup, Schema } from "./Schema";
import { FieldType, IEventScope } from "./types";

export class FieldRef {
    public name: string;
    public record:any;
    public meta: RecordMeta;
    public canvas:Canvas;
    public scope:IEventScope;
    public def:IFieldDefinition;
    public lookupLabelRef:FieldRef;

    constructor(canvas:Canvas,scope:IEventScope,data:any,name:string){
        this.canvas = canvas;
        this.scope = scope;
        if (data){
            this.record = data;
            this.meta = data.$$meta;
            if (this.meta){
                let def = Schema.getFieldFromMeta(this.meta,name);
                if (def){
                    this.def = def;
                    name = def.name;
                    if (def.lookupLabel){
                        this.lookupLabelRef = canvas.getFieldRef(def.lookupLabel,scope);
                    }
                }
            }
        }
        this.name = name;
    }
    
    get fieldMeta():IFieldMetaProps {
        if (!this.meta || !this.meta.metaProps) return null;
        var meta = this.meta.metaProps;
        if (!meta) return null;
        return meta[this.name];
    }

    get value(): any {
        if (!this.record) return null;
        if (this.name[0] == '@'){
            return this.record[this.name.substr(1)];
        }
        return this.record[this.name];
    }

    async setValue(value:any){
        await this.canvas.setValue(this,value);
    }

    async setValueNoUpdate(value:any){
        await this.canvas.setRecordValueNoUpdate(this,value);
    }

    get shouldFocus():boolean {
        if (this.readonly || this.disabled) return false;
        return Focus.shouldFocus(this.canvas,this);
    }


    get disabled(): boolean {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let value = fieldMeta.disabled;
            if (value !== undefined) return value;
        }
    }

    get disabledHelp(): string {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            return fieldMeta.disabledHelp;
        }
    }

    get style():string {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
             return fieldMeta.style;
        }
    }
    
    get readonly():boolean {
        if (this.meta && this.meta.readonly) return true;
        if (this.def && this.def.readonly) return true;
        return false;
    }
    
    get hidden(): boolean {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let value = fieldMeta.hidden;
            if (value !== undefined) return value;
        }
    }

    get required(): boolean {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let value = fieldMeta.required;
            if (value !== undefined) return value;
        }
        if (this.def){
            return this.def.required;
        }
    }

    get options(): IFieldOption[] {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let value = fieldMeta.options;
            if (value !== undefined) return value;
        }
        if (this.def){
            return this.def.options;
        }
    }

    get formattedValue(): string {
        var value = this.value;
        var options = this.options;
    
        if (options) {
            return this.getOptionLabel(options,value);
        }
        else {
            var format = this.format;
            let formatted = FieldFormatter.format(value, this.type, format);
            return formatted;
        }
        return value;
    }

    private getOptionLabel(options:IFieldOption[],value:any):string {
        if (!options) return value;
        let compare:string;
        if (value === null || value === undefined){
            compare = "";
        }
        else if (value.toUpperCase){
            compare = value.toUpperCase();
        }
        else {
            compare = value.toString();
        }
        if (compare){
            for(let i =0 ; i < options.length;i++){
                let opt = options[i];
                if (opt.value){
                    if (opt.value.toUpperCase){
                        if (opt.value.toUpperCase() == compare) return opt.label;
                    }
                    else {
                        if (opt.value == compare) return opt.label;
                    }
                }
            }
            return value;
        }
        // looking for option without value;
        for (var i = 0; i < options.length; i++) {
            let opt = options[i];
            if (!opt.value) return opt.label;
        }
        return value;
    }

    get error(): FieldError {
        var error = RecordErrorSet.get(this.meta);
        if (!error || !error.fields) return null;
        
        return error.fields[this.name];
    }

    clearError() {
        var error = RecordErrorSet.get(this.meta);
        if (error){
            error.fields[this.name] = null;
        }
    }

    handleEnterKey(){
        this.canvas.handleEnterKey(this.scope);
    }

    get label():string {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let metaValue = fieldMeta.label;
            if (metaValue !== undefined) return metaValue;
        }
        if (this.def){
            return this.def.label;
        }
    }

    get placeholder():string {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let metaValue = fieldMeta.placeholder;
            if (metaValue !== undefined) return metaValue;
        }
        if (this.def){
            return this.def.placeholder;
        }
    }

    get format():string {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let metaValue = fieldMeta.format;
            if (metaValue !== undefined) return metaValue;
        }
        if (this.def){
            return this.def.format;
        }
    }

    get help():string {
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            let metaValue = fieldMeta.help;
            if (metaValue !== undefined) return metaValue;
        }
        if (this.def){
            return this.def.help;
        }
    }

    get lookup():IFieldLookup {
        if (this.def) {
            return this.def.lookup;
        }
    }

    get type():FieldType {
        if (this.def){
            return this.def.type;
        }
    }

   

    get lookupLabel():string {
        if (this.lookupLabelRef){
            return this.lookupLabelRef.formattedValue;
        }
        let fieldMeta = this.fieldMeta;
        if (fieldMeta){
            return fieldMeta.lookupLabel || "";
        }
        return "";
    }

    set lookupLabel(value:string){
        if (this.lookupLabelRef){
            this.canvas.setRecordValueNoUpdate(this.lookupLabelRef,value);
        }
        else if (this.meta){
           RecordMeta.setLookupLabel(this.meta,this.name,value);
        }
    }
   

}