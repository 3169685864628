import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Column,Panel,Card} from 'ktm-ui';

interface IdsBenCat {
    Id:string;
    HSASingleLimit:number;
    HSACoupleLimit:number;
    HSAFamilyLimit:number;
    HSASinglePercent:number;
    HSACouplePercent:number;
    HSAFamilyPercent:number;
    CreditCalcMethod:string;
};

export default KTMScreen({name:"planadmin.ben-limit.add-hsa"}, class extends React.Component<{limit_id:string}> {

    data:{
        dsBenCat:IdsBenCat;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen,{
            "@limit_id":this.props.limit_id,
        });
        screen.title = "Add HSA benefit";
        await screen.triggerAction(this.set_limit_visibility);
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add HSA benefit"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    limits({field,draw,action}:RenderEvent){
        return (
            <>
                <Line>
                    <Display required field={field("HSASingleLimit")}/>
                    <Display required field={field("HSACoupleLimit")}/>
                    <Display required field={field("HSAFamilyLimit")}/>
                </Line>
            </>
        );
    }

    percentages({field,draw,action}:RenderEvent){
        return (
            <>
                <Line>
                    <Display required field={field("HSASinglePercent")}/>
                    <Display required field={field("HSACouplePercent")}/>
                    <Display required field={field("HSAFamilyPercent")}/>
                </Line>
            </>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                {draw(this.limits)}
                {draw(this.percentages)}
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        if (this.data.dsBenCat.HSASingleLimit == null)
            this.data.dsBenCat.HSASingleLimit = 0;
        if (this.data.dsBenCat.HSACoupleLimit == null)
            this.data.dsBenCat.HSACoupleLimit = 0;
        if (this.data.dsBenCat.HSAFamilyLimit == null)
            this.data.dsBenCat.HSAFamilyLimit = 0;
        if (this.data.dsBenCat.HSASinglePercent == null)
            this.data.dsBenCat.HSASinglePercent = 0;
        if (this.data.dsBenCat.HSACouplePercent == null)
            this.data.dsBenCat.HSACouplePercent = 0;
        if (this.data.dsBenCat.HSAFamilyPercent == null)
            this.data.dsBenCat.HSAFamilyPercent = 0;

        await KTMApi.action(screen,"submit",{
            "@dsBenCat.HSASingleLimit":this.data.dsBenCat.HSASingleLimit,
            "@dsBenCat.HSACoupleLimit":this.data.dsBenCat.HSACoupleLimit,
            "@dsBenCat.HSAFamilyLimit":this.data.dsBenCat.HSAFamilyLimit,
            "@dsBenCat.HSASinglePercent":this.data.dsBenCat.HSASinglePercent,
            "@dsBenCat.HSACouplePercent":this.data.dsBenCat.HSACouplePercent,
            "@dsBenCat.HSAFamilyPercent":this.data.dsBenCat.HSAFamilyPercent,
            "@limit_id":this.props.limit_id,
        });
        screen.close({"continue":true});
    }

    async set_limit_visibility({screen}:ActionEvent){
        screen.hide(this.limits);
        screen.hide(this.percentages);
        if (screen.getValue("@dsBenCat.CreditCalcMethod") == 'SALARY'){
            screen.unhide(this.percentages);
        }
        else {
            screen.unhide(this.limits);
        } 
    }

});
