import React from "react";
import axios from "axios";
import toastr from "toastr";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

export default class EditDependentModal extends React.Component<any> {
  state = {
    id: "",
    employee_Id: "",
    firstName: "",
    lastName: "",
    maidenName: "",
    nickname: "",
    dateOfBirth: "",
    relation: "",
    errors: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      relation: "",
    },
    buttonDisabled: true,
  };

  constructor(props) {
    super(props);
    this.handleDependentChange = this.handleDependentChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.dependent.id,
      firstName: nextProps.dependent.firstName,
      lastName: nextProps.dependent.lastName,
      maidenName: nextProps.dependent.maidenName,
      nickname: nextProps.dependent.nickname,
      dateOfBirth: moment(nextProps.dependent.dateOfBirth)
        .utc()
        .format("YYYY-MM-DD"),
      relation: nextProps.dependent.relation,
    });
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  handleDependentChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);

    let errors = this.state.errors;
    let buttonDisabled = this.state.buttonDisabled;
    switch (event.target.name) {
      case "firstName":
        errors.firstName =
          event.target.value.length === 0 ? "First Name is required" : "";
        break;
      case "lastName":
        errors.lastName =
          event.target.value.length === 0 ? "Last Name is required" : "";
        break;
      case "dateOfBirth":
        errors.dateOfBirth =
          event.target.value.length === 0 ? "Date of Birth is required" : "";
        break;
      case "relation":
        errors.relation =
          event.target.value.length === 0 ? "Relation is required" : "";
        break;
      default:
        break;
    }
    if (
      this.state.firstName &&
      this.state.firstName.length > 0 &&
      this.state.lastName &&
      this.state.lastName.length > 0 &&
      this.state.dateOfBirth
    ) {
      buttonDisabled = false;
    } else {
      buttonDisabled = true;
    }
    this.setState({ change, errors, buttonDisabled });
  };

  handleDependentSave = async () => {
    let dependentModel = {
      EmployeeId: this.props.dependent.employee_Id,
      Id: this.state.id,
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      MaidenName: this.state.maidenName,
      Nickname: this.state.nickname,
      Relation: this.state.relation,
      DateOfBirth: this.state.dateOfBirth,
    };

    axios
      .put("/api/OpsEmployee/updatedependent", dependentModel)
      .then((response) => {
        this.setState({ allowEditAddress: false, errors: null });
        toastr.success("Dependent updated", "Success");
        this.props.onHide();
      })
      .catch((error) => {
        this.props.onHide();
      })
      .then(() => {
        this.props.onHide();
      });
  };

  render() {
    return (
      <Modal
        className="react-modal"
        backdrop="static"
        keyboard={false}
        show={this.props.show}
      >
        <Modal.Header className="react-modal-title">
          <Modal.Title>Edit dependent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                First Name
                <span className="tx-danger"> *</span>
              </label>
              <input
                value={this.state.firstName}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="firstName"
              />
              {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["firstName"] ? (
                    <div>{this.state.errors["firstName"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Last Name
                <span className="tx-danger"> *</span>
              </label>
              <input
                value={this.state.lastName}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="lastName"
              />
               {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["lastName"] ? (
                    <div>{this.state.errors["lastName"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Maiden Name
              </label>
              <input
                value={this.state.maidenName}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="maidenName"
              />
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">Nickname</label>
              <input
                value={this.state.nickname}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="nickname"
              />
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Relation <span className="tx-danger"> *</span>
              </label>
              <select
                className="form-control"
                name="relation"
                value={this.state.relation}
                onChange={this.handleDependentChange}
              >
                <option value="SPOUSE">Spouse</option>
                <option value="CHILD">Child</option>
              </select>
              {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["relation"] ? (
                    <div>{this.state.errors["relation"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Date Of Birth
                <span className="tx-danger"> *</span>
              </label>
              <input
                value={this.state.dateOfBirth}
                onChange={this.handleDependentChange}
                className="form-control"
                type="date"
                name="dateOfBirth"
              />
              {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["dateOfBirth"] ? (
                    <div>{this.state.errors["dateOfBirth"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary pd-y-5"
            onClick={() => this.handleDependentSave()}
            disabled={this.state.buttonDisabled}
          >
            Save
          </button>
          <button className="btn btn-light pd-y-5" onClick={this.props.onHide}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
