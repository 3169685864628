import { useState, useEffect } from 'react';
import { Card, Table, Modal, Button } from 'react-bootstrap';
import EditFlexDatesModal from './EditFlexDatesModal';
import CustomPagination from './CustomPagination';
import axios from 'axios';
import { CustomLoading } from './CustomLoading';
import Moment from 'react-moment';
import toastr from "toastr";

const FlexDatesSettings = ({ policyId }) => {
  const [loading, setLoading] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showToggleConfirmModal, setShowToggleConfirmModal] = useState(false);

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const getEmployeeDetails = async () => {
    try {
      const response = await axios.get('/api/Settings/getflexdatesdetails', {
        params: {
          policyId: policyId
        }
      });
      if (response.data.success) {
        setEmployeeDetails(response.data.employeeDetails);
        setSelectedEmployees([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleEmails = async () => {
    setShowToggleConfirmModal(false);
    var body = {
        PolicyId: policyId,
        SelectedEmployees: selectedEmployees,
    };
   
    try {
      const response = await axios.put('/api/Settings/toggleflexemailsmidyear', body);
      if (response.data.success) {
        toastr.success("Flex Emails Toggled", "Success");
        getEmployeeDetails();
      } else {
        toastr.error("Failed to toggle emails", "Error");
      }
    } catch (error) {
      toastr.error("Failed to toggle emails", "Error");
    }
  };

  const updateSelectedEmployee = () => {
    if(selectedEmployees.length > 0) {
        setShowEditModal(true);
    }
  };

  const selectAll = e => {
    e.preventDefault();
    if (selectedEmployees.length === employeeDetails.length) {
      setSelectedEmployees([]);
    } else {
      const employeeIds = employeeDetails.map(employee => employee.id);
      setSelectedEmployees(employeeIds);
    }
  };

  const openEditEmployeeModal = (event, employeeId) => {
    event.preventDefault();
    setShowEditModal(true);
    setSelectedEmployees([employeeId]);
  };

  const handleCheckboxChange = (e, employeeId) => {
    const isChecked = e.target.checked;
    setSelectedEmployees(prevSelected => {
      if (isChecked) {
        return [...prevSelected, employeeId];
      } else {
        return prevSelected.filter(id => id !== employeeId);
      }
    });
  };

  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = employeeDetails.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(employeeDetails.length / itemsPerPage);

  let editFlexDatesModal = null;
  if (showEditModal) {
    editFlexDatesModal = (
      <EditFlexDatesModal
        showEditModal={showEditModal}
        policyId={policyId}
        selectedEmployees={selectedEmployees}
        refreshEmployees={() => {
          setShowEditModal(false);
          getEmployeeDetails();
        }}
        closeModal={() => {
          setShowEditModal(false);
          setSelectedEmployees([]);
        }}
      />
    );
  }

  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <div style={{ width: '87%' }}>
          <Card>
            {editFlexDatesModal}
            <Table hover>
              <thead>
                <tr>
                  <th />
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Benefit Category</th>
                  <th>Flex Launch Date</th>
                  <th>Flex Reminder Date</th>
                  <th>Flex Last Reminder Date</th>
                  <th>Flex Deadline</th>
                  <th>Flex Emails Enabled</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {currentRecords.map(employee => (
                  <tr key={employee.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedEmployees.includes(employee.id)}
                        onChange={e => handleCheckboxChange(e, employee.id)}
                        style={{ marginRight: '10px', marginTop: '2px' }}
                      />
                    </td>
                    <td>{employee.lastName}</td>
                    <td>{employee.firstName}</td>
                    <td>{employee.benefitCategory}</td>
                    <td>
                      {employee.flexLaunchDate == null ? (
                        'N/A'
                      ) : (
                        <Moment format="YYYY-MM-DD">
                          {employee.flexLaunchDate}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {employee.flexReminderDate == null ? (
                        'N/A'
                      ) : (
                        <Moment format="YYYY-MM-DD">
                          {employee.flexReminderDate}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {employee.flexLastDayReminderDate == null ? (
                        'N/A'
                      ) : (
                        <Moment format="YYYY-MM-DD">
                          {employee.flexLastDayReminderDate}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {employee.flexSelectionDeadline == null ? (
                        'N/A'
                      ) : (
                        <Moment format="YYYY-MM-DD">
                          {employee.flexSelectionDeadline}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {employee.enableFlexEmails ? "Yes" : "No"}
                    </td>
                    <td style={{ position: 'relative', width:40 }}>
                      <a
                        href="#"
                        className="RT-Link"
                        onClick={event =>
                          openEditEmployeeModal(event, employee.id)
                        }
                        style={{ position: 'absolute', top: '8px', right: '17px' }}
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="employee-campaign-button-container" style={{padding:20}}>
              <button className="btn btn-secondary" onClick={selectAll}>
                Select/Deselect All
              </button>
              <button
                className="btn btn-primary"
                onClick={updateSelectedEmployee}
                disabled={disableBtn ? true : false}
              >
                Update Selected Employees
              </button>
              <button className="btn btn-primary"
                  onClick={() => setShowToggleConfirmModal(true)}
                  disabled={disableBtn ? true : false}
                  style={{marginLeft:"30px"}}
              >
                  {"Toggle Selection Email(s)"}
              </button>
              <div className="pagination-container">
                <div className="pagination-info">
                  <span>
                    Showing {indexOfFirstRecord + 1} -{' '}
                    {indexOfFirstRecord + currentRecords.length} entries out of{' '}
                    {employeeDetails.length} entries
                  </span>
                </div>
                <CustomPagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </Card>
        </div>
      )}
      <Modal show={showToggleConfirmModal} style={{top:100}}>
        <Modal.Header>
          <Modal.Title style={{fontSize:"17px"}}>Toggle Flex Emails</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color:"red"}}>
          Toggling Flex Selection emails from going out to employees, in order for Ops users to allocate on their behalf.
          <br/>
          Are you sure you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowToggleConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={toggleEmails}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FlexDatesSettings;
