import axios from "axios";
import { useState, useEffect } from "react";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { CustomLoading } from "components/CustomLoading";
import { CSVLink } from "react-csv";
import toastr from "toastr";
import { Button, Table } from "react-bootstrap";
import CustomPagination from "components/CustomPagination";
import { EmptyStateTemplate } from "./Empty_state_Template";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import "../../styles/Reports.css";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const FundingActivityAndTransactionsTable = ({ policyId }) => {
  const date = new Date();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [fromDate, setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [toDate, setToDate] = useState(new Date());
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [fromDate, toDate]);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/api/Reports/getfundingactivityandtransactions?policyId=${policyId}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`);
      if (res.data.success) {
        const formattedFromDate = fromDate.toISOString().slice(0, 10);
        const formattedToDate = toDate.toISOString().slice(0, 10);
        
        let activities = res.data.activities;
        activities.unshift({
          date: formattedFromDate,
          transaction: "Opening Balance",
          info: "",
          amount: "",
          balance: res.data.openingBalance,
        });
        
        activities.push({
          date: formattedToDate,
          transaction: "Closing Balance",
          info: "",
          amount: "",
          balance: res.data.closingBalance,
        });

        const exportData = activities.map((item) => [
          item.date,
          item.transaction,
          item.info,
          item.amount,
          item.balance,
        ]);

        exportData.unshift(["Date", "Transaction", "Info", "Amount", "Balance"]);
        exportData.unshift(["Date Range", `${formattedFromDate} - ${formattedToDate}`]);

        setData(activities);
        setDataToExport(exportData);
      } else {
        setData([]);
        setDataToExport([]);
      }
    } catch (error) {
      toastr.error("Error");
    } finally {
      setLoading(false);
    }
  };

  const sortByDate = () => {
    const sortedData = [...data].reverse();
    const sortedDataToExport = [...dataToExport].reverse();
    setData(sortedData);
    setDataToExport(sortedDataToExport);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const exportToPdf = () => {
    const header = dataToExport[1];
    const bodyRows = dataToExport.slice(2);

    const headerData = formatRow(header, "tableHeader");
    const bodyData = formatTableBody(bodyRows);

    const docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      content: [
        {
          columns: [createLogo(), createDateRange(dataToExport[0][1])],
        },
        createTitle(),
        createTable(headerData, bodyData),
      ],
      styles: getPdfStyles(),
      layout: getTableLayout(),
    };

    pdfMake.createPdf(docDefinition).download("Funding_Activity_And_Transactions_Report.pdf");
  };

  const createLogo = () => ({
    image: base64.logo,
    width: 230,
  });

  const createTitle = () => ({
    text: "Funding Activity and Transactions Report",
    fontSize: 16,
    bold: true,
    alignment: "center",
    margin: [10, 40, 0, 10],
  });

  const createDateRange = (dateRange) => ({
    text: `Date Range: ${dateRange}`,
    fontSize: 12,
    bold: true,
    alignment: "right",
    margin: [0, 0, 0, 8],
  });

  const formatRow = (rowData, style) => {
    return rowData.map((cell) => {
      const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
      const isPercentage = typeof cell === "string" && cell.includes("%");

      return {
        text: String(cell || ""),
        style,
        alignment: isNumeric || isPercentage ? "right" : "left",
      };
    });
  };

  const formatTableBody = (rows) => rows.map((row) => formatRow(row, "tableBody"));

  const createTable = (header, body) => ({
    style: "table",
    table: {
      widths: Array(header.length).fill("auto"),
      body: [header, ...body],
    },
  });

  const getPdfStyles = () => ({
    table: {
      margin: [70, 5, 50, 15],
      alignment: "center",
    },
    tableHeader: {
      bold: true,
      fontSize: 12,
      color: "black",
    },
    tableBody: {
      fontSize: 10,
      color: "black",
      padding: [2, 2, 2, 2],
    },
  });

  const getTableLayout = () => ({
    hLineWidth: (i, node) => 0.5,
    vLineWidth: (i, node) => 0.5,
    paddingLeft: (i, node) => 4,
    paddingRight: (i, node) => 4,
  });

  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div className="funding-activity-and-transactions-table report-table-container">
      {loading ? (
        <CustomLoading />
      ) : (
        <>
          <div className="report-toolbar" style={{ display: "flex", alignItems: "center" }}>
            <CustomDateRangePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
            {data.length > 0 && (
              <div className="export-button-container">
                <Button variant="primary" className="export-to-pdf" onClick={exportToPdf}>
                  <span className="pdf-text">Export to PDF</span>
                </Button>
                <CSVLink data={dataToExport} filename={"Funding_Activity_And_Transactions_Report.csv"} className="export-button">
                  <Button variant="primary" className="csv-button export-to-csv">
                    <span className="csv-text">Export to CSV</span>
                  </Button>
                </CSVLink>
              </div>
            )}
          </div>
          {data.length > 0 ? (
            <>
              <Table hover className="report-table">
                <thead>
                  <tr>
                    <th className="table-text-content" onClick={sortByDate}>
                      Date
                      <FontAwesomeIcon icon={sortOrder === "asc" ? faSortUp : faSortDown} className={sortOrder === "asc" ? "asc-icon" : "desc-icon"} />
                    </th>
                    <th className="table-text-content">Transaction</th>
                    <th className="table-text-content">Info</th>
                    <th>Amount</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.map((item, index) => (
                    <tr key={index} className="table-body-item">
                      <td className="table-text-content">{item.date}</td>
                      <td className="table-text-content">{item.transaction}</td>
                      <td className="table-text-content">{item.info}</td>
                      <td>{item.amount}</td>
                      <td>{item.balance}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="pagination-container">
                <div className="pagination-info">
                  <span>
                    Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord + currentRecords.length} entries out of {data.length} entries
                  </span>
                </div>
                <CustomPagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </div>
            </>
          ) : (
            <EmptyStateTemplate />
          )}
        </>
      )}
    </div>
  );
};

export default FundingActivityAndTransactionsTable;
