import React from "react";
import axios from "axios";
import "../../styles/Reports.css";
import { CustomLoading } from "../../components/CustomLoading";
import {Button, Table} from "react-bootstrap";
import CustomPagination from "../../components/CustomPagination";
import { EmptyStateTemplate } from "./Empty_state_Template";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import {CustomDateRangePicker} from "../../components/CustomDateRangePicker";
import { CSVLink } from "react-csv";
export default class CorehealthFundingActivityTable extends React.Component<any, any> {
    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
            toDate: new Date(),
            currentPage: 1,
            itemsPerPage: 12,
            sortOrder: "asc"
        };
        this.fetchData = this.fetchData.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.setToDate = this.setToDate.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        axios.get("/api/Reports/getcorehealthfundingactivity", {
            params: {
                policyId: this.props.policyId,
                fromDate: this.state.fromDate,
                toDate: this.state.toDate,
            }
        })
            .then(res => {
                if (res.data && res.data.results) {
                    this.setupData(res.data.results);
                } else {
                    this.setState({
                        loading: false,
                        data: [],
                        dataToExport: [],
                    });
                }
            })
            .catch(err => {
                console.error("Error fetching data: ", err);
                this.setState({
                    loading: false,
                    data: [],
                    dataToExport: [],
                });
            });
    }
    setupData = (data) => {
        let invoices = data.map(item => ({
            date: item.transDate,
            transaction: item.transType,
            info: item.note,
            amount: item.amount.toFixed(2),
            balance: item.runningTotal.toFixed(2),
        }));
        const exportData = [
            ["Date Range", `${this.state.fromDate.toISOString().slice(0, 10)} - ${this.state.toDate.toISOString().slice(0, 10)}`],
            ["Date", "Transaction", "Info", "Amount", "Balance"],
            ...invoices.map(item => [
                moment.utc(item.date).format('YYYY-MM-DD'),
                item.transaction,
                item.info,
                item.amount,
                item.balance
            ])
        ];
        this.setState({
            dataToExport: exportData,
            data: invoices,
            loading: false,
        });
    };

    setFromDate(date) {
        this.setState({fromDate: date}, this.fetchData);
    }

    setToDate(date) {
        this.setState({toDate: date}, this.fetchData);
    }

    sortByDate = () => {
        const sortedData = [...this.state.data].reverse();
        const sortedDataToExport = [...this.state.dataToExport].reverse();
        this.setState((prevState) => ({
            data: sortedData,
            dataToExport: sortedDataToExport,
            sortOrder: prevState.sortOrder === "asc" ? "desc" : "asc",
        }));
    };
    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    render() {
        let contents = null;
        if (this.state.loading) {
            contents = (<CustomLoading/>);
        } else {
            const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
            const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
            const currentRecords = this.state.data.slice(indexOfFirstRecord, indexOfLastRecord);
            const nPages = Math.ceil(this.state.data.length / this.state.itemsPerPage);
            contents = (
                <div className="invoices-table report-table-container">
                    <div className="report-toolbar" style={{display: "flex", alignItems: "center"}}>
                        <CustomDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            setFromDate={this.setFromDate}
                            setToDate={this.setToDate}
                        />
                        {this.state.data.length > 0 && (
                            <div className="export-button-container">
                                <CSVLink data={this.state.dataToExport} filename={"CorehealthFundingActivity.csv"}
                                         className="export-button">
                                    <Button variant="primary" className="csv-button export-to-csv">
                                        <span className="csv-text">Export to CSV</span>
                                    </Button>
                                </CSVLink>
                            </div>
                        )}
                    </div>
                    {this.state.data.length > 0 ? (
                        <>
                            <Table hover className="report-table">
                                <thead>
                                <tr>
                                    <th className="table-text-content" onClick={this.sortByDate}>
                                        Date
                                        <FontAwesomeIcon icon={this.state.sortOrder === "asc" ? faSortUp : faSortDown}
                                                         className={this.state.sortOrder === "asc" ? "asc-icon" : "desc-icon"}/>
                                    </th>
                                    <th className="table-text-content">Transaction</th>
                                    <th className="table-text-content">Info</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentRecords.map((item, index) => (
                                    <tr key={index} className="table-body-item">
                                        <td className="table-text-content">{moment.utc(item.date).format('YYYY-MM-DD')}</td>
                                        <td className="table-text-content">{item.transaction}</td>
                                        <td className="table-text-content">{item.info}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.balance}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <div className="pagination-container">
                                <div className="pagination-info">
                                    <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord + currentRecords.length} entries out of {this.state.data.length} entries</span>
                                </div>
                                <CustomPagination
                                    nPages={nPages}
                                    currentPage={this.state.currentPage}
                                    setCurrentPage={this.setCurrentPage}
                                />
                            </div>
                        </>
                    ) : (
                        <EmptyStateTemplate/>
                    )}
                </div>
            );
        }
        return (<>{contents}</>);
    }
}