import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';

interface IdsPolicy {
    Id:string;
    EnrollPAFirstName:string;
    EnrollPALastName:string;
    EnrollPAEmail:string;
    PolicyNo:string;
};

export default KTMScreen({name:"planadmin.policy.edit-plan-admin"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsPolicy:IdsPolicy;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsPolicy";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Edit plan administrator";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="medium">
                {draw(this.header)}
                {draw(this.details)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit plan administrator"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.detail_card)}
                </Column>
            </Panel>
        );
    }

    detail_card({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display field={field("EnrollPAFirstName")}/>
                    <Display field={field("EnrollPALastName")}/>
                </Line>
                <Display field={field("EnrollPAEmail")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@policy_id":this.props.policy_id,
            "@dsPolicy.EnrollPAFirstName":this.data.dsPolicy.EnrollPAFirstName,
            "@dsPolicy.EnrollPALastName":this.data.dsPolicy.EnrollPALastName,
            "@dsPolicy.EnrollPAEmail":this.data.dsPolicy.EnrollPAEmail,
        });
        screen.close({"continue":true});
    }


});
