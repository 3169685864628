import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  RecordHeader,
  TabSet,
  Tab,
  Display,
  Line,
  Button,
  Card,
  Column,
  Panel,
} from "ktm-ui";
import Edit_generalScreen from "./Edit_general";
import Edit_hsaScreen from "./Edit_hsa";
import Edit_lsaScreen from "./Edit_lsa";
import Edit_flexScreen from "./Edit_flex";
import Edit_commission_ratioScreen from "./Edit_commission_ratio";
import Edit_commission_splitsScreen from "./Edit_commission_splits";
import Edit_bank_infoScreen from "./Edit_bank_info";
import Edit_enrollment_feeScreen from "./Edit_enrollment_fee";
import Edit_pricingScreen from "./Edit_pricing";
import Remove_hsaScreen from "./Remove_hsa";
import Remove_lsaScreen from "./Remove_lsa";
import Remove_flexScreen from "./Remove_flex";
import CustomToolTip from "./../../components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  Name: string;
  Description: string;
  HasHSA: boolean;
  HSAAdminRate: number;
  HSAMinAdminFee: number;
  HSAMaxAdminFee: number;
  HasLSA: boolean;
  LSAAdminRate: number;
  LSAMinAdminFee: number;
  LSAMaxAdminFee: number;
  EffectiveDate: string;
  CancelDate: string;
  AdvisorRate: number;
  Advisor2_Id: string;
  Advisor2Split: number;
  Advisor3_Id: string;
  Advisor3Split: number;
  BenefitPricing_Id: string;
  BankTransitNo: string;
  BankNo: string;
  BankAccountNo: string;
  EnrollmentFee: number;
  EnrollmentCommission: number;
  FirstYearProrateMonths: number;
  CarryFwdHSA: string;
  CarryFwdLSA: string;
  CommissionRatioOurPortion: number;
  CommissionRatioAdvisorPortion: number;
  CarryFwdHSALabel: string;
  CarryFwdLSALabel: string;
  ProductType: string;
  HasFlex: boolean;
}

interface IdsAdvisor2 {
  Id: string;
  Name: string;
  Distro: string;
}

interface IdsAdvisor3 {
  Id: string;
  Name: string;
  Distro: string;
}

interface IdsBenefitPrice {
  Id: string;
  Name: string;
  PriceSingle: number;
  PriceCouple: number;
  PriceFamily: number;
}

export default KTMScreen(
  { name: "enroll.product.record" },
  class extends React.Component<{ policyBenefit_id: string }, any> {
    data: {
      dsBenefit: IdsBenefit;
      dsAdvisor2: IdsAdvisor2;
      dsAdvisor3: IdsAdvisor3;
      dsBenefitPrice: IdsBenefitPrice;
    };

    constructor(props) {
      super(props);
      this.state = {
        hideDisableHsa: false,
        hideDisableLsa: false,
        hideDisableFlex: false,
      }
    }

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.title = this.data.dsBenefit.Name;
      await this.updateDiableBtns();
      await screen.triggerAction(this.configure_add);
      await screen.triggerAction(this.configure_panels);
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.tabs)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return (
        <RecordHeader
          entityLabel="Product"
          label={this.data.dsBenefit.Name}
        ></RecordHeader>
      );
    }

    tabs({ field, draw, action }: RenderEvent) {
      return (
        <TabSet>
          <Tab label="main-tab" template={this.main_tab} />
        </TabSet>
      );
    }

    advisor_2_split({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("@dsAdvisor2.Name")} />
          <Display field={field("@dsAdvisor2.Distro")} />
          <Display field={field("Advisor2Split")} />
        </Line>
      );
    }

    advisor_3_split({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("@dsAdvisor3.Name")} />
          <Display field={field("@dsAdvisor3.Distro")} />
          <Display field={field("Advisor3Split")} />
        </Line>
      );
    }

    hsa_details({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="HSA Details"
          actions={
            <>
              <Button
                action={action(this.edit_hsa)}
                label="Edit"
                style="link"
              />
              {this.state.hideDisableHsa ? null :
              <Button
                action={action(this.disable_hsa)}
                label="Disable"
                style="link"
              />}
            </>
          }
        >
          <Line>
            <Display
              field={field("HSAAdminRate")}
              toolTip={
                <CustomToolTip
                  header="HSA Admin Rate"
                  bodyContent="Typically 10% The administration fee applied per approved claim (plus tax). The amount of tax is determined by the Tax Jurisdiction of your policy."
                />
              }
            />
            <Display
              field={field("HSAMinAdminFee")}
              toolTip={
                <CustomToolTip
                  header="HSA Min Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a minimum admin charge applied per claim."
                />
              }
            />
            <Display
              field={field("HSAMaxAdminFee")}
              toolTip={
                <CustomToolTip
                  header="HSA Max Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a maximum admin charge applied per claim."
                />
              }
            />
          </Line>
          <Display
            field={field("CarryFwdHSALabel")}
            toolTip={
              <CustomToolTip
                header="HSA Carry Fwd"
                bodyContent="Choose between Credit, Expense or No CarryForward."
              />
            }
          />
        </Card>
      );
    }

    lsa_details({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="LSA Details"
          actions={
            <>
              <Button
                action={action(this.edit_lsa)}
                label="Edit"
                style="link"
              />
              {this.state.hideDisableLsa ? null : 
              <Button
                action={action(this.disable_lsa)}
                label="Disable"
                style="link"
              />}
            </>
          }
        >
          <Line>
            <Display
              field={field("LSAAdminRate")}
              toolTip={
                <CustomToolTip
                  header="LSA Admin Rate"
                  bodyContent="Typically 10% The administration fee applied per approved claim (plus tax). The amount of tax is determined by the Tax Jurisdiction of your policy."
                />
              }
            />
            <Display
              field={field("LSAMinAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Min Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a minimum admin charge applied per claim."
                />
              }
            />
            <Display
              field={field("LSAMaxAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Max Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a maximum admin charge applied per claim. "
                />
              }
            />
          </Line>
          <Display
            field={field("CarryFwdLSALabel")}
            toolTip={
              <CustomToolTip
                header="LSA CarryFwd"
                bodyContent="Choose between Credit, Expense or No CarryForward."
              />
            }
          />
        </Card>
      );
    }

    flex_details({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Flex Details"
          actions={
            <>
              <Button
                action={action(this.edit_flex)}
                label="Edit"
                style="link"
              />
              {this.state.hideDisableFlex ? null :
              <Button
                action={action(this.disable_flex)}
                label="Disable"
                style="link"
              />}
            </>
          }
        >
          <Line>
            <Display
              field={field("HSAAdminRate")}
              toolTip={
                <CustomToolTip
                  header="HSA Admin Rate"
                  bodyContent="Typically 10% The administration fee applied per approved claim (plus tax). The amount of tax is determined by the Tax Jurisdiction of your policy."
                />
              }
            />
            <Display
              field={field("HSAMinAdminFee")}
              toolTip={
                <CustomToolTip
                  header="HSA Min Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a minimum admin charge applied per claim."
                />
              }
            />
            <Display
              field={field("HSAMaxAdminFee")}
              toolTip={
                <CustomToolTip
                  header="HSA Max Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a maximum admin charge applied per claim."
                />
              }
            />
          </Line>
          <Display
            field={field("CarryFwdHSALabel")}
            toolTip={
              <CustomToolTip
                header="HSA Carry Fwd"
                bodyContent="Choose between Credit, Expense or No CarryForward."
              />
            }
          />
          <Line>
            <Display
              field={field("LSAAdminRate")}
              toolTip={
                <CustomToolTip
                  header="LSA Admin Rate"
                  bodyContent="Typically 10% The administration fee applied per approved claim (plus tax). The amount of tax is determined by the Tax Jurisdiction of your policy."
                />
              }
            />
            <Display
              field={field("LSAMinAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Min Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a minimum admin charge applied per claim."
                />
              }
            />
            <Display
              field={field("LSAMaxAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Max Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a maximum admin charge applied per claim. "
                />
              }
            />
          </Line>
          <Display
            field={field("CarryFwdLSALabel")}
            toolTip={
              <CustomToolTip
                header="LSA CarryFwd"
                bodyContent="Choose between Credit, Expense or No CarryForward."
              />
            }
          />
        </Card>
      );
    }

    pricing({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Pricing"
          actions={
            <>
              <Button
                action={action(this.edit_price)}
                label="Edit pricing"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display field={field("@dsBenefitPrice.Name")} />
            <Display field={field("@dsBenefitPrice.PriceSingle")} />
            <Display field={field("@dsBenefitPrice.PriceCouple")} />
            <Display field={field("@dsBenefitPrice.PriceFamily")} />
          </Line>
        </Card>
      );
    }

    general({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="General"
          actions={
            <>
              <Button
                action={action(this.edit_details)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Display field={field("Name")} />
          <Line>
            <Display
              field={field("EffectiveDate")}
              toolTip={
                <CustomToolTip
                  header="Effective Date"
                  bodyContent="To backdate correctly, you must backdate both the benefit year under the “Details” tab and this Product Effective Date. A policy can only be backdated up to a maximum of 2 years prior to the Benefit End Date."
                />
              }
            />
            {/* <Display field={field("CancelDate")} /> */}
          </Line>
        </Card>
      );
    }

    bank_information({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Bank information"
          actions={
            <>
              <Button
                action={action(this.edit_bank_info)}
                label="Edit bank info"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display field={field("BankTransitNo")} />
            <Display field={field("BankNo")} />
            <Display field={field("BankAccountNo")} />
          </Line>
        </Card>
      );
    }

    commission_ratio({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Commission ratio"
          actions={
            <>
              <Button
                action={action(this.edit_commissions)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display
              field={field("CommissionRatioOurPortion")}
              toolTip={
                <CustomToolTip
                  header="Our Portion"
                  bodyContent="Our Portion plus the Advisor portion must equal a total of 100."
                />
              }
            />
            <Display
              field={field("CommissionRatioAdvisorPortion")}
              toolTip={
                <CustomToolTip
                  header="Advisor Portion"
                  bodyContent="Our Portion plus the Advisor portion must equal a total of 100."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    advisor_splits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Multi-advisor splits"
          actions={
            <>
              <Button
                action={action(this.edit_multi_split)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          {draw(this.advisor_2_split)}
          {draw(this.advisor_3_split)}
        </Card>
      );
    }

    enrollment_fee({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Enrollment fee"
          actions={
            <>
              <Button
                action={action(this.edit_enrollment_fee)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display
              field={field("EnrollmentFee")}
              toolTip={
                <CustomToolTip
                  header="Enrollment Fee"
                  bodyContent="Do not include tax, the system will automatically include it. The enrollment fee is the total we are charging. The advisor portion is how much of that total goes to the advisor."
                />
              }
            />
            <Display
              field={field("EnrollmentCommission")}
              toolTip={
                <CustomToolTip
                  header="Advisor Portion"
                  bodyContent="Do not include tax, the system will automatically include it. The enrollment fee is the total we are charging. The advisor portion is how much of that total goes to the advisor."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    enable_action_bar({ field, draw, action }: RenderEvent) {
      return (
        <div className="RT-Action-Bar">
          <Button
            action={action(this.enable_hsa)}
            label="Enable HSA"
            style="add"
          />
          <Button
            action={action(this.enable_lsa)}
            label="Enable LSA"
            style="add"
          />
          <Button
            action={action(this.enable_flex)}
            label="Enable Flex"
            style="add"
          />
        </div>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.general)}
            {draw(this.commission_ratio)}
            {draw(this.advisor_splits)}
          </Column>
          <Column>
            {draw(this.enrollment_fee)}
            {draw(this.hsa_details)}
            {draw(this.lsa_details)}
            {draw(this.flex_details)}
            {draw(this.pricing)}
            {draw(this.enable_action_bar)}
          </Column>
        </Panel>
      );
    }

    async edit_details({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_generalScreen policyBenefit_id={this.props.policyBenefit_id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_hsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_hsaScreen policyBenefit_id={this.props.policyBenefit_id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async enable_hsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_hsaScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_lsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_lsaScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async enable_lsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_lsaScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_flex({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_flexScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async enable_flex({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_flexScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_commissions({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_commission_ratioScreen
          policyBenefit_id={this.data.dsBenefit.Id}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_multi_split({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_commission_splitsScreen
          policyBenefit_id={this.data.dsBenefit.Id}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_bank_info({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_bank_infoScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_enrollment_fee({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_enrollment_feeScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_price({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_pricingScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async configure_add({ screen }: ActionEvent) {
      screen.hide(this.enable_lsa);
      screen.hide(this.enable_hsa);
      screen.hide(this.enable_flex);
      
      if (screen.getValue("@dsBenefit.ProductType") == "SPEND") {
        if (!screen.getValue("@dsBenefit.HasLSA")) {
          screen.unhide(this.enable_lsa);
        }
        if (!screen.getValue("@dsBenefit.HasHSA")) {
          screen.unhide(this.enable_hsa);
        }
        if (!screen.getValue("@dsBenefit.HasFlex")) {
          screen.unhide(this.enable_flex);
        }
      }
    }

    async configure_panels({ screen }: ActionEvent) {
      screen.hide(this.hsa_details);
      screen.hide(this.lsa_details);
      screen.hide(this.flex_details);
      screen.hide(this.pricing);
      screen.hide(this.advisor_2_split);
      screen.hide(this.advisor_3_split);
      if (screen.getValue("@dsBenefit.ProductType") == "SPEND") {
        if (screen.getValue("@dsBenefit.HasFlex")) {
          screen.unhide(this.flex_details);
        } else {
          if (screen.getValue("@dsBenefit.HasHSA")) {
            screen.unhide(this.hsa_details);
          }
          if (screen.getValue("@dsBenefit.HasLSA")) {
            screen.unhide(this.lsa_details);
          }
        }
      } else {
        screen.unhide(this.pricing);
      }
      if (screen.getValue("@dsBenefit.Advisor2_Id")) {
        screen.unhide(this.advisor_2_split);
      }
      if (screen.getValue("@dsBenefit.Advisor3_Id")) {
        screen.unhide(this.advisor_3_split);
      }
    }

    async disable_hsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_hsaScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async disable_lsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_lsaScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async disable_flex({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_flexScreen policyBenefit_id={this.data.dsBenefit.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }

    async updateDiableBtns() {
      let response = await fetch(
        "/api/Utility/getcategorysatype?policyId=" + this.data.dsBenefit.Policy_Id
      );
      let res = await response.json();
      this.setState({ 
        hideDisableHsa : res.hasHsa, 
        hideDisableLsa : res.hasLsa,
        hideDisableFlex : res.hasFlex
      });
    }
  }
);
