import React from "react";
import "../../ops/styles/migration-message.css";

export default class MigrationMessageScreen extends React.Component<any> {
  state = {
    loading: false,
    errorMessage: null,
    email: "",
  };

  componentDidMount() {}

  renderContent() {
    let errorMessage = null;
    if (this.state.errorMessage != null) {
      errorMessage = (
        <div className="text-danger pd-t-15">{this.state.errorMessage}</div>
      );
    }

    let spinner = null;
    if (this.state.loading) {
      spinner = (
        <div>
          <div className="d-flex pos-relative align-items-center">
            <div className="sk-circle" style={{ margin: "0 auto" }}>
              <div className="sk-circle1 sk-child"></div>
              <div className="sk-circle2 sk-child"></div>
              <div className="sk-circle3 sk-child"></div>
              <div className="sk-circle4 sk-child"></div>
              <div className="sk-circle5 sk-child"></div>
              <div className="sk-circle6 sk-child"></div>
              <div className="sk-circle7 sk-child"></div>
              <div className="sk-circle8 sk-child"></div>
              <div className="sk-circle9 sk-child"></div>
              <div className="sk-circle10 sk-child"></div>
              <div className="sk-circle11 sk-child"></div>
              <div className="sk-circle12 sk-child"></div>
            </div>
          </div>
        </div>
      );
    }

    let signInButton = null;

    return (
      <div className="message-wrapper">
        <div className="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
          <div className=" wd-300 wd-xs-500 pd-25 pd-xs-40 bg-white rounded shadow-base">
            <div className="RT-Login__logo">
              <img src="/assets/images/nhclogoblue.png"/>
            </div>
            <div
              style={{ paddingTop: "15px", color: "#1a3e91", fontWeight: 500 }}
            >
              <p className="custom-p">
                <b>Account Temporarily Unavailable</b>
              </p>
              <p className="custom-p">
                National HealthClaim is currently in the process of migrating
                your account to this new platform.
              </p>
              <p className="custom-p">
                You won’t have access to make any changes to your account until
                the upgrade is finished.
              </p>
              <p className="custom-p">
                Please try accessing your account again in a few business days.
              </p>
            </div>
            <div className="text-center pd-t-15">
              <a href="/">Back to login</a>
            </div>
            {spinner}
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
