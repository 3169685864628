import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import toastr from "toastr";

export default class NotesReadOnlyModal extends React.Component<any> {
  state = {
    open: false,
    notes: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    axios
      .get(`/api/claims/getimagenotes?imageId=${this.props.imageId}`)
      .then((response) => {
        this.setState({ notes: response.data.notes });
      });
  }

  handleChange = (e) => {
    if (e.target.value == "") {
      this.setState({ notes: e.target.null });
    } else {
      this.setState({ notes: e.target.value });
    }
  };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  render() {
    let modalDisplay = {};
    let backdropClass = "";

    backdropClass = "modal-backdrop show";
    modalDisplay = { display: "block", top: 200 };
    return (
      <>
        <div className={backdropClass}></div>
        <div className="modal" style={modalDisplay}>
          <div
            className="modal-dialog modal-dialog-vertical-center"
            role="document"
          >
            <div className="modal-content bd-0 tx-14" style={{ width: 500 }}>
              <div className="modal-header pd-y-20 pd-x-25">
                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                  Image Notes
                </h6>
                <button
                  onClick={this.props.close}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pd-25">
                <textarea
                  disabled
                  maxLength={500}
                  rows={4}
                  className="form-control"
                  placeholder="Enter notes (optional).."
                  value={this.state.notes || ""}
                  onChange={this.handleChange.bind(this)}
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  onClick={this.props.close}
                  type="button"
                  className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
