import React from "react";

export default class ClaimSubmittedPendingModal extends React.Component<any> {
  state = {
    open: false,
    notes: null,
  };

  constructor(props) {
    super(props);
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  render() {
    let modalDisplay = {};
    let backdropClass = "";

    backdropClass = "modal-backdrop show";
    modalDisplay = { display: "block", top:200 };
    return (
      <>
        <div className={backdropClass}></div>
        <div className="modal" style={modalDisplay}>
          <div
            className="modal-dialog modal-dialog-vertical-center"
            role="document"
          >
            <div className="modal-content bd-0 tx-14" style={{ width: 350 }}>
              <div className="modal-header pd-y-20 pd-x-25">
                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                  Claim Submitted
                </h6>
              </div>
              <div className="modal-body" style={{paddingTop:0,paddingBottom:25}}>
                <div className="mg-b-0 text-center black">
                  <div className="bold tx-20 mg-b-15 pd-t-5">Please Wait</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
