import { ExpressionOps } from "./ExpressionOps";
import { Parser } from "./Parser";
import { IEvalContext, IExpressionNode } from "./types";

export class Serializer {
   

    public static serialize(node: IExpressionNode): string {
        switch (node.type) {
            case "str":
                return this.quotedString(node.value);
            case "bool":
                return this.serializeBool(node.value);
            case "num":
                return node.value.toString();
            case "get":
                return "_.get(ctx," + this.quotedString(node.name) + ")";
            case "current":
                return "_.current(ctx," + this.quotedString(node.name) + ")";
            case "row":
                return "_.row(ctx," + this.quotedString(node.name) + "," + node.value + ")";
            case "gcp":
                return "_.gcp(" + this.quotedString(node.name) + ")";
            case "loc":
                return "_.loc(ctx," + this.quotedString(node.name) + ")";
            case "prop":
                return (
                    "_.prop(" +
                    this.serialize(node.target) +
                    "," +
                    this.quotedString(node.name) +
                    ")"
                );
            case "computed":
                return node.expr;
            case "ind":
                return (
                    "_.ind(" +
                    this.serialize(node.target) +
                    "," +
                    this.serialize(node.value) +
                    ")"
                );
            case "udf":
                return (
                    "_.udf(" +
                    this.quoteFunction(node.name) +
                    "," +
                    this.serializeArgs(node.args) +
                    ")"
                );
            case "sdf":
                return (
                    "_.sdf(" +
                    this.quoteFunction(node.name) +
                    "," +
                    this.serializeArgs(
                        node.args,
                        Parser.ScriptFunctions[node.name].args
                    ) +
                    ")"
                );
            case "odf":
                return (
                    "_.odf(" +
                    this.serialize(node.target) +
                    "," +
                    this.quoteFunction(node.name) +
                    "," +
                    this.serializeArgs(node.args) +
                    ")"
                );
            case "add":
            case "sub":
            case "mult":
            case "div":
            case "idiv":
            case "exp":
            case "mod":
            case "con":
            case "eq":
            case "neq":
            case "gt":
            case "gte":
            case "lt":
            case "lte":
            case "and":
            case "or":
            case "xor":
            case "begins":
            case "contains":
            case "ends":
                return (
                    "_." +
                    node.type +
                    "(" +
                    this.serialize(node.left) +
                    "," +
                    this.serialize(node.right) +
                    ")"
                );
            case "neg":
            case "not":
            case "isempty":
                return (
                    "_." +
                    node.type +
                    "(" +
                    this.serialize(node.value) +
                    ")"
                );
            case "between":
                return (
                    "_." +
                    node.type +
                    "(" +
                    this.serialize(node.left) +
                    "," +
                    this.serialize(node.right1) +
                    "," +
                    this.serialize(node.right) +
                    ")"
                );
            case "null":
                return "undefined";
        }
        return "";
    }

    private static serializeBool(value: any): string {
        if ((value && value[0] == "T") || value[0] == "t") return "true";
        return "false";
    }

    public static serializeArgs(
        args: IExpressionNode[],
        argTypes?: string[]
    ): string {
       
        var s = ['ctx'];
        if (args){
            for (var i = 0; i < args.length; i++) {
                let argType: string;
                if (argTypes) {
                    argType = argTypes[i];
                } else {
                    argType = null;
                }
                s.push(this.serialize(args[i]));
            }
        }
        return "[" + s.join(",") + "]";
    }

    public static quotedString(value: string): string {
        if (value === null || value === undefined) {
            return "''";
        }
        return "'" + value.replace(/\'/g, "\\'") + "'";
    }

    public static toLiteral(value: any): string {
        if (value === null || value === undefined) {
            return "?"; // unknown
        }
        if (typeof value == "string") {
            return "'" + value.replace(/\'/g, "\\'") + "'";
        } else {
            return value;
        }
    }

    public static quoteFunction(value: string): string {
        if (value === null || value === undefined) {
            return "''";
        }
        if (value[0] == "@" && value[1] == "@"){
            value = value.substr(2);
        }
        else if (value[0] == "@"){
            value = value.substr(1);
        }
        return "'" + value.replace(/\'/g, "\\'").replace(/\-/g, "_") + "'";
    }

    public static tableParam(tableName: string, options: any) {
        return (
            "_.tblParam(_.get(" +
            Serializer.quotedString(tableName) +
            ")," +
            JSON.stringify(options) +
            ")"
        );
    }

    public static tableRef(tableName: string) {
        return (
            "_.tableRef(_.get(" +
            Serializer.quotedString(tableName) +
            "))"
        );
    }

    public static createExpressionFunction(expr:string):(ops:ExpressionOps,ctx:IEvalContext) => any {
        return new Function('_',"ctx","return " + expr) as any;
    }
}