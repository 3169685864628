import axios from "axios";
import React from "react";
import { CustomLoading } from "components/CustomLoading";
import "../../styles/Reports.css";
import CustomPagination from "components/CustomPagination";
import { Button, Table } from "react-bootstrap";
import { CSVLink } from "react-csv"; 
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { EmptyStateTemplate } from "./Empty_state_Template";
import CustomToolTip from "components/CustomToolTip";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class LifestyleTaxableReportTable extends React.Component<any,any> {
    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            totalPaid: 0,
            fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
            toDate: new Date(),
        };

        this.fetchData = this.fetchData.bind(this);
        this.getTotals = this.getTotals.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.toggleDatePicker = this.toggleDatePicker.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.setToDate = this.setToDate.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fromDate !== this.state.fromDate || prevState.toDate !== this.state.toDate) {
            this.setState({loading: true});
            this.fetchData();
        }
    }

    fetchData = () => {
        axios.get(`/api/Reports/lifestyletaxablereport?policyId=${this.props.policyId}&fromDate=${this.state.fromDate.toISOString()}&toDate=${this.state.toDate.toISOString()}`)
            .then(res => {
                if (res.data.success) {
                    this.getTotals(res.data.result);
                } else {
                    this.setState({
                        data: [],
                        dataToExport: [],
                        totalPaid: 0,
                        loading: false
                    });
                }
            });
    }
    
    getTotals = (data) => {
        const { fromDate, toDate } = this.state;
        const formattedFromDate = fromDate.toISOString().slice(0,10);
        const formattedToDate = toDate.toISOString().slice(0,10);

        let totalPaid = 0;
        let dataToExport = [];
        data.forEach((item) => {
            let formattedItem = [
                item.lastName,
                item.firstName,
                item.employeeNo,
                item.paidTotal
            ];
            
            dataToExport.push(formattedItem);
            totalPaid += parseFloat(item.paidTotal);
        });

        const headerRow = ["Last Name", "First Name", "Employee No", "LSA Paid Total"];
        dataToExport.unshift(headerRow);

        let totalItem = [
            "Total",
            "",
            "",
            totalPaid.toFixed(2)
        ];
        dataToExport.push(totalItem);

        const dateRangeRow = [
            "Date Range",
            `${formattedFromDate} - ${formattedToDate}`
        ];
        dataToExport.unshift(dateRangeRow);

        this.setState({
            data: data,
            dataToExport: dataToExport,
            totalPaid: totalPaid.toFixed(2),
            loading: false,
            currentPage: 1,
            itemsPerPage: 12,
        });
    }

    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    toggleDatePicker = () => {
        this.setState({
            showDatePicker: !this.state.showDatePicker,
        });
    }

    setFromDate = (date) => {
        this.setState({
            fromDate: date,
        });
    }

    setToDate = (date) => {
        this.setState({
            toDate: date,
        });
    }

    exportToPdf = () => {
        const { dataToExport } = this.state;
    
        const header = dataToExport[1];
        const bodyRows = dataToExport.slice(2, dataToExport.length - 1);
        const footer = dataToExport[dataToExport.length - 1];
      
        const headerData = this.formatRow(header, 'tableHeader');
        const bodyData = this.formatTableBody(bodyRows);
        bodyData.push(this.formatRow(footer, 'tableFooter'));

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            content: [
                {
                    columns: [
                        this.createLogo(),
                        this.createDateRange(dataToExport[0][1]),
                    ],
                },
                this.createTitle(),
                this.createTable(headerData, bodyData)
            ],
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download("Lifestyle_Taxable_Report.pdf");
    };
        
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    createTitle = () => ({
        text: 'Lifestyle Taxable Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map(row => this.formatRow(row, 'tableBody'));
    
    createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('*'),
            body: [header, ...body]
        },
        margin: [59.528, 0, 59.528, 0]
    });
    
    getPdfStyles = () => ({
        table: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 10,
            color: 'black'
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    render() {
        const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
        const currentRecords = this.state.data.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(this.state.data.length / this.state.itemsPerPage);

        let contents = null;
        if (this.state.loading) {
            contents = (<CustomLoading />);
        } else {
            contents = (
                <div className="lifestyle-taxable-report-Table report-table-container">
                    <div className="report-toolbar"
                        style={{display: "flex", alignItems: "center"}}
                    >
                        <CustomDateRangePicker fromDate={this.state.fromDate} toDate={this.state.toDate} setFromDate={this.setFromDate} setToDate={this.setToDate} />
                        {this.state.data.length > 0 ? (
                        <div className="export-button-container">
                            <Button variant="primary" className="export-to-pdf" onClick={this.exportToPdf}>
                                <span className="pdf-text">Export to PDF</span>
                            </Button>
                            <CSVLink data={this.state.dataToExport} filename={"Lifestyle_Taxable_Report.csv"} className="export-button">
                                <Button variant="primary" className="csv-button export-to-csv">
                                    <span className="csv-text">Export to CSV</span>
                                </Button>
                            </CSVLink>
                        </div>
                        ) : null}
                    </div>
                    {this.state.data.length > 0 ? (
                    <>
                        <Table hover className="report-table">
                            <thead>
                                <tr>
                                    <th className="table-text-content">Last Name</th>
                                    <th className="table-text-content">First Name</th>
                                    <th>Employee No</th>
                                    <th>
                                        Paid Total
                                        <CustomToolTip
                                            header="Paid Total"
                                            bodyContent="Claim amount excluding taxes and admin fees."
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((item, index) => 
                                    (
                                        <tr key={index} className="table-body-itme">
                                            <td className="table-text-content">{item.lastName}</td>
                                            <td className="table-text-content">{item.firstName}</td>
                                            <td>{item.employeeNo}</td>
                                            <td>{item.paidTotal}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3} className="table-footer-item total-amount">Total</td>
                                    <td className="table-footer-item">{this.state.totalPaid}</td>
                                </tr>
                            </tfoot>
                        </Table>
                        <div className="pagination-container">
                            <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {this.state.data.length} entries</span>
                            </div>
                            <CustomPagination
                                nPages={nPages} 
                                currentPage={this.state.currentPage} 
                                setCurrentPage={this.setCurrentPage} 
                            />
                        </div> 
                    </>) : ( <EmptyStateTemplate /> )}
                </div>
            );
        }

        return (<>{contents}</>);
    }
}