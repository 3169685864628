import { Collection } from "../Collection";
import { IEvalContext } from "./types";

export class ExpressionOps{

    public static add(left,right){
        if (left === null || left === undefined){
            if (right === null || right === undefined) {
                return null;
            }
            return right;
        }
        if (right == null || right === undefined){
            return left;
        }
        return left + right;
    }

    public static sub(left,right){
        return left - right;
    }

    public static mult(left,right){
        return left * right;
    }

    public static div(left,right){
        try {
            return left / right;
        }
        catch {
            return 0;
        }
    }

    public static idiv(left,right){
        try {
            return left / right;
        }
        catch {
            return 0;
        }
    }

    public static exp(left,right){
        return left ^ right;
    }

    public static mod(left,right){
        return left % right;
    }

    public static con(left,right){
        if (left === null || left === undefined){
            if (right === null || right === undefined) {
                return null;
            }
            return right;
        }
        if (right == null || right === undefined){
            return left;
        }
        return left + right;
    }

    public static isempty(value){
        return !value;
    }

    public static contains(left,right){
        let s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return (s.left.toUpperCase().indexOf(s.right.toUpperCase()) != -1);
        }
        return false;
    }
    public static begins(left,right){
        let s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase().startsWith(s.right.toUpperCase());
        }
        return false;
    }

    public static ends(left,right){
        let s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase().endsWith(s.right.toUpperCase());
        }
        return false;
    }

    public static between(left,right1,right2){
        let s = ExpressionOps.stringsForCompare(left,right1);
        if (s){
            if (s.left < s.right) return false;
        }
        else if(left < right1) return false;

        s = ExpressionOps.stringsForCompare(left,right2);
        if (s){
            return (s.left <= s.right);
        }
        return left < right2;
    }

    public static eq(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() == s.right.toUpperCase();
        }
        return left == right;
    }

    private static stringsForCompare(left:any,right:any):{left:string,right:string}{
        if (typeof(left) === "string"){
            if (right === null || right === undefined){
                return {left,right:""};
            }
            return {left,right:right.toString()}
        }
        if (typeof(right) === "string"){
            if (left === null || left === undefined){
                return {left:"",right};
            }
            return {left,right}
        }
        return null;
    }

    public static neq(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() != s.right.toUpperCase();
        }
        return left != right;
    }

    public static gt(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() > s.right.toUpperCase();
        }
        return left > right;
    }

    public static gte(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() >= s.right.toUpperCase();
        }
        return left >= right;
    }

    public static lt(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() < s.right.toUpperCase();
        }
        return left < right;
    }

    public static lte(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() <= s.right.toUpperCase();
        }
        return left <= right;
    }

    public static and(left,right){
        return left && right;
    }

    public static or(left,right){
        return left || right;
    }

    public static xor(left,right){
        return false;
    }

    public static neg(value){
        return -(value);
    }

    public static not(value){
        return !value;
    }

   

    public static get(context:IEvalContext,name:string){
        let canvas = context.canvas;
        return canvas.data[name.substr(1)];
    }

    public static current(context:IEvalContext,name:string){
        let canvas = context.canvas;
        if (context.scope){
            let value = context.scope[name];
            if (value !== undefined) return value;
        }
        return Collection.getCurrentRow(context.canvas,name);
    }

    public static row(context:IEvalContext,name:string,index:number){
        return Collection.getRow(context.canvas,name,index);
    }

    /*
    public gcp(name:string){
        var value:any;
        var proc = this.proc;
        value = proc.currentCall.target.$$get(name);
        if (typeof(value) == 'function'){
            return value(this);
        }
        return value;
    }
    */

    public static loc(context:IEvalContext,name:string){
        let canvas = context.canvas;
        if (context.scope){
            let value = context.scope[name];
            if (value !== undefined) return value;
        }
        return canvas.data[name.substr(1)];
    }
    /*
    public loct(name:string){
        return this.proc.currentCall.getVariableTarget(name);
    }
    */
   
    public static prop(target,name:string){
        if (!target) return null;

        // if target is collection then use target.current as target
        if (target.$$get){
            return target.$$get(name);
        }
        if (name[0] == '@'){
            return target[name.substr(1)];
        }
        else {
            return target[name];
        }
    }

    public static ind(target,value){
        if (!target) return null;
       
        if (target.$$isTable){
            return target[value];
        }
        if (target.$$get){
            return target.$$get(value);
        }
        else {
            return target[value];
        }
    }

    public static sdf(name:string,args:any[]){
        let f = ExpressionOps[name];
        if (!f) throw "Invalid function: " + name;
        return f.apply(this,args);
    }

    public static th(context:IEvalContext){
        return context.canvas.data;
    }

    public static EVENT_VALUE(context:IEvalContext){
        return context.eventValue;
    }

    public static RETURN_VALUE(context:IEvalContext){
        return context.returnValue;
    }
    public static MOD_SELECTED_ROWS(context:IEvalContext,name:string){
        var collection:any[] = context.canvas.data[name.substr(1)];
        if (!collection) return null;
        let selected = [];
        for(let i = 0; i < collection.length;i++){
            let row = collection[i];
            if (Collection.isRowSelected(row)){
                selected.push(row);
            }
        }
        return selected;
    }

    public static MOD_ALL_ROWS(context:IEvalContext,name:string){
        var collection = context.canvas.data[name.substr(1)];
        return collection;
    }
}