import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {} from 'ktm-ui';

interface IdsData {
    level_no:number;
};

export default KTMScreen({name:"planadmin.test.test"}, class extends React.Component<{level:number}> {

    data:{
        dsData:IdsData;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsData";
        await KTMApi.start(screen,{
            "@level":this.props.level,
        });
    }

    async action1({screen}:ActionEvent){
        await KTMApi.action(screen,"action1",{
            "@dsData.level_no":this.data.dsData.level_no,
        });
    }

});
