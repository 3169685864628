import { Application } from "./Application";
import { RenderEvent } from "./RenderEvent";
import React from 'react';
import { IComponentDefinition, IComponentPropType } from "./ComponentDefinition";
import { KIND } from "./types";

interface IDynamicInfo {
    id:string;
}
interface IContainerProps {
    event?:RenderEvent;
    children?:any[];
    actions?:any[];
    header?:any[];
    footer?:any[];
    key?:any;
    $dynamic?:boolean;
}

export class DynamicRender {
    static nextKey:number = 1;

    static render(event:RenderEvent, elems:any[]):any {
        let containerProps = {children:[]};
        DynamicRender.renderArray(event,elems,containerProps);
        let children = containerProps.children;
        if (children.length == 1){
            return children[0];
        }
        return children;
    }

    static renderArray(event:RenderEvent,elems:any[],containerProps:IContainerProps){
        if (!elems) return;
    
        for(let i = 0; i < elems.length;i++){
            let elem = elems[i];
            let kind = elem.$kind;
            let id = elem.$id;
            if (!id){
                id = DynamicRender.nextKey++;
                elem.$id = id;
            }
        
            if (kind == KIND.DRAW){
                event.draw(elem.name,{each:elem.each,span:elem.span,containerProps});
                continue;
            }
          
            let Comp = Application.getKind(kind);
            if (Comp){
                let def:IComponentDefinition = Comp.$def;
                let props:IContainerProps = {key:id,event,$dynamic:true};
                
                if (def && def.props){
                    let childrenProp = def.props["children"];
                    if (childrenProp)
                    {
                        if (childrenProp.type == "content"){
                            if (elem.children){
                                props.children = [];
                                DynamicRender.renderArray(event,elem.children,props);
                            }
                        }
                    }
                    for(let key in def.props){
                        let prop = def.props[key];
                        if (prop.type == "content") continue;
                        let value = elem[key];
                        if (prop.type == "object"){
                            props[key] = value;
                        }
                        else if (value || value === 0){
                            props[key] = DynamicRender.computeProp(event,prop.type,value);
                        }
                    }
                    let draw = React.createElement(Comp,props)
                    if (!def.section){
                        containerProps.children.push(draw);
                    }
                    else if (def.section == "actions"){
                        containerProps.actions = containerProps.actions || [];
                        containerProps.actions.push(draw)
                    }
                    else if (def.section == "header"){
                        containerProps.header = containerProps.header || [];
                        containerProps.header.push(draw)
                    }
                    else if (def.section == "footer"){
                        containerProps.footer = containerProps.footer || [];
                        containerProps.footer.push(draw)
                    }
                }
                else {
                 //   containerProps.children.push(React.createElement(Comp,elem));
                }
            }
        }       
    }

    static computeProp(event:RenderEvent,type:IComponentPropType,value:any):any {
        switch(type){
            case "field":return event.field(value);
            case "text":return value;
            case "enum":return value;
            case "text-expr":
                if (value[0] == '='){
                    return event.getValue(value.substr(1));
                }
                if (value[0] == '@'){
                    return event.getValue(value);
                }
                return value;
            case "boolean":return !!value;
            case "expr": return event.getValue(value);
            case "action":return event.action(value);
            case "collection":return event.collection(value);
            case "template":return value;
            case "fragment":return DynamicRender.render(event,value);
            case "number":return value;
            case "record":return event.getValue(value);
            case "handler":return DynamicRender.getParams(event,value);
            case "screen":return value;                
        }
    }

    static getParams(event:RenderEvent,elems:any[]):any {
        let params = {};
        if (!elems) return params;
        for(let i = 0; i < elems.length;i++){
            let elem = elems[i];
            if (elem.$kind == KIND.PARAM_VALUE){
                let propName = elem.name;
                if (propName){
                    if (propName[0] == '@'){
                        propName = propName.substr(1);
                    }
                    params[propName] = event.getValue(elem.value);
                }
            }
        }
        return params;
    }
}
