import { useEffect, useState } from "react";
import { CustomLoading } from "./CustomLoading";
import { Card, Line } from "ktm-ui";
import "../styles/Settings.css";
import EditGeneralSettingModal from "./EditGeneralSettingModal";
import CustomToolTip from "./CustomToolTip";
import EditCompanyStatusModal from "./EditCompanyStatusModal";
import Moment from "react-moment";

const GeneralSettings = ({ policyId }) => {
    const [loading, setLoading] = useState(true);
    const [showEditGeneralModel, setShowEditGeneralModel] = useState(false);
    const [showEditCompanyStatusModel, setShowEditCompanyStatusModel] = useState(false);
    const [showEditAllowBenefitCategoryModel, setShowEditAllowBenefitCategoryModel] = useState(false);
    const [uiDesignView, setUIDesignView] = useState(null);
    const [companyStatus, setCompanyStatus] = useState({ status: "", date: null, note: null });
    const [allowBenefitCategoryEdit, setAllowBenefitCategoryEdit] = useState(null);
    const [terminationLogs, setTerminationLogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getUIDesignView();
                await getCompanyStatus();
                await getAllowBenefitCategoryEdit();
                await getTerminationLogs();
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const openEditGeneralModal = () => setShowEditGeneralModel(true);
    const openEditCompanyStatusModal = () => setShowEditCompanyStatusModel(true);
    const openEditAllowBenefitCategoryModal = () => setShowEditAllowBenefitCategoryModel(true);

    const getUIDesignView = async () => {
        let response = await fetch(`/api/Settings/checkviewtype?policyId=${policyId}`, { method: "GET" });
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        setUIDesignView(data.viewType.viewType);
    };

    const updateUIDesignView = async (viewType) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ policyId: policyId, viewType: viewType }),
        };

        let response = await fetch("/api/Settings/updateviewtype", requestOptions);
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        await getUIDesignView();
        if (viewType === "plan_admin") {
            window.location.reload();
        }
    };

    const getCompanyStatus = async () => {
        let response = await fetch(`/api/Settings/getcompanystatus?policyId=${policyId}`, { method: "GET" });
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        setCompanyStatus({ status: data.status, date: data.terminationDate, note: data.terminationNote });
    };

    const updateCompanyStatus = async (status) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                PolicyId: policyId,
                Status: status.status,
                Date: status.date,
                Note: status.note,
            }),
        };

        let response = await fetch("/api/Settings/updatecompanystatus", requestOptions);
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        await getCompanyStatus();
    };

    const getAllowBenefitCategoryEdit = async () => {
        let response = await fetch(`/api/Settings/checkallowbenefitcategoryedit?policyId=${policyId}`, { method: "GET" });
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        setAllowBenefitCategoryEdit(data.isAllowBenefitCategoryEdit.eligibility);
    };

    const updateAllowBenefitCategoryEdit = async (eligibility) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ policyId: policyId, eligibility: eligibility }),
        };

        let response = await fetch("/api/Settings/updateallowbenefitcategoryedit", requestOptions);
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        await getAllowBenefitCategoryEdit();
    };

    const getTerminationLogs = async () => {
        let response = await fetch(`/api/Settings/getterminationlogs?policyId=${policyId}`, { method: "GET" });
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        setTerminationLogs(data.logs);
    };

    const renderModal = (ModalComponent, showModal, setShowModal, props) => (
        showModal && <ModalComponent {...props} closeModal={() => setShowModal(false)} />
    );

    const renderField = (label, content) => (
        <div className="RT-Static-Field">
            <div className='RT-Static-Field__label'>{label}</div>
            <div className='RT-Static-Field__content'>{content}</div>
        </div>
    );

    const renderLogsTable = () => (
        <table>
            <thead style={{ border: "1px solid black" }}>
                <tr style={{ border: "1px solid black" }}>
                    <th style={{ border: "1px solid black", padding: "5px" }}>Date</th>
                    <th style={{ border: "1px solid black", padding: "5px" }}>Action</th>
                    <th style={{ border: "1px solid black", padding: "5px" }}>Note</th>
                    <th style={{ border: "1px solid black", padding: "5px" }}>Created By</th>
                </tr>
            </thead>
            <tbody>
                {terminationLogs.map(entry => (
                    <tr key={entry.log.id} style={{ border: "1px solid black" }}>
                        <td style={{ border: "1px solid black", padding: "5px" }}><Moment local format="YYYY-MM-DD hh:mm A">{entry.log.activityDate}</Moment></td>
                        <td style={{ border: "1px solid black", padding: "5px" }}>{entry.log.activityType}</td>
                        <td style={{ border: "1px solid black", padding: "5px" }}>{entry.log.content}</td>
                        <td style={{ border: "1px solid black", padding: "5px" }}>{entry.lastName + "," + entry.firstName}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    let contents = loading ? <CustomLoading /> : (
        <div className="appearance-setting-container">
            {renderModal(EditGeneralSettingModal, showEditGeneralModel, setShowEditGeneralModel, {
                modalLabel: "Edit General",
                options: ["Company Benefits", "Business Owner"],
                showEditModal: showEditGeneralModel,
                selectedOption: uiDesignView,
                setSelectedOption: updateUIDesignView,
                dropdownLabel: "UI Design View:",
            })}
            {renderModal(EditCompanyStatusModal, showEditCompanyStatusModel, setShowEditCompanyStatusModel, {
                showEditModal: showEditCompanyStatusModel,
                selectedOption: companyStatus,
                setSelectedOption: updateCompanyStatus,
            })}
            {renderModal(EditGeneralSettingModal, showEditAllowBenefitCategoryModel, setShowEditAllowBenefitCategoryModel, {
                modalLabel: "Edit Allow Benefit Category",
                options: ["Yes", "No"],
                showEditModal: showEditAllowBenefitCategoryModel,
                selectedOption: allowBenefitCategoryEdit,
                setSelectedOption: updateAllowBenefitCategoryEdit,
                dropdownLabel: "Allow Benefit Category Edit:",
            })}
            <Card actions={<a href="#" className="RT-Link" onClick={openEditGeneralModal}>Edit</a>}>
                <Line>{renderField("UI Design View", uiDesignView)}</Line>
            </Card>
            <Card actions={<a href="#" className="RT-Link" onClick={openEditCompanyStatusModal}>Edit</a>}>
                <Line>
                    {renderField("Company Status", companyStatus.status)}
                    {companyStatus.date != null ? renderField("Cancellation Date", <Moment format="YYYY-MM-DD">{companyStatus.date}</Moment>) : <></>}
                </Line>
                {companyStatus.note != null ? (
                    <Line>
                        {renderField("Cancellation Note", companyStatus.note)}
                    </Line>
                ) : <></>}
                {terminationLogs != null && terminationLogs.length > 0 ? (
                    <Line>
                        {renderLogsTable()}
                    </Line>
                ) : <></>}
            </Card>
            <Card actions={<a href="#" className="RT-Link" onClick={openEditAllowBenefitCategoryModal}>Edit</a>}>
                <Line>
                    <div className="RT-Static-Field">
                        <div className='RT-Static-Field__label'>
                            Allow Benefit Category/Dates Edit
                            <CustomToolTip
                                header="Allow Benefit Category/Dates Edit"
                                bodyContent="Enable the Plan Admin to edit the Benefit Category for both Policy and Employee, as well as the Benefit Dates for the Employee."
                            />
                        </div>
                        <div className='RT-Static-Field__content'>{allowBenefitCategoryEdit}</div>
                    </div>
                </Line>
            </Card>
        </div>
    );

    return <div className="appearance-settings">{contents}</div>;
}

export default GeneralSettings;