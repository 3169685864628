import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Button, DataGrid, IDataGridLayout, EmptyState } from "ktm-ui";
import EmployeeAddScreen from "planadmin/employee/Add";
import EmployeeRecordScreen from "planadmin/employee/Record";
import TerminateScreen from "../employee/Terminate";
import "../../styles/Employee.css";

interface IdsEmployees {
  Id: string;
  Policy_Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  DateOfBirth: string;
  BenefitCategory: string;
  HireDate: string;
}

export default KTMScreen(
  { name: "planadmin.policy.employees" },
  class extends React.Component<{ policy_id: string, view_type?:string }> {
    data: {
      dsEmployees: IdsEmployees;
    };

    state = {
      filterText: "",
      collection: null,
      allEmployees: null,
      filteredEmployees: null,
      firstRun: true,
      submitDisabled: false,
      showDeactivatedEmployees : false,
      showPendingDeactivatedEmployees : false
    };

    render() {
      return renderScreen(this);
    }

    handleChange(e) {
      this.setState({ filterText: e.target.value, firstRun: false });
      if (e.target.value == "") {
        let val = this.state.filteredEmployees;
        this.setState({ activeEmployees: val });
        if (this.state.showDeactivatedEmployees) {
          this.setState({ filteredEmployees: this.getInactiveEmployees() });
        } else if(this.state.showPendingDeactivatedEmployees){
          this.setState({ filteredEmployees: this.getPendingInactiveEmployees() });
        } else {
          this.setState({ filteredEmployees: this.getActiveEmployees() });
        }
      }
    }

    updateCollection(array) {
      this.setState({ newCollection: array });
    }

    reset() {
      this.setState({
        filterText: "",
        submitDisabled: false,
        filteredEmployees: this.getActiveEmployees(),
      });
    }

    handleClick() {
      this.setState({ submitDisabled: true });
      let array = this.state.filteredEmployees;

      if (this.state.filterText != "") {
        let val = this.state.filterText;
        let regex = new RegExp(val.toLowerCase());
        let filteredArray = new Array<any>();
        array.rows.forEach((element) => {
          if (element.FirstName == null) {
            element.FirstName = "";
          }
          if (element.LastName == null) {
            element.LastName = "";
          }
          if (element.Email == null) {
            element.Email = "";
          }
          if (
            regex.test(element.FirstName.toLowerCase()) ||
            regex.test(element.LastName.toLowerCase()) ||
            regex.test(element.Email.toLowerCase()) ||
            regex.test(element.FirstName.toLowerCase() + " " + element.LastName.toLowerCase())) {
            filteredArray.push(element);
          }
        });
        array.rows = filteredArray;
      }
      this.setState({ filteredEmployees: array });
    }

    handleKeyPress = (e) => {
      if (e.key == "Enter") {
        this.handleClick();
      }
    };

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsEmployees";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
      });
      this.setState({firstRun: true});
      screen.title = "Employees";
    }

    main({ field, draw, action }: RenderEvent) {
      return <Layout viewType={this.props.view_type}>{draw(this.grid)}</Layout>;
    }

    getActiveEmployees() {
      let array = this.state.allEmployees;
      array.rows = array.rows.filter(x => x.IsActive && x.BenefitEndDate == null);
      return array;
    }

    getPendingInactiveEmployees() {
      let array = this.state.allEmployees;
      array.rows = array.rows.filter(x => x.IsActive && x.BenefitEndDate != null);
      return array;
    }

    getInactiveEmployees() {
      let array = this.state.allEmployees;
      var now = new Date();
      array.rows = array.rows.filter(x => x.IsActive == false && new Date(x.BenefitEndDate) <= now);
      return array;
    }

    toggleInactiveEmployees(e){
      this.setState({
        showDeactivatedEmployees : e.target.checked,
        firstRun: false
      });

      if(e.target.checked){
        this.setState({ filteredEmployees: this.getInactiveEmployees() });
      } else {
        this.setState({ filteredEmployees: this.getActiveEmployees() });
      }
    }

    toggelPendingDeactivaed(e){
      this.setState({
        showPendingDeactivatedEmployees : e.target.checked,
        firstRun: false
      });

      if(e.target.checked){
        this.setState({ filteredEmployees: this.getPendingInactiveEmployees() });
      } else {
        this.setState({ filteredEmployees: this.getActiveEmployees() });
      }
    }

    grid({ field, draw, action, collection }: RenderEvent) {
      this.state.allEmployees = collection("@dsEmployees");

      if (this.state.firstRun) {
        this.state.filteredEmployees = this.getActiveEmployees();
        this.state.allEmployees = collection("@dsEmployees");
        this.state.firstRun = false;
      }

      let actions = (
        <>
          <Button
            className="add-employee"
            action={action(this.add)} 
            label="Add Employee" 
            style="add" 
          />
          <input
            className="RT-TextBox__input"
            type="text"
            placeholder="Search"
            id="empSearch"
            onKeyPress={this.handleKeyPress}
            value={this.state.filterText}
            onChange={this.handleChange.bind(this)}
            style={{ backgroundColor: "white", width: "auto", height: "26px" }}
          />
          <button
            className="RT-Button RT-Button--primary"
            disabled={this.state.submitDisabled}
            onClick={this.handleClick.bind(this)}
          >
            Submit
          </button>
          <button
            className="RT-Button RT-Button--primary"
            onClick={this.reset.bind(this)}
          >
            Reset
          </button>
          <label>
            <input
              type="checkbox"
              checked={this.state.showDeactivatedEmployees}
              onChange={this.toggleInactiveEmployees.bind(this)}
              style={{ paddingLeft: 0, marginRight: 10 }}
            />
            Show deactivated employees
          </label>
          <label>
            <input
              type="checkbox"
              checked={this.state.showPendingDeactivatedEmployees}
              onChange={this.toggelPendingDeactivaed.bind(this)}
              style={{ paddingLeft: 0, marginRight: 10 }}
            />
            Show pending deactivated employees
          </label>
        </>
      );
      
      let layout: IDataGridLayout = {
        columns: [
          {
            name: "LastName",
            label: "Last Name",
            type: "character",
            width: 200,
          },
          {
            name: "FirstName",
            label: "First Name",
            type: "character",
            width: 200,
          },
          { name: "Email", 
            label: "Email", 
            type: "character", 
            width: 300 
          },
          {
            name: "HireDate",
            label: "Date Of Hire",
            type: "date",
            width: 203,
          },
          {
            name: "BenefitStartDate",
            label: "Benefit Start Date",
            type: "date",
            width: 150,
          },
          {
            name: "Coverage",
            label: "Coverage",
            type: "character",
            width: 150,
          },
          {
            name: "IsPlanAdmin",
            label: "Is Plan Admin",
            type: "logical",
            width: 150,
          },
          {
            name: "BenefitCategory",
            label: "Benefit Category",
            type: "character",
            width: 200,
          },
          {
            name: "BenefitEndDate",
            label: "Benefit End Date",
            type: "date",
            width: 150,
          }
        ],
        sort: [{ name: "LastName" }, { name: "FirstName" }],
      };

      return (
        <DataGrid
          viewType={this.props.view_type}
          collection={this.state.filteredEmployees}
          layout={layout}
          actions={actions}
          onRowClick={action(this.open_row)}
        />
      );
    }

    empty_state({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState label="No employees created yet." icon="users">
          <Button
            action={action(this.add)}
            label="Add Employee"
            style="primary"
          />
        </EmptyState>
      );
    }

    async add({ screen }: ActionEvent) {
      await screen.openDialog(
        <EmployeeAddScreen policy_id={this.props.policy_id} />,
        { onRecordChanged: this.refresh_row }
      );

      await screen.restart();
    }

    async open_row({ screen }: ActionEvent) {
      screen.browse(
        <EmployeeRecordScreen employee_id={screen.getValue("Id")} />,
        { onRecordChanged: this.refresh_row, label: "Employee" }
      );
    }

    async terminate({ screen }: ActionEvent) {
      await screen.openDialog(
        <TerminateScreen employee_id={screen.getValue("Id")} />
      );

      await screen.restart();
    }

    async refresh_row({ screen, eventValue }: ActionEvent) {
      screen.showLayout(this.main);
      await KTMApi.refreshRow(screen, {
        collection: "@dsEmployees",
        rowid: eventValue,
      });
    }
  }
);
