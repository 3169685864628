import { AnyAaaaRecord } from "dns";
import React from "react";
import ReactButton from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default class TextModal extends React.Component<any, any> {

    constructor(props) {
        super(props);
    }

    formatText(text:string):any{
        if (!text) return null;
        let segments = text.split('\n');
        let elems = [];
        for(var i = 0; i < segments.length;i++){
            if (segments[i]){
                elems.push(<div key={i}>{this.getSegmentElements(segments[i])}</div>);
            }
            else {
                elems.push(<br key={i}/>);
            }
        }
        return elems;
    }

    getSegmentElements(segment:string):any{
        let spans = segment.split("**");
        if (spans.length == 1) return segment;
        let asStrong = false;
        let elems = [];
        for(let i = 0; i < spans.length;i++){
            if (asStrong){
                elems.push(<strong key={i}>{spans[i]}</strong>)
            }
            else {
                elems.push(spans[i]);
            }
            asStrong = !asStrong;
        }
        return elems;
    }

    render() {
        return(
            <Modal 
                className="react-modal"
                show={this.props.showModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header className="react-modal-title">
                    <Modal.Title>{this.props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.formatText(this.props.bodyText)}
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton
                        variant="light"
                        onClick={this.props.toggleModal}
                        style={{ width: "70px" }}
                    >
                        Close
                    </ReactButton>
                </Modal.Footer>
            </Modal>
        );
    }

}