import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';

interface IdsDateRange {
    Id:string;
    Policy_Id:string;
    Employee_Id:string;
    Dependent_Id:string;
    RangeType:string;
    FromDate:string;
    ToDate:string;
    Comment:string;
};

export default KTMScreen({name:"enroll.employee.remove-date-range"}, class extends React.Component<{dateRange_id:string}> {

    data:{
        dsDateRange:IdsDateRange;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsDateRange";
        await KTMApi.start(screen,{
            "@dateRange_id":this.props.dateRange_id,
        });
        screen.title = "Edit date range";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Remove date range"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
       return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Remove" style="destructive"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    <div>
                        Are you sure you want to remove this date range?
                    </div>  
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dateRange_id":this.props.dateRange_id,
        });
        screen.close({"continue":true});
    }
});
