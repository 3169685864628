import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Dialog, Button, Display, Line, Card } from "ktm-ui";
import TaxLookup from "enroll/lookups/Tax_jurisdiction";
import CustomToolTip from "../../components/CustomToolTip";
import { CustomDropdown } from "components/CustomDropdown";

interface IdsPolicy {
  Id: string;
  Name: string;
  PolicyNo: string;
  EnrollmentNote: string;
  DependentAge: number;
  StudentAge: number;
  TaxJurisdiction_Id: string;
  Type: string;
  StartDate: string;
  UpdatedAt: string;
  UpdatedBy: string;
  Representative: string;
}

interface IdsTaxJurisdiction {
  Id: string;
  Name: string;
}

const representatives = ["-- Select --", "NONE", "SALESON", "SALESAB", "JG"];

export default KTMScreen(
  { name: "enroll.policy.edit-details" },
  class extends React.Component<{ policy_id: string }> {
    data: {
      dsPolicy: IdsPolicy;
      dsTaxJurisdiction: IdsTaxJurisdiction;
    };

    state = {
      selectedRep: representatives[0]
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsPolicy";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
      });
      screen.title = "Edit policy details";
    }

    tax_lookup({ getValue, eventValue }: RenderEvent) {
      return <TaxLookup eventValue={eventValue} />;
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.detail_card)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit Policy Details"></Dialog.Header>;
    }

    footer({ field, draw, action }: RenderEvent) {
      var isDisabled =
        this.data.dsPolicy.Type === '-- Select --' ||
        this.data.dsPolicy.StudentAge === 0 ||
        this.data.dsPolicy.DependentAge === 0 ||
        this.data.dsPolicy.TaxJurisdiction_Id === null
          ? true
          : false;
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }

    detail_card({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Display
            required
            field={field("Type")}
            size="medium"
            textStyle="bold"
            toolTip={
              <CustomToolTip
                header="Type (options are Group or Individual) "
                bodyContent="Marketing only field. Group accounts have employees. 
                             An individual account is a Business Owner only account."
              />
            }
          />
          <Line>
            <Display
              required
              field={field("DependentAge")}
              toolTip={
                <CustomToolTip
                  header="Dependent Age "
                  bodyContent="Typically 20, meaning coverage ends at 21."
                />
              }
            />
            <Display
              required
              field={field("StudentAge")}
              toolTip={
                <CustomToolTip
                  header="Student Age"
                  bodyContent="Typically 24, meaning coverage ends at 25."
                />
              }
            />
          </Line>
          <Line>
            <Display
              required
              field={field("TaxJurisdiction_Id")}
              toolTip={
                <CustomToolTip
                  header="Province"
                  bodyContent="Each claim is taxed based on the home address of the employee."
                />
              }
            />
            <div className="employee-edit-bencat">
              <div className="RT-TextBox edit-employee-ben-cat">
                <label className="RT-Label">
                  Representative
                </label>
                <CustomDropdown
                    options={representatives}
                    selectedOption={this.data.dsPolicy.Representative ?? this.state.selectedRep}
                    setSelectedOption={this.setSelectedOption}
                />
              </div>
            </div>
            {/* <Display field={field("InstantReleaseAmount")} /> */}
          </Line>
        </Card>
      );
    }

    setSelectedOption = async (selectedOption: any) => {
      var findOption = representatives.find((option) => option == selectedOption);
      this.setState({ 
        selectedRep: findOption
      });
      this.data.dsPolicy.Representative = findOption;
    };


    async submit({ screen }: ActionEvent) {
      
      if(this.data.dsPolicy.Representative == undefined || 
        this.data.dsPolicy.Representative == "-- Select --" || 
        this.data.dsPolicy.Representative == "NONE" )
      {
        this.data.dsPolicy.Representative = null;
      }

      await KTMApi.action(screen, "submit", {
        "@dsPolicy.Name": this.data.dsPolicy.Name,
        "@dsPolicy.DependentAge": this.data.dsPolicy.DependentAge,
        "@dsPolicy.StudentAge": this.data.dsPolicy.StudentAge,
        "@dsPolicy.TaxJurisdiction_Id": this.data.dsPolicy.TaxJurisdiction_Id,
        "@dsPolicy.Type": this.data.dsPolicy.Type,
        "@dsPolicy.Representative": this.data.dsPolicy.Representative,
        "@policy_id": this.props.policy_id,
      });
      screen.recordChanged("updated", this.props.policy_id);
      screen.close({ continue: true });
    }
  }
);
