import {
  NotificationToast,
  Spinner,
  Application,
  Card,
  Display,
  Tab,
  MessageBox,
  ICanvasError,
  ErrorDialog,
  Confirm,
  Layout,
  ButtonGroup,
  RecordHeader,
  ColorSwatch,
  Column,
  DataGrid,
  EmptyState,
  FileUpload,
  FilterBox,
  Icon,
  Line,
  NavLink,
  Panel,
  RadioButtons,
  RecordTabs,
  TabSet,
  Timeline,
  Url,
  Screen,
  Spacer,
  Text,
} from "./ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Dialog } from "ktm-ui";
import { Navigator } from "ktm-ui/components/Navigator";
import Signin from "ktm-ui/components/Signin";
import { ISigninProps } from "ktm-ui/core/Application";
import { BrowserRouter, Route } from "react-router-dom";
import { FiEdit, FiUsers } from "react-icons/fi";
import { BiBuildings, BiBarChartSquare } from "react-icons/bi";
import { FaHandHoldingUsd } from "react-icons/fa";
import { HiOutlineSearchCircle } from "react-icons/hi";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { CustomLoading } from "components/CustomLoading";
import { Button, Modal } from "react-bootstrap";

Application.addComponents("UI", {
  Card,
  Display,
  Tab,
  Layout,
  Button,
  DialogHeader: Dialog.Header,
  DialogFooter: Dialog.Footer,
  ButtonGroup,
  RecordHeader,
  ColorSwatch,
  Column,
  DataGrid,
  EmptyState,
  FileUpload,
  FilterBox,
  Icon,
  Line,
  NavLink,
  Panel,
  RadioButtons,
  RecordTabs,
  TabSet,
  Timeline,
  Screen,
  Spacer,
  Text,
});

Application.instance.getMessageBox = () => MessageBox as any;
Application.instance.getConfirmDialog = () => Confirm as any;
Application.instance.doPostRequest = KTMApi.doPostRequest;
Application.instance.renderError = (error: ICanvasError) => (
  <ErrorDialog error={error} />
);
Application.instance.host = "";
Application.instance.renderSignin = (props: ISigninProps) => {
  return (
    <Signin
      app={props.app}
      done={props.done}
      token={props.token}
      settings={props.app.signinSettings}
    />
  );
};

let userVal = null;
function App() {
  const [user, setUser] = useState(false);
  const [hideFinancials, setHideFinancials] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getToken() {
      await fetch("/api/PlanAdmin/validateadmin")
        .then((res) => res.json())
        .then((res) => {
          setUser(res.disableAccess);
          setHideFinancials(res.hideFinancials);
        })
        .catch((err) => {
          return err;
        });
    }

    getToken();
    setLoading(false);
  }, []);

  userVal = user;

  const homeStyle = {
      backgroundColor: "#f0f2f4",
      margin: "auto",
      height: "100vh",
  };

  return (
    <div className="App">
      <NotificationToast />
      <Spinner />
      {loading ? <CustomLoading /> : 
        <div style={homeStyle}>
          <BrowserRouter>
            <Route path="/" render={(routeProps) => <RenderNavigator routeProps={routeProps} hideFinancials={hideFinancials} />} />
          </BrowserRouter>
        </div>
      }
    </div>
  );
}

function RenderNavigator(props) {
  const [showPolicyHolders, setShowPolicyHolders] = useState(false);
  const [selectedPolicyHolder, setSelectedPolicyHolder] = useState(extractPolicyId(props.routeProps.location.search));
  let location = props.routeProps.location;
  let parsed = Url.parse(location.pathname, location.search);
  const brandInfo = useBrandInfo(location.pathname, location.search);
  const policyHolders = usePolicyHolders(parsed.screen);

  let urlPath = location.pathname;
  if (parsed.screen === "undefined") parsed.screen = undefined;

  if (urlPath === "/") window.location.href = "/ops";

  const customLogoStyle = brandInfo.brandLogo !== "/nhclogo.png" ? { padding: "0" } : {};

  let navigatorDisplay = !parsed.screen ? "none" : "block";

  let menu;
  if (urlPath === "/ops" && userVal === false) {
    menu = (
      <>
        <div className="home-card-container" style={{display: "flex", flexWrap: "wrap", height: "100%"}}>
          <Panel>
            <Column>
              <a href="/ops/enroll.policy.browse" className="home-card">
                <div className="home-card-icon">
                  <FiEdit color={"#223e7f"} size={25} />
                </div>
                <div>
                  <div className="RT-Section__title">Enrollment</div>
                  <div className="RT-Static-Field__content">
                    Add new or manage pending enrollments
                  </div>
                </div>
              </a>
            </Column>
            <Column>
              <a href="/ops/active.policy.browse" className="home-card">
                <div className="home-card-icon">
                  <BiBuildings color={"#223e7f"} size={27} />
                </div>
                <div>
                  <div className="RT-Section__title">Companies</div>
                  <div className="RT-Static-Field__content">
                    Manage existing companies
                  </div>
                </div>
              </a>
            </Column>
            <Column>
              <a href="ops/advisors" className="home-card">
                <div className="home-card-icon">
                  <FiUsers color={"#223e7f"} size={25} />
                </div>
                <div>
                  <div className="RT-Section__title">Advisors</div>
                  <div className="RT-Static-Field__content">
                    Manage distro accounts and advisors
                  </div>
                </div>
              </a>
            </Column>
          </Panel>
          <Panel>
          {!props.hideFinancials && (
            <>
              <div className="RT-Column financials-card" style={{flexBasis: 0, flexGrow: 1}}>
                <a href="ops/financials" className="home-card">
                      <div className="home-card-icon">
                        <FaHandHoldingUsd color={"#223e7f"} size={24} />
                      </div>
                      <div>
                        <div className="RT-Section__title">Financials</div>
                        <div className="RT-Static-Field__content">
                          Admin financial operations
                        </div>
                      </div>
                    </a>
              </div>
              <div className="RT-Column reports-card" style={{flexBasis: 0, flexGrow: 1}}>
                <a href="ops/dashboardreport" className="home-card">
                  <div className="home-card-icon">
                    <BiBarChartSquare color={"#223e7f"} size={30} />
                  </div>
                  <div>
                    <div className="RT-Section__title">Reports</div>
                    <div className="RT-Static-Field__content">
                      General NHC reports
                    </div>
                  </div>
                </a>
              </div>
            </>
            )}
            <div className="RT-Column search-home-card" style={{flexBasis: 0, flexGrow: 1}}>
              <a href="ops/search" className="home-card search-card-link">
                <div className="home-card-icon">
                  <HiOutlineSearchCircle color={"#223e7f"} size={30} />
                </div>
                <div>
                  <div className="RT-Section__title">Search</div>
                  <div className="RT-Static-Field__content">
                    Company and user search
                  </div>
                </div>
              </a>
            </div>
          </Panel>
        </div>
      </>
    );
  } else if (urlPath.indexOf("/ops/planadmin") === -1 && userVal === true) {
    Application.instance.session.signout();
  }

  const mainMenuStyle = parsed.screen === "planadmin.policy.record" ? { display: "none" } : { borderRight: "1px solid #e0e0e0", paddingRight: 15 };
  
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {showPolicyHolders && (
        <PolicyHoldersModal 
          onClose={() => setShowPolicyHolders(false)}
          onSubmit={(policy_id) => {
            setShowPolicyHolders(false);
            setSelectedPolicyHolder(policy_id);
            props.routeProps.history.push(`/ops/planadmin.policy.record?policy_id=${policy_id}`);
            window.location.reload();
          }}
          policyHolders={policyHolders}
          selectedPolicyHolder={selectedPolicyHolder}
        />
      )}
      <div className="nhc-nav-bar" style={{backgroundColor:brandInfo.brandColor}}>
        <div>
          <img className="nav-logo" src={brandInfo.brandLogo} style={customLogoStyle}></img>
        </div>
        <div
          style={{
            alignItems: "flex-end",
            marginRight: 15,
            marginTop: 5,
          }}
        >
          <button
            style={mainMenuStyle}
            onClick={() => {
              props.routeProps.history.push("/ops");
            }}
          >
            Main Menu
          </button>
          {(parsed.screen === "planadmin.policy.record" && policyHolders.length > 1) && (
          <button
            onClick={() => setShowPolicyHolders(!showPolicyHolders)}
            style={{ borderRight: "1px solid #e0e0e0", paddingRight: 15}}
          >
            Select Company
          </button>
          )}
          <button
            style={{ paddingLeft: 15 }}
            onClick={() => Application.instance.session.signout()}
          >
            Logout
          </button>
        </div>
      </div>
      <div style={{ flexGrow: 1 }}>
        {menu}
        <div key="navigator" style={{ display: navigatorDisplay }}>
          <Navigator
            appName={parsed.app}
            domain={parsed.domain}
            screen={parsed.screen}
            branch={parsed.branch}
            screenProps={parsed.search}
            history={props.routeProps.history}
          />
        </div>
      </div>
    </div>
  );
}

async function fetchAndParseUrl(path, search, resource) {
  let parsed = Url.parse(path, search);
  if (parsed.screen === "undefined") {
    parsed.screen = undefined;
  }
  
  if(parsed.search.policy_id === undefined) return;
  
  const response = await fetch(`/api/Settings/${resource}?policyId=${parsed.search.policy_id}`);
  const res = await response.json();
  if (res.success) {
    return res;
  } else {
    throw new Error('Failed to fetch data');
  }
}

function useBrandInfo(pathname, search) {
  const [brandLogo, setBrandLogo] = useState("/nhclogo.png");
  const [brandColor, setBrandColor] = useState("#fff");
  const [error, setError] = useState(null);

  useEffect(() => {

    async function getBrandLogo() {
      try {
        const res = await fetchAndParseUrl(pathname, search, 'getcompanybrandlogo');
        setBrandLogo("https://nhcfilestorage.blob.core.windows.net/images/"+res.companyBrandLogo);
      } catch (error) {
        setError(error);
      }
    }

    async function getBrandColor() {
      try {
        const res = await fetchAndParseUrl(pathname, search, 'getcompanybrandcolor');
        setBrandColor(res.companyBrandColor);
      } catch (error) {
        setError(error);
      }
    }
  
    getBrandLogo();
    getBrandColor();
  }, [pathname, search]);

  return {brandLogo, brandColor, error};
}

function PolicyHoldersModal(props) {
  const [selectedPolicyHolder, setSelectedPolicyHolder] = useState(props.selectedPolicyHolder);

  const submit = () => {
    props.onSubmit(selectedPolicyHolder);
  }

  return (
    <Modal show={true} onHide={props.onClose} style={{top:100}}>
      <Modal.Header>
        <Modal.Title style={{fontSize:"17px"}}>Companies</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <select
          className="form-select"
          value={selectedPolicyHolder}
          onChange={(e) => setSelectedPolicyHolder(e.target.value)}
          style={{ width: "300px" }}
        >
          {props.policyHolders.map((policyHolder) => (
            <option value={policyHolder.policyId}>{policyHolder.name}</option>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>Close</Button>
        <Button variant="primary" onClick={submit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
}

function extractPolicyId(input: string): string | null {
  const regex = /policy_id=([a-f0-9\-]+)/;
  const match = input.match(regex);
  
  return match ? match[1] : null;
}

function usePolicyHolders(screen) {
  const [policyHolders, setPolicyHolders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("/api/PlanAdmin/assignedpolicies");
        const res = await response.json();
        if (res.success)
        {
          setPolicyHolders(res.policies);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        setError(error);
      }
    }
    fetchData();
  }, [screen]);

  return policyHolders;
}

export default App;
