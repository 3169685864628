import { type } from "os";
import { ExpressionOps } from "./ExpressionOps";
import { Parser } from "./Parser";
import { Serializer } from "./Serializer";
import { TypeChecking } from "./TypeChecking";
import { IEvalContext } from "./types";

export class Evaluator {
    public static evaluate(context:IEvalContext,expr:string):any {
        let f = context.canvas.expressionCache[expr];
        if (f){
            return f(ExpressionOps,context);
        }
        let typeChecking = new TypeChecking();
        typeChecking.rootSchema = context.canvas.schema;
        typeChecking.defaultRecord = context.canvas.defaultRecord;
        let node = Parser.parse(expr,typeChecking);
        if (!node) return null;
        var js = Serializer.serialize(node);
        if (js){
            let exprFunc = Serializer.createExpressionFunction(js);
            context.canvas.expressionCache[expr] = exprFunc;
            return exprFunc(ExpressionOps,context);
        }
    }
}