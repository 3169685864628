import React, { Component, useState } from "react";
import {
  KTMScreen,
  // ActionEvent,
  // RenderEvent,
  // renderScreen,
  // Button,
} from "ktm-ui";
import toastr from "toastr"
import { Button, Table, Container, Row, Col, Form, FormCheck, Modal, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { isTemplateTail } from "typescript";
import { FaTruckMonster } from "react-icons/fa";
import Cost_centres from "planadmin/policy/Cost_centres";
import axios from "axios";
import querystring from "querystring";
import FileUploader from 'file-uploader-js';

export default KTMScreen(
  { name: "migrationdashboard" },
  class extends React.Component<any> {
    state = {
      companies: [],
      showModal: false,
      change: true,
      multiSelect: [],
      checkRow: {
        legalName: "initial",
        id: ""
      },
      unselectable: [],
      selected: [],
      selectedFile: null,
      uploadDisabled: true,
      buttonText: <div>Upload</div>
    };

    componentDidMount() {
      fetch("/api/Migration/getcompanies", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => this.setState({ companies: data }));
    }

    resetDB() {
      fetch("/api/Migration/resetdb", {
        method: "GET",
      })
        .then((response) => console.log(response));
    }

    uploadcsv = () => {

      toastr.success("Please Wait", "Uploading");

      var formData = new FormData();
      formData.append("csvdata", this.state.selectedFile);

      this.setState({ uploadDisabled: true, buttonText: <Spinner as="span" animation={"border"} /> });

      axios
        .post(
          "/api/Migration/insertcsv",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }
        ).then(function (response) {

          toastr.success("Reloading...", "CSV Imported");
          window.location.reload();
        })
        .catch((error) => {
          return false;
          toastr.error(error.response.data.error_description);
        });
      // var body = {
      //   csvdata: this.state.selectedFile
      // }
      //  axios({
      //    method: 'post',
      //    url: '/api/Migration/insertcsv',
      //    body: body
      //  })
      //    .then(function (response) {
      //      toastr.success("Status Updated", "Success");
      //    })
      //    .catch((error) => {
      //      return false;
      //      toastr.error(error.response.data.error_description);
      //    });
    }

    updateStatus = (Id: string, Status: string) => {
      var body = {
        Id: Id,
        Status: Status
      }
      axios({
        method: 'post',
        url: '/api/Migration/setstatus',
        data: body
      })
        .then(function (response) {
          toastr.success("Status Updated", "Success");
        })
        .catch((error) => {
          return false;
          toastr.error(error.response.data.error_description);
        });
    }

    updateStatusBulk = () => {
      let indexes = new Array<number>(this.state.multiSelect.length);
      var i = 0;
      this.state.multiSelect.forEach((id) => {
        indexes[i] = this.state.companies.findIndex(item => item.id === id);
        i++;
      });
      var arr = this.state.companies;
      indexes.forEach((index) => {
        arr[index].status = "queued";
        console.log(arr);
      });
      this.setState({ companies: arr, selected: [] });
      if (this.state.change) {
        this.setState({ change: false });
      } else {
        this.setState({ change: true });
      }

      var body = {
        Ids: this.state.multiSelect,
        Status: "queued"
      }
      axios({
        method: 'post',
        url: '/api/Migration/setstatusbulk',
        data: body
      })
        .then(function (response) {
          toastr.success("Status Updated", "Success");
        })
        .catch((error) => {
          return false;
          toastr.error(error.response.data.error_description);
        });
    }


    render() {

      const setSelectedFile = (e) => this.setState({ selectedFile: e, uploadDisabled: false });
      const handleClose = () => this.setState({ showModal: false });
      const handleCloseActivate = () => {
        this.setState({ showModal: false });
        var arr = this.state.companies;
        arr[arr.indexOf(this.state.checkRow)].status = "active";
        this.setState({ companies: arr });
        if (this.state.change) {
          this.setState({ change: false });
        } else {
          this.setState({ change: true });
        }
        this.updateStatus(this.state.checkRow.id, "active");
      }
      const handleShow = (row) => this.setState({ showModal: true, checkRow: row });
      const paginationOption = {
        custom: true,
        totalSize: this.state.companies.length
      };

      const selectOptions = {
        true: "Yes",
        false: "No"
      }

      const selectOptions2 = {
        "true": 'true',
        "false": 'false'
      }

      const statusSelectOptions = {
        "healthclaimactive": "HealthClaim Active",
        "queued": "Queued",
        "migration1": "Migration 1",
        "migration2": "Migration 2",
        "downloaded": "Downloaded",
        //"migrating": "Migrating",
        "active": "Active"
      }

      const expandRow = {
        renderer: row => (
          <div>
            <p>{`This Expand row is belong to rowKey ${row.id}`}</p>
            <p>You can render anything here, also you can add additional data on every row object</p>
            <p>expandRow.renderer callback will pass the origin row object to you</p>
          </div>
        )
      };

      const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        nonSelectable: this.state.unselectable,
        hideSelectAll: false,
        selected: this.state.selected,
        onSelect: (row, isSelect) => {
          if (isSelect) {
            var arr = this.state.multiSelect;
            arr.push(row.id);
            this.setState({ multiSelect: arr, selected: [...this.state.selected, row.id] });
            console.log(this.state.multiSelect);
          }
          else {
            var arr = this.state.multiSelect;
            arr.splice(arr.indexOf[row.id], 1);
            this.setState({ multiSelect: arr, selected: this.state.selected.filter(x => x !== row.id) });
            console.log(this.state.multiSelect);
          }
        },
        onSelectAll: (isSelect, rows) => {
          const ids = rows.map(r => r.id);
          if (isSelect) {
            var arr = this.state.multiSelect;
            rows.forEach((row) => {
              arr.push(row.id);
            })
            this.setState({ multiSelect: arr, selected: ids });
            console.log(this.state.multiSelect);
          } else {
            var arr = this.state.multiSelect;
            arr.length = 0;
            this.setState({ multiSelect: arr, selected: [] });
            console.log(this.state.multiSelect);
          }
        },
      };
      const columns = [
        {
          dataField: "legalName",
          text: 'Company',
          filter: textFilter()
        },
        {
          dataField: "id",
          text: "HealthClaim ID",
          headerStyle: (colum, colIndex) => {
            return { width: '175px' };
          }
        },
        {
          dataField: "status",
          text: "Migration/Enrollment Status",
          filter: selectFilter({
            options: statusSelectOptions
          }),
          formatter: (cell, row, rowIndex) => {
            if (row.status != "healthclaimactive") {
              var arr = this.state.unselectable;
              arr.push(row.id);
              this.setState({ unselectable: arr })
              if (row.status == "queued") {
                return "Queued";
              } else if (row.status == "migration1") {
                return "Migration 1";
              } else if (row.status == "migration2") {
                return "Migration 2";
              } else if (row.status == "downloaded") {
                return "Downloaded";
              } else if (row.status == "active") {
                return "Active";
              }
            }
            else {
              return "HealthClaim Active";
            }
          },
          headerStyle: (colum, colIndex) => {
            return { width: '160px' };
          }
        },
        {
          dataField: "activeEmployees",
          text: "Total Employees",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '100px' };
          }
        },
        {
          dataField: "distroId",
          text: "Distro",
          //sort: true,
          filter: textFilter({
            comparator: Comparator.EQ
          }),
          headerStyle: (colum, colIndex) => {
            return { width: '100px' };
          }
        },
        {
          dataField: "advisorId",
          text: "Advisor",
          //sort: true,
          filter: textFilter({
            comparator: Comparator.EQ
          }),
          headerStyle: (colum, colIndex) => {
            return { width: '100px' };
          }
        },
        {
          dataField: "benefitStartDate",
          text: "Benefit Start Date",
          formatter: (cell, row, rowIndex) => {
            var formattedDate = row.benefitStartDate.split("T");
            return formattedDate[0];
          }
        },
        {
          dataField: "benefitEndDate",
          text: "Benefit End Date",
          formatter: (cell, row, rowIndex) => {
            var formattedDate = row.benefitEndDate.split("T");
            return formattedDate[0];
          }
        },
        {
          dataField: "runoffDate",
          text: "Runoff End Date",
          formatter: (cell, row, rowIndex) => {
            var formattedDate = row.runoffDate.split("T");
            return formattedDate[0];
          }
        },
        {
          text: "Has CoreHealth",
          dataField: "hasCh",
          formatter: (cell: any, row: { hasCh: any; }, rowIndex: any) => {
            if (row.hasCh) {
              return <div>✔️</div>
            } else {
              return <div>❌</div>
            }
          },
          filter: selectFilter({
            options: selectOptions
          }),
          headerStyle: (colum, colIndex) => {
            return { width: '100px' };
          }
        },
        {
          text: "Has EDIS",
          dataField: "hasEdis",
          formatter: (cell: any, row: { hasEdis: any; }, rowIndex: any) => {
            if (row.hasEdis) {
              return <div>✔️</div>
            } else {
              return <div>❌</div>
            }
          },
          filter: selectFilter({
            options: selectOptions
          }),
          headerStyle: (colum, colIndex) => {
            return { width: '100px' };
          }
        },
        {
          text: "Notification Sent",
          dataField: "isNotificationSent",
          formatter: (cell: any, row: { isNotificationSent: boolean; }, rowIndex: any) => {
            // return <div>{row.isNotificationSent}</div>
            if (row.isNotificationSent) {
              return <div>✔️</div>
            } else {
              return <div>❌</div>
            }
          },
          filter: selectFilter({
            options: selectOptions
          }),
          headerStyle: (colum, colIndex) => {
            return { width: '100px' };
          }
        },
        {
          text: "Notification Last Sent",
          dataField: "notificationLastSent",
          formatter: (cell, row, rowIndex) => {
            if (row.notificationLastSent != null) {
              var formattedDate = row.notificationLastSent.split("T");
              return formattedDate[0];
            } else {
              return row.notifcationLastSent;
            }

          }
        },
        {
          text: "Action",
          isDummyField: true,
          formatExtraData: this.state.change,
          formatter: (cell: any, row: { status: string; id: any; legalName: string; }, rowIndex: any, extraData: any) => {
            if (row.status == "active") {
              return;
            }
            else if (row.status == "migration1") {
              var url = '/ops/enroll.policy.record?policy_id=' + row.id + "&preview=1";
              return <div><a href={url} target="_blank"><Button style={{ marginRight: "6px" }}>Preview</Button></a></div>
            }
            else if (row.status == "migration2") {
              var url = '/ops/enroll.policy.record?policy_id=' + row.id + "&preview=1";
              return <div><a href={url} target="_blank"><Button style={{ marginRight: "6px" }}>Preview</Button></a><Button variant="danger" onClick={() => { handleShow(row) }}>Activate</Button></div>
            }
            else if (row.status == "downloaded") {
              return <Button onClick={() => {
                var arr = this.state.companies;
                arr[arr.indexOf(row)].status = "migration1";
                this.setState({ companies: arr });
                if (this.state.change) {
                  this.setState({ change: false });
                } else {
                  this.setState({ change: true });
                }
                this.updateStatus(row.id, "Migrating");
              }}>Migrate</Button>
            }
            else if (row.status == "queued") {
              return <Button onClick={() => {
                var arr = this.state.companies;
                arr[arr.indexOf(row)].status = "healthclaimactive";
                this.setState({ companies: arr });
                if (this.state.change) {
                  this.setState({ change: false });
                } else {
                  this.setState({ change: true });
                }
                this.updateStatus(row.id, "healthclaimactive");
                this.setState({ unselectable: this.state.unselectable.filter(x => x !== row.id) });
              }}>Unqueue</Button>
            }
            else if (row.status == "healthclaimactive") {
              return <Button onClick={() => {
                var arr = this.state.companies;
                arr[arr.indexOf(row)].status = "queued";
                this.setState({ companies: arr });
                if (this.state.change) {
                  this.setState({ change: false });
                } else {
                  this.setState({ change: true });
                }
                this.updateStatus(row.id, "queued");
              }}>Queue</Button>
            }
          }
        }]

      return (
        <div>
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col xs="11">
                <Row>
                  <Col xs="2"><Button onClick={this.updateStatusBulk}>Queue Selected</Button></Col>
                  <Col xs="2"><FileUploader accept=".csv" title="Upload csv file here" uploadedFileCallback={e => {
                    setSelectedFile(e);
                  }} /></Col>
                  <Col xs="2"><Button onClick={this.uploadcsv} disabled={this.state.uploadDisabled}>{this.state.buttonText}</Button></Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs="11">
                {/* <PaginationProvider
                pagination={paginationFactory(paginationOption)}
              >
                {
                  ({When we r
                    paginationProps,
                    paginationTableProps
                  }) => ( */}

                {/* <Button onClick={this.resetDB}>test</Button> */}
                {/* <input type='file' onChange={(e) => setSelectedFile(e)}/> */}

                <BootstrapTable striped hover bootstrap4 keyField='id' columns={columns} ex data={this.state.companies} pagination={paginationFactory()} filter={filterFactory()} selectRow={selectRow}></BootstrapTable>
                {/*}   )
                 }
               </PaginationProvider> 
              {/* <Table striped bordered hover>
                <thead>
                  <th></th>
                  <th>Company</th>
                  <th>HealthClaim ID</th>
                  <th>Migration/Enrollment Status</th>
                  <th>Total Employees</th>
                  <th>Distro</th>
                  <th>Advisor</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {this.state.companies.map((item, i) => {
                    return (
                      <tr>
                        <td><input type="checkbox"/></td>
                        <td>{item.legalName}</td>
                        <td>{item.id}</td>
                        <td>{item.status}</td>
                        <td>{item.activeEmployees}</td>
                        <td>{item.distroId}</td>
                        <td>{item.advisorId}</td>
                        <td><a>Action</a></td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table> */}
              </Col>
            </Row>
          </Container>
          <Modal show={this.state.showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to activate {this.state.checkRow.legalName}?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={handleCloseActivate}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
);
