import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Display,
  Line,
  Card,
  Column,
  Panel,
} from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  HasFlex: boolean;
  HSAAdminRate: number;
  HSAMinAdminFee: number;
  HSAMaxAdminFee: number;
  CarryFwdHSA: string;
  LSAAdminRate: number;
  LSAMinAdminFee: number;
  LSAMaxAdminFee: number;
  CarryFwdLSA: string;
}

export default KTMScreen(
  { name: "enroll.product.edit-flex" },
  class extends React.Component<{ policyBenefit_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.title = "Edit product Flex settings";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit Flex" />;
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.hsa_details)}
            {draw(this.lsa_details)}
          </Column>
        </Panel>
      );
    }

    hsa_details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display
              required
              field={field("HSAAdminRate")}
              toolTip={
                <CustomToolTip
                  header="HSA Admin Rate"
                  bodyContent="Typically 10% The administration fee applied per approved claim (plus tax). The amount of tax is determined by the Tax Jurisdiction of your policy."
                />
              }
            />
            <Display
              required
              field={field("HSAMinAdminFee")}
              toolTip={
                <CustomToolTip
                  header="HSA Min Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a minimum admin charge applied per claim."
                />
              }
            />
            <Display
              required
              field={field("HSAMaxAdminFee")}
              toolTip={
                <CustomToolTip
                  header="HSA Max Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a maximum admin charge applied per claim."
                />
              }
            />
          </Line>
          <Display
            required
            field={field("CarryFwdHSA")}
            toolTip={
              <CustomToolTip
                header="HSA Carry Fwd"
                bodyContent="Choose between Credit, Expense or No CarryForward."
              />
            }
          />
        </Card>
      );
    }

    lsa_details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display
              required
              field={field("LSAAdminRate")}
              toolTip={
                <CustomToolTip
                  header="LSA Admin Rate"
                  bodyContent="Typically 10% The administration fee applied per approved claim (plus tax). The amount of tax is determined by the Tax Jurisdiction of your policy."
                />
              }
            />
            <Display
              required
              field={field("LSAMinAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Min Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a minimum admin charge applied per claim."
                />
              }
            />
            <Display
              required
              field={field("LSAMaxAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Max Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a maximum admin charge applied per claim. "
                />
              }
            />
          </Line>
          <Display
            required
            field={field("CarryFwdLSA")}
            toolTip={
              <CustomToolTip
                header="LSA CarryFwd"
                bodyContent="Choose between Credit, Expense or No CarryForward."
              />
            }
          />
        </Card>
      );
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    async submit({ screen }: ActionEvent) {
      if (this.data.dsBenefit.CarryFwdHSA === "No Carry Forward" || this.data.dsBenefit.CarryFwdHSA === "") {
        this.data.dsBenefit.CarryFwdHSA = null;
      }
      if (this.data.dsBenefit.HSAAdminRate === null) {
        this.data.dsBenefit.HSAAdminRate = 0;
      }
      if (this.data.dsBenefit.HSAMinAdminFee === null) {
        this.data.dsBenefit.HSAMinAdminFee = 0;
      }
      if (this.data.dsBenefit.HSAMaxAdminFee === null) {
        this.data.dsBenefit.HSAMaxAdminFee = 0;
      }

      if (this.data.dsBenefit.CarryFwdLSA === "No Carry Forward" || this.data.dsBenefit.CarryFwdLSA === "") {
        this.data.dsBenefit.CarryFwdLSA = null;
      }
      if (this.data.dsBenefit.LSAAdminRate === null) {
        this.data.dsBenefit.LSAAdminRate = 0;
      }
      if (this.data.dsBenefit.LSAMinAdminFee === null) {
        this.data.dsBenefit.LSAMinAdminFee = 0;
      }
      if (this.data.dsBenefit.LSAMaxAdminFee === null) {
        this.data.dsBenefit.LSAMaxAdminFee = 0;
      }

      await KTMApi.action(screen, "submit", {
        "@dsBenefit.HSAAdminRate": this.data.dsBenefit.HSAAdminRate,
        "@dsBenefit.HSAMinAdminFee": this.data.dsBenefit.HSAMinAdminFee,
        "@dsBenefit.HSAMaxAdminFee": this.data.dsBenefit.HSAMaxAdminFee,
        "@dsBenefit.CarryFwdHSA": this.data.dsBenefit.CarryFwdHSA,
        "@dsBenefit.LSAAdminRate": this.data.dsBenefit.LSAAdminRate,
        "@dsBenefit.LSAMinAdminFee": this.data.dsBenefit.LSAMinAdminFee,
        "@dsBenefit.LSAMaxAdminFee": this.data.dsBenefit.LSAMaxAdminFee,
        "@dsBenefit.CarryFwdLSA": this.data.dsBenefit.CarryFwdLSA,
        "@dsBenefit.HasFlex": this.data.dsBenefit.HasFlex,
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.close({ continue: true });
    }
  }
);
