import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Display,
  Line,
  Card,
  Column,
  Panel
} from "ktm-ui";
import CustomToolTip from "components/CustomToolTip";
import { CustomDropdown } from "components/CustomDropdown";
import "../../styles/Employee.css";
import moment from "moment";
import toastr from "toastr";
import { Modal } from "react-bootstrap";
import ReactButton from "react-bootstrap/Button";
import ReactMoment from "react-moment";

interface IdsEmployee {
  Id: string;
  Policy_Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MaidenName: string;
  Nickname: string;
  EmployeeNo: string;
  DateOfBirth: string;
  HireDate: string;
  BenefitCategory_Id: string;
  WaitingPeriodMonths: number;
  HasProrating: boolean;
  FlexLaunchDate: string;
  FlexReminderDate: string;
  FlexLastDayReminderDate: string;
  FlexSelectionDeadline: string;
  SalaryMoney: number;
  TeladocWaitingPeriodMonths: number;
}

interface IdsDependents {
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  Relation: string;
  IsStudent: boolean;
}

interface IdsPolicy {
  Id: string;
  IsActive: boolean;
  HasTeladoc: boolean;
}

const waitingPeriodMonths = [
  { value: null, label: "--Select--" },
  { value: 0, label: "None" },
  { value: 1, label: "1 month" },
  { value: 2, label: "2 months" },
  { value: 3, label: "3 months" },
  { value: 4, label: "4 months" },
  { value: 5, label: "5 months" },
  { value: 6, label: "6 months" },
  { value: 7, label: "7 months" },
  { value: 8, label: "8 months" },
  { value: 9, label: "9 months" },
  { value: 10, label: "10 months" },
  { value: 11, label: "11 months" },
  { value: 12, label: "12 months" },
];

const TeladocWaitingPeriodMonths = [
  { value: null, label: "--Select--" },
  { value: 0, label: "None" },
  { value: 1, label: "1 month" },
  { value: 2, label: "2 months" },
  { value: 3, label: "3 months" },
  { value: 4, label: "4 months" },
  { value: 5, label: "5 months" },
  { value: 6, label: "6 months" },
  { value: 7, label: "7 months" },
  { value: 8, label: "8 months" },
  { value: 9, label: "9 months" },
  { value: 10, label: "10 months" },
  { value: 11, label: "11 months" },
  { value: 12, label: "12 months" },
];

export default KTMScreen(
  { name: "planadmin.employee.add" },
  class extends React.Component<{ policy_id: string }> {
    data: {
      dsEmployee: IdsEmployee;
      dsDependents: IdsDependents[];
      dsPolicy: IdsPolicy;
    };

    state = {
      showStartDateModal: false,
      showStartDateInput: false,
      employeeStartDate: null,
      overrideEmployeeStartDate: null,
      effectiveDate: null,
      isFlex: false,
      options: [],
      selectedOption: null,
      creditCalcMethod: null,
      selectedWaitingPeriod: waitingPeriodMonths[0],
      selectedTeladocWaitingPeriod: TeladocWaitingPeriodMonths[0],
      isEligibleForCurrenYear: false,
      showReinstateModal: false,
      existingEmployeeId: null,
      showDuplicateEmailModal: false,
      isBelongToCurrentPolicy: false
    };

    render() {
      let startDateInput = null;
      if (this.state.showStartDateInput) {
        startDateInput = (
          <div className="RT-TextBox__container">
            <div>
              <div style={{ paddingBottom: 10 }}>PA Approved Start Date</div>
              <div>
                <input
                  value={this.state.overrideEmployeeStartDate}
                  onChange={(e) => {
                    this.setState({
                      overrideEmployeeStartDate: e.target.value,
                    });
                  }}
                  className="form-control"
                  type="date"
                />
              </div>
            </div>
          </div>
        );
      }

      if (this.state.showStartDateModal) {
        var waitingPeriodMonthsLabel =
          this.state.employeeStartDate.waitingPeriodMonths &&
          this.state.employeeStartDate.waitingPeriodMonths > 1
            ? this.state.employeeStartDate.waitingPeriodMonths + " months"
            : this.state.employeeStartDate.waitingPeriodMonths + " month";

        return (
          <Modal
            className="react-modal"
            show={this.state.showStartDateModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header className="react-modal-title">
              <Modal.Title>Add Employee (Step 2)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="RT-Section">
                <div className="RT-Line">
                  <div className="RT-Line__content RT-Line__content--space-evenly">
                    <div className="RT-Line__item">
                      <div className="RT-TextBox">
                        <label>Hire Date</label>
                        <div className="RT-TextBox__container">
                          <ReactMoment format="YYYY-MM-DD">
                            {this.state.employeeStartDate.hireDate}
                          </ReactMoment>
                        </div>
                      </div>
                    </div>
                    <div className="RT-Line__item">
                      <div className="RT-TextBox">
                        <label>Waiting Period</label>
                        <div className="RT-TextBox__container">
                          {waitingPeriodMonthsLabel}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="RT-Section">
                <div className="RT-Line">
                  <div className="RT-Line__content RT-Line__content--space-evenly">
                    <div className="RT-Line__item">
                      <div className="RT-TextBox">
                        <label>
                          Based on the Hire Date and Waiting Period you entered, 
                          the system has calculated the Benefit Start Date as:
                        </label>
                        <div className="RT-TextBox__container">
                          <ReactMoment format="YYYY-MM-DD">
                            {this.state.employeeStartDate.startDate}
                          </ReactMoment>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="RT-Section">
                <div className="RT-Line">
                  <div className="RT-Line__content RT-Line__content--space-evenly">
                    <div className="RT-Line__item">
                      <div className="RT-TextBox">
                        <label>
                          If you wish to over-ride the system calculated start
                          date for this employee, please click the check box
                          and enter in your desired start date.
                        </label>
                      </div>
                    </div>
                    <div className="RT-Line__item">
                      <div className="RT-TextBox">
                        <div className="form-check" style={{ paddingLeft: 0 }}>
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.showStartDateInput}
                              onChange={() => this.toggleStartDateInput()}
                              style={{ paddingLeft: 0, marginRight: 10 }}
                            />
                            Override Employee Start Date
                          </label>
                        </div>
                        {startDateInput}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ReactButton
                variant="primary"
                onClick={() => this.finishEmployeeAdd()}
              >
                Finish Employee Add
              </ReactButton>
            </Modal.Footer>
          </Modal>
        );
      }

      return renderScreen(this);
    }

    finishEmployeeAdd = () => {
      this.update_employee_start_date();
    };

    closeStartDateModal = () => {
      this.setState({ showStartDateModal: false });
    };

    toggleStartDateInput = () => {
      this.setState({ showStartDateInput: !this.state.showStartDateInput });
    };

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsEmployee";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
      });
      screen.title = "Add employee";
      screen.setOnValueChanged(
        "@dsDependents.FirstName",
        this.DependentFirstName_onchange
      );

      await this.getEffectiveDate();
      await this.getBenefitCategories();
      screen.setOnValueChanged("@dsEmployee.Email", this.checkIfEmployeeExists);
    }

    async getEffectiveDate() {
      let response = await fetch(
        "/api/Employees/geteffectivedate?policyId=" + this.data.dsPolicy.Id
      );
      let planEffectiveDate = await response.json();
      this.setState({ effectiveDate: planEffectiveDate.date });
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout dialogSize="medium">
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
          {this.state.showReinstateModal && draw(this.reinstateModal)}
          {this.state.showDuplicateEmailModal && draw(this.duplicateEmailModal)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Add Employee (Step 1)" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      var isDisabled =
        this.data.dsEmployee.FirstName === null ||
        this.data.dsEmployee.FirstName === undefined ||
        this.data.dsEmployee.FirstName === "" ||
        this.data.dsEmployee.LastName === null ||
        this.data.dsEmployee.LastName === undefined ||
        this.data.dsEmployee.LastName === "" ||
        this.data.dsEmployee.Email === null ||
        this.data.dsEmployee.Email === undefined ||
        this.data.dsEmployee.Email === "" ||
        this.data.dsEmployee.DateOfBirth === null ||
        this.data.dsEmployee.DateOfBirth === undefined ||
        this.data.dsEmployee.DateOfBirth === "" ||
        this.data.dsEmployee.WaitingPeriodMonths === null ||
        this.data.dsEmployee.WaitingPeriodMonths === undefined ||
        this.data.dsEmployee.BenefitCategory_Id === null ||
        this.data.dsEmployee.BenefitCategory_Id === undefined ||
        this.state.existingEmployeeId !== null
          ? true
          : false;

      if (!isDisabled && Array.isArray(this.data.dsDependents) && this.data.dsDependents.length > 0) {
        const partiallyFilledDependentExists = this.data.dsDependents.some(dependent => {
          const fields = [dependent.FirstName, dependent.LastName, dependent.DateOfBirth];
          const filledFields = fields.filter(Boolean).length;
          return filledFields > 0 && filledFields < fields.length;
        });

        if (partiallyFilledDependentExists) {
          isDisabled = true;
        }
      }

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }

    dependents_table({ field, draw, action }: RenderEvent) {
      return (
        <table className="RT-Table">
          <thead>
            <tr className="RT-Table__tr">
              <th className="RT-Table__th RT-Table__th--align-left">
                First name
              </th>
              <th className="RT-Table__th RT-Table__th--align-left">
                Last name
              </th>
              <th className="RT-Table__th RT-Table__th--align-left">
                Date of birth
              </th>
              <th className="RT-Table__th RT-Table__th--align-left">
                Relation
              </th>
            </tr>
          </thead>
          <tbody>{draw(this.dependent_row, { each: "@dsDependents" })}</tbody>
        </table>
      );
    }

    dependent_row({ field }: RenderEvent) {
      return (
        <tr className="RT-Table__tr">
          <td className="RT-Table__td RT-Table__td--align-left">
            <Display
              field={field("@dsDependents.FirstName")}
              hideLabel={true}
            />
          </td>
          <td className="RT-Table__td RT-Table__td--align-left">
            <Display field={field("@dsDependents.LastName")} hideLabel={true} />
          </td>
          <td className="RT-Table__td RT-Table__td--align-left">
            <Display
              field={field("@dsDependents.DateOfBirth")}
              hideLabel={true}
            />
          </td>
          <td className="RT-Table__td RT-Table__td--align-left">
            <Display field={field("@dsDependents.Relation")} hideLabel={true} />
          </td>
        </tr>
      );
    }

    general({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display required field={field("FirstName")} />
            <Display required field={field("LastName")} />
            <Display field={field("Nickname")} />
            <Display field={field("MaidenName")} />
          </Line>
          <Line>
            <Display required field={field("Email")} span="6" />
            <Display required field={field("DateOfBirth")} />
          </Line>
        </Card>
      );
    }

    hire_date({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <div>
              By default, we enter in the Plan Effective Date as the Hire Date
              for you, but over-ride this date as needed with the employee’s
              real hire date. This date is used in combination with Waiting
              Period and Pro-rating to determine the Employee’s Benefit
              Effective Date and first year limits.
              <br />
              <br />
              You will see the calculations under the Employee’s Profile and can
              over-ride there, if needed.
            </div>
          </Line>
          <Line>
            <Display
              required
              field={field("HireDate")}
              defaultValue={
                this.state.effectiveDate != null ? this.state.effectiveDate : ""
              }
              span="6"
            />
          </Line>
        </Card>
      );
    }

    teladoc_waiting_period({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <div className="RT-Line__content RT-Line__content--space-evenly" style={{height:60}}>
              <div className="RT-Line__item" style={{ flexGrow: 0 }}>
                <div className="RT-TextBox add-employee-teladoc-waiting-period">
                  <label className="RT-Label">
                    Teladoc Waiting Period (months)
                  </label>
                  <CustomDropdown
                    options={TeladocWaitingPeriodMonths.map((option) => {
                      return option.label;
                    })}
                    selectedOption={this.state.selectedTeladocWaitingPeriod.label}
                    setSelectedOption={this.setSelectedTeladocWaitingPeriod}
                  />
                </div>
              </div>
            </div>
          </Line>
        </Card>
      );
    }

    category({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <div className="RT-Line">
            <div className="RT-Line__content RT-Line__content--space-evenly">
              <div
                className="RT-Line__item"
                style={{ height: 50, flexGrow: 0 }}
              >
                <div className="RT-TextBox add-employee-ben-cat">
                  <label className="RT-Label">
                    <span className="text-danger">*</span>
                    Benefit Category
                  </label>
                  <CustomDropdown
                    options={this.state.options.map((option) => {
                      return option.name;
                    })}
                    selectedOption={this.state.selectedOption.name}
                    setSelectedOption={this.setSelectedOption}
                  />
                </div>
              </div>
              <div className="RT-Line__item" style={{ flexGrow: 0 }}>
                <div className="RT-TextBox add-employee-waiting-period">
                  <label className="RT-Label">
                    <span className="text-danger">*</span>
                    Waiting Period (months)
                    <CustomToolTip
                      header="Waiting Period (months)"
                      bodyContent="Choose how long an employee waits after being hired for their benefits to start. "
                    />
                  </label>
                  <CustomDropdown
                    options={waitingPeriodMonths.map((option) => {
                      return option.label;
                    })}
                    selectedOption={this.state.selectedWaitingPeriod.label}
                    setSelectedOption={this.setSelectedWaitingPeriod}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 30, marginTop: 35 }}>
                <Display 
                  field={field("HasProrating")} 
                  toolTip={<CustomToolTip
                    header="Pro-rating"
                    bodyContent="If checked, pro-rating is ON and an employee’s available credits are adjusted based on the months remaining in the benefit year. If unchecked, an employee receives their full yearly limit."
                  />}
                />
              </div>
            </div>
          </div>
        </Card>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.general)}
            {draw(this.reporting)}
            {draw(this.hire_date)}
            {/*{this.data.dsPolicy.HasTeladoc && draw(this.teladoc_waiting_period)}*/}
            {draw(this.category)}
            {this.state.isFlex && this.state.isEligibleForCurrenYear
              ? draw(this.flex)
              : null}
            {this.state.creditCalcMethod === "SALARY"
              ? draw(this.salaryInput)
              : null}
            {draw(this.dependents)}
          </Column>
        </Panel>
      );
    }

    reporting({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
              <Display field={field("Division_Id")}/>
              <Display field={field("CostCentre_Id")}/>
          </Line>
        </Card>
      );
    }

    flex({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display field={field("FlexLaunchDate")} />
            <Display field={field("FlexReminderDate")} />
          </Line>
          <Line>
            <Display field={field("FlexLastDayReminderDate")} />
            <Display field={field("FlexSelectionDeadline")} />
          </Line>
        </Card>
      );
    }

    salaryInput({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display field={field("SalaryMoney")} />
          </Line>
        </Card>
      );
    }

    dependents({ field, draw, action }: RenderEvent) {
      return (
        <Card label="Dependents">
          {draw(this.dependents_table)}
          <Button action={action(this.add_child)} label="Add child" style="link" />
        </Card>
      );
    }

    reinstateModal({ draw, action }: RenderEvent) {
      const {
        showReinstateModal,
        isBelongToCurrentPolicy
      } = this.state;

      return (
        <Modal 
          show={showReinstateModal}
          centered
          style={{top:200, zIndex:9999}}
          aria-label="Reinstate Modal"
        >
          <Modal.Header>
            <Modal.Title style={{fontSize:"17px"}}>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Card>
            This address is associated with a previously terminated employee. Please update the terminated account first to avoid duplication.
            <br />
            To reinstate the terminated account instead of proceeding, click 'Reinstate Employee' to open the terminated profile in a new tab, and reinstate the employee under 'Terminations'.
            <br />
            Reinstating is effective immediately, so remember to adjust their Waiting Period, Prorate Limit, and PA Approved Benefit Start Date under their 'Benefit Limits' tab if necessary.
            <br />
            If more assistance is required, please don't hesitate to contact <a href="mailto:support@nationalhealthclaim.com" style={{"color":"#0d6efd"}}> support@nationalhealthclaim.com</a>.
          </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              label="Close"
              style="cancel"
              action={action(this.close_reinstate_modal)}
            />
            {isBelongToCurrentPolicy && (
              <Button
                label="Reinstate Employee"
                style="primary"
                action={action(this.view_existing_employee)}
              />
            )}
          </Modal.Footer>
        </Modal>
      );
    }

    duplicateEmailModal({ draw, action }: RenderEvent) {
      const {
        showDuplicateEmailModal,
        isBelongToCurrentPolicy,
      } = this.state;
    
      return (
        <Modal 
          show={showDuplicateEmailModal}
          centered
          style={{ top: 200, zIndex: 9999 }}
          aria-label="Duplicate Email Modal"
        >
          <Modal.Header>
            <Modal.Title style={{ fontSize: "17px" }}>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              The email address you entered is already associated with an existing employee. 
              If you need further assistance, please don't hesitate to contact 
              <a href="mailto:support@nationalhealthclaim.com" style={{ color: "#0d6efd" }}> support@nationalhealthclaim.com</a>.
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              label="Close"
              style="cancel"
              action={action(this.close_duplicate_email_modal)}
            />
            {isBelongToCurrentPolicy && (
              <Button
                label="Check Employee"
                style="primary"
                action={action(this.view_existing_employee)}
              />
            )}
          </Modal.Footer>
        </Modal>
      );
    }    
    
    async close_duplicate_email_modal({screen}: ActionEvent) {
      this.setState({
        showDuplicateEmailModal: false
      })
    }

    async close_reinstate_modal({screen}: ActionEvent) {
      this.setState({
        showReinstateModal: false
      })
    }

    async view_existing_employee({screen}: ActionEvent) {
      window.open(`/ops/planadmin.employee.record?employee_id=${this.state.existingEmployeeId}`, "_blank");
    }

    async submit({ screen }: ActionEvent) {
      let dependents = {
        $type: "dataset",
        name: "@dsDependents",
        rows: this.data.dsDependents,
      };

      if (
        this.data.dsEmployee.HireDate == null ||
        this.data.dsEmployee.HireDate == "" ||
        this.data.dsEmployee.HireDate == undefined
      ) {
        this.data.dsEmployee.HireDate = this.state.effectiveDate;
      }

      if (
        this.data.dsEmployee.SalaryMoney == null ||
        this.data.dsEmployee.SalaryMoney == undefined
      ) {
        this.data.dsEmployee.SalaryMoney = 0;
      }

      if (this.state.isFlex && this.state.isEligibleForCurrenYear) {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        const futureDate = moment(date).format("YYYY-MM-DD");

        const {
          FlexLaunchDate,
          FlexReminderDate,
          FlexLastDayReminderDate,
          FlexSelectionDeadline,
        } = this.data.dsEmployee;

        if (moment(FlexLaunchDate).isBefore(futureDate)) {
          toastr.error("Flex Launch Date should be greater than today's date");
          return;
        }
        if (moment(FlexLaunchDate).isAfter(FlexReminderDate)) {
          toastr.error("Flex Launch Date should be less than Flex Reminder Date");
          return;
        }
        if (moment(FlexLaunchDate).isAfter(FlexLastDayReminderDate)) {
          toastr.error("Flex Launch Date should be less than Flex Last Day Reminder Date");
          return;
        }
        if (moment(FlexLaunchDate).isAfter(FlexSelectionDeadline)) {
          toastr.error("Flex Launch Date should be less than Flex Selection Deadline");
          return;
        }
        if (moment(FlexReminderDate).isSame(FlexLaunchDate)) {
          toastr.error("Flex Reminder Date should not be same as Flex Launch Date");
          return;
        }
        if (moment(FlexReminderDate).isAfter(FlexLastDayReminderDate)) {
          toastr.error("Flex Reminder Date should be less than Flex Last Day Reminder Date");
          return;
        }
        if (moment(FlexReminderDate).isAfter(FlexSelectionDeadline)) {
          toastr.error("Flex Reminder Date should be less than Flex Selection Deadline");
          return;
        }
        if (moment(FlexReminderDate).isSame(FlexLastDayReminderDate)) {
          toastr.error("Flex Reminder Date should not be same as Flex Last Day Reminder Date");
          return;
        }
        if (moment(FlexLastDayReminderDate).isBefore(FlexLaunchDate)) {
          toastr.error("Flex Last Day Reminder Date should be greater than Flex Launch Date");
          return;
        }
        if (moment(FlexLastDayReminderDate).isSame(FlexLaunchDate)) {
          toastr.error("Flex Last Day Reminder Date should not be same as Flex Launch Date");
          return;
        }
        if (moment(FlexLastDayReminderDate).isAfter(FlexSelectionDeadline)) {
          toastr.error("Flex Last Day Reminder Date should be less than Flex Selection Deadline");
          return;
        }

        if (
          this.data.dsEmployee.SalaryMoney != null &&
          this.data.dsEmployee.SalaryMoney != undefined
        ) {
          this.data.dsEmployee.SalaryMoney = Math.round(
            this.data.dsEmployee.SalaryMoney
          );
        }
      }
      if (
        this.data.dsEmployee.TeladocWaitingPeriodMonths == null ||
        this.data.dsEmployee.TeladocWaitingPeriodMonths == undefined
      ) {
        this.data.dsEmployee.TeladocWaitingPeriodMonths = 0;
      }

      if (this.data.dsPolicy.IsActive) {
        await KTMApi.action(screen, "submitandgenerate", {
          "@policy_id": this.props.policy_id,
          "@dsDependents:all_rows": dependents,
          "@dsEmployee.FirstName": this.data.dsEmployee.FirstName,
          "@dsEmployee.LastName": this.data.dsEmployee.LastName,
          "@dsEmployee.Email": this.data.dsEmployee.Email,
          "@dsEmployee.MaidenName": this.data.dsEmployee.MaidenName,
          "@dsEmployee.Nickname": this.data.dsEmployee.Nickname,
          "@dsEmployee.DateOfBirth": this.data.dsEmployee.DateOfBirth,
          "@dsEmployee.HireDate": this.data.dsEmployee.HireDate,
          "@dsEmployee.EmployeeNo": this.data.dsEmployee.EmployeeNo,
          "@dsEmployee.BenefitCategory_Id":
            this.data.dsEmployee.BenefitCategory_Id,
          "@dsEmployee.WaitingPeriodMonths":
            this.data.dsEmployee.WaitingPeriodMonths,
          "@dsEmployee.HasProrating": this.data.dsEmployee.HasProrating,
          "@dsEmployee.FlexLaunchDate": this.data.dsEmployee.FlexLaunchDate,
          "@dsEmployee.FlexSelectionDeadline":
            this.data.dsEmployee.FlexSelectionDeadline,
          "@dsEmployee.FlexReminderDate": this.data.dsEmployee.FlexReminderDate,
          "@dsEmployee.FlexLastDayReminderDate":
            this.data.dsEmployee.FlexLastDayReminderDate,
          "@dsEmployee.SalaryMoney": this.data.dsEmployee.SalaryMoney,
          "@dsEmployee.TeladocWaitingPeriodMonths": this.data.dsEmployee.TeladocWaitingPeriodMonths
        });

      } else {
        await KTMApi.action(screen, "submit", {
          "@policy_id": this.props.policy_id,
          "@dsDependents:all_rows": dependents,
          "@dsEmployee.FirstName": this.data.dsEmployee.FirstName,
          "@dsEmployee.LastName": this.data.dsEmployee.LastName,
          "@dsEmployee.Email": this.data.dsEmployee.Email,
          "@dsEmployee.MaidenName": this.data.dsEmployee.MaidenName,
          "@dsEmployee.Nickname": this.data.dsEmployee.Nickname,
          "@dsEmployee.DateOfBirth": this.data.dsEmployee.DateOfBirth,
          "@dsEmployee.HireDate": this.data.dsEmployee.HireDate,
          "@dsEmployee.EmployeeNo": this.data.dsEmployee.EmployeeNo,
          "@dsEmployee.BenefitCategory_Id":
            this.data.dsEmployee.BenefitCategory_Id,
          "@dsEmployee.WaitingPeriodMonths":
            this.data.dsEmployee.WaitingPeriodMonths,
          "@dsEmployee.HasProrating": this.data.dsEmployee.HasProrating,
          "@dsEmployee.FlexLaunchDate": this.data.dsEmployee.FlexLaunchDate,
          "@dsEmployee.FlexSelectionDeadline":
            this.data.dsEmployee.FlexSelectionDeadline,
          "@dsEmployee.FlexReminderDate": this.data.dsEmployee.FlexReminderDate,
          "@dsEmployee.FlexLastDayReminderDate":
            this.data.dsEmployee.FlexLastDayReminderDate,
          "@dsEmployee.SalaryMoney": this.data.dsEmployee.SalaryMoney,
          "@dsEmployee.TeladocWaitingPeriodMonths": this.data.dsEmployee.TeladocWaitingPeriodMonths
        });

      }
      await screen.restart();
      await this.get_employee_start_date();
      this.setState({ showStartDateModal: true });
    }

    setSelectedOption = (selectedOption: any) => {
      var findOption = this.state.options.find(
        (option) => option.name == selectedOption
      );
      this.setState({ selectedOption: findOption });
      this.data.dsEmployee.BenefitCategory_Id = findOption.id;
      this.check_ben_cat_type();
    };

    setSelectedWaitingPeriod = (selectedWaitingPeriod: any) => {
      var findOption = waitingPeriodMonths.find(
        (option) => option.label == selectedWaitingPeriod
      );
      this.setState({ selectedWaitingPeriod: findOption });
      this.data.dsEmployee.WaitingPeriodMonths = findOption.value;
      this.check_benfit_start_date();
    };

    setSelectedTeladocWaitingPeriod = (selectedTeladocWaitingPeriod: any) => {
      var findOption = TeladocWaitingPeriodMonths.find(
        (option) => option.label == selectedTeladocWaitingPeriod
      );
      this.setState({ selectedTeladocWaitingPeriod: findOption });
      this.data.dsEmployee.TeladocWaitingPeriodMonths = findOption.value;
      this.check_benfit_start_date();
    };

    async getBenefitCategories() {
      let response = await fetch(
        "/api/Employees/getbenefitcategories?policyId=" + this.props.policy_id
      );
      let res = await response.json();
      var options = [{ Id: null, name: "--Select--" }, ...res];
      this.setState({
        options: options,
        selectedOption: options[0],
      });
    }

    async check_ben_cat_type() {
      if (
        this.data.dsEmployee.BenefitCategory_Id == null ||
        this.data.dsEmployee.BenefitCategory_Id == undefined
      ) {
        this.setState({
          isFlex: false,
          creditCalcMethod: null,
        });
        return;
      }

      let response = await fetch(
        "/api/Employees/checkbencattype?policyId=" +
          this.props.policy_id +
          "&bencatId=" +
          this.data.dsEmployee.BenefitCategory_Id
      );
      let res = await response.json();

      if (res.success) {
        this.setState({
          isFlex: res.benCat.isFlex,
          creditCalcMethod: res.benCat.creditCalcMethod,
        });
      } else {
        this.setState({
          isFlex: false,
          creditCalcMethod: null,
        });
      }
    }

    async add_child({ screen }: ActionEvent) {
      screen.addRow("@dsDependents");
      screen.setValue("@dsDependents.Relation", "CHILD");
      screen.focus("@dsDependents.FirstName");
    }

    async remove_child({ screen }: ActionEvent) {
      screen.removeRow("@dsDependents");
    }

    async DependentFirstName_onchange({ screen }: ActionEvent) {
      let lastName = screen.getValue("@dsDependents.LastName");
      if (!lastName) {
        screen.setValue("@dsDependents.LastName", screen.getValue("LastName"));
      }
    }

    async check_benfit_start_date() {
      if (
        this.data.dsEmployee.WaitingPeriodMonths == null ||
        this.data.dsEmployee.WaitingPeriodMonths == undefined
      ) {
        return;
      }

      let hireDate = this.data.dsEmployee.HireDate;
      if (
        hireDate == null ||
        hireDate == undefined ||
        hireDate == ""
      ) {
        hireDate = this.state.effectiveDate;
      }

      let response = await fetch(
        "/api/Employees/checkbenefitstartdate?policyId=" +
          this.props.policy_id +
          "&waitingPeriodMonths=" +
          this.data.dsEmployee.WaitingPeriodMonths +
          "&hireDate=" +
          hireDate
      );
      let res = await response.json();
      if (res.success) {
        this.setState({
          isEligibleForCurrenYear: res.isEligibleForCurrenYear,
        });
      }
    }

    async checkIfEmployeeExists({ screen }: ActionEvent) {
      const email = screen.getValue("@dsEmployee.Email");
      if (!email) return;
      const encodedEmail = encodeURIComponent(email);
      let response = await fetch(`/api/Employees/checkifemployeeexists?email=${encodedEmail}`
        +"&policyId=" + this.props.policy_id);
      let res = await response.json();
      if (res.success) {
        this.setState({ 
          showReinstateModal: !res.isActive, 
          existingEmployeeId: res.employeeId,
          showDuplicateEmailModal: res.isActive,
          isBelongToCurrentPolicy: res.isBelongToCurrentPolicy
        });
      } else {
        this.setState({
          showReinstateModal: false, 
          existingEmployeeId: null,
          showDuplicateEmailModal: false,
          isBelongToCurrentPolicy: false,
        })
      }
    }

    async get_employee_start_date() {
      let response = await fetch(
        "/api/PlanAdmin/getemployeestartdate?policyId=" + this.data.dsPolicy.Id
      );
      let emp = await response.json();
      this.setState({ employeeStartDate: emp });
    }

    update_employee_start_date = async () => {
      if (this.state.overrideEmployeeStartDate != null) {
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            Id: this.state.employeeStartDate.id,
            BenefitStartDate: this.state.overrideEmployeeStartDate,
            IsStartDateAdjusted: true,
          }),
        };

        await fetch("/api/PlanAdmin/updatestartdate", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            toastr.success("Employee created", "Success");
          })
          .catch((error) => {
            toastr.error("Failed to add employee", "Error");
          });
      } else {
        toastr.success("Employee created", "Success");
      }
      this.setState({
        showStartDateInput: false,
        overrideEmployeeStartDate: null,
      });
      this.closeStartDateModal();
    };
  }
);
