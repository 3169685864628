import React from 'react';
import {makeComponent,RenderEvent} from '../core';
import { ClickThrottle } from './helpers/ClickThrottle';


export const DialogFooter = makeComponent(class extends React.Component<{event?:RenderEvent;cancelLabel?:string}>{

    clickThrottle = new ClickThrottle();

    render(){
        let buttons = this.props.children;
        let cancelLabel = this.props.cancelLabel || "Cancel";
        return (<div className="RT-Action-Dialog__buttons">
            <div style={{marginLeft:"auto"}}>
                <button className="RT-Button" onClick={this.handleCancel} data-studio-click>{cancelLabel}</button>{'\n'}
                {buttons}
            </div>

        </div>)
    }

    handleCancel = (e:React.MouseEvent) => {
        this.clickThrottle.handle(e,()=> {
            e.preventDefault();
            e.stopPropagation();
            if (!this.props.event) return;
            let canvas = this.props.event.canvas;
            if (canvas.closeDialogBox){
                canvas.closeDialogBox({continue:false});
            }
        });
    }
    static $section = "footer";
});