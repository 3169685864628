import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Card} from 'ktm-ui';

export default KTMScreen({name:"planadmin.test.add-funds-to-policy"}, class extends React.Component<{policy_id:string}> {

    data:{
        amount:number;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Add funds to policy";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add funds to policy"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Submit" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("@amount")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@policy_id":this.props.policy_id,
            "@amount":this.data.amount,
        });
        await screen.message(screen.returnValue,{style:"success",asDialog:true});
        screen.recordChanged("updated",null);
        screen.close({"continue":true});
    }

});
