export enum AnchorPoint {
    TopLeftCorner = "topleftCorner",
    TopAlignCenter = "topAlignCenter",
    TopRightCorner = "topRightCorner",
    RightVerticalCenter = "rightVerticalCenter",
    BottomRightCorner = "bottomRightCorner",
    BottomAlignCenter = "bottomAlignCenter",
    BottomLeftCorner = "bottomLeftCorner",
    LeftVerticalCenter = "leftVerticalCenter",
    TopAlignLeft = "topAlignLeft",
    BottomAlignLeft = "bottomAlignLeft",
    TopAlignRight = "topAlignRight",
    BottomAlignRight = "bottomAlignRight"
}

const OppositeAnchorPoints = {
    [AnchorPoint.TopLeftCorner]: AnchorPoint.BottomRightCorner,
    [AnchorPoint.TopAlignCenter]: AnchorPoint.BottomAlignCenter,
    [AnchorPoint.TopRightCorner]: AnchorPoint.BottomLeftCorner,
    [AnchorPoint.RightVerticalCenter]: AnchorPoint.LeftVerticalCenter,
    [AnchorPoint.BottomRightCorner]: AnchorPoint.TopLeftCorner,
    [AnchorPoint.BottomAlignCenter]: AnchorPoint.TopAlignCenter,
    [AnchorPoint.BottomLeftCorner]: AnchorPoint.TopRightCorner,
    [AnchorPoint.LeftVerticalCenter]: AnchorPoint.RightVerticalCenter,
    [AnchorPoint.TopAlignLeft]: AnchorPoint.BottomAlignLeft,
    [AnchorPoint.BottomAlignLeft]: AnchorPoint.TopAlignLeft,
    [AnchorPoint.TopAlignRight]: AnchorPoint.BottomAlignRight,
    [AnchorPoint.BottomAlignRight]: AnchorPoint.TopAlignRight,
}

export interface IRectangle { top: number; bottom: number; left: number; right: number }
export interface IRectangleOutsideResult { isOutside: boolean; top: number, left: number; right: number; bottom: number }

export let ZeroRect: IRectangle = { top: 0, bottom: 0, left: 0, right: 0 }

export const getOppositeAnchorPoint = (anchorPoint: AnchorPoint) => {
    return OppositeAnchorPoints[anchorPoint]
}

export const getRectForAnchor = (contentRect: IRectangle, anchorRect: IRectangle, anchorPoint): IRectangle => {
    let style: any = {}

    let anchorWidth = anchorRect.right - anchorRect.left;
    let contentWidth = contentRect.right - contentRect.left;
    let anchorHeight = anchorRect.bottom - anchorRect.top;
    let contentHeight = contentRect.bottom - contentRect.top;


    if (anchorPoint == AnchorPoint.BottomAlignCenter) {
        style.top = anchorRect.bottom;
            style.left = anchorRect.left + (anchorWidth / 2) - (contentWidth / 2)

    } else if (anchorPoint == AnchorPoint.TopAlignCenter) {
        style.top = anchorRect.top - contentHeight;
            style.left = anchorRect.left + (anchorWidth / 2) - (contentWidth / 2)

    } else if (anchorPoint == AnchorPoint.LeftVerticalCenter) {
        style.top = anchorRect.top + (anchorHeight / 2) - (contentHeight / 2)
        style.left = anchorRect.left - contentWidth

    } else if (anchorPoint == AnchorPoint.RightVerticalCenter) {
        style.top = anchorRect.top + (anchorHeight / 2) - (contentHeight / 2)
        style.left = anchorRect.right

    } else if (anchorPoint == AnchorPoint.TopAlignLeft) {
        style.top = anchorRect.top - contentHeight
        style.left = anchorRect.left

    } else if (anchorPoint == AnchorPoint.TopAlignRight) {
        style.top = anchorRect.top - contentHeight
        style.left = anchorRect.right - contentWidth

    } else if (anchorPoint == AnchorPoint.BottomAlignRight) {
        style.top = anchorRect.bottom
        style.left = anchorRect.right - contentWidth

    } else if (anchorPoint == AnchorPoint.BottomAlignLeft) {
        style.top = anchorRect.bottom
        style.left = anchorRect.left

    } else if (anchorPoint == AnchorPoint.TopLeftCorner) {
        style.top = anchorRect.top - contentHeight
        style.left = anchorRect.left - contentWidth

    } else if (anchorPoint == AnchorPoint.TopRightCorner) {
        style.top = anchorRect.top - contentHeight
        style.left = anchorRect.right

    } else if (anchorPoint == AnchorPoint.BottomRightCorner) {
        style.top = anchorRect.bottom
        style.left = anchorRect.right

    } else if (anchorPoint == AnchorPoint.BottomLeftCorner) {
        style.top = anchorRect.bottom
        style.left = anchorRect.left - contentWidth
    }

    return {
        ...style,
        bottom: style.top + contentHeight,
        right: style.left + contentWidth
    }
}

export const isRectangleOutside = (collider: IRectangle, bounds: IRectangle): IRectangleOutsideResult => {
    let result: IRectangleOutsideResult = {
        isOutside: false,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
    if (collider.left < bounds.left) {
        result.isOutside = true;
        result.left = collider.left - bounds.left
    }
    if (collider.bottom > bounds.bottom) {
        result.isOutside = true;
        result.bottom = collider.bottom - bounds.bottom
    }
    if (collider.top < bounds.top) {
        result.isOutside = true;
        result.top = collider.top - bounds.top
    }
    if (collider.right > bounds.right) {
        result.isOutside = true;
        result.right = collider.right - bounds.right
    }
    return result;
}

export const getTestAnchorsForPreferredAnchor = (anchorPoint) => {
    if (anchorPoint == AnchorPoint.TopLeftCorner) {
        return [
            AnchorPoint.TopLeftCorner,
            AnchorPoint.BottomLeftCorner,
            AnchorPoint.LeftVerticalCenter,
            AnchorPoint.TopAlignCenter,
            AnchorPoint.BottomAlignCenter,
            AnchorPoint.TopRightCorner,
            AnchorPoint.BottomRightCorner,
            AnchorPoint.RightVerticalCenter]

    } else if (anchorPoint == AnchorPoint.BottomLeftCorner) {
        return [
            AnchorPoint.BottomLeftCorner,
            AnchorPoint.TopLeftCorner,
            AnchorPoint.LeftVerticalCenter,
            AnchorPoint.TopAlignCenter,
            AnchorPoint.BottomAlignCenter,
            AnchorPoint.TopRightCorner,
            AnchorPoint.BottomRightCorner,
            AnchorPoint.RightVerticalCenter]

    } else if (anchorPoint == AnchorPoint.LeftVerticalCenter) {
        return [
            AnchorPoint.LeftVerticalCenter,
            AnchorPoint.RightVerticalCenter]

    } else if (anchorPoint == AnchorPoint.TopAlignCenter) {
        return [
            AnchorPoint.TopAlignCenter,
            AnchorPoint.BottomAlignCenter]

    } else if (anchorPoint == AnchorPoint.TopRightCorner) {
        return [
            AnchorPoint.TopRightCorner,
            AnchorPoint.BottomRightCorner,
            AnchorPoint.RightVerticalCenter,
            AnchorPoint.TopAlignCenter,
            AnchorPoint.BottomAlignCenter,
            AnchorPoint.TopLeftCorner,
            AnchorPoint.BottomLeftCorner,
            AnchorPoint.LeftVerticalCenter]

    } else if (anchorPoint == AnchorPoint.BottomRightCorner) {
        return [
            AnchorPoint.BottomRightCorner,
            AnchorPoint.TopRightCorner,
            AnchorPoint.RightVerticalCenter,
            AnchorPoint.BottomAlignCenter,
            AnchorPoint.TopAlignCenter,
            AnchorPoint.BottomLeftCorner,
            AnchorPoint.TopLeftCorner,
            AnchorPoint.LeftVerticalCenter]

    } else if (anchorPoint == AnchorPoint.RightVerticalCenter) {
        return [
            AnchorPoint.RightVerticalCenter,
            AnchorPoint.TopRightCorner,
            AnchorPoint.BottomRightCorner,
            AnchorPoint.BottomAlignCenter,
            AnchorPoint.TopAlignCenter,
            AnchorPoint.BottomLeftCorner,
            AnchorPoint.TopLeftCorner,
            AnchorPoint.LeftVerticalCenter]

    } else if (anchorPoint == AnchorPoint.BottomAlignCenter) {
        return [
            AnchorPoint.BottomAlignCenter,
            AnchorPoint.TopAlignCenter]

    } else if (anchorPoint == AnchorPoint.TopAlignLeft) {
        return [
            AnchorPoint.TopAlignLeft,
            AnchorPoint.TopAlignRight,
            AnchorPoint.BottomAlignLeft,
            AnchorPoint.BottomAlignRight]

    } else if (anchorPoint == AnchorPoint.TopAlignRight) {
        return [
            AnchorPoint.TopAlignRight,
            AnchorPoint.TopAlignLeft,
            AnchorPoint.BottomAlignRight,
            AnchorPoint.BottomAlignLeft]

    } else if (anchorPoint == AnchorPoint.BottomAlignLeft) {
        return [
            AnchorPoint.BottomAlignLeft,
            AnchorPoint.BottomAlignRight,
            AnchorPoint.TopAlignLeft,
            AnchorPoint.TopAlignRight]

    } else if (anchorPoint == AnchorPoint.BottomAlignRight) {
        return [
            AnchorPoint.BottomAlignRight,
            AnchorPoint.BottomAlignLeft,
            AnchorPoint.TopAlignRight,
            AnchorPoint.TopAlignLeft]
    }
    return null;
}

export const getWindowRect = () => {
    return { top: 0, left: 0, right: window.innerWidth, bottom: window.innerHeight }
}

export const getAnchoredPositionResult = (contentRect: IRectangle, anchorRect: IRectangle, preferedAnchorPoint): Partial<{
    anchorPoint: AnchorPoint;
    bounds: IRectangle;
}> => {
    let windowRect = getWindowRect()
    let bestChoice: IRectangle | undefined
    let bestAnchorPoint: AnchorPoint | undefined
    let testAnchors = getTestAnchorsForPreferredAnchor(preferedAnchorPoint);
    if(testAnchors) {
        for (let i = 0; i < testAnchors.length; i++) {
            let testAnchor = testAnchors[i];
            let positionedRect = getRectForAnchor(contentRect, anchorRect, testAnchor)
            if (i == 0) {
                bestChoice = positionedRect;
                bestAnchorPoint = testAnchor;
            }
            var result = isRectangleOutside(positionedRect, windowRect);
            if (!result.isOutside) {
                bestChoice = positionedRect;
                bestAnchorPoint = testAnchor;
                break;
            }
        }
    }
    
    return {
        bounds: bestChoice,
        anchorPoint: bestAnchorPoint
    }
}

export const getAnchoredPositionForPortal = (contentRect: IRectangle, anchorRect: IRectangle, preferedAnchorPoint): IRectangle | undefined => {
    let result = getAnchoredPositionResult(contentRect, anchorRect, preferedAnchorPoint)
    return result.bounds
}