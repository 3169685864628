import React from 'react';
import {  FieldType,  makeComponent ,ActionRef} from '../core';


export interface IDataTableColumn {
    name?:string;
    label?:string;
    type?:FieldType;
    align?:'left' | 'center' | 'right';
    render?:(row:any) => any;
}

export const DataTable = makeComponent(class extends React.Component<{columns:IDataTableColumn[],rows:any[],actions?:any,onRowClick?:ActionRef}>{

    render(){
        return this.renderContent();
    }
    renderContent(){
        let rows = this.props.rows;
        let found;
        if (rows){
            found = (  <div className="RT-Datatable__verbose-search">
                <span>Found <strong>{rows.length}</strong></span>
            </div>)
        }
        let actions = <div className="RT-DataTable__list-actions">
            {this.props.actions}
            <div style={{marginLeft:"auto",display:"flex",alignItems:"center"}}>
                {found}
            </div>
        </div>
        let table = <table className="RT-Data-Table RT-DataTabe--pull-padding" tabIndex={-1}>
            <thead className="RT-DataTable__header">
                {this.renderHeader()}
            </thead>
            <tbody className="RT-DataTable__body">
                {this.renderRows()}
            </tbody>
        </table>
        return <div style={{display:"flex",flexDirection:"column",height:"100%",overflow:"hidden"}}>
            {actions}
            {table}
        </div>
    }

    renderHeader(){
        let columns = this.props.columns;
        if (!columns) return null;
        let elems = [];
        for(let i = 0; i < columns.length;i++){
            let col = columns[i];
            elems.push(<th key={i} className="RT-DataTable__header-cell" style={{display:"table-cell",textAlign:"left"}}>{col.label}</th>)
        }
        return (<tr>{elems}</tr>)
    }

    renderRows(){
     
        let rows = this.props.rows;
        if (!rows) return null;
        let elems = [];
        let columns = this.props.columns;
        let showPointer:boolean;
        if (this.props.onRowClick){
            showPointer = true;
        }
        for(let i = 0; i < rows.length;i++){
            let row = rows[i];
            elems.push(<TableRow key={i} row={row} columns={columns} showPointer={showPointer} onClick={this.handleRowClick}/>);
        }
        return elems;
    }

    handleRowClick = (row:any) => {
        if (this.props.onRowClick){
            this.props.onRowClick.trigger({current:row,value:row.Id,table:this.props.rows});
        }
    }

});


class TableRow extends React.Component<{row:any,columns:IDataTableColumn[],showPointer:boolean;onClick:(row:any) => void}>{
    render(){
        let cells:any[] = [];
        let columns = this.props.columns;
        let row = this.props.row;
        for(let i = 0; i < columns.length;i++){
            let col = columns[i];
            let value;
            if (col.render){
                value = col.render(row);
            }
            else {
                value = row[col.name];
            }
            cells.push(<td key={i} className="RT-DataTable__cell">{value}</td>)
        }
        let style:React.CSSProperties = {};
        if (this.props.showPointer){
            style.cursor = "pointer";
        }
        return (<tr  onClick={this.handleClick} className="RT-DataTable__row" style={style}>{cells}</tr>);
    }
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.row);
    }
}
