import React from 'react';
import {makeComponent,RenderEvent, Url} from '../core';
import { Icon } from './Icon';


export const RecordHeader = makeComponent(class extends React.Component<{event?:RenderEvent,entityLabel?:string,label?:string;actions?:any}>{
    render(){
        let actions =this.props.actions;
        let label= this.props.label;
        if (!label){
            let event = this.props.event;
            if (event){
                label = event.canvas.title;
            }
        }
    
        return <RecordHeaderPresenter event={this.props.event} entityLabel={this.props.entityLabel} header={this.props.children} actions={actions} recordTitle={label} 
            onClose={this.handleClose} />
    }

    handleClose = () => {
        let event = this.props.event;
        event.canvas.closeDialogBox({continue:false});
    }
    static $section = "header";
});


class RecordHeaderPresenter extends React.Component<{event:RenderEvent,header:any,covered?:boolean,helpTopic?:string;entityLabel:string;
    recordTitle:string,actions?:any,onClose:() => void}> {
    render(){
        return this.renderDetailHeader(this.props.covered);
    }

    renderDetailHeader(covered?: boolean) {
     
        var recordTitle = this.props.recordTitle;

        let editLink;
        let helpButton;
        /*
        if (this.props.helpTopic){
            helpButton = <div style={{marginLeft:10}}><Help.DialogHelpButton topic={this.props.helpTopic}/></div>
        }
        */
        let toolbar;

        let entityLabel;
        if (this.props.entityLabel){
            entityLabel =    <label className="RT-Static-Field__label">{this.props.entityLabel}</label>;
        }
        let recordLink = this.getLinkHref();
        let title;
        if (recordLink){
            title = <div>
                {entityLabel}
                <a href={recordLink} onClick={this.handleTitleClick} className="RT-Record-Header__title" style={{}}>
                    {recordTitle}
                    <div className="RT-Record-Header__link">
                        {<Icon icon="link" size={16}/>}
                    </div>
                </a>
            </div>
        }
        else {
            title = <div>
                {entityLabel}
                <div className="RT-Record-Header__title" style={{}}>
                    {recordTitle}
                </div>
            </div>
        }

        let actions;
        let fields;
        let marginBottom;
        if (this.props.actions){
            actions = (<div style={{marginLeft:"auto",marginRight:20}}>{this.props.actions}</div>);
           
        }
        
        if (this.props.header){
            fields = (<div style={{marginTop:12}}>
                {this.props.header}
            </div>)
             marginBottom = 5;
        }
        else {
            marginBottom = 20;
        }  
        let className = "RT-Record-Header RT-Record-Header--" + this.props.event.canvas.layer;
        let content = <div className={className} style={{ position: "relative",marginBottom}}>
            <div style={{display:"flex"}}>
                {title}
                {actions}
            </div>
            {fields}
        </div>;

        if (covered) {
            return <>
                <div style={{ opacity: 0 }}>{content}</div>
                <div style={{ position: "absolute", top: 0, left: 0, right: 0, height: 80, padding: "8px 20px",cursor:"pointer"}}><h5 style={{ fontSize: "16px" }}>{recordTitle}</h5></div>
            </>
        }
        return (<>
            {toolbar}
            {content}
        </>);
    }


    handleTitleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let event = this.props.event;
        if (event){
            let app = event.canvas.app;
            let link = this.getLinkHref();
            navigator.clipboard.writeText(link);
            app.notifications.dispatch("show",{text:"Link copied to clipboard",style:"success"});
        }

    }

    getLinkHref():string {
        let event = this.props.event;
        if (event){
            let app = event.canvas.app;
            let link = document.location.origin + Url.stringify(app,event.canvas.launchParams);
            return link;
        }
    }

    handleClose = () => {
       this.props.onClose();
    }

}
