import {Template,ActionHandler,IEventOptions, IActionResult, FilterOp,getTemplateName, getActionName, getTargetName} from './types';
import {Canvas} from './Canvas';
import {Dialog, DialogResult, IBrowseOptions, Stack} from './Dialog';
import {Focus} from './Focus';
import { IFieldOptions, RecordMeta } from './RecordMeta';
import { RecordErrorSet } from './RecordErrorSet';
import { Application } from './Application';
import { IFieldLookup, Schema } from './Schema';
import { FieldRef } from './FieldRef';
import { Collection } from './Collection';
import {Request} from './Request';

import { ActionRef, RenderEvent } from '.';

export class ActionEvent {
    canvas:Canvas;
    options:IEventOptions;

    screen:ScreenRef;
    constructor(canvas:Canvas,options:IEventOptions = {}){
        this.canvas = canvas;
        this.options = options;
        this.screen = new ScreenRef(canvas,options);
    }

    get eventValue():any {
        return this.options.value;
    }

    get returnValue():any {
        return this.options.returnValue;
    }
    
}

export type  UITarget  = string | ActionHandler | Template;

export class ScreenRef {
    canvas:Canvas;
    eventOptions:IEventOptions
    constructor(canvas:Canvas,options:IEventOptions){
        this.canvas = canvas;
        this.eventOptions = options;
    }
    set defaultRecord(value:string){
        this.canvas.defaultRecord = value;
    }
    get defaultRecord():string {
        return this.canvas.defaultRecord;
    }

    set title(value:string){
        this.canvas.title = value;
    }
    get title():string {
        return this.canvas.title;
    }

    get returnValue():any {
        return this.eventOptions.returnValue;
    }
    
    set returnValue(value:any){
        this.eventOptions.returnValue = value;
    }
    
    field(fieldName:string):FieldRef {
        return this.canvas.getFieldRef(fieldName,this.eventOptions.scope);
    }


    setData(data:any){
        this.canvas.setData(data);
    }

    initRecord(recordTypeName:string,data:any):any{
        let schema = Schema.getRecordSchema(this.canvas,recordTypeName);
        let record = RecordMeta.initalizeRecord(schema,data);
        this.canvas.setRecord(recordTypeName,record);
        return data;
    }

    showLayout(template:Template | string){
        let templateRef = this.canvas.getTemplateRef(template);
        if (templateRef){
            this.canvas.layoutTemplate = templateRef.name;
        }
    }

    locate(collection:string,rowid:any){
        let collectionRef = this.canvas.getCollectionRef(collection,this.eventOptions.scope);
        if (!collectionRef) return null;
        return collectionRef.locate(rowid);
    }

    action(action:string | ActionHandler):ActionRef {
        let actionName = getActionName(action);
        if (actionName){
            return new ActionRef(this.canvas,actionName,this.eventOptions.scope);
        }
    }
    
    async triggerAction(action:string | ActionHandler,options?:IEventOptions):Promise<IActionResult>{
        let actionRef = this.action(action);
        if (actionRef) {
            let result = await this.canvas.triggerAction(actionRef,options);
            if (!result.continue){
                throw "stopped";
            }
            return result;
        }
        return {continue:true};
    }
    
    openDialog(dialog:any,options:{onRecordChanged?:string | ActionHandler} = {}):Promise<any> {
        return Dialog.open(this.canvas,dialog,this.action(options.onRecordChanged),"dialog");
    }

    openOnStack(stacked:any,options:{onRecordChanged?:string | ActionHandler} = {}) {
        return Stack.open(this.canvas,stacked,this.action(options.onRecordChanged),{showStackContent:this.eventOptions.showStackContent});
    }

    browse(recordScreen:any, options:{onRecordChanged?:ActionHandler | string,label?:any} = {}){
       
        let browseOptions:IBrowseOptions = {
            label:options.label,
            rows:this.eventOptions.table,
            current:this.eventOptions.current,
            onRecordChanged:this.action(options.onRecordChanged),
            triggerAction:this.action(this.eventOptions.actionName)
        }
        Stack.browse(this.canvas,recordScreen,browseOptions);
    }

    close(result:DialogResult){
        if (this.canvas.closeDialogBox){
            this.canvas.closeDialogBox(result);
        }
    }
    
    set onEnterKey(action:string | ActionHandler){
        let actionName = getActionName(action);
        if (actionName){
            this.canvas.enterKeyAction = actionName;
        }
        else {
            this.canvas.enterKeyAction = null;
        }
    }

    async prompt(template:string | Template){
        let templateRef = this.canvas.getTemplateRef(template);
        if (!templateRef) return;
        let renderEvent = new RenderEvent(this.canvas,templateRef.name);
        renderEvent.scope = this.eventOptions.scope;
        let PromptComponent = renderEvent.draw(template);
        if (Dialog){
            let result = await Dialog.open(this.canvas,PromptComponent,null,"dialog");
            if (!result.continue){
                throw "stopped";
            }
        }
        else {
            throw "invalid prompt";
        }
    }

    async message(message:string,options:{style?:'success' | 'danger' |'info' | 'warning',asDialog?:boolean,template?:Template | string} = {}){
        if (options.asDialog){
            if (options.template){
                let templateRef = this.canvas.getTemplateRef(options.template);
                if (!templateRef) return;
                let renderEvent = new RenderEvent(this.canvas,templateRef.name);
                renderEvent.scope = this.eventOptions.scope;
                let MessageComponent = renderEvent.draw(options.template);
                await Dialog.open(this.canvas,MessageComponent,null,"dialog");
            }
            else {
                let MessageBox = Application.instance.getMessageBox();
                await Dialog.open(this.canvas,<MessageBox message={message} style={options.style}/>,null,"dialog");
            }
           
        }
        else {
            Application.instance.notifications.dispatch("show",{text:message,style:options.style});
        }
    }

    getValue(field:string):any{
        return this.canvas.getValue(field,this.eventOptions);
    }

    private getMeta(record:any):RecordMeta{
        if (record) return record.$$meta;
        return null;
    }

    manditory(fieldName:string){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setRequired(fieldRef.meta,fieldRef.name,true);
        }
    }

    optional(fieldName:string){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setRequired(fieldRef.meta,fieldRef.name,false);
        }
    }

    format(fieldName:string,formatString:string){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setFormat(fieldRef.meta,fieldRef.name,formatString);
        }
    }

    setStyle(fieldName:string,style:string){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setStyle(fieldRef.meta,fieldRef.name,style);
        }
    }

    setHelp(fieldName:string,options:{text?:string}){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setHelp(fieldRef.meta,fieldRef.name,options.text);
        }
    }

    setLabel(target:UITarget,options:{text?:string}){

        let t = parseTarget(target);
        if (!t) return;
        if (t.type == 'field'){
            let fieldRef = this.field(t.name);
            if (fieldRef && fieldRef.meta){
                RecordMeta.setLabel(fieldRef.meta,fieldRef.name,options.text);
            }
        }
        else {
            RecordMeta.setLabel(this.canvas.$$meta,t.name,options.text);
        }
    }

    setPlaceholder(fieldName:string,options:{text?:string}){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setPlaceholder(fieldRef.meta,fieldRef.name,options.text);
        }
    }

    setLookup(fieldName:string,options:{name?:string,autoLaunch?:boolean,template?:string | Template,labelField?:string}){
        let fieldDef = Schema.getQualifiedFieldDef(this.canvas,fieldName);
        if (fieldDef){
            let lookup:IFieldLookup = {autoLaunch:options.autoLaunch};
            if (options.name){
                lookup.name = options.name;
            }
            lookup.template = getTemplateName(options.template);
            if (options.labelField !== undefined){
                fieldDef.lookupLabel = options.labelField;
            }
            fieldDef.lookup = lookup;
        }
    }

    setOnValueChanged(fieldName:string,action:string | ActionHandler){
        let fieldDef = Schema.getQualifiedFieldDef(this.canvas,fieldName);
        if (fieldDef){
            fieldDef.onValueChanged = getActionName(action);
        }
    }

  
    disable(target:UITarget,help?:string){
        let t = parseTarget(target);
        if (!t) return;
        if (t.type == 'field'){
            let fieldRef = this.field(t.name);
            if (fieldRef && fieldRef.meta){
                RecordMeta.setDisabled(fieldRef.meta,fieldRef.name,true);
                RecordMeta.setDisabledHelp(fieldRef.meta,fieldRef.name,help);
            }
        }
        else {
            RecordMeta.setDisabled(this.canvas.$$meta,t.name,true);
            RecordMeta.setDisabledHelp(this.canvas.$$meta,t.name,help);
        }
    }

    enable(target:UITarget){
        let t = parseTarget(target);
        if (!t) return;
        if (t.type == 'field'){
            let fieldRef = this.field(t.name);
            if (fieldRef && fieldRef.meta){
                RecordMeta.setDisabled(fieldRef.meta,fieldRef.name,false);
            }
        }
        else {
            RecordMeta.setDisabled(this.canvas.$$meta,t.name,false);
        }
    }

    hide(target:UITarget){
        let t = parseTarget(target);
        if (!t) return;
        if (t.type == 'field'){
            let fieldRef = this.field(t.name);
            if (fieldRef && fieldRef.meta){
                RecordMeta.setHidden(fieldRef.meta,fieldRef.name,true);
            }
        }
        else {
            RecordMeta.setHidden(this.canvas.$$meta,t.name,true);
        }
    }

    unhide(target:UITarget){
        let t = parseTarget(target);
        if (!t) return;
        if (t.type == 'field'){
            let fieldRef = this.field(t.name);
            if (fieldRef && fieldRef.meta){
                RecordMeta.setHidden(fieldRef.meta,fieldRef.name,false);
            }
        }
        else {
            RecordMeta.setHidden(this.canvas.$$meta,t.name,false);
        }
    }

    lock(fieldName:string){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setReadonly(fieldRef.meta,fieldRef.name,true);
        }
    }

    unlock(fieldName:string){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setReadonly(fieldRef.meta,fieldRef.name,false);
        }
    }

    setOptions(fieldName:string,options:IFieldOptions){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            options = options || [];
            
            let defaultOptionExists = options.some(option => option.value === null);
            if (!defaultOptionExists) {
                options.unshift({ value: null, label: "-- Select -- " });
            }

            RecordMeta.setOptions(fieldRef.meta,fieldRef.name,options);
        }
    }

    focus(fieldName:string){
        let field = this.field(fieldName);
        Focus.focus(this.canvas,field);
    }

    lockRecord(recordName:string){
        let record =this.canvas.getRecord(recordName,this.eventOptions.scope);
        let meta = this.getMeta(record);
        if (!meta) return;
        RecordMeta.setRecordReadonly(meta,true);
    }

    unlockRecord(recordName:string){
        let record =this.canvas.getRecord(recordName,this.eventOptions.scope);
        let meta = this.getMeta(record);
        if (!meta) return;
        RecordMeta.setRecordReadonly(meta,false);
    }

    setRecordErrors(recordName:string,errorSet:RecordErrorSet){   
        let record =this.canvas.getRecord(recordName,this.eventOptions.scope);
        let meta = this.getMeta(record);
        if (!meta) return;
        meta.error = errorSet;
    }

    error(fieldName:string,message:string){
        let fieldRef = this.field(fieldName);
        if (!fieldRef) return;
        
        let meta = fieldRef.meta;
        if (!meta) return;
        let error = meta.error;
        if (!error){
            error = new RecordErrorSet(null);
            meta.error = error;
        }
        error.field(fieldRef.name,message,"ERROR");
    }

    async recordChanged(action:'created' | 'updated' | 'deleted',id:string){
        await this.canvas.recordChanged(action,id);
    }

    setTemplateContent(name:string,content:any){
        this.canvas.templates[name] = {draw:content};
    }

    activateTab(template:string | Template){
        // todo: implement
        throw "not implemented";
    }

    addRow(collection:any[] | string,options:{position?:null | 'first' | 'current'} = {}){
        let collectionName = getCollectionName(collection);
        let collectionRef = this.canvas.getCollectionRef(collectionName,this.eventOptions.scope);
        collectionRef.addRow(options);
    }

    async refreshRow(collection:any[] | string,id:any,options:{newRowsOnBottom?:boolean} = {}){
        let collectionName = getCollectionName(collection);
        let newRows = (options.newRowsOnBottom) ? "append" : "prepend";
        let paramValues = {
            collection:collectionName,
            "@event-value":id,
            newRows
        }
        await Request.callScript(this,"refresh-row","refresh-row",paramValues);
    }

    removeRow(collection:any[] | string,options:{rowid?:any,mode?:null | 'all'} = {}){
        let collectionName = getCollectionName(collection);
        let collectionRef = this.canvas.getCollectionRef(collectionName,this.eventOptions.scope);
        collectionRef.removeRow(options);
    }

    clearErrors(recordName:string,options:{} = {}){
        let recordDef = Schema.getFieldDef(this.canvas.schema,recordName);
        if (!recordDef) return;
        if (recordDef.type == 'collection'){
            let collectionRef = this.canvas.getCollectionRef(recordName,this.eventOptions.scope);
            collectionRef.clearErrors();
        }
        else {
            let record = this.canvas.getRecord(recordName,this.eventOptions.scope);
            if (record){
                RecordMeta.clearErrors(record);
            }
        }
    }

    async restart():Promise<any>{
        this.canvas.resetData();
        await this.triggerAction("start");
        this.canvas.initialized = true;
    }

    async setValue(fieldName:string,value:any):Promise<any> {
        let field = this.field(fieldName);
        if (field){
            await this.canvas.setValue(field,value);
        }
    }

    setLookupLabel(fieldName:string,value:any){
        let fieldRef = this.field(fieldName);
        if (fieldRef && fieldRef.meta){
            RecordMeta.setLookupLabel(fieldRef.meta,fieldRef.name,value);
        }
    }

    async setFilter(fieldName:string,options:{value?:any,op?:FilterOp,toValue?:any}){
        if (options.op !== undefined){
            let opField = this.field(fieldName + "$op");
            this.canvas.setValue(opField,options.op);
        }
        if (options.toValue !== undefined){
            let toField = this.field(fieldName + "$to");
            this.canvas.setValue(toField,options.toValue);
        }
        if (options.value != undefined){
            let field = this.field(fieldName);
            await this.canvas.setValue(field,options.value);
        }
    }

    getFilter(fieldName):{value:any,op:FilterOp,toValue:any}{
        let result = {
            value:this.canvas.getValue(fieldName,this.eventOptions),
            op:this.canvas.getValue(fieldName + "$op",this.eventOptions),
            toValue:this.canvas.getValue(fieldName + "$to",this.eventOptions),
            $type:"filter"
        }
        return result;
    }

    async showWarning(options:{message?:string,style?:string,template?:Template | string}){
        let Confirm = this.canvas.app.getConfirmDialog();
        let result = await Dialog.open(this.canvas,<Confirm message={options.message} style={options.style}/>,null,"dialog");
        if (!result.continue){
            throw "stopped";
        }
    }

    /**
     * Runs the dynamic code for an action
     * Typically this call is used inside a defined class method to run the dynamic
     * portion of the code when the action contains a mix of actual code and dynamic
     * code.
     * 
     * @param name 
     */
    async runScript(name?:string){
        let options:IEventOptions = {noUpdate:true,scope:this.eventOptions.scope};
        let actionHandler = this.canvas.createDynamicActionHandler(name); // always run the dynamic code
        if (!actionHandler) return;
        await this.canvas.executeActionHandler(name,actionHandler,options);
        this.eventOptions.returnValue = options.returnValue;
    }

}


interface ITarget {
    type:'field' | 'component';
    name:string;
}


function parseTarget(target:string | ActionHandler | Template):ITarget {
    if (typeof target == "string"){
        if (target[0] == "@"){
            return {type:'field',name:target};
        }
        /*
        let [record,name] = target.split('.');
        if (!name){
            return {type:'field',name:record};
        }
        */
        return {type:'component',name:target};
    }
    let name = getTargetName(target);
    if (name){
        return {type:"component",name};
    }

}

function getCollectionName(target:string | any[]):string{
    if (typeof target == "string"){
        return target;
    }
    else if (target){
        let schema = Collection.getSchema(target);
        if (schema){
            return schema.name;
        }
    }
}