import { Application, ISigninSettings } from "ktm-ui/core";
import { ISessionResult } from "ktm-ui/core/AppSession";
import React from "react";
import MigrationMessageScreen from "./MigrationMessageScreen";
import TwoFactorScreen from "./TwoFactorScreen";
import axios from "axios";

export interface ISigninProps {
  app: Application;
  done: (result: ISessionResult) => void;
  token?: string;
  settings: ISigninSettings;
}

export default class Signin extends React.Component<
  ISigninProps,
  {
    mode: string;
    username: string;
    password: string;
    confirmPassword: string;
    pwdToken: string;
    error: string;
    busy: boolean;
    ipAddress: string;
    requires2FA: boolean;
  }
> {
  supportLinkLabel: string;
  supportLinkHref: string;
  usernameLabel: string;
  submitLabel: string;
  registerLabel: string;
  forgotPasswordLabel: string;
  passwordResetInstruction: string;
  resetSentMessage: string;
  logoUrl: string;

  constructor(props) {
    super(props);
    var mode = "login";
    let pwdToken;

    if (props.token) {
      pwdToken = props.token;
      mode = "reset";
    }

    this.state = {
      mode,
      username: "",
      password: "",
      confirmPassword: "",
      pwdToken,
      error: "",
      busy: false,
      ipAddress: "",
      requires2FA: false,
    };
    let app = this.props.app;
    let settings = this.props.settings;
    this.supportLinkLabel = settings.supportLinkLabel;
    this.supportLinkHref = settings.supportLinkHref;
    this.usernameLabel = settings.usernameLabel;
    this.submitLabel = settings.submitLabel;
    this.registerLabel = settings.registerLabel;
    this.forgotPasswordLabel = settings.forgotPasswordLabel;
    this.resetSentMessage = settings.resetSentMessage;
    this.logoUrl = settings.logoUrl;
    this.passwordResetInstruction = settings.passwordResetInstruction;
  }

  componentDidMount() {
    this.fetchIpAddress();
  }

  fetchIpAddress = async () => {
    try {
      let app = this.props.app;
      const response = await axios.get("https://api.ipify.org?format=json");
      const ipAddress = response.data.ip;
      this.setState({ ipAddress });
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  render() {
    return <div className="RT-Login__container">{this.renderContent()}</div>;
  }

  submit = async (e) => {
    e.preventDefault();
    let app = this.props.app;
    try {
      this.setState({ busy: true });
      let result = await app.session.attemptSignin(
        this.state.username.trim(),
        this.state.password,
        this.state.ipAddress
      );
      this.setState({ busy: false });
      if (result.requires2FA) {
        this.setState({ requires2FA: true });
      } else if (result.success) {
        return this.props.done(result);
      } else {
        if (result.error === "Inactive Enrollment Status") {
          this.setState({ mode: "inactive_enrollment" });
        } else if (result.error === "Set password") {
          this.setState({ mode: "forgot" });
        }
        this.setState({ error: result.error });
      }
    } catch (e) {
      let error = this.getErrorMessage(e);
      this.setState({ error, busy: false });
    }
  };

  getErrorMessage(e: any): string {
    if (!e.body || !e.body.error || !e.body.error.message) {
      return "An error occurred, unable to " + this.submitLabel;
    }
    return e.body.error.message;
  }

  requestToken = (requestType: string) => async (e) => {
    e.preventDefault();
    let app = this.props.app;
    try {
      this.setState({ busy: true });
      let result = await app.session.requestToken(this.state.username);
      if (result.success) {
        this.setState({ mode: requestType, busy: false, error: "" });
      } else {
        this.setState({ error: result.error, busy: false });
      }
    } catch (e) {
      let error = this.getErrorMessage(e);
      this.setState({ error, busy: false });
    }
  };

  signup = (e) => {
    e.preventDefault();
  };

  resetPassword = async (e) => {
    e.preventDefault();

    let app = this.props.app;
    try {
      this.setState({ busy: true });
      let result = await app.session.resetPassword(
        this.props.token,
        this.state.password
      );
      if (!result.success) {
        this.setState({
          busy: false,
          error: result.error,
        });
        return;
      }
      this.setState(
        {
          mode: "login",
          busy: false,
          password: "",
          error: "",
        },
        () => {
          window.location.href = "/ops";
        }
      );
    } catch (e) {
      let error = this.getErrorMessage(e);
      this.setState({ error, busy: false });
    }
  };

  handleInputChange = (e) => {
    let name: "username" | "password" | "confirmPassword" = e.target.name;
    var value = e.target.value;
    let stateChange = { [name]: value };
    this.setState(stateChange as any);
  };

  handleRegisterClick = (e) => {
    e.preventDefault();
    this.setState({
      mode: "register",
      error: "",
      password: "",
      confirmPassword: "",
    });
  };

  handleForgotClick = (e) => {
    e.preventDefault();
    this.setState({
      mode: "forgot",
      error: "",
      password: "",
      confirmPassword: "",
    });
  };

  handleReturnToLoginClick = (e) => {
    e.preventDefault();
    this.setState({
      mode: "login",
      error: "",
      password: "",
      confirmPassword: "",
    });
  };

  handle2FASubmit = async (token: string) => {
    let app = this.props.app;
    this.setState({ busy: true });
    let result = await app.session.verifyTwoFactorCode(
      this.state.username,
      token,
      this.state.ipAddress
    );
    this.setState({ busy: false });
    if (result.success) {
      return this.props.done(result);
    } else {
      throw new Error(result.error || "Verification failed.");
    }
};

  renderContent() {
    if (this.state.requires2FA) {
      return (
        <TwoFactorScreen
          onSubmit={this.handle2FASubmit}
          logoUrl={this.logoUrl}
        />
      );
    }

    var mode = this.state.mode;
    if (mode == "login") {
      return this.renderLoginScreen();
    } else if (mode == "register") {
      return this.renderRegister();
    } else if (mode == "forgot") {
      return this.renderForgot();
    } else if (mode == "requestregister") {
      return this.renderRequestSent("requestregister");
    } else if (mode == "requestforgot") {
      return this.renderRequestSent("requestforgot");
    } else if (mode == "reset") {
      return this.renderReset();
    } else if (mode == "inactive_enrollment") {
      return <MigrationMessageScreen></MigrationMessageScreen>;
    }
  }

  renderLoginScreen() {
    let assistanceLink;
    if (this.supportLinkHref) {
      assistanceLink = (
        <a href={this.supportLinkHref}>{this.supportLinkLabel}</a>
      );
    } else {
      assistanceLink = this.supportLinkLabel;
    }
    let assistance = (
      <div className="RT-Login__help">
        <div>Need assistance?</div>
        <div>{assistanceLink}</div>
      </div>
    );
    let register;
    if (this.registerLabel) {
      register = (
        <a
          href=""
          className="RT-Login__link"
          onClick={this.handleRegisterClick}
        >
          {this.registerLabel}
        </a>
      );
    }

    let forgot;
    if (this.forgotPasswordLabel) {
      forgot = (
        <a href="" onClick={this.handleForgotClick} className="RT-Login__link">
          {this.forgotPasswordLabel}
        </a>
      );
    }
    let submitButton;
    if (this.state.busy) {
      submitButton = (
        <button
          disabled
          type="submit"
          className="RT-Button RT-Button--primary RT-Button--block"
        >
          Logging in ...
        </button>
      );
    } else {
      submitButton = (
        <button
          type="submit"
          className="RT-Button RT-Button--primary RT-Button--block"
        >
          {this.submitLabel}
        </button>
      );
    }

    let logo = this.renderLogo();

    return (
      <div className="RT-Login__form">
        {logo}
        <form onSubmit={this.submit}>
          {this.renderError()}
          <label className="RT-Login__label">{this.usernameLabel}</label>
          <input
            type="text"
            name="username"
            className="RT-Login__input"
            value={this.state.username}
            onChange={this.handleInputChange}
          />
          <label className="RT-Login__label">Password</label>
          <input
            type="password"
            name="password"
            className="RT-Login__input"
            value={this.state.password}
            onChange={this.handleInputChange}
          />

          <div className="RT-Login__button-container">{submitButton}</div>

          <div className="RT-Login__link-container">
            {register}
            {forgot}
          </div>
          {assistance}
        </form>
      </div>
    );
  }

  renderError() {
    if (this.state.error) {
      return <div className="RT-Login__error">{this.state.error}</div>;
    }
    return null;
  }

  renderRegister() {
    return (
      <div className="RT-Login__form">
        {this.renderLogo()}

        <form onSubmit={this.signup}>
          {this.renderError()}
          <label className="RT-Login__label">
            Enter your email address to set up your password.
          </label>

          <input
            type="text"
            name="username"
            className="RT-Login__input"
            value={this.state.username}
            onChange={this.handleInputChange}
          />
          <div className="RT-Login__button-container">
            <button
              type="submit"
              className="RT-Button RT-Button--primary RT-Button--block"
            >
              Continue
            </button>
          </div>
          <div className="RT-Login__link-container">
            <a
              href=""
              onClick={this.handleReturnToLoginClick}
              className="RT-Login__link"
            >
              Return to {this.submitLabel}
            </a>
          </div>
        </form>
      </div>
    );
  }

  renderForgot() {
    let submitButton;
    if (this.state.busy) {
      submitButton = (
        <button
          disabled
          type="submit"
          className="RT-Button RT-Button--primary RT-Button--block"
        >
          Working ...
        </button>
      );
    } else {
      submitButton = (
        <button
          type="submit"
          className="RT-Button RT-Button--primary RT-Button--block"
        >
          Continue
        </button>
      );
    }
    return (
      <div className="RT-Login__form">
        {this.renderLogo()}

        <form onSubmit={this.requestToken("requestforgot")}>
          {this.renderError()}
          <label className="RT-Login__label">
            Enter your email address to set up your password
          </label>
          <input
            type="text"
            name="username"
            className="RT-Login__input"
            value={this.state.username}
            onChange={this.handleInputChange}
          />
          <div className="RT-Login__button-container">{submitButton}</div>
          <div className="RT-Login__link-container">
            <a
              href=""
              onClick={this.handleReturnToLoginClick}
              className="RT-Login__link"
            >
              Return to {this.submitLabel}
            </a>
          </div>
        </form>
      </div>
    );
  }

  renderReset() {
    let submitButton;

    if (this.state.busy) {
      submitButton = (
        <button
          disabled
          type="submit"
          className="RT-Button RT-Button--primary RT-Button--block"
        >
          Working...
        </button>
      );
    } else {
      submitButton = (
        <button
          type="submit"
          className="RT-Button RT-Button--primary RT-Button--block"
        >
          Set Password
        </button>
      );
    }
    return (
      <div className="RT-Login__form">
        {this.renderLogo()}
        <form onSubmit={this.resetPassword}>
          {this.renderError()}
          <div className="RT-Login__label">Enter new password</div>
          <input
            type="password"
            name="password"
            className="RT-Login__input"
            value={this.state.password}
            onChange={this.handleInputChange}
          />
          <div className="RT-Login__label">Confirm password</div>
          <input
            type="password"
            name="confirmPassword"
            className="RT-Login__input"
            value={this.state.confirmPassword}
            onChange={this.handleInputChange}
          />
          <div className="RT-Login__button-container">{submitButton}</div>
          <div className="RT-Login__link-container">
            <a
              href=""
              onClick={this.handleReturnToLoginClick}
              className="RT-Login__link"
            >
              Return to {this.submitLabel}
            </a>
          </div>
        </form>
      </div>
    );
  }

  renderLogo() {
    if (this.logoUrl) {
      return (
        <div className="RT-Login__logo">
          <img src={this.logoUrl} />
        </div>
      );
    }
    return null;
  }

  renderRequestSent(requestType: string) {
    var textDesc =
      "If you have entered a registered email address you will receive an email containing the instructions to set up your password.";

    return (
      <div className="RT-Login__form">
        {this.renderLogo()}

        <form onSubmit={this.requestToken(requestType)}>
          {this.renderError()}
          <div className="RT-Login__label">{textDesc}</div>
          <div className="RT-Login__link-container">
            <a
              href=""
              onClick={this.handleReturnToLoginClick}
              className="RT-Login__link"
            >
              Return to {this.submitLabel}
            </a>
          </div>
        </form>
      </div>
    );
  }
}
