import React from "react";
import ImageUploading from "react-images-uploading";
import axios from "axios";
import toastr from "toastr";

export const CustomImageUploader = ({policyId, updateLogo}) => {
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const onChange = (imageList) => {
        setImages(imageList);
        handleUpload(imageList);
    };
    
    const handleUpload = (imageList: string | any[]) => {
        if (!imageList || imageList.length === 0) {
            alert("No image selected");
            return;
        }

        if (!imageList[0] || !imageList[0].file) {
            toastr.warning("No valid image selected");
            return;
        }

        // Check if image size is greater than 1MB
        if (imageList[0].file.size > 1 * 1024 * 1024) {
            toastr.warning("Image size should not be greater than 1MB");
            return;
        }

        const formData = new FormData();
        formData.append("policyId", policyId);
        formData.append("file", imageList[0].file);
        axios
            .post("/api/ImageService/uploadbrandlogo", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data.success) {
                    updateLogo(response.data.fileName);
                    toastr.success("Logo uploaded successfully");
                } else {
                    updateLogo(null);
                    toastr.error("Failed to upload image");
                }
            })
            .catch((error) => {
                updateLogo(null);
                toastr.error(error.response?.data || "Failed to upload image");
            });
    };

    return (
        <div>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                    onImageUpload,
                    isDragging,
                    dragProps,
                }) => (
                    <div className="upload__image-wrapper">
                         <div
                            className="drop-area"
                            style={{
                                border: isDragging ? "2px dashed red" : "2px dashed gray",
                                padding: "40px 20px 20px 20px",
                                textAlign: "center",
                                cursor: "pointer",
                                height: "100px"
                            }}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            Click or Drop here
                        </div>
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};