import React from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import toastr from 'toastr';
import { Card, Line } from "ktm-ui";
import CustomIntegerInput from "./CustomIntegerInput";
import CustomToolTip from "./CustomToolTip";
import DatePicker from "react-date-picker";

export default class TerminateEmployeeModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
          TerminationDate: new Date(),
          AccessAfterTerminationDays: 0,
          TerminationNote: "",
        };
      }
    
    handleInputChange = (value) => {
        this.setState({ AccessAfterTerminationDays: value });
    };

    handleDateChange = (date) => {
        this.setState({ TerminationDate: date });
    };

    handleNoteChange = (e) => {
        this.setState({ TerminationNote: e.target.value });
    };

    handleSave = async () => {
        try {
            const { TerminationDate, AccessAfterTerminationDays, TerminationNote } = this.state;
            const body = {
            PolicyId: this.props.policyId,
            EmployeeIds: this.props.selectedEmployees,
            TerminationDate: TerminationDate.toLocaleDateString(),
            AccessAfterTerminationDays,
            TerminationNote,
            };

            const response = await axios.put("/api/Settings/terminateemployees", body);
            if (response.data.success) {
            toastr.success('Employee status updated successfully.');
            this.props.refreshEmployees();
            } else {
            toastr.error('Failed to update employee status.');
            }
        } catch (error) {
            console.error(error);
            toastr.error('An error occurred while updating employee status.');
        }
    };

    render() {
        const { TerminationDate, AccessAfterTerminationDays, TerminationNote } = this.state;

        return (
          <Modal
            className="react-modal terminate-employee-modal"
            show={this.props.showEditModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title className="modal-title" style={{fontSize:17}}>Edit Employee Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Line>
                  <div className="date-picker-container">
                    <label className="RT-Label">
                      Benefit End Date
                      <CustomToolTip
                        header="Benefit End Date"
                        bodyContent="The last day you want the employee to have coverage. Only expenses incurred prior to this date will be processed."
                      />
                    </label>
                    <DatePicker
                      onChange={this.handleDateChange}
                      value={TerminationDate}
                      format={"y-MM-dd"}
                      clearIcon={null}
                    />
                  </div>
                  <CustomIntegerInput
                    label="Access After Termination (days)"
                    value={AccessAfterTerminationDays}
                    onChange={this.handleInputChange}
                    toolTip={
                      <CustomToolTip
                        header="Access After Termination (days)"
                        bodyContent="How long the employee can access their account to log in, make claims, and see their claim history."
                      />
                    }
                  />
                </Line>
                <Line>
                  <div className="RT-TextBox Termination-Note">
                    <label className="RT-Label">Termination Note</label>
                    <div className="RT-TextBox__container">
                      <input
                        className="RT-TextBox__input"
                        type="text"
                        value={TerminationNote}
                        onChange={this.handleNoteChange}
                      />
                    </div>
                  </div>
                </Line>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.closeModal}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleSave}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }
};