import {useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {Button, Form, Table} from 'react-bootstrap';
import CustomPagination from 'components/CustomPagination';
import AdvisorDetailsModal from './AdvisorDetailsModal';
import {CustomLoading} from 'components/CustomLoading';

const AdvisorsReport = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('healthClaimAccountId');
    const [sortDirection, setSortDirection] = useState('asc');
    const [rmdList, setRmdList] = useState([]);
    const [bddList, setBddList] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('/api/Advisors/advisors');
            const { advisors, rmdList, bddList } = response.data;
            setData(advisors);
            setFilteredData(advisors);
            setRmdList(rmdList);
            setBddList(bddList);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        const filtered = data.filter((item) =>
            item.healthClaimAccountId?.toString().includes(query) ||
            item.healthClaimAdvisorId?.toString().includes(query) ||
            item.healthClaimRelationship?.toLowerCase().includes(query) ||
            item.healthClaimDescription?.toLowerCase().includes(query) ||
            item.lastName?.toLowerCase().includes(query) ||
            item.firstName?.toLowerCase().includes(query) ||
            item.email?.toLowerCase().includes(query) ||
            item.user_Id?.toString().includes(query)
        );

        setSearchQuery(query);
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        const sorted = [...filteredData].sort((a, b) => {
            if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setSortColumn(column);
        setSortDirection(direction);
        setFilteredData(sorted);
    };

    const handleShowDetails = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedItem(null);
    };

    const currentRecords = useMemo(() => {
        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        return filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
    }, [currentPage, itemsPerPage, filteredData]);

    const nPages = useMemo(() => {
        return Math.ceil(filteredData.length / itemsPerPage);
    }, [filteredData.length, itemsPerPage]);

    const handleAdvisorUpdate = (updatedA1Number, updatedRmd1, updatedBdd1, updatedBdd2) => {
        setSelectedItem((prev) => ({
            ...prev,
            a1number: updatedA1Number,
            rmd1DistroId: updatedRmd1,
            bdd1DistroId: updatedBdd1,
            bdd21DistroId: updatedBdd2,
        }));

        setData((prevData) =>
            prevData.map((item) =>
                item.id === selectedItem.id
                    ? { ...item, a1number: updatedA1Number, rmd1DistroId: updatedRmd1 }
                    : item
            )
        );

        setFilteredData((prevFilteredData) =>
            prevFilteredData.map((item) =>
                item.id === selectedItem.id
                    ? { ...item, a1number: updatedA1Number, rmd1DistroId: updatedRmd1 }
                    : item
            )
        );
    };


    return (
        <>
            {loading ? (
                <CustomLoading/>
            ) : (
                <>
                    <AdvisorDetailsModal
                        showModal={showModal}
                        onHide={handleCloseModal}
                        selectedItem={selectedItem}
                        onUpdate={handleAdvisorUpdate}
                        rmdList={rmdList}
                        bddList={bddList}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className="mb-3"
                    />
                    <Table hover className="report-table advisors-table">
                        <thead>
                        <tr>
                            <th className="table-text-content" onClick={() => handleSort('healthClaimAccountId')}>
                                Health Claim Account
                                ID {sortColumn === 'healthClaimAccountId' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('healthClaimAdvisorId')}>
                                Health Claim Advisor
                                ID {sortColumn === 'healthClaimAdvisorId' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('healthClaimRelationship')}>
                                Health Claim
                                Relationship {sortColumn === 'healthClaimRelationship' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('healthClaimDescription')}>
                                Health Claim
                                Description {sortColumn === 'healthClaimDescription' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('lastName')}>
                                Last Name {sortColumn === 'lastName' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('firstName')}>
                                First Name {sortColumn === 'firstName' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('isActive')}>
                                Is Active {sortColumn === 'isActive' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('isOwner')}>
                                Is Owner {sortColumn === 'isOwner' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('user_Id')}>
                                User ID {sortColumn === 'user_Id' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content" onClick={() => handleSort('email')}>
                                Email {sortColumn === 'email' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th className="table-text-content">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentRecords.map((item, index) => (
                            <tr
                                key={index}
                                className="table-body-item"
                                style={item.isActive ? null : {backgroundColor: 'pink'}}
                            >
                                <td className="table-text-content">{item.healthClaimAccountId ?? "N/A"}</td>
                                <td className="table-text-content">{item.healthClaimAdvisorId ?? "N/A"}</td>
                                <td className="table-text-content">{item.healthClaimRelationship ?? "N/A"}</td>
                                <td className="table-text-content">{item.healthClaimDescription ?? "N/A"}</td>
                                <td className="table-text-content">{item.lastName ?? "N/A"}</td>
                                <td className="table-text-content">{item.firstName ?? "N/A"}</td>
                                <td className="table-text-content">{item.isActive ? "Yes" : "No"}</td>
                                <td className="table-text-content">{item.isOwner ? "Yes" : "No"}</td>
                                <td className="table-text-content">{item.user_Id ?? "N/A"}</td>
                                <td className="table-text-content">{item.email ?? "N/A"}</td>
                                <td>
                                    <Button onClick={() => handleShowDetails(item)} variant="primary">
                                        Show Details
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className="pagination-container" style={{float: 'inline-end'}}>
                        <div className="pagination-info">
                            <span>Showing {currentPage * itemsPerPage - itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredData.length)} entries out of {filteredData.length} entries</span>
                        </div>
                        <CustomPagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default AdvisorsReport;
