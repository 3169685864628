import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  RecordHeader,
  TabSet,
  Tab,
  Button,
  Display,
  Line,
  Card,
  Column,
  Panel,
} from "ktm-ui";
import EditScreen from "./Edit";
import TestCreate_claimScreen from "enroll/test/Create_claim";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsEmployeeYear {
  Id: string;
  Policy_Id: string;
  Employee_Id: string;
  PolicyBenefit_Id: string;
  BenefitCategory_Id: string;
  Coverage: string;
  HasProrating: boolean;
  BenefitYear_Id: string;
}

interface IdsPolicyBenefit {
  Name: string;
  Id: string;
}

interface IdsBenefitCategory {
  Id: string;
  Name: string;
}

interface IdsBenefitYear {
  Id: string;
  BenefitYear: string;
}

interface IdsEmployee {
  Id: string;
  Name: string;
}

export default KTMScreen(
  { name: "enroll.employee-benefits.record" },
  class extends React.Component<{ employeeBenefit_id: string }> {
    data: {
      dsEmployeeYear: IdsEmployeeYear;
      dsPolicyBenefit: IdsPolicyBenefit;
      dsBenefitCategory: IdsBenefitCategory;
      dsBenefitYear: IdsBenefitYear;
      dsEmployee: IdsEmployee;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsEmployeeYear";
      await KTMApi.start(screen, {
        "@employeeBenefit_id": this.props.employeeBenefit_id,
      });
      screen.title = this.data.dsPolicyBenefit.Name;
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.tabs)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return (
        <RecordHeader
          entityLabel="Benefit"
          label={this.data.dsPolicyBenefit.Name}
        >
        </RecordHeader>
      );
    }

    tabs({ field, draw, action }: RenderEvent) {
      return (
        <TabSet>
          <Tab label="main-tab" template={this.main_tab} />
        </TabSet>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card
          actions={
            <>
              <Button action={action(this.edit)} label="Edit" style="link" />
            </>
          }
        >
          <Display field={field("@dsEmployee.Name")} textStyle="bold" />
          <Line>
            <Display field={field("@dsBenefitYear.BenefitYear")} />
            <Display field={field("@dsPolicyBenefit.Name")} span="8" />
          </Line>
          <Line>
            <Display field={field("@dsBenefitCategory.Name")} />
            <Display field={field("Coverage")} />
            <Display
              field={field("HasProrating")}
              toolTip={
                <CustomToolTip
                  header="Pro-rating"
                  bodyContent="If checked, pro-rating is ON and an employee’s available credits are adjusted based on the months remaining in the benefit year. If unchecked, an employee receives their full yearly limit."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    async edit({ screen }: ActionEvent) {
      await screen.openDialog(
        <EditScreen employeeBenefit_id={this.data.dsEmployeeYear.Id}/>,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }

    async create_claim({ screen }: ActionEvent) {
      await screen.openDialog(
        <TestCreate_claimScreen
          benefit_id={this.data.dsPolicyBenefit.Id}
          employee_id={this.data.dsEmployee.Id}
        />
      );
    }
  }
);
