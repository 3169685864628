import CustomPagination from "components/CustomPagination";
import { Card, Line } from "ktm-ui";
import React from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { CSVLink } from "react-csv";

export default class OpsTransferReport extends React.Component<any, any> {
  state = {
    loading: true,
    success: false,
    opsTransfers: [],
    total: 0,
    currentPage: 1,
    itemsPerPage: 10,
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
  };

  componentDidMount() {
    this.initialize();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fromDate !== this.state.fromDate ||
      prevState.toDate !== this.state.toDate
    ) {
      this.setState({ loading: true });
      this.initialize();
    }
  }
  setCurrentPage = (page: number) => {
    this.setState({ currentPage: page });
  };

  initialize = () => {
    fetch(
      "/api/opstransfer/getopstransferreport?dateFrom=" +
        this.state.fromDate.toISOString() +
        "&dateTo=" +
        this.state.toDate.toISOString(),
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loading: false,
          opsTransfers: data.transfers,
          total: data.total
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  setFromDate = (date) => {
    this.setState({
      fromDate: date,
    });
  };

  setToDate = (date) => {
    this.setState({
      toDate: date,
    });
  };

  render() {
    const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
    const currentRecords = this.state.opsTransfers.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    const nPages = Math.ceil(
      this.state.opsTransfers.length / this.state.itemsPerPage
    );

    var opsTransfersTable = null;
    if (this.state.opsTransfers.length > 0) {
      opsTransfersTable = (
        <>
          <table className="table">
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Date</th>
                <th>Type</th>
                <th className="text-end">Amount</th>
                <th className="text-end">Admin</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((data, i) => {
                let utcDateTime = data.transDate;
                let localDateTime = moment(utcDateTime)
                  .local()
                  .format("YYYY-MM-DD hh:mm A");
                return (
                  <tr>
                    {/* <td>{i + 1}</td> */}
                    <td>{localDateTime}</td>
                    <td>{data.transType}</td>
                    <td className="text-end">
                      {data.transAmount.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                    <td className="text-end">{data.createdBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              position: "absolute",
              left: "25px",
              margin: "0px",
              fontWeight: 500,
              fontSize: 20,
            }}
          >
            Total:  {this.state.total.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
          </div>
          <div
            className="pagination-container"
            style={{
              position: "relative",
              right: "10px",
              margin: "0px",
              justifyContent: "end",
            }}
          >
            <div className="pagination-info">
              <span>
                Showing {indexOfFirstRecord + 1} -{" "}
                {indexOfFirstRecord + currentRecords.length} entries out of{" "}
                {this.state.opsTransfers.length} entries
              </span>
            </div>
            <CustomPagination
              nPages={nPages}
              currentPage={this.state.currentPage}
              setCurrentPage={this.setCurrentPage}
            />
          </div>
        </>
      );
    }
    
    const today = new Date().toISOString().slice(0, 10);
    const fileName = `opstransfers_${today}.csv`;

    return (
      <>
        {this.state.loading ? (
          <div className="financial-spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <div>
            <div className="mb-2 d-flex justify-content-between">
              <CustomDateRangePicker
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                setFromDate={this.setFromDate}
                setToDate={this.setToDate}
              />
              {this.state.opsTransfers.length > 0 ? (
                <div>
                  <CSVLink
                    data={this.state.opsTransfers}
                    filename={fileName}
                  >
                    <Button
                      variant="primary"
                      className="csv-button export-to-csv"
                    >
                      <span className="csv-text">Export to CSV</span>
                    </Button>
                  </CSVLink>
                </div>
              ) : null}
            </div>

            {opsTransfersTable}
          </div>
        )}
      </>
    );
  }
}
