import { ScreenRef } from "./ActionEvent";
import { Application } from "./Application";
import {IServiceResponse, Response} from './Response';

export type IRequestCallType = 'start' | 'batch' | 'refresh-row' | 'method';
export interface IServiceRequest {
    domain:string;
    branch:string;
    client?:string;
    screen?:string;
    parameters?:any;
    stopOnWarning?:boolean;
    callType:IRequestCallType;
    service?:string;
    method?:string;
    studioOpen?:boolean;
}

export class Request {

    static async refreshRow(screen:ScreenRef,params:{collection:string,rowid:string,appendNewRows?:boolean}){
    
        let canvas = screen.canvas;
        let method = "refresh-row";
        let newRows = (params.appendNewRows) ? "append" : "prepend";
        let body:IServiceRequest = {
            domain:canvas.app.domain,
            branch:canvas.app.branch,
            client:"ktm-ui",
            callType:"refresh-row",
            method,
            screen:canvas.launchParams.screen,
            parameters: {
                collection:params.collection,
                "@event-value":params.rowid,
                newRows
            }
        };
        let json = await Request.executeRequest(screen,"refresh-row",method,body);

        await Response.process(screen,"refresh-row",json);     
        canvas.update();
    }

    private static async executeRequest(screen:ScreenRef,callType:IRequestCallType,method:string,body:IServiceRequest):Promise<any> {
        let path = Request.getScreenPath() + "/" + method;
        let app = screen.canvas.app;
        body.studioOpen = app.studioOpen;

        let json = await app.doPostRequest(path,body);

        if (json.error && json.error.code == "NOT_SIGNED_IN"){
            app.spinner.pause();
            await Application.instance.session.signin(Application.instance);
            app.spinner.resume();

            json = await app.doPostRequest(path,body);    
        }
        
        await Response.process(screen,callType,json);    
        return json;  
    }

    static async callScript(screen:ScreenRef,callType:IRequestCallType,method:string,params:any){
    
        let canvas = screen.canvas;
      
        let body:IServiceRequest = {
            domain:canvas.app.domain,
            branch:canvas.app.branch,
            client:"ktm-ui",
            screen:canvas.launchParams.screen,
            method,
            callType,
            parameters: params,
            stopOnWarning:true
        };

        let res = await Request.executeRequest(screen,callType,method,body);
        if (res.retryAfterWarnings){
            body.stopOnWarning = false;
            await Request.executeRequest(screen,callType,method,body);
        }  
    }

    static async start(screen:ScreenRef,params:any){
    
        let canvas = screen.canvas;
        params["event-value"] = screen.eventOptions.value;

        let body:IServiceRequest = {
            domain:canvas.app.domain,
            branch:canvas.app.branch,
            client:"ktm-ui",
            callType:"start",
            screen:canvas.launchParams.screen,
            parameters: params
        };
        let json = await Request.executeRequest(screen,"start","start",body);
        await Response.process(screen,"start",json);     
    }

    public static getParameters(screen:ScreenRef,method:string):any {
        let params = {};
        let canvas = screen.canvas;
        if (canvas.scriptMethods){
            let scriptMethod = canvas.scriptMethods[method];
            if (scriptMethod && scriptMethod.parameters){
                for(let i = 0; i < scriptMethod.parameters.length;i++){
                    let name = scriptMethod.parameters[i];
                    if (name.endsWith(":all")){
                        // handle all
                    }
                    else if (name.endsWith(":selected-rows")){
                        // handle selected rows
                    }
                    params[name] = canvas.getValue(name,screen.eventOptions);
                }
            }
        }
        return params;
    }

    static getScreenPath():string {
        return "/screen";
    }

 

}