import React from "react";
import Spinner from "components/Spinner";
import moment from "moment";
import axios from "axios";
import Cookie from "js-cookie";

export default class LimitCalculationScreen extends React.Component<any> {
  state = {
    loaded: false,
    plans: null,
    type: null,
    employee: null,
    currEmpYear: null,
    prevEmpYear: null,
    yearBeforePrevEmpYear: null,
    benefitCategory: null,
    company: null,
    currBenLimits: null,
    prevBenLimits: null,
    yearBeforePrevYearBenLimits: null,
    enableWellness: false,
    lsaCurrentLimitAdjustments: null,
    hsaCurrentLimitAdjustments: null,
    lsaPreviousLimitAdjustments: null,
    hsaPreviousLimitAdjustments: null,
    prevBenefitCategory: null,
    yearBeforePrevBenefitCategory: null,
    prorateMonths: 0,
    HsaSelected: 0,
    LsaSelected: 0,
    prevHsaSelected: 0,
    prevLsaSelected: 0,
    yearBeforePrevHsaSelected: 0,
    yearBeforePrevLsaSelecte: 0,
    lsaCurrentBonusCredits: null,
    hsaCurrentBonusCredits: null,
    lsaPreviousBonusCredits: null,
    hsaPreviousBonusCredits: null,
  };

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }

  async startLoading() {
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');
    const type = params.get('type');
    this.setState({type});

    const reqOne = axios.get(`/api/OpsEmployee/getplan?employeeId=${employeeId}`);
    const reqTwo = axios.get(`/api/OpsEmployee/getemployee?employeeId=${employeeId}`);

    axios
      .all([reqOne, reqTwo])
      .then(
        axios.spread((...responses) => {
          this.setState({
            loaded: true,
            plans: responses[0].data.spendingAccounts,
            enableWellness: responses[0].data.enableWellness,
            lsaCurrentLimitAdjustments:
              responses[0].data.lsaLimitAdjustmentsList,
            hsaCurrentLimitAdjustments:
              responses[0].data.hsaLimitAdjustmentsList,
            lsaPreviousLimitAdjustments:
              responses[0].data.lsaPreviousLimitAdjustmentsList,
            hsaPreviousLimitAdjustments:
              responses[0].data.hsaPreviousLimitAdjustmentsList,
            lsaCurrentBonusCredits: responses[0].data.lsaBonusCreditList,
            hsaCurrentBonusCredits: responses[0].data.hsaBonusCreditList,
            lsaPreviousBonusCredits: responses[0].data.lsaPreviousBonusCreditList,
            hsaPreviousBonusCredits: responses[0].data.hsaPreviousBonusCreditList,
            prevBenefitCategory: responses[1].data.prevBenefitCategory,
            yearBeforePrevBenefitCategory: responses[1].data.yearBeforePrevBenefitCategory,
            prorateMonths: responses[1].data.prorateMonths,
            HsaSelected: responses[1].data.hsaSelected,
            LsaSelected: responses[1].data.lsaSelected,
            prevHsaSelected: responses[1].data.prevHsaSelected,
            prevLsaSelected: responses[1].data.prevLsaSelected,
            yearBeforePrevHsaSelected: responses[1].data.yearBeforePrevHsaSelected,
            yearBeforePrevLsaSelected: responses[1].data.yearBeforePrevLsaSelected,
            employee: responses[1].data.employee,
            company: responses[1].data.company,
            benefitCategory: responses[1].data.benefitCategory,
            currEmpYear: responses[1].data.empYear,
            prevEmpYear: responses[1].data.prevEmpYear,
            yearBeforePrevEmpYear: responses[1].data.yearBeforePrevEmpYear,
            currBenLimits: responses[1].data.currBenLimits,
            prevBenLimits: responses[1].data?.prevBenLimits,
            yearBeforePrevYearBenLimits: responses[1].data?.yearBeforePrevYearBenLimits,
          });
        })
      )
      .catch((error) => {
        if (error.response.status == 401) {
          Cookie.remove("ktm_user_token");
          Cookie.remove("noAddress");
          this.props.history.push("/login");
        } else {
          this.props.history.push("/error");
        }
      });
  }

  renderContent() {
    const {
      loaded,
      plans,
      type,
      currBenLimits,
      prevBenLimits,
      yearBeforePrevYearBenLimits,
      hsaCurrentLimitAdjustments,
      hsaPreviousLimitAdjustments,
      lsaCurrentLimitAdjustments,
      lsaPreviousLimitAdjustments,
      currEmpYear,
      prevEmpYear,
      yearBeforePrevEmpYear,
      prorateMonths,
      employee,
      prevBenefitCategory,
      HsaSelected,
      LsaSelected,
      prevHsaSelected,
      prevLsaSelected,
      yearBeforePrevHsaSelected,
      yearBeforePrevLsaSelecte,
      hsaCurrentBonusCredits,
      lsaCurrentBonusCredits,
      hsaPreviousBonusCredits,
      lsaPreviousBonusCredits
    } = this.state;

    if (!loaded) {
      return <Spinner />;
    }

    var plan = plans.filter((obj) => {
      return obj.type === type.toUpperCase();
    })[0];

    var ccType = "";
    if (plan.carryType == "CCF") {
      ccType = "Credit Carry Forward";
    } else if (plan.carryType == "ECF") {
      ccType = "Expense Carry Forward";
    } else{
      ccType = "No Carry Forward"
    }

    var currlimit = 0;
    var currsingleLimit = plan.type == "HSA" ? currBenLimits.hsasingleLimit : currBenLimits.lsasingleLimit;
    if(currBenLimits?.isFlex)
    {
      currsingleLimit = currBenLimits.flexSingleLimit;
    }

    var currcoupleLimit = plan.type == "HSA" ? currBenLimits.hsacoupleLimit : currBenLimits.lsacoupleLimit;
    if(currBenLimits?.isFlex)
    {
      currcoupleLimit = currBenLimits.flexCoupleLimit;
    }

    var currfamilyLimit = plan.type == "HSA" ? currBenLimits.hsafamilyLimit : currBenLimits.lsafamilyLimit;
    if(currBenLimits?.isFlex)
    {
      currfamilyLimit = currBenLimits.flexFamilyLimit;
    }

    var prevlimit = 0;
    var prevsingleLimit = plan.type == "HSA" ? prevBenLimits?.hsasingleLimit : prevBenLimits?.lsasingleLimit;
    if(prevBenLimits?.isFlex)
    {
      prevsingleLimit = prevBenLimits.flexSingleLimit;
    }

    var prevcoupleLimit = plan.type == "HSA" ? prevBenLimits?.hsacoupleLimit : prevBenLimits?.lsacoupleLimit;
    if(prevBenLimits?.isFlex)
    {
      prevcoupleLimit = prevBenLimits.flexCoupleLimit;
    }

    var prevfamilyLimit = plan.type == "HSA" ? prevBenLimits?.hsafamilyLimit : prevBenLimits?.lsafamilyLimit;
    if(prevBenLimits?.isFlex)
    {
      prevfamilyLimit = prevBenLimits.flexFamilyLimit;
    }

    var yearBeforePrevlimit = 0;
    var yearBeforePrevSingleLimit = plan.type == "HSA" ? yearBeforePrevYearBenLimits?.hsasingleLimit : yearBeforePrevYearBenLimits?.lsasingleLimit;
    if(yearBeforePrevYearBenLimits?.isFlex)
    {
      yearBeforePrevSingleLimit = yearBeforePrevYearBenLimits.flexSingleLimit;
    }

    var yearBeforePrevCoupleLimit = plan.type == "HSA" ? yearBeforePrevYearBenLimits?.hsacoupleLimit : yearBeforePrevYearBenLimits?.lsacoupleLimit;
    if(yearBeforePrevYearBenLimits?.isFlex)
    {
      yearBeforePrevCoupleLimit = yearBeforePrevYearBenLimits.flexCoupleLimit;
    }

    var yearBeforePrevFamilyLimit = plan.type == "HSA" ? yearBeforePrevYearBenLimits?.hsafamilyLimit : yearBeforePrevYearBenLimits?.lsafamilyLimit;
    if(yearBeforePrevYearBenLimits?.isFlex)
    {
      yearBeforePrevFamilyLimit = yearBeforePrevYearBenLimits.flexFamilyLimit;
    }

    var carryIn = "";
    var prevYearCarryIn = "";
    var yearBeforePrevYearCarryIn = "";
    var adjustmentList = [];
    var previousAdjustmentList = [];
    var currCategoryLimit = 0;
    var prevCategoryLimit = 0;
    var yearPrevCategoryLimit = 0;
    var bonusCreditList = [];
    var previousBonusCreditList = [];

    if (plan.type == "HSA") {
      if (hsaCurrentLimitAdjustments != null) {
        adjustmentList = hsaCurrentLimitAdjustments
      }

      if (hsaPreviousLimitAdjustments != null) {
        previousAdjustmentList = hsaPreviousLimitAdjustments;
      }

      if (hsaCurrentBonusCredits != null) {
        bonusCreditList = hsaCurrentBonusCredits;
      }

      if (hsaPreviousBonusCredits != null) {
        previousBonusCreditList = hsaPreviousBonusCredits;
      }

      carryIn = currEmpYear?.hsacarriedIn;
      prevYearCarryIn = prevEmpYear?.hsacarriedIn;
      yearBeforePrevYearCarryIn = yearBeforePrevEmpYear?.hsacarriedIn;

    } else if (plan.type == "LSA") {

      if (lsaCurrentLimitAdjustments != null) {
        adjustmentList = lsaCurrentLimitAdjustments;
      }

      if (lsaPreviousLimitAdjustments != null) {
        previousAdjustmentList = lsaPreviousLimitAdjustments;
      }

      if (lsaCurrentBonusCredits != null) {
        bonusCreditList = lsaCurrentBonusCredits;
      }

      if (lsaPreviousBonusCredits != null) {
        previousBonusCreditList = lsaPreviousBonusCredits;
      }

      carryIn = currEmpYear?.lsacarriedIn;
      prevYearCarryIn = prevEmpYear?.lsacarriedIn;
      yearBeforePrevYearCarryIn = yearBeforePrevEmpYear?.lsacarriedIn;
    }

    if (this.state.currEmpYear?.coverage == "SINGLE") {
        currCategoryLimit = currsingleLimit === null ? 0 : currsingleLimit;
    } else if (this.state.currEmpYear?.coverage == "COUPLE") {
      if(currBenLimits?.isFlex)
      {
        currcoupleLimit = currBenLimits.flexCoupleLimit;
      } else
      {
        currCategoryLimit = currcoupleLimit === null ? 0 : currcoupleLimit;
      }
    } else if (this.state.currEmpYear?.coverage == "FAMILY") {
      if(currBenLimits?.isFlex)
      {
        currfamilyLimit = currBenLimits.flexFamilyLimit;
      } else
      {
        currCategoryLimit = currfamilyLimit === null ? 0 : currfamilyLimit;
      }
    }

    if(currBenLimits?.isFlex)
    {
      if(plan.type == "HSA")
      {
        currlimit = HsaSelected;
      } else if (plan.type == "LSA")
      {
        currlimit = LsaSelected;
      }
    } else {
      currlimit = currCategoryLimit;
    }

    if (prevEmpYear?.coverage == "SINGLE") {
      if(prevsingleLimit != null)
      {
        prevCategoryLimit = prevsingleLimit;
      } else {
        prevCategoryLimit = plan.type == "HSA" ? prevEmpYear?.hsamaxCarryFwd : prevEmpYear?.lsamaxCarryFwd;
      }

    } else if (prevEmpYear?.coverage == "COUPLE") {
      if(prevcoupleLimit != null)
      {
        prevCategoryLimit = prevcoupleLimit;
      } else {
        prevCategoryLimit = plan.type == "HSA" ? prevEmpYear?.hsamaxCarryFwd : prevEmpYear?.lsamaxCarryFwd;
      }

    } else if (prevEmpYear?.coverage == "FAMILY") {
      if(prevfamilyLimit != null)
      {
        prevCategoryLimit = prevfamilyLimit;
      } else {
        prevCategoryLimit = plan.type == "HSA" ? prevEmpYear?.hsamaxCarryFwd : prevEmpYear?.lsamaxCarryFwd;
      }
    }

    if(prevBenLimits?.isFlex)
    {
      if(plan.type == "HSA")
      {
        prevlimit = prevHsaSelected;
      } else if (plan.type == "LSA")
      {
        prevlimit = prevLsaSelected;
      }
    } else {
      prevlimit = prevCategoryLimit;
    }

    if (this.state.yearBeforePrevEmpYear?.coverage == "SINGLE") {
      yearPrevCategoryLimit = yearBeforePrevSingleLimit;
    } else if (this.state.yearBeforePrevEmpYear?.coverage == "COUPLE") {
      yearPrevCategoryLimit = yearBeforePrevCoupleLimit;
    } else if (this.state.yearBeforePrevEmpYear?.coverage == "FAMILY") {
      yearPrevCategoryLimit = yearBeforePrevFamilyLimit;
    }

    if(yearBeforePrevYearBenLimits?.isFlex)
    {
      if(plan.type == "HSA")
      {
        yearBeforePrevlimit = yearBeforePrevHsaSelected;
      } else if (plan.type == "LSA")
      {
        yearBeforePrevlimit = yearBeforePrevLsaSelecte;
      }
    } else {
      yearBeforePrevlimit = yearPrevCategoryLimit;
    }

    let proratedCurrLimit = Math.round((currCategoryLimit * prorateMonths) / 12 * 100) / 100;
    let proratedPrevLimit = Math.round((prevCategoryLimit * prorateMonths) / 12 * 100) / 100;
    let proratedYearPrevLimit = Math.round((yearPrevCategoryLimit * prorateMonths) / 12 * 100) / 100;

    var prevYear = null;
    plan.label =
      this.state.enableWellness && plan.type === "LSA"
        ? "Wellness Spending Account"
        : plan.label;
    plan.type = this.state.enableWellness && plan.type === "LSA" ? "WSA" : plan.type;

    if (plan.prevYear != null) {
      prevYear = (
        <div style={{marginTop:10}}>
          <div className="black" style={{marginBottom:10}}>
            <p>
              This is detailed information about your {plan.label} limit from
              the period of {plan.prevYear.startDate} ending{" "}
              {plan.prevYear.endDate} this period is: {plan.prevYear.status}.
            </p>
          </div>
          <div className="bd bd-gray-300 rounded table-responsive">
            <table
              className="table table-hover black"
              style={{ marginBottom: 0 }}
            >
              <thead>
                <tr>
                  <th>{plan.type} AMOUNT</th>
                  <th>DESCRIPTION</th>
                  <th>LAST DATE CHANGED</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {prevEmpYear.hasProrating ? 
                  (
                    <td>
                      ${proratedPrevLimit.toFixed(2)}
                    </td>
                  ) : (
                    <td>
                      ${prevlimit.toFixed(2)}
                    </td>
                  )}
                  <td>
                    Benefit Category: {prevBenefitCategory.name} 
                    <br/>
                    Category Limits: ${prevsingleLimit}/${prevcoupleLimit}/${prevfamilyLimit} (Single/Couple/Family) 
                    <br/>
                    Type: {prevEmpYear?.coverage}
                    <br/>
                    {prevEmpYear.hasProrating && (
                        <>
                          Limit of ${prevCategoryLimit.toFixed(2)} prorated for {prorateMonths} full employment months out of 12 months
                          <br/>
                          Hire date {moment(employee.hireDate).utc().format("YYYY-MM-DD")} + waiting period of {employee.waitingPeriodMonths} months, expires {moment(employee.benefitStartDate).utc().format("YYYY-MM-DD")}.
                        </>
                    )}
                  </td>
                  <td className="text-right">{}</td>
                </tr>
              </tbody>
              {previousAdjustmentList.length > 0 && (
                <tbody>
                {previousAdjustmentList.map((adjustment, index) => (
                  <tr key={index}>
                    <td>
                      {adjustment.adjustmentAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                    <td>
                      Limit Adjustment
                      <br/>
                      {adjustment.reason != "" ? "Reason: " + adjustment.reason : ""}
                    </td>
                    <td>
                      {moment(adjustment.transDate).utc().format("YYYY-MM-DD")}
                    </td>
                  </tr>
                ))}
              </tbody>
              )}
              {previousBonusCreditList.length > 0 && (
                <tbody>
                  {previousBonusCreditList.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {data.credit.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </td>
                      <td>
                        Type: Bonus Credit
                        <br/>
                        {data.reason != "" ? "Reason: " + data.reason : ""}
                      </td>
                      <td>
                        {moment(data.transDate).utc().format("YYYY-MM-DD")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {prevYearCarryIn != "0" && (
                <tbody>
                  <tr>
                    <td>
                      {parseFloat(prevYearCarryIn).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                    <td>Rollover credit carried forward</td>
                    <td className="text-right">{}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      );
    }

    var yearBeforePrevYear = null;
    if (
      plan.yearBeforePrevYear != null &&
      plan.yearBeforePrevYear.status != null
    ) {
      yearBeforePrevYear = (
        <div style={{marginTop:10}}>
          <div className="black">
            <p>
              This is detailed information about your {plan.label} limit from
              the period of {plan.yearBeforePrevYear.startDate} ending{" "}
              {plan.yearBeforePrevYear.endDate} this period is:{" "}
              {plan.yearBeforePrevYear.status}.
            </p>
          </div>
          <div className="bd bd-gray-300 rounded table-responsive">
            <table
              className="table table-hover black"
              style={{ marginBottom: 0 }}
            >
              <thead>
                <tr>
                  <th>{plan.type} AMOUNT</th>
                  <th>DESCRIPTION</th>
                  <th>LAST DATE CHANGED</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {yearBeforePrevEmpYear.hasProrating ? 
                  (
                    <td>
                      ${proratedYearPrevLimit.toFixed(2)}
                    </td>
                  ) : (
                    <td>
                      ${yearBeforePrevlimit.toFixed(2)}
                    </td>
                  )}
                  <td>
                    Benefit Category: {this.state.yearBeforePrevBenefitCategory?.name} 
                    <br/>
                    Category Limits: ${yearBeforePrevSingleLimit}/${yearBeforePrevCoupleLimit}/${yearBeforePrevFamilyLimit}{" "}
                    (Single/Couple/Family) 
                    <br/>
                    Type: {this.state.yearBeforePrevEmpYear?.coverage}
                    {yearBeforePrevEmpYear.hasProrating && (
                        <>
                          Limit of ${yearPrevCategoryLimit.toFixed(2)} prorated for {prorateMonths} full employment months out of 12 months
                          <br/>
                          Hire date {moment(employee.hireDate).utc().format("YYYY-MM-DD")} + waiting period of {employee.waitingPeriodMonths} months, expires {moment(employee.benefitStartDate).utc().format("YYYY-MM-DD")}.
                        </>
                    )}
                  </td>
                  <td className="text-right">{}</td>
                </tr>
              </tbody>
              {yearBeforePrevYearCarryIn != "0" && (
                <tbody>
                  <tr>
                    <td>
                      {parseFloat(yearBeforePrevYearCarryIn).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    </td>
                    <td>Rollover credit carried forward</td>
                    <td className="text-right">{}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      );
    }

    return (
      <div className="br-mainpanel site-content">
        <div
          className="br-section-wrapper mg-b-30 no-padding mg-x-50"
          style={{ maxWidth: 1250 }}
        >
          <div className="card mg-t-35">
            <div
              className="card-header tx-medium tx-black"
              style={{ backgroundColor: "#fff" }}
            >
              {plan.label.toUpperCase()}
            </div>
            <div className="card-body">
              <div className="mg-b-0">
                <div className="black" style={{marginBottom:10}}>
                  <p>
                    This is detailed information about your {plan.label} limit
                    from the period of {plan.currYear.startDate} ending{" "}
                    {plan.currYear.endDate} this period is:{" "}
                    {plan.currYear.status}.
                  </p>
                  <p>
                    You will have until {plan.currYear.runoff} to submit your
                    claims for the period. After this, the period will close
                    with {ccType}.
                  </p>
                </div>
                <div className="bd bd-gray-300 rounded table-responsive">
                  <table
                    className="table table-hover black"
                    style={{ marginBottom: 0 }}
                  >
                    <thead>
                      <tr>
                        <th>{plan.type} AMOUNT</th>
                        <th>DESCRIPTION</th>
                        <th>LAST DATE CHANGED</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {(currEmpYear.hasProrating && !currBenLimits?.isFlex) ? 
                        (
                          <td>
                            ${proratedCurrLimit.toFixed(2)}
                          </td>
                        ) : (
                          <td>
                            ${currlimit.toFixed(2)}
                          </td>
                        )}
                        <td>
                          Benefit Category: {this.state.benefitCategory.name}{" "}
                          <br/>
                          Category Limits: ${currsingleLimit}/${currcoupleLimit}/${currfamilyLimit} (Single/Couple/Family) 
                          <br/>
                          Type: {this.state.currEmpYear.coverage}
                          <br/>
                          {currEmpYear.hasProrating && (
                            <>
                              Limit of ${currCategoryLimit.toFixed(2)} prorated for {prorateMonths} full employment months out of 12 months
                              <br/>
                              Hire date {moment(employee.hireDate).utc().format("YYYY-MM-DD")} + waiting period of {employee.waitingPeriodMonths} months, expires {moment(employee.benefitStartDate).utc().format("YYYY-MM-DD")}.
                            </>
                          )}
                        </td>
                        <td className="text-right">{}</td>
                      </tr>
                    </tbody>
                    {adjustmentList.length > 0 && (
                      <tbody>
                        {adjustmentList.map((adjustment, index) => (
                          <tr key={index}>
                            <td>
                              {adjustment.adjustmentAmount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </td>
                            <td>
                              Type: Limit Adjustment
                              <br/>
                              {adjustment.reason != "" ? "Reason: " + adjustment.reason : ""}
                            </td>
                            <td>
                              {moment(adjustment.transDate).utc().format("YYYY-MM-DD")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                    {bonusCreditList.length > 0 && (
                      <tbody>
                        {bonusCreditList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {data.credit.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </td>
                            <td>
                              Type: Bonus Credit
                              <br/>
                              {data.reason != "" ? "Reason: " + data.reason : ""}
                            </td>
                            <td>
                              {moment(data.transDate).utc().format("YYYY-MM-DD")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                    {carryIn != "0" && (
                      <tbody>
                        <tr>
                          <td>
                            {parseFloat(carryIn).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>Rollover credit carried forward</td>
                          <td className="text-right">{}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {prevYear}
              {yearBeforePrevYear}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
