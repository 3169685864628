import React from 'react';
import {  ActionRef } from '../core';
import { Icon } from './Icon';

export class NavLink extends React.Component<{
    action:ActionRef;
    label: string;
    name?: string;
    description?:string;
    icon?:string;
    params?: any;
    asDialog?:boolean;
}> {
    render() {
        let label;
        let description;
        let iconName = this.props.icon;
        label = this.props.label;
        description = this.props.description;
        if (!label) return null;
       
        if (description){
            description = <div className="RT-NavLink__description">{description}</div>
        }
        let text = <>
            <div className="RT-NavLink__label">{label}</div>
            {description}
        </>
        let content;
        if (iconName){
            let icon = this.renderIcon(iconName,null)
            content = <div style={{display:"flex"}}>
                <div className="RT-NavLink__icon">{icon}</div>
                <div>{text}</div>
            </div>
        }
        else {
            content = text;
        }
        return (
            <div className="RT-NavLink">
                <a href="#" onClick={this.handleClick}>
                    {content}
                </a>
            </div>
        );
    }

    renderIcon(icon:string,fill:string):any {
        return <Icon icon={icon} fill={fill} size={16}/>
    }

    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    
        // form.closeMenu();
        if (this.props.action){
            this.props.action.trigger();
        }

    };
}