import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';

interface IdsEmployee {
    Id:string;
    FirstName:string;
    LastName:string;
    MailingStreet1:string;
    MailingStreet2:string;
    MailingCity:string;
    MailingProv:string;
    MailingCountry:string;
    MailingPostalCode:string;
    HomePhone:string;
    MobilePhone:string;
    UpdatedBy:string;
};

export default KTMScreen({name:"planadmin.employee.edit-address"}, class extends React.Component<{employee_id:string}> {

    data:{
        dsEmployee:IdsEmployee;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsEmployee";
        await KTMApi.start(screen,{
            "@employee_id":this.props.employee_id,
        });
        screen.title = "Edit contact details";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit contact details"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("MailingStreet1")} hideLabel={true}/>
                <Display field={field("MailingStreet2")}/>
                <Line>
                    <Display field={field("MailingCity")}/>
                    <Display field={field("MailingProv")}/>
                </Line>
                <Line>
                    <Display field={field("MailingCountry")}/>
                    <Display field={field("MailingPostalCode")}/>
                </Line>
                <Line>
                    <Display field={field("HomePhone")}/>
                    <Display field={field("MobilePhone")}/>
                </Line>
            </Card>
        );
    }



    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsEmployee.MailingCity":this.data.dsEmployee.MailingCity,
            "@dsEmployee.HomePhone":this.data.dsEmployee.HomePhone,
            "@dsEmployee.MobilePhone":this.data.dsEmployee.MobilePhone,
            "@dsEmployee.MailingProv":this.data.dsEmployee.MailingProv,
            "@dsEmployee.MailingStreet1":this.data.dsEmployee.MailingStreet1,
            "@dsEmployee.MailingStreet2":this.data.dsEmployee.MailingStreet2,
            "@dsEmployee.MailingCountry":this.data.dsEmployee.MailingCountry,
            "@dsEmployee.MailingPostalCode":this.data.dsEmployee.MailingPostalCode,
            "@employee_id":this.props.employee_id,
        });
        screen.close({"continue":true});
    }

});
