export interface IBroadcastListener {
    component: React.Component;
    action: string;
    handler?:(action?:string,data?:any)=>any;
}

export class Broadcast {
    private listeners: IBroadcastListener[] = [];

    dispatch(action: string,data?:any) {
        for (var i = 0; i < this.listeners.length; i++) {
            let listener = this.listeners[i];
            if (listener.action == "*" || listener.action == action) {
                if (listener.handler){
                    listener.handler(action,data);
                }
                else {
                    listener.component.forceUpdate();
                }
            }
        }
    }

    connect(listener: React.Component, action?: string,handler?:(action:string,data:any)=> any) {
        let i = this.findListenerIndex(listener);
        if (i == -1) {
            if (!action) {
                action = "*";
            }
            this.listeners.push({ component: listener, action,handler });
        }
    }

    disconnect(listener: React.Component) {
        let i = this.findListenerIndex(listener);
        if (i != -1) {
            this.listeners.splice(i, 1);
        }
    }

    refresh(){
        this.dispatch('refresh');
    }
    private findListenerIndex(component: React.Component): number {
        for (let i = 0; i < this.listeners.length; i++) {
            if (this.listeners[i].component == component) return i;
        }
        return -1;
    }
}
