import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';

interface IdsBenefit {
    Id:string;
    Policy_Id:string;
    BankTransitNo:string;
    BankNo:string;
    BankAccountNo:string;
};

export default KTMScreen({name:"planadmin.product.edit-bank-info"}, class extends React.Component<{policyBenefit_id:string}> {

    data:{
        dsBenefit:IdsBenefit;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenefit";
        await KTMApi.start(screen,{
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.title = "Edit product bank information";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit product bank information"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    detail_card({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display field={field("BankTransitNo")}/>
                    <Display field={field("BankNo")}/>
                    <Display field={field("BankAccountNo")}/>
                </Line>
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.detail_card)}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsBenefit.BankTransitNo":this.data.dsBenefit.BankTransitNo,
            "@dsBenefit.BankNo":this.data.dsBenefit.BankNo,
            "@dsBenefit.BankAccountNo":this.data.dsBenefit.BankAccountNo,
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.close({"continue":true});
    }

});
