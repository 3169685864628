import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  Column,
  Panel
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Button, DataGrid, IDataGridLayout, Card } from "ktm-ui";
import Add_dependentScreen from "./Add_dependent";
import Edit_dependentScreen from "./Edit_dependent";
import { FaUsers } from "react-icons/fa";

interface IdsData {
  Id: string;
  Employee_Id: string;
  Policy_Id: string;
  FirstName: string;
  LastName: string;
  MaidenName: string;
  DateOfBirth: string;
  Relation: string;
  IsHidden: boolean;
  Nickname: string;
  RelationLabel: string;
}

export default KTMScreen(
  { name: "planadmin.employee.dependent-list" },
  class extends React.Component<{ employee_id: string }> {
    data: {
      dsData: IdsData[];
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsData";
      await KTMApi.start(screen, {
        "@employee_id": this.props.employee_id,
      });
      screen.title = "Dependents";
    }

    main({ field, draw, action }: RenderEvent) {
      let default_view = null;
      if (this.data.dsData.length == 0) {
        default_view = (
          <div
            style={{
              textAlign: "center",
              width: "50%",
              margin: "auto",
              padding: "50px",
              position: "relative",
            }}
          >
            <div style={{ margin: "auto", fontSize: "75px" }}>
              <FaUsers color={"#bebebe"} style={{ margin: "30px auto" }} />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <b>Welcome to dependents!</b>
            </div>
            <div style={{ paddingBottom: 25 }}>
              If you need to add a dependent to an employee’s account, this is
              the place to do it! You can enter in a Spouse, Child, and/or, in
              special cases, an Unconditional Dependent. Make sure you have
              their First Name, Last Name, and Date of Birth to continue.
            </div>
            <Button
              action={action(this.add)}
              label="Add dependent"
              style="add"
            />
          </div>
        );
      } else {
        return (
          <Panel>
            <Column>
            {draw(this.default_view)}
            {draw(this.note)}
            </Column>
          </Panel>
        );
      }

      return <Layout>{default_view}</Layout>;
    }

    default_view({ field, draw, action, collection }: RenderEvent) {
      let actions = (
        <>
          <Button action={action(this.add)} label="Add dependent" style="add" />
        </>
      );
      let layout: IDataGridLayout = {
        columns: [
          { action: "edit", label: "Edit", width: 80 },
          { name: "FirstName", label: "First Name", type: "character" },
          { name: "LastName", label: "Last Name", type: "character" },
          { name: "MaidenName", label: "Maiden Name", type: "character" },
          { name: "Nickname", label: "Nickname", type: "character" },
          { name: "RelationLabel", label: "Relation", type: "character" },
          { name: "DateOfBirth", label: "Date Of Birth", type: "date" },
          { name: "IsHidden", label: "Is Hidden", type: "logical" },
        ],
        sort: [{ name: "DateOfBirth" }],
      };
      return (
        <DataGrid
          collection={collection("@dsData")}
          layout={layout}
          actions={actions}
        />
      );
    }

    note() {
      return (
        <div>
          <div style={{ margin: "100px 0 0 0 " }}>
            <Card label="Need to make additional edits to a Dependent?">
              <div>
                You can make a dependent Inactive or grant them “Student Status”
                to extend their eligibility to make claims under the “Date
                Ranges” tab of this employee.
              </div>
            </Card>
          </div>
        </div>
      );
    }

    async search({ screen }: ActionEvent) {
      await KTMApi.action(screen, "search", {
        "@employee_id": this.props.employee_id,
      });
    }

    async add({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_dependentScreen employee_id={this.props.employee_id} dependents={this.data.dsData} />,
        { onRecordChanged: this.refresh_row }
      );
    }

    async edit({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_dependentScreen dependent_id={screen.getValue("Id")} />,
        { onRecordChanged: this.refresh_row }
      );
    }

    async refresh_row({ screen, eventValue }: ActionEvent) {
      await KTMApi.refreshRow(screen, {
        collection: "@dsData",
        rowid: eventValue,
      });
    }
  }
);
