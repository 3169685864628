import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsEmployee {
  Id: string;
  Name: string;
  BenefitStartDate: string;
  WaitingPeriodMonths: number;
  BenefitCategory_Id: string;
  Policy_Id: string;
}

interface IdsBenCat {
  Id: string;
  Name: string;
  Description: string;
}

export default KTMScreen(
  { name: "enroll.employee.edit-bencat" },
  class extends React.Component<{ employee_id: string }> {
    data: {
      dsEmployee: IdsEmployee;
      dsBenCat: IdsBenCat;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsEmployee";
      await KTMApi.start(screen, {
        "@employee_id": this.props.employee_id,
      });
      screen.title = "Edit employee benefits";
      screen.setOptions("WaitingPeriodMonths", [
        { value: "0", label: "None" },
        { value: "1", label: "1 month" },
        { value: "2", label: "2 months" },
        { value: "3", label: "3 months" },
        { value: "4", label: "4 month" },
        { value: "5", label: "5 months" },
        { value: "6", label: "6 months" },
        { value: "7", label: "7 months" },
        { value: "8", label: "8 months" },
        { value: "9", label: "9 months" },
        { value: "10", label: "10 months" },
        { value: "11", label: "11 months" },
        { value: "12", label: "12 months" },
      ]);
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout dialogSize="medium">
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit employee benefits" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Display required field={field("BenefitCategory_Id")} />
          <Line>
            <Display
              required
              field={field("WaitingPeriodMonths")}
              toolTip={
                <CustomToolTip
                  header="Waiting Period (months)"
                  bodyContent="Choose how long an employee waits after being hired for their benefits to start."
                />
              }
            />
            <Display
              required
              field={field("BenefitStartDate")}
              toolTip={
                <CustomToolTip
                  header="Benefit Start Date"
                  bodyContent="Based on pro-rating and waiting period, the system calculates the employee’s Benefit Start Date. The system suggested date can be overridden by PAs."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "update-record");
      screen.close({ continue: true });
    }
  }
);
