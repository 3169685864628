import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel} from 'ktm-ui';

export default KTMScreen({name:"planadmin.policy.activate-next-year"}, class extends React.Component<{policy_id:string}> {

    data:{
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Activate next year";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Activate next year"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Activate" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    <div>The current year will be placed into run-off and the next benefit year will become the current active year.</div>
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@policy_id":this.props.policy_id,
        });
        screen.close({"continue":true});
    }

});
