import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, TemplateEditor, TemplateTagList} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Card} from 'ktm-ui';

interface IdsCampaign {
    Id:string;
    EmailSubject:string;
    EmailTemplate:string;
    UseCustomEmailTemplate:boolean;
};

interface IdsTemplate {
    Name:string;
    Content:string;
    Subject:string;
    Tags:string;
};

interface IdsTags {
    Name:string;
    Label:string;
};

export default KTMScreen({name:"planadmin.campaign.edit-email-template"}, class extends React.Component<{campaign_id:string}> {

    data:{
        dsCampaign:IdsCampaign;
        dsTemplate:IdsTemplate;
        dsTags:IdsTags;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsCampaign";
        await KTMApi.start(screen,{
            "@campaign_id":this.props.campaign_id,
        });
        screen.title = "Edit email template";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="medium">
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit email template"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                {draw(this.tag_list)}
                {draw(this.subject_editor)}
                {draw(this.email_editor)}
            </Card>
        );
    }

    email_editor({field,draw,action,collection}:RenderEvent){
        return (
            <TemplateEditor field={field("@dsCampaign.EmailTemplate")} tags={collection("@dsTags")} /> 
        );
    }

    subject_editor({field,draw,action,collection}:RenderEvent){
        return (
            <TemplateEditor field={field("@dsCampaign.EmailSubject")} tags={collection("@dsTags")} /> 
        );
    }

    tag_list({field,draw,action,collection}:RenderEvent){
        return (
            <TemplateTagList tags={collection("@dsTags")} /> 
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsCampaign.EmailSubject":this.data.dsCampaign.EmailSubject,
            "@dsCampaign.EmailTemplate":this.data.dsCampaign.EmailTemplate,
            "@campaign_id":this.props.campaign_id,
        });
        screen.close({"continue":true});
    }

});
