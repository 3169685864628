import { Canvas } from "./Canvas";
import { IFieldOptions, RecordMeta } from "./RecordMeta";
import { FieldType } from "./types";


export interface IRecordSchema {
    name:string;
    fields:{[name:string]:IFieldDefinition};
}

export type IScreenParameter = {name:string,value:string};

export interface IFieldLookup {
    name?:string;
    autoLaunch?:boolean;
    params?:IScreenParameter[];
    template?:string;
}

export interface IFieldDefinition {
    name:string;
    type:FieldType;
    label?:string;
    size?:number;
    onValueChanged?:string;
    onClick?:string;
    lookup?:IFieldLookup;
    lookupLabel?:string;
    recordSchema?:IRecordSchema;
    readonly?:boolean;
    required?:boolean;
    options?:IFieldOptions;
    placeholder?:string;
    format?:string;
    help?:string;
    style?:string;
    uppercase?:boolean;
    computed?:string;
    computedExpr?:string;
}

export class Schema {
    static getFieldFromMeta(meta:RecordMeta,name:string):IFieldDefinition{
        if (meta.schema && name){
            return meta.schema.fields[name.toLowerCase()];
        }
    }

    static getFieldDef(schema:IRecordSchema,name:string):IFieldDefinition{
        if (schema && name){
            return schema.fields[name.toLowerCase()];
        }
    }

    static getQualifiedFieldDef(canvas:Canvas,qualified:string):IFieldDefinition {
        if (!qualified) return null;
        let target:string;
        let name:string;
        if (qualified[0] != '@'){
            if (canvas.defaultRecord){
                target = canvas.defaultRecord;
                name = qualified;
            }
            else {
                return null;
            }
        }
        else {
            [target,name] = qualified.split('.');
        }
        if (name){
            let schema = Schema.getRecordSchema(canvas,target);
            if (!schema) return null;
            return Schema.getFieldDef(schema,name);
        }
      
        let schema = canvas.schema;
        return Schema.getFieldDef(schema,target);

    }
    static getRecordSchema(canvas:Canvas,recordName:string):IRecordSchema {
        if (canvas && canvas.schema && recordName){
            let f = canvas.schema.fields[recordName.toLowerCase()];
            if (!f) return null;
            return f.recordSchema;
        }
    }
}