import { useEffect, useState } from "react";
import { CustomLoading } from "./CustomLoading";
import { Card, Line } from "ktm-ui";
import "../styles/Settings.css";
import EditGeneralSettingModal from "./EditGeneralSettingModal";

const EmployeeSettings = ({ policyId }) => {
    const [loading, setLoading] = useState(true);
    const [showEditGeneralModal, setShowEditGeneralModal] = useState(false);
    const [allowDependentEdit, setAllowDependentEdit] = useState(null);
    const [showEditEnableBonusCreditModal, setShowEditEnableBonusCreditModal] = useState(false);
    const [enableBonusCredit, setEnableBonusCredit] = useState(null);

    useEffect(() => {
        getAllowDependentEdit();
        getEnableBonusCredit();
        setLoading(false);
    }, []);

    useEffect(() => {
        getAllowDependentEdit();
    }, [allowDependentEdit]);

    useEffect(() => {
        getEnableBonusCredit();
    }, [enableBonusCredit]);

    const openEditGeneralModal = () => {
        setShowEditGeneralModal(true);
    };

    const openEditEnableBonusCreditModal = () => {
        setShowEditEnableBonusCreditModal(true);
    };

    const getAllowDependentEdit = async () => {
        let response = await fetch("/api/Settings/checkallowedependentedit?policyId=" + policyId, { method: "GET" });
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        setAllowDependentEdit(data.isAllowDepEdit.eligibility);
    };

    const updateAllowDependentEdit = async (eligibility) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ policyId: policyId, eligibility: eligibility }),
        };

        let response = await fetch("/api/Settings/updateallowdependentedit", requestOptions);
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        await getAllowDependentEdit();
    };

    const getEnableBonusCredit = async () => {
        let response = await fetch("/api/Settings/checkenablebonuscredit?policyId=" + policyId, { method: "GET" });
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        setEnableBonusCredit(data.isEnableBonusCredit.eligibility);
    };

    const updateEnableBonusCredit = async (eligibility) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ policyId: policyId, eligibility: eligibility }),
        };

        let response = await fetch("/api/Settings/updateenablebonuscredit", requestOptions);
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }
        await getEnableBonusCredit();
    };

    let editGeneralModal = null;
    if (showEditGeneralModal) {
        editGeneralModal = (
            <EditGeneralSettingModal
                modalLabel={"Edit Allow Dependent Edits"}
                options={["Yes", "No"]}
                showEditModal={showEditGeneralModal}
                selectedOption={allowDependentEdit}
                setSelectedOption={updateAllowDependentEdit}
                closeModal={() => setShowEditGeneralModal(false)}
                dropdownLabel={"Allow Dependent Edits by Employee:"}
            />
        );
    }

    let editEnableBonusCreditModal = null;
    if (showEditEnableBonusCreditModal) {
        editEnableBonusCreditModal = (
            <EditGeneralSettingModal
                modalLabel={"Edit Bonus Credit"}
                options={["Yes", "No"]}
                showEditModal={showEditEnableBonusCreditModal}
                selectedOption={enableBonusCredit}
                setSelectedOption={updateEnableBonusCredit}
                closeModal={() => setShowEditEnableBonusCreditModal(false)}
                dropdownLabel={"Enable Bonus Credit for Employee:"}
            />
        );
    }

    let contents = null;
    if (loading) {
        contents = (<CustomLoading />);
    } else {
        contents = (
            <div className="appearance-setting-container">
                {editGeneralModal}
                {editEnableBonusCreditModal}
                <Card
                    actions={<><a href="#" className="RT-Link" onClick={openEditGeneralModal}>Edit</a></>}
                >
                    <Line>
                        <div className="RT-Static-Field">
                            <div className='RT-Static-Field__label'>Allow Dependent Edits by Employee:</div>
                            <div className='RT-Static-Field__content'>{allowDependentEdit}</div>
                        </div>
                    </Line>
                </Card>
                <Card
                    actions={<><a href="#" className="RT-Link" onClick={openEditEnableBonusCreditModal}>Edit</a></>}
                >
                    <Line>
                        <div className="RT-Static-Field">
                            <div className='RT-Static-Field__label'>Enable Bonus Credit for Employee:</div>
                            <div className='RT-Static-Field__content'>{enableBonusCredit}</div>
                        </div>
                    </Line>
                </Card>
            </div>
        );
    }

    return (
        <div className="appearance-settings">
            {contents}
        </div>
    );
}

export default EmployeeSettings;
