import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, Line} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,RecordHeader,TabSet,Tab,Display,Button,Column,Panel,EmptyState} from 'ktm-ui';
import BenefitsScreen from './Benefits';
import EditScreen from './Edit';

interface IdsBenCat {
  Id: string;
  Name: string;
  Description: string;
  Policy_Id: string;
}

interface IdsPolicy {
  Id: string;
  CurrentBenYear_Id: string;
  NextBenYear_Id: string;
}

export default KTMScreen(
  { name:"planadmin.bencat.record"}, 
  class extends React.Component<{ bencat_id: string }> {

    
    data: {
      dsBenCat: IdsBenCat;
      dsPolicy: IdsPolicy;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenCat";
      await KTMApi.start(screen, {
        "@bencat_id": this.props.bencat_id,
      });
      screen.title = this.data.dsBenCat.Name;
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.tabs)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return (
        <RecordHeader
          entityLabel="Benefit Category"
          label={this.data.dsBenCat.Name}
        >
          <Line>
            <Display field={field("Description")} />
          </Line>
        </RecordHeader>
      );
    }

    tabs({ field, draw, action }: RenderEvent) {
      return (
        <TabSet>
          <Tab label="Benefits" template={this.benefits_tab} />
        </TabSet>
      );
    }

    benefits_tab({field,draw,action}:RenderEvent){
        return (
            <BenefitsScreen 
                bencat_id={this.data.dsBenCat.Id} 
                policy_id={this.data.dsBenCat.Policy_Id} 
                benyear_id={this.data.dsPolicy.CurrentBenYear_Id}
            />
        );
    }

    next_year_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.next_year_empty)}</Column>
        </Panel>
      );
    }

    next_year_empty({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState
          label="Next year not available for edit yet"
          icon="edit"
        ></EmptyState>
      );
    }

    async edit({ screen }: ActionEvent) {
      await screen.openDialog(
        <EditScreen bencat_id={this.data.dsBenCat.Id}/>,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }
  }
);
