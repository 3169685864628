import React from 'react';
import { InputControl } from '../../core';


export class FieldError extends React.Component<{input:InputControl}>{
    render(){
        let error = this.props.input.field.error;
        if (!error) return null;
        return <div className="RT-TextBox__error">{error.message}</div>;
    }
}