import axios from "axios";
import React from "react";
import { Button, Table } from "react-bootstrap";
import toastr from "toastr";
import { CSVLink } from "react-csv"; 
import { CustomLoading } from "components/CustomLoading";
import "../../styles/Reports.css";
import { EmptyStateTemplate } from "./Empty_state_Template";
import CustomToolTip from "components/CustomToolTip";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class LsaDisbursementReportTable extends React.Component<any, any> {
    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            loading: true,
            disbursementData: [],
            dataToExport: [],
            empColTotal: 0,
            depColTotal: 0,
            totalColTotal: 0,
            showInactiveEmployees: false,
            fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
            toDate: new Date(),
        };

        this.fetchDisbursementData = this.fetchDisbursementData.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.setToDate = this.setToDate.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.fetchDisbursementData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fromDate !== this.state.fromDate || prevState.toDate !== this.state.toDate) {
            this.setState({loading: true});
            this.fetchDisbursementData();
        }

        if(prevState.showInactiveEmployees !== this.state.showInactiveEmployees) {
            this.getTotals({disbursements: this.state.disbursementData});
        }
    }

    fetchDisbursementData = () => {
        axios.get(`/api/Reports/disbursement?policyId=${this.props.policyId}&accountType=LSA&fromDate=${this.state.fromDate.toISOString()}&toDate=${this.state.toDate.toISOString()}`)
            .then(res => {
                if (res.data.success) {
                    this.getTotals(res.data);
                } else {
                    toastr.error(res.data.message, "Error");
                }
            })
            .catch(error => {
                toastr.error(error, "Error");
            });
    }

    getTotals = (data) => {
        const { 
            showInactiveEmployees,
            fromDate,
            toDate 
        } = this.state;

        const formattedFromDate = fromDate.toISOString().slice(0,10);
        const formattedToDate = toDate.toISOString().slice(0,10);

        let empColTotal = 0;
        let depColTotal = 0;
        let totalColTotal = 0;
        let dataToExport = data.disbursements.map((item) => {
            const empTotal = showInactiveEmployees ? item.employeesTotal : item.activeEmployeesTotal;
            const depTotal = showInactiveEmployees ? item.dependentsTotal : item.activeDependentsTotal;
            const total = showInactiveEmployees ? item.total : item.activeTotal;

            empColTotal += parseFloat(empTotal);
            depColTotal += parseFloat(depTotal);
            totalColTotal += parseFloat(total);

            return [
                item.expenseType,
                empTotal,
                depTotal,
                total
            ];
        });

        const headerRow = ["Expense Type", "Employees", "Dependents", "Total"];
        dataToExport.unshift(headerRow);

        let totalItem = ["Total", empColTotal.toFixed(2), depColTotal.toFixed(2), totalColTotal.toFixed(2)];
        dataToExport.push(totalItem);
        
        const dateRangeRow = [
            "Date Range",
            `${formattedFromDate} - ${formattedToDate}`
        ];
        dataToExport.unshift(dateRangeRow);

        this.setState({ 
            disbursementData: data.disbursements,
            dataToExport: dataToExport,
            empColTotal: empColTotal.toFixed(2),
            depColTotal: depColTotal.toFixed(2),
            totalColTotal: totalColTotal.toFixed(2),
            loading: false 
        });
    }

    exportToPdf = () => {
        const { dataToExport } = this.state;
    
        const header = dataToExport[1];
        const bodyRows = dataToExport.slice(2, dataToExport.length - 1);
        const footer = dataToExport[dataToExport.length - 1];
      
        const headerData = this.formatRow(header, 'tableHeader');
        const bodyData = this.formatTableBody(bodyRows);
        bodyData.push(this.formatRow(footer, 'tableFooter'));

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
                {
                    columns: [
                        this.createLogo(),
                        this.createDateRange(dataToExport[0][1]),
                    ],
                },
                this.createTitle(),
                this.createTable(headerData, bodyData)
            ],
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download("LSA_Disbursement_Report.pdf");
    };
        
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    createTitle = () => ({
        text: 'LSA Disbursement Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map(row => this.formatRow(row, 'tableBody'));
    
    createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('auto'),
            body: [header, ...body]
        }
    });
    
    getPdfStyles = () => ({
        table: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 12,
            color: 'black'
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    toggleInactiveEmployees = () => {
        this.setState({
            showInactiveEmployees: !this.state.showInactiveEmployees,
        });
    }

    setFromDate = (date) => {
        this.setState({
            fromDate: date,
        });
    }

    setToDate = (date) => {
        this.setState({
            toDate: date,
        });
    }

    render() {
        const {
            loading,
            disbursementData,
            dataToExport,
            showInactiveEmployees
        } = this.state;

        let contents = null;
        if (loading) {
            contents = (<CustomLoading />);
        } else {
            contents = (
                <div className="Lsa-Disbursement-Table report-table-container">
                    <div 
                        className="report-toolbar"
                        style={{display: "flex", alignItems: "center"}}
                    >
                    <CustomDateRangePicker 
                        fromDate={this.state.fromDate} 
                        toDate={this.state.toDate} 
                        setFromDate={this.setFromDate} 
                        setToDate={this.setToDate} 
                    />
                    <label className="utilization-deactivated-employees">
                        <input
                            type="checkbox"
                            checked={showInactiveEmployees}
                            onChange={this.toggleInactiveEmployees}
                            style={{marginRight:"10px"}}
                        />
                        Include deactivated employees
                    </label>
                    {disbursementData.length !== 0 ?
                     <div className="export-button-container">
                        <Button variant="primary" className="export-to-pdf" onClick={this.exportToPdf}>
                            <span className="pdf-text">Export to PDF</span>
                        </Button>
                        <CSVLink data={dataToExport} filename={"LSA_Disbursement_Report.csv"} className="export-button">
                            <Button variant="primary" className="csv-button export-to-csv">
                                <span className="csv-text">Export to CSV</span>
                            </Button>
                        </CSVLink>
                    </div> : null}
                    </div>
                    {disbursementData.length === 0 ? (
                        <EmptyStateTemplate /> 
                    ) : (
                    <Table hover className="report-table">
                        <thead>
                            <tr>
                                <th className="table-text-content">Expense Type</th>
                                <th>
                                    Employees
                                    <CustomToolTip
                                        header="Employees"
                                        bodyContent="Claim amount excluding taxes and admin fees."
                                    />
                                </th>
                                <th>
                                    Dependents
                                    <CustomToolTip
                                        header="Dependents"
                                        bodyContent="Claim amount excluding taxes and admin fees."
                                    />
                                </th>
                                <th>
                                    Total
                                    <CustomToolTip
                                        header="Total"
                                        bodyContent="Claim amount excluding taxes and admin fees."
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.disbursementData.map((item, index) => 
                                (
                                    <tr key={index}>
                                        <td className="table-text-content">{item.expenseType}</td>
                                        <td>{showInactiveEmployees ? item.employeesTotal : item.activeEmployeesTotal}</td>
                                        <td>{showInactiveEmployees ? item.dependentsTotal : item.activeDependentsTotal }</td>
                                        <td>{showInactiveEmployees ? item.total : item.activeTotal }</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="table-footer-item total-amount">Total</td>
                                <td className="table-footer-item">{this.state.empColTotal}</td>
                                <td className="table-footer-item">{this.state.depColTotal}</td>
                                <td className="table-footer-item">{this.state.totalColTotal}</td>
                            </tr>
                        </tfoot>
                    </Table>
                    )}
                </div>
            );
        }

        return (<>{contents}</>);
    }
}