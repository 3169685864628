import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const TeladocWallet = (props) => {
    const generateTeladocWallet = async () => {
        const getImageBase64 = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        const teladocLogo = await getImageBase64('/teladoc_logo.png');
        const cutoutImage = await getImageBase64('/cutout.png');

        const docDefinition = {
            info: {
                title: `${props.policyNo} - ${props.employeeName}`
            },
            content: [
                {
                    text: `Policy Number: ${props.policyNo}`,
                    style: 'header',
                    margin: [0, 20, 0, 0]
                },
                {
                    text: `Plan Member: ${props.employeeName}`,
                    style: 'header'
                },
                {
                    text: 'Teladoc Health - Elite Diagnostic Imaging Service',
                    style: 'subheader',
                    decoration: 'underline',
                    margin: [0, 10]
                },
                {
                    image: teladocLogo,
                    width: 280,
                    absolutePosition: { x: 300, y: 0 },
                },
                {
                    text: 'Best Doctors is now Teladoc Medical Experts part of Teladoc Health, the global leader in virtual care.',
                    margin: [0, 10, 0, 0]
                },
                {
                    text: `We all know the kind of wait times we experience today for medical services. If you require Diagnostic Imaging to help diagnose a serious medical condition, you can be facing a 2 to 3 month wait, or even longer. Of course, this adds additional anxiety to the stress of worrying what the results of the test will be.`,
                    margin: [0, 5]
                },
                {
                    text: [
                        { text: `Teladoc's Elite Diagnostic Service removes that worry. Teladoc has developed the Teladoc Health Elite Diagnostic Imaging Services (EDIS) to ensure that correct diagnosis and treatment plan options are identified in days or weeks instead of months, by providing immediate access to diagnostic testing.` },
                        { text: `You will receive your test within 72 hours ` },
                        { text: `(1)`, style: 'subscript'},
                        { text: ` of Teladoc contacting the imaging clinic on your behalf. The Radiologist's report will be forwarded to your medical team within 48 hours `},
                        { text: `(2)`, style: 'subscript'},
                        { text: `. Together with Teladoc's exhaustive analysis of your medical information, quick access to diagnostic testing greatly improves the quality of care you receive.`},
                    ],
                    margin: [0, 5]
                },
                {
                    text: "Features:",
                    bold: true,
                    margin: [0, 5]
                },
                {
                    ul: [
                        {
                            text: [
                                { text: 'Elite Diagnostic Imaging Services: ', bold: true },
                                { text: 'Through your Teladoc Health you can use our Elite Diagnostic Imaging Service to get an MRI or CT scan when you need it. This can reduce your wait time, allowing you and your treating physician to quickly move forward with a diagnosis and treatment plan.'}
                            ],
                            margin: [0, 5]
                        },
                        {
                            text: [
                                { text: 'Expert Medical Opinion: ', bold: true },
                                { text: 'Review of an existing diagnosis and treatment from a world-renowned expert to confirm them or recommend a change.'},
                            ],
                            margin: [0, 5]
                        },
                        {
                            text: [
                                { text: 'Find a Doctor: ', bold: true},
                                { text: 'Provides a list of local in-person experts who meet your specific medical needs.'},
                            ],
                            margin: [0, 5]
                        },
                        {
                            text: [
                                { text: 'Care Finder: ', bold: true },
                                { text: 'Help locate specialists or facilities outside of Canada for your treatment/condition needs.'},
                            ],
                            margin: [0, 5]
                        },
                        {
                            text: [
                                { text: 'Personal Health Navigator: ', bold: true},
                                { text: "Get a variety of information that's condition-specific from a registered healthcare professional including articles, and community resources that can assist your medical needs."},
                            ],
                            margin: [0, 5]
                        },
                        {
                            text: [
                                { text: 'Elite Diagnostic Imaging Service: ', bold: true},
                                { text: "Receive an MRI or CT scan within a matter of days of your doctor's appointment so you can move forward with treatment and care sooner."},
                            ],
                            margin: [0, 5]
                        }
                    ]
                },
                {
                    text: "Teladoc Medical Experts is a confidential service provided to you as part of your employee benefits plan. This service does not replace your provincial healthcare plan; it provides rapid access to diagnostic imaging services when the medical system cannot.",
                    margin: [0, 5],
                },
                {
                    text: "Teladoc, Teladoc Medical Experts and the Teladoc Medical Experts logo are trademarks of Teladoc Health, Inc., and may not be used without written permission.",
                    style: "subscript",
                    margin: [0,5]
                },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 515,
                            y2: 0,
                            lineWidth: 1,
                            color: '#000000'
                        }
                    ],
                    margin: [0, 5]
                },
                {
                    text: "You can cut out and fold the following summary card to carry in your wallet.",
                    margin: [0, 5]
                },
                {
                    image: cutoutImage,
                    width: 30,
                    absolutePosition: { x: 540, y: 630 },
                },
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                {
                                    stack: [
                                        {
                                            image: teladocLogo,
                                            fit: [200, 200],
                                            margin: [20, 30, 10, 0],
                                        },
                                        {
                                            text: 'TeladocHealth.ca',
                                            alignment: 'center'
                                        },
                                        {
                                            text: 'Phone: 1 877 419-2378',
                                            alignment: 'center',
                                            bold: true,
                                            fontSize: 13
                                        },
                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            text: `Member: ${props.employeeName} \nPolicy Number: ${props.policyNo}`,
                                            bold: true,
                                            margin: [10, 10, 0, 10],
                                            fontSize: 12,
                                        },
                                        {
                                            text: 'Teladoc Medical Experts Service is a confidential service provided to you as part of your benefits plan. This is not insurance, nor is this service transferable.',
                                            style: 'cutout',
                                            margin: [10, 0, 0, 10]
                                        },
                                        {
                                            text: 'This card identifies the member of the Teladoc Medical Experts Service. The right to use this card is subject to the terms and conditions of the program, and they may be amended from time to time. This card is the property of Teladoc Medical Experts and must be returned upon request.',
                                            style: 'cutout',
                                            margin: [10, 0, 0, 10]
                                        },
                                        {
                                            text: 'To use Teladoc Medical Experts Service call: 1 877 419-2378',
                                            bold: true,
                                            style: 'cutout',
                                            margin: [10, 0, 0, 10]
                                        },
                                        {
                                            text: 'If eligibility is not recognized, please call your Human Resources Representative.',
                                            style: 'cutout',
                                            margin: [10, 0, 0, 10]
                                        },
                                        {
                                            text: 'TELADOC and other trademarks shown are trademarks of Teladoc Health, Inc.',
                                            style: 'cutout',
                                            margin: [10, 0, 0, 10]
                                        }
                                    ]
                                }
                            ]
                        ]
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 14,
                    bold: true
                },
                subheader: {
                    fontSize: 13,
                    bold: true
                },
                subscript: {
                    fontSize: 6
                },
                cutout: {
                    fontSize: 7
                }
            },
            defaultStyle: {
                fontSize: 10,
            }
        };

        pdfMake.createPdf(docDefinition).open();
    };

    return (
        <div>
            <button onClick={generateTeladocWallet} style={{color: "blue"}}>Teladoc Wallet Card</button>
        </div>
    );
};
