import { useState, useEffect } from 'react';
import toastr from "toastr";
import axios from "axios";
import { CustomLoading } from 'components/CustomLoading';

const ViewUser = ({ data }) => {
    const [loading, setLoading] = useState(true);
    const [isActive, setIsActive] = useState(data.isActive);
    const [isLoginLocked, setIsLoginLocked] = useState(data.isLoginLocked);
    const [failedAttempts, setFailedAttempts] = useState(data.failedConsecutivePasswordAttempts);

    useEffect(() => {
        setLoading(true);
        fetchUserData();
    }, [data.userId, isActive, isLoginLocked]);

    const fetchUserData = async () => {
        try {
          const response = await axios.get(`/api/support/getuserdata?userId=${data.userId}`);
          if (response.data.success) {
            setIsActive(response.data.isActive);
            setIsLoginLocked(response.data.isLoginLocked);
            setFailedAttempts(response.data.failedAttempts);
          }
          setLoading(false);
        } catch (error) {
          toastr.error("Failed to fetch employee notes", "Error");
        }
      }

      const handleActiveToggle = () => {
        const body = {
            userId: data.userId,
            IsActive: !isActive,
        };
        axios.post("/api/support/updateuserstatus", body)
            .then(response => {
                if (response.data.success) {
                    setIsActive(!isActive);
                    toastr.success("User status updated", "Success");
                } else {
                    toastr.error("Failed to update user status", "Error");
                }
            })
            .catch(error => {
                toastr.error("Failed to update user status", "Error");
            });
    };
    
    const handleLoginLockedToggle = () => {
        const body = {
            userId: data.userId,
            isLoginLocked: !isLoginLocked,
        };
        axios.post("/api/support/updateuserloginlock", body)
            .then(response => {
                if (response.data.success) {
                    setIsLoginLocked(!isLoginLocked);
                    toastr.success("Login lock status updated", "Success");
                } else {
                    toastr.error("Failed to update login lock status", "Error");
                }
            })
            .catch(error => {
                toastr.error("Failed to update login lock status", "Error");
            });
    };

    const handleFailedAttemptsChange = (event) => {
        setFailedAttempts(event.target.value);
    };

    const handleSaveFailedAttempts = async () => {

        const body = {
            userId: data.userId,
            failedAttempts: failedAttempts,
        };
        axios.post("/api/support/updateFailedAttempts", body)
            .then(response => {
                if(response.data.success)
                    {
                        toastr.success('Failed attempts updated successfully!');
                    } else {
                        toastr.error("Failed save failed attempts", "Error");
                }
            })
            .catch(error => {
                toastr.error("Failed save failed attempts", "Error");
            });
    };

    if (loading) {
        return <CustomLoading />;
    }

    return (
        <div className="mt-3 card shadow-sm p-3 mb-5 bg-body rounded">
            <div className="row">
                <div className="col-3">Username</div>
                <div className="col-9"><b>{data.username}</b></div>
            </div>
            <div className="row">
                <div className="col-3">Name</div>
                <div className="col-9"><b>{data.lastName}, {data.firstName}</b></div>
            </div>
            <div className="row pt-1">
                <div className="col-3">Email</div>
                <div className="col-9">
                    <a
                        href={"mailto:" + data.email}
                        target="_blank"
                        style={{color: "blue"}}
                    >
                        {data.email}
                    </a>
                </div>
            </div>
            <div className="row pt-1" style={{flexWrap: "nowrap"}}>
                <div className="col-3">Is Active</div>
                <div className="col-9">
                    <input
                        type="checkbox"
                        id="active-checkbox"
                        checked={isActive}
                        onChange={handleActiveToggle}
                        style={{marginRight: 10}}
                    />
                    <label htmlFor="active-checkbox">{isActive ? "Yes" : "No"}</label>
                </div>
            </div>
            <div className="row pt-1" style={{flexWrap: "nowrap"}}>
                <div className="col-3">Is Login Locked</div>
                <div className="col-9">
                    <input
                        type="checkbox"
                        id="is-login-locked-checkbox"
                        checked={isLoginLocked}
                        onChange={handleLoginLockedToggle}
                        style={{marginRight: 10}}
                    />
                    <label htmlFor="is-login-locked-checkbox">{isLoginLocked ? "Yes" : "No"}</label>
                </div>
            </div>
            <div className="row pt-1">
                <div className="col-3">Failed Consecutive Password Attempts</div>
                <div className="col-9 RT-TextBox__container" style={{width:"20%", display: "flex", flexDirection: "row"}}>
                    <input
                        type="number"
                        className='RT-TextBox__input'
                        value={failedAttempts}
                        onChange={handleFailedAttemptsChange}
                        min="0"
                        style={{marginRight: 10}}
                    />
                    <button 
                        onClick={handleSaveFailedAttempts} 
                        className="btn btn-primary ml-2"
                    >
                        Save
                    </button>
                </div>
            </div>            
            <div className="row pt-1">
                <div className="col-3">Is Two Factor Enabled</div>
                <div className="col-9">
                    {data.isTwoFactorEnabled ? "Yes" : "No"}
                </div>
            </div>
            <div className="row pt-1">
                <div className="col-3">Is Onboarding Completed</div>
                <div className="col-9">
                    {data.isOnboardingCompleted ? "Yes" : "No"}
                </div>
            </div>
        </div>
    );
};

export default ViewUser;
