import React from 'react';
import { CollectionRef, FieldRef, InputControl, makeComponent, makeInput } from "ktm-ui/core";

type Tag = {name:string;label:string};

export const TemplateTagList = makeComponent(class extends React.Component<{tags:CollectionRef}>{
    render(){
        let tags:Tag[];
        if (this.props.tags){
            tags = getTagList(this.props.tags.rows);
        }
        let elems = tags.map((tag,index) => {
            let tagName = "*|" + tag.name + "|*";
            return <TagRow key={tagName} tagName={tagName} label={tag.label}/>
        });

        let instructions = (<div style={{marginBottom:10,fontSize:"13px"}}><span style={{fontWeight:500}}>Click</span> on a merge tag below to copy it to the clipboard, then 
        <span style={{fontWeight:700}}> paste</span> into your template.</div>);
    
        return (<div style={{backgroundColor:"rgb(248,249,252)",padding:10,marginBottom:15}}>
            {instructions}
            <table>
                <tbody>
                    {elems}
                </tbody>
            </table>
        </div>)
    }
});


export const TemplateEditor = makeInput(class extends React.Component<{field:FieldRef,tags:CollectionRef,input?:InputControl}>{

    render(){
        let input = this.props.input;
        let field = input.field;
        
        if (field.readonly) {
            return this.renderReadonly();
        }
        let height:number;
        if (field.type == "longchar"){
            height = 250;
        }
        else {
            height = 50;
        }
        let textAreaStyle:React.CSSProperties = {
            width:"100%",
            height
        }

        let label;
        if (field.label){
            label = <div style={{fontSize:"12px",fontWeight:500,marginBottom:6}}>{field.label}</div>
        }
    
    
        return (<div style={{marginBottom:12,paddingBottom:2}}>
        
            {label}
            <textarea className="RT-TextBox__textarea" style={textAreaStyle}
                    ref={input.focusElemRef as any}
                    placeholder={field.placeholder}
                    value={input.editValue || ""}
                    readOnly={field.disabled}
                    onBlur={input.onBlur as any}
                    onChange={input.onChange as any}
                    onKeyDown={input.onKeyDown as any}
                    onKeyPress={input.onKeyPress as any}
                    autoComplete="custom-data"
                />
        </div>)
    }

    renderReadonly(){
        let input = this.props.input;
        if (!input) return null;
        let field = input.field;

        let formatted = this.formatReadonly(input.editValue);
        let label;
        if (field.label){
            label = <div className="RT-Static-Field__label" style={{marginBottom:5}}>{field.label}</div>
        }
        return (<div style={{marginBottom:12,paddingBottom:2}}>
            {label}
            <pre style={{fontFamily:"var(--rt-font-family)"}}>{formatted}</pre>
        </div>)
    }

    formatReadonly(value:string){
        if (!value) return null;
        let segments = value.split('*|');
        let elems = [];
        let tagStyle:React.CSSProperties = {color:"var(--rt-primary-color)",fontWeight:500};
        let delimStyle:React.CSSProperties = {color:"rgb(180,180,180)",marginLeft:1,marginRight:1};
        let errorStyle:React.CSSProperties = {color:"var(--rt-text-color-danger)"};

        let tagsCollection = this.props.tags;
        let tags:Tag[];
        if (tagsCollection){
            tags = getTagList(tagsCollection.rows);
        }
        for(let i = 0; i < segments.length;i++){
            let segment = segments[i];
            if (i > 0){
                let end = segment.indexOf('|*');
                if (end == -1){
                    elems.push(<span key={i} style={errorStyle}>*|{segment}</span>)
                }
                else{
                    let tag = segment.substr(0,end);
                    let style = (this.isValidTag(tag,tags)) ? tagStyle : errorStyle
                    elems.push(<span key={i}><span style={delimStyle}>*|</span><span style={style}>{tag}</span><span style={delimStyle}>|*</span></span>);
                    elems.push(segment.substr(end + 2))
                }
            }
            else {
                elems.push(segment);
            }    
        }
        return elems;
    }

    isValidTag(tagName:string,tags:Tag[]):boolean {
        if (!tags) return false;
        tagName = tagName.toUpperCase();
        for(let i =0 ; i < tags.length;i++){
            let tag = tags[i];
            if (tag.name == tagName) return true;
        }
        return false;
    }

});

function getTagList(tags:any[]):Tag[] {
    if (!tags) return null;
    return tags.map((row:any,index) => ({name:row.Name,label:row.Label}));
}

class TagRow extends React.Component<{tagName:string,label:string}>{
    render(){
        return (<tr onClick={this.handleClick} style={{cursor:"pointer"}}>
             <td style={{color:"var(--rt-primary-color)",padding:4}}>{this.props.tagName}</td>
             <td style={{padding:4}}>{this.props.label}</td>
        </tr>);
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(this.props.tagName);
    }
}