import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Card} from 'ktm-ui';

interface IdsLimit {
    Id:string;
    Policy_Id:string;
    CreditCalcMethod:string;
};

export default KTMScreen({name:"planadmin.ben-limit.change-credit-calc"}, class extends React.Component<{limit_id:string}> {

    data:{
        dsLimit:IdsLimit;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsLimit";
        await KTMApi.start(screen,{
            "@limit_id":this.props.limit_id,
        });
        screen.title = "Edit Credit Calculation Method";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.details)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Change credit calculation method"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Submit" style="primary"/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display required field={field("CreditCalcMethod")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsLimit.CreditCalcMethod":this.data.dsLimit.CreditCalcMethod,
            "@limit_id":this.props.limit_id,
        });
        screen.recordChanged("updated",this.props.limit_id);
        screen.close({"continue":true});
    }

});
