import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Display,
  Line,
  Card,
  Column,
  Panel,
} from "ktm-ui";
import CustomToolTip from "./../../components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  EnrollmentFee: number;
  EnrollmentCommission: number;
}

export default KTMScreen(
  { name: "enroll.product.edit-enrollment-fee" },
  class extends React.Component<{ policyBenefit_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.title = "Edit product enrollment fee";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit product enrollment fee" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display
              required
              field={field("EnrollmentFee")}
              toolTip={
                <CustomToolTip
                  header="Enrollment Fee"
                  bodyContent="Do not include tax, the system will automatically include it. The enrollment fee is the total we are charging. The advisor portion is how much of that total goes to the advisor."
                />
              }
            />
            <Display
              required
              field={field("EnrollmentCommission")}
              toolTip={
                <CustomToolTip
                  header="Advisor Portion"
                  bodyContent="Do not include tax, the system will automatically include it. The enrollment fee is the total we are charging. The advisor portion is how much of that total goes to the advisor."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@dsBenefit.EnrollmentFee": this.data.dsBenefit.EnrollmentFee,
        "@dsBenefit.EnrollmentCommission":
          this.data.dsBenefit.EnrollmentCommission,
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.close({ continue: true });
    }
  }
);
