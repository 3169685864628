import React from 'react';
import { ScreenRef } from './ActionEvent';
import { ActionRef } from './ActionRef';
import { Canvas } from './Canvas';
import { CollectionRef } from './CollectionRef';
import { DynamicRender } from './DynamicRender';
import { FieldRef } from './FieldRef';
import { RecordMeta } from './RecordMeta';
import { ActionHandler, getActionName,  IEventScope, Template } from './types';

let maxDepth = 8;
export class RenderEvent {

    canvas:Canvas;
    scope:IEventScope;
    depth:number;
    eventValue:any;
    template:string;

    constructor(canvas:Canvas,template:string){
        this.canvas = canvas;
        this.depth = 1;
        this.template = template;
    }
    
    create(scope:IEventScope,template:string):RenderEvent{
        let child = new RenderEvent(this.canvas,template);
        child.scope = scope;
        child.eventValue = this.eventValue;
        child.depth = this.depth + 1;
        if (child.depth > maxDepth){
            throw "Maximum nested draw depth reached (" + maxDepth + ")";
        }
        return child;
    }

    field = (field:string):FieldRef => {
       return this.canvas.getFieldRef(field,this.scope);
    }

    collection = (collection:string):CollectionRef => {
        return this.canvas.getCollectionRef(collection,this.scope)
    }

    action = (action:ActionHandler | string):ActionRef => {
        let canvas = this.canvas;
        let actionName = getActionName(action);
        if (!actionName) return null;
        
        let actionRef = new ActionRef(canvas,actionName,this.scope);
        let def = this.canvas.actions[actionName];
        actionRef.def = def;
        return actionRef;
    }

    draw = (template:Template | string,options?:{each?:string,scope?:any,span?:any,containerProps?:any}):any => {
     
        let canvas = this.canvas;
        options = options || {};
        let templateRef = canvas.getTemplateRef(template,options.scope);
        if (!templateRef) return null;
        let elem;
        let event:RenderEvent;
    
        let dynamicContent = templateRef.dynamicContent;
        if (dynamicContent){
            event = this.create(this.scope,templateRef.name);
            if (options.containerProps){
                DynamicRender.renderArray(event,dynamicContent,options.containerProps);
                return;
            }
            else {
                return DynamicRender.render(event,dynamicContent);         
            }
        }

        if (options.each){
            let collectionRef = canvas.getCollectionRef(options.each,this.scope);
            if (!collectionRef) return null;
            let rows:any[] = collectionRef.rows;
            if (rows){
                let out = [];
                for(let index = 0; index < rows.length;index++){
                    let row = rows[index];
                    event = this.create({...this.scope,[options.each]:row},templateRef.name);
                    let rowElem = templateRef.render.call(canvas.templateTarget,event);
                    if (rowElem){
                        out.push(React.cloneElement(rowElem,{key:index,event}));
                    }
                }
                return out;
            }
            else {
                return null;
            }
        }
        
        if (RecordMeta.isTemplateHidden(canvas,templateRef.name)){
            return null;
        }
        event = this.create(this.scope,templateRef.name);
        elem =  templateRef.render.call(canvas.templateTarget,event);
        return this.injectEvent(elem,event);
    }

    getValue = (expr:string):any => {
        return this.canvas.getValue(expr,{scope:this.scope,value:this.eventValue});
    }

    dynamic = (field:string):any => {
        let fieldRef = this.canvas.getFieldRef(field,this.scope);
        if (!fieldRef || fieldRef.hidden) return null;
    
        let renderEvent = this.create(this.scope,fieldRef.name);
        return DynamicRender.render(renderEvent,fieldRef.value);
    }


    private injectEvent(elem:any,event:RenderEvent){
        if (elem){
            return React.cloneElement(elem,{event});
        }
        return null;
    }

    getTemplateState():any {
        return this.canvas.templateState[this.template];
    }

    setTemplateState(values:any){
        let newState = {...this.getTemplateState(),...values};
        this.canvas.templateState[this.template] = newState;
    }
    screen:ScreenRef;
}