import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card} from 'ktm-ui';

interface IdsBenefitYear {
    Id:string;
    StartDate:string;
    EndDate:string;
    RunoffMonths:number;
};

export default KTMScreen({name:"enroll.policy.add-first-year"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsBenefitYear:IdsBenefitYear;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenefitYear";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.setOptions("RunoffMonths",[
            {value:"1",label:"1 month"},
            {value:"2",label:"2 months"},
            {value:"3",label:"3 months"}
        ])
        screen.title = "Add benefit year";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.details)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add benefit year"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Create" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display field={field("StartDate")}/>
                    <Display field={field("EndDate")}/>
                    <Display field={field("RunoffMonths")}/>
                </Line>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@policy_id":this.props.policy_id,
            "@dsBenefitYear.StartDate":this.data.dsBenefitYear.StartDate,
            "@dsBenefitYear.EndDate":this.data.dsBenefitYear.EndDate,
            "@dsBenefitYear.RunoffMonths":this.data.dsBenefitYear.RunoffMonths,
        });
        screen.close({"continue":true});
    }

});
