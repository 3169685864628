import CustomPagination from "components/CustomPagination";
import { Card, Line } from "ktm-ui";
import React from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { CSVLink } from "react-csv";

export default class OpsDisbursementSummary extends React.Component<any, any> {
  state = {
    loading: true,
    success: false,
    opsSummary: [],
    currentPage: 1,
    itemsPerPage: 10,
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
  };

  componentDidMount() {
    this.initialize();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fromDate !== this.state.fromDate ||
      prevState.toDate !== this.state.toDate
    ) {
      this.setState({ loading: true });
      this.initialize();
    }
  }
  setCurrentPage = (page: number) => {
    this.setState({ currentPage: page });
  };

  initialize = () => {
    fetch(
      "/api/opstransfer/getdisbursementsummary?dateFrom=" +
        this.state.fromDate.toISOString() +
        "&dateTo=" +
        this.state.toDate.toISOString(),
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loading: false,
          opsSummary: data.summary,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  setFromDate = (date) => {
    this.setState({
      fromDate: date,
    });
  };

  setToDate = (date) => {
    this.setState({
      toDate: date,
    });
  };

  render() {
    const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
    const currentRecords = this.state.opsSummary.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    const nPages = Math.ceil(
      this.state.opsSummary.length / this.state.itemsPerPage
    );

    var opsSummaryTable = null;
    if (this.state.opsSummary.length > 0) {
      opsSummaryTable = (
        <>
          <table className="table">
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Province</th>
                <th>Total HSA (Paid)</th>
                <th>Total LSA (Paid)</th>
                <th className="text-end">NHC Revenue</th>
                <th className="text-end">Distro Commission</th>
                <th>Tax Code</th>
                <th className="text-end">Tax Rate</th>
                <th className="text-end">Total Tax Amount</th>
                <th className="text-end">RST</th>
                <th className="text-end">Premium Tax</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((data, i) => {
                return (
                  <tr>
                    {/* <td>{i + 1}</td> */}
                    <td>{data.province}</td>
                    <td
                      className="text-end"
                      style={{
                        color: data.claimType == "HSA" ? "blue" : "green",
                      }}
                    >
                      {data.claimType == "HSA"
                        ? data.paidHSAClaim.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })
                        : null}
                    </td>

                    <td
                      className="text-end"
                      style={{
                        color: data.claimType == "HSA" ? "blue" : "green",
                      }}
                    >
                      {data.claimType == "HSA"
                        ? null
                        : data.paidLSAClaim.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })}
                    </td>
                    <td className="text-end">
                      {" "}
                      {data.nhcRevenue.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                    <td className="text-end">
                      {" "}
                      {data.distroCommissions.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                    <td>{data.taxCode}</td>
                    <td className="text-end">{data.taxRate}</td>
                    <td className="text-end">
                      {data.taxAmount.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                    <td className="text-end">
                      {data.rst.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                    <td className="text-end">
                      {data.premiumTax.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            className="pagination-container"
            style={{
              position: "relative",
              right: "10px",
              margin: "0px",
              justifyContent: "end",
            }}
          >
            <div className="pagination-info">
              <span>
                Showing {indexOfFirstRecord + 1} -{" "}
                {indexOfFirstRecord + currentRecords.length} entries out of{" "}
                {this.state.opsSummary.length} entries
              </span>
            </div>
            <CustomPagination
              nPages={nPages}
              currentPage={this.state.currentPage}
              setCurrentPage={this.setCurrentPage}
            />
          </div>
        </>
      );
    }

    const today = new Date().toISOString().slice(0, 10);
    const fileName = `opsdisbursementsummary_${today}.csv`;

    return (
      <>
        {this.state.loading ? (
          <div className="financial-spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <div>
            <div className="mb-2 d-flex justify-content-between">
              <CustomDateRangePicker
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                setFromDate={this.setFromDate}
                setToDate={this.setToDate}
              />
              {this.state.opsSummary.length > 0 ? (
                <div>
                  <CSVLink data={this.state.opsSummary} filename={fileName}>
                    <Button
                      variant="primary"
                      className="csv-button export-to-csv"
                    >
                      <span className="csv-text">Export to CSV</span>
                    </Button>
                  </CSVLink>
                </div>
              ) : null}
            </div>

            {opsSummaryTable}
          </div>
        )}
      </>
    );
  }
}
