import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';
import toastr from "toastr";

interface IdsBenCat {
    Id:string;
    CreditCalcMethod:string;
    FlexSingleLimit:number;
    FlexCoupleLimit:number;
    FlexFamilyLimit:number;
    FlexSinglePercent:number;
    FlexCouplePercent:number;
    FlexFamilyPercent:number;
    AllowRSPOption:boolean;
    AllowStockOption:boolean;
    HasHSA:boolean;
    HasLSA:boolean;
    HSALabel:string;
    LSALabel:string;
    RSPLabel:string;
    Stocklabel:string;
    DefaultRspAllocation:number;
    DefaultStockAllocation:number;
    DefaultHSAAllocation:number;
    DefaultLSAAllocation:number;
    AllowLSADependentClaims:boolean;
    HSACap:number;
    LSACap:number;
    RSPCap:number;
    StockCap:number;
};

export default KTMScreen({name:"planadmin.ben-limit.add-flex"}, class extends React.Component<{limit_id:string}> {

    data:{
        dsBenCat:IdsBenCat;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen,{
            "@limit_id":this.props.limit_id,
        });
        screen.title = "Add flex benefit";
        await screen.triggerAction(this.set_limit_visibility);
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="large">
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add flex benefit"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    limits({field,draw,action}:RenderEvent){
        return (
            <>
                <Line>
                    <Display required field={field("FlexSingleLimit")}/>
                    <Display required field={field("FlexCoupleLimit")}/>
                    <Display required field={field("FlexFamilyLimit")}/>
                </Line>
            </>
        );
    }

    percentages({field,draw,action}:RenderEvent){
        return (
            <>
                <Line>
                    <Display required field={field("FlexSinglePercent")}/>
                    <Display required field={field("FlexCouplePercent")}/>
                    <Display required field={field("FlexFamilyPercent")}/>
                </Line>
            </>
        );
    }

    amounts({field,draw,action}:RenderEvent){
        return (
            <Card>
                {draw(this.limits)}
                {draw(this.percentages)}
            </Card>
        );
    }

    hsa({field,draw,action}:RenderEvent){
        if (!this.data.dsBenCat.HasHSA) {
            this.data.dsBenCat.HSALabel = "";
            this.data.dsBenCat.DefaultHSAAllocation = 0;
        }
        return (
            <Card label="HSA">
                <Display required field={field("HasHSA")}/>
                <Line>
                    <Display required field={field("DefaultHSAAllocation")} disabled={!this.data.dsBenCat.HasHSA}/>
                    <Display field={field("HSALabel")} disabled={!this.data.dsBenCat.HasHSA}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" &&
                <Line>
                    <Display field={field("HSACap")} disabled={!this.data.dsBenCat.HasHSA}/>
                </Line>
                }
            </Card>
        );
    }

    lsa({field,draw,action}:RenderEvent){
        if (!this.data.dsBenCat.HasLSA) {
            this.data.dsBenCat.AllowLSADependentClaims = false;
            this.data.dsBenCat.LSALabel = "";
            this.data.dsBenCat.DefaultLSAAllocation = 0;
        }
        return (
            <Card label="LSA">
                <Line>
                    <Display required field={field("HasLSA")}/>
                    <Display required field={field("AllowLSADependentClaims")} disabled={!this.data.dsBenCat.HasLSA}/>
                </Line>
                <Line>
                    <Display required field={field("DefaultLSAAllocation")} disabled={!this.data.dsBenCat.HasLSA}/>
                    <Display field={field("LSALabel")} disabled={!this.data.dsBenCat.HasLSA}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" &&
                <Line>
                    <Display field={field("LSACap")} disabled={!this.data.dsBenCat.HasLSA}/>
                </Line>
                }
            </Card>
        );
    }

    rsp({field,draw,action}:RenderEvent){
        if (!this.data.dsBenCat.AllowRSPOption) {
            this.data.dsBenCat.RSPLabel = "";
            this.data.dsBenCat.DefaultRspAllocation = 0;
        }
        return (
            <Card label="RSP">
                <Display field={field("AllowRSPOption")}/>
                <Line>
                    <Display field={field("DefaultRspAllocation")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                    <Display field={field("RSPLabel")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" &&
                <Line>
                    <Display field={field("RSPCap")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                </Line>
                }
            </Card>
        );
    }

    stock({field,draw,action}:RenderEvent){
        return (
            <Card label="Stock">
                <Display field={field("AllowStockOption")}/>
                <Line>
                    <Display field={field("DefaultStockAllocation")}/>
                    <Display field={field("Stocklabel")}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" &&
                <Line>
                    <Display field={field("StockCap")}/>
                </Line>
                }
            </Card>
        );
    }

    options_panel({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.hsa)}
                    {draw(this.lsa)}
                </Column>
                <Column>
                    {draw(this.rsp)}
                    {/* {draw(this.stock)} */}
                </Column>
            </Panel>
        );
    }

    amounts_panel({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.amounts)}
                </Column>
            </Panel>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.amounts_panel)}
                    {draw(this.options_panel)}
                </Column>
                <Column>
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        this.data.dsBenCat.DefaultHSAAllocation = Math.round(this.data.dsBenCat.DefaultHSAAllocation);
        this.data.dsBenCat.DefaultLSAAllocation = Math.round(this.data.dsBenCat.DefaultLSAAllocation);
        this.data.dsBenCat.DefaultRspAllocation = Math.round(this.data.dsBenCat.DefaultRspAllocation);
        let totalAllocation = this.data.dsBenCat.DefaultHSAAllocation + this.data.dsBenCat.DefaultLSAAllocation + this.data.dsBenCat.DefaultRspAllocation;
        if(totalAllocation > 100){
            toastr.error("Total allocation cannot exceed 100%.");
            return;
        } else if(totalAllocation < 100){
            toastr.error("Total allocation must equal 100%.");
            return;
        }
        
        if (this.data.dsBenCat.FlexSingleLimit == null)
            this.data.dsBenCat.FlexSingleLimit = 0;
        if (this.data.dsBenCat.FlexCoupleLimit == null)
            this.data.dsBenCat.FlexCoupleLimit = 0;
        if (this.data.dsBenCat.FlexFamilyLimit == null)
            this.data.dsBenCat.FlexFamilyLimit = 0;
        if (this.data.dsBenCat.FlexSinglePercent == null)
            this.data.dsBenCat.FlexSinglePercent = 0;
        if (this.data.dsBenCat.FlexCouplePercent == null)
            this.data.dsBenCat.FlexCouplePercent = 0;
        if (this.data.dsBenCat.FlexFamilyPercent == null)
            this.data.dsBenCat.FlexFamilyPercent = 0;

        await KTMApi.action(screen,"submit",{
            "@dsBenCat.FlexSingleLimit":this.data.dsBenCat.FlexSingleLimit,
            "@dsBenCat.FlexCoupleLimit":this.data.dsBenCat.FlexCoupleLimit,
            "@dsBenCat.FlexFamilyLimit":this.data.dsBenCat.FlexFamilyLimit,
            "@dsBenCat.FlexSinglePercent":this.data.dsBenCat.FlexSinglePercent,
            "@dsBenCat.FlexCouplePercent":this.data.dsBenCat.FlexCouplePercent,
            "@dsBenCat.FlexFamilyPercent":this.data.dsBenCat.FlexFamilyPercent,
            "@dsBenCat.AllowRSPOption":this.data.dsBenCat.AllowRSPOption,
            "@dsBenCat.AllowStockOption":this.data.dsBenCat.AllowStockOption,
            "@dsBenCat.HasHSA":this.data.dsBenCat.HasHSA,
            "@dsBenCat.HasLSA":this.data.dsBenCat.HasLSA,
            "@dsBenCat.HSALabel":this.data.dsBenCat.HSALabel,
            "@dsBenCat.LSALabel":this.data.dsBenCat.LSALabel,
            "@dsBenCat.RSPLabel":this.data.dsBenCat.RSPLabel,
            "@dsBenCat.Stocklabel":this.data.dsBenCat.Stocklabel,
            "@dsBenCat.DefaultRspAllocation":this.data.dsBenCat.DefaultRspAllocation,
            "@dsBenCat.DefaultStockAllocation":this.data.dsBenCat.DefaultStockAllocation,
            "@dsBenCat.DefaultHSAAllocation":this.data.dsBenCat.DefaultHSAAllocation,
            "@dsBenCat.DefaultLSAAllocation":this.data.dsBenCat.DefaultLSAAllocation,
            "@dsBenCat.AllowLSADependentClaims":this.data.dsBenCat.AllowLSADependentClaims,
            "@dsBenCat.HSACap":this.data.dsBenCat.HSACap,
            "@dsBenCat.LSACap":this.data.dsBenCat.LSACap,
            "@dsBenCat.RSPCap":this.data.dsBenCat.RSPCap,
            "@dsBenCat.StockCap":this.data.dsBenCat.StockCap,
            "@limit_id":this.props.limit_id,
        });
        screen.recordChanged("updated",this.props.limit_id);
        screen.close({"continue":true});
    }

    async set_limit_visibility({screen}:ActionEvent){
        screen.hide(this.limits);
        screen.hide(this.percentages);
        if (screen.getValue("@dsBenCat.CreditCalcMethod") == "SALARY"){
            screen.unhide(this.percentages);
        }
        else {
            screen.unhide(this.limits);
        } 
    }
});
