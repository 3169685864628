import axios from "axios";
import React from "react";
import { Button, Table } from "react-bootstrap";
import toastr from "toastr";
import { CSVLink } from "react-csv"; 
import { CustomLoading } from "components/CustomLoading";
import { CustomDropdown } from "components/CustomDropdown";
import "../../styles/Reports.css";
import { EmptyStateTemplate } from "./Empty_state_Template";
import CustomPagination from "components/CustomPagination";
import moment from "moment";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CustomToolTip from "components/CustomToolTip";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class FlexSelectionReport extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            selectedOption: this.props.benefitYearOptions[0],
            currentPage: 1,
            itemsPerPage: 12,
            totalLimitTotal: "",
            adjustedTotalLimitTotal: "",
            totalHsaSelections: "",
            totalLsaSelections: "",
            totalRspSelections: "",
            totalHsaCarriedIn: "",
            totalLsaCarriedIn: "",
            showHSASelections: false,
            showLSASelections: false,
            showRSPSelections: false,
        };

        this.setSelectedOption = this.setSelectedOption.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.checkBenefitCategory();
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedOption !== this.state.selectedOption) {
            this.checkBenefitCategory();
            this.fetchData();
        }
    }

    checkBenefitCategory = async () => {
        try {
            this.setState({loading: true});
            const response = await axios.get("/api/Utility/getcategorysatype?policyId=" + this.props.policyId);
            if (response.data.success) {
                this.setState({loading: false});
                this.setState({
                    showHSASelections: response.data.hasHsa, 
                    showLSASelections: response.data.hasLsa, 
                    showRSPSelections: response.data.hasRsp
                });
            } else {
                toastr.error(response.data.message, "Error");
            }
        }
        catch (err) {
            toastr.error(err, "Error");
        }
    }

    fetchData = async () => {
        try {
            this.setState({loading: true});
            const response = await axios.get("/api/Reports/getflexselectionreport", {
                params: {
                    policyId: this.props.policyId,
                    benefitYearId: this.state.selectedOption.id,
                }
            });

            if (response.data.success) {
                this.setupData(response.data.result);
            } else {
                this.setState({
                    loading: false,
                    data:[],
                    dataToExport: [],
                });
            }
        }
        catch (err) {
            toastr.error(err, "Error");
        }
    }

    setSelectedOption = (option) => {
        let findOption = this.props.benefitYearOptions.find(x => x.dateRange === option);
        this.setState({selectedOption: findOption});
    }
    
    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    setupData = (data) => {
        let totalLimitTotal = 0;
        let adjustedTotalLimitTotal = 0;
        let totalHsaSelections = 0;
        let totalLsaSelections = 0;
        let totalRspSelections = 0;
        let totalHsaCarriedIn = 0;
        let totalLsaCarriedIn = 0;

        const dataToExport = data.map(item => {
            totalLimitTotal += parseFloat(item.totalLimit);
            adjustedTotalLimitTotal += parseFloat(item.adjustedTotalLimit);
            totalHsaSelections += parseFloat(item.hsaSelections);
            totalLsaSelections += parseFloat(item.lsaSelections);
            totalRspSelections += parseFloat(item.rspSelections);
            totalHsaCarriedIn += parseFloat(item.hsaCarriedIn);
            totalLsaCarriedIn += parseFloat(item.lsaCarriedIn);

            item.selectionDate = item.selectionDate.toString() === "1900-01-01T00:00:00"
                ? "N/A"
                : moment.utc(item.selectionDate).local().format("YYYY-MM-DD hh:mm A");

            const result = [
                item.lastName,
                item.firstName,
                item.totalLimit,
                item.adjustedTotalLimit,
            ];
    
            if (this.state.showHSASelections) 
            {
                result.push(item.hsaSelections);
                result.push(item.hsaCarriedIn);
            }
            if (this.state.showHSASelections) 
            {
                result.push(item.lsaSelections);
                result.push(item.lsaCarriedIn);
            }
            if (this.state.showRSPSelections) result.push(item.rspSelections);

            result.push(item.isDefault);
            result.push(item.selectionDate);
            return result;
        });

        const headerRow = [
            "Last Name",
            "First Name",
            "Eligible Total Limit",
            "Adjusted Total Limit",
        ];
        
        if (this.state.showHSASelections) 
        {
            headerRow.push("HSA Selections");
            headerRow.push("HSA Carried In");
        }

        if (this.state.showLSASelections) 
        {
            headerRow.push("LSA Selections");
            headerRow.push("LSA Carried In");
        }
        if (this.state.showRSPSelections) headerRow.push("RSP Selections");

        headerRow.push("Default");
        headerRow.push("Selection Date");
        
        dataToExport.unshift(headerRow);
        
        const totalItem = [
            "Total",
            "",
            totalLimitTotal.toFixed(2),
            adjustedTotalLimitTotal.toFixed(2),
        ];

        if (this.state.showHSASelections) 
        {
            totalItem.push(totalHsaSelections.toFixed(2));
            totalItem.push(totalHsaCarriedIn.toFixed(2));
        }

        if (this.state.showLSASelections) 
        {
            totalItem.push(totalLsaSelections.toFixed(2));
            totalItem.push(totalLsaCarriedIn.toFixed(2));
        }
        if (this.state.showRSPSelections) totalItem.push(totalRspSelections.toFixed(2));
       

        totalItem.push("");
        totalItem.push("");
        dataToExport.push(totalItem);

        const dateRangeRow = [
            "Date Range",
            `${this.state.selectedOption.dateRange}`
        ];
        dataToExport.unshift(dateRangeRow);

        this.setState({
            data: data,
            dataToExport: dataToExport,
            loading: false,
            totalLimitTotal: totalLimitTotal.toFixed(2),
            adjustedTotalLimitTotal: adjustedTotalLimitTotal.toFixed(2),
            totalHsaSelections: totalHsaSelections.toFixed(2),
            totalLsaSelections: totalLsaSelections.toFixed(2),
            totalRspSelections: totalRspSelections.toFixed(2),
            totalHsaCarriedIn: totalHsaCarriedIn.toFixed(2),
            totalLsaCarriedIn: totalLsaCarriedIn.toFixed(2),
        });
    }

    exportToPdf = () => {
        const { dataToExport } = this.state;

        const header = dataToExport[1];
        const bodyRows = dataToExport.slice(2, dataToExport.length - 1);
        const footer = dataToExport[dataToExport.length - 1];
      
        const headerData = this.formatRow(header, 'tableHeader');
        const bodyData = this.formatTableBody(bodyRows);
        bodyData.push(this.formatRow(footer, 'tableFooter'));

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'Landscape',
            content: [
                {
                    columns: [
                        this.createLogo(),
                        this.createDateRange(dataToExport[0][1]),
                    ],
                },
                this.createTitle(),
                this.createTable(headerData, bodyData)
            ],
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download("Flex_Selection_Report.pdf");
    };
        
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    createTitle = () => ({
        text: 'Flex Selection Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map(row => this.formatRow(row, 'tableBody'));
    
    createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('auto'),
            body: [header, ...body]
        }
    });
    
    getPdfStyles = () => ({
        table: {
            margin: [50, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 12,
            color: 'black'
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    render() {
        const { 
            data, 
            dataToExport, 
            currentPage, 
            itemsPerPage, 
            selectedOption, 
            loading,
            showHSASelections,
            showLSASelections,
            showRSPSelections,
            totalLimitTotal,
            adjustedTotalLimitTotal,
        } = this.state;
        
        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(data.length / itemsPerPage);

        let contents = null;
        if (loading) {
            contents = (<CustomLoading />);
        } else {
            contents = (
                <div className="flex-selection-report-Table report-table-container">
                      <div className="report-toolbar" style={{display: "flex", alignItems: "center"}}>
                        <CustomDropdown
                            options={this.props.benefitYearOptions.map((item) => {
                                return item.dateRange;
                            })}
                            selectedOption={selectedOption.dateRange}  
                            setSelectedOption={this.setSelectedOption}
                        />
                        <div className="benefit-year-status">
                            <span className="benefit-year-status-text">{selectedOption.status}</span>
                        </div>
                        {dataToExport.length !== 0 ?
                        <div className="export-button-container">
                            <Button variant="primary" className="export-to-pdf" onClick={this.exportToPdf}>
                                <span className="pdf-text">Export to PDF</span>
                            </Button>
                            <CSVLink data={this.state.dataToExport} filename={"Flex_Selection_Report.csv"} className="export-button">
                                <Button variant="primary" className="csv-button export-to-csv">
                                    <span className="csv-text">Export to CSV</span>
                                </Button>
                            </CSVLink>
                        </div> : null}
                      </div>
                    {data.length === 0 ? <EmptyStateTemplate />:
                    <>
                        <Table hover className="report-table">
                            <thead>
                                <tr>
                                    <th className="table-text-content">Last Name</th>
                                    <th className="table-text-content">First Name</th>
                                    <th>
                                        Category Total Limit
                                        <CustomToolTip
                                            header="Category Total Limit"
                                            bodyContent="Employee’s categorical flex total."
                                        />
                                    </th>
                                    <th>
                                        Adjusted Total Limit
                                        <CustomToolTip
                                            header="Adjusted Total Limit"
                                            bodyContent="Employee’s actual total after proration and/or limit adjustments."
                                        />
                                    </th>
                                    {showHSASelections ? 
                                        <th>
                                            HSA Selections
                                            <CustomToolTip
                                                header="HSA Selections"
                                                bodyContent="Amount allocated by employee."
                                            />
                                        </th>: null}
                                    {showHSASelections ? 
                                        <th>
                                            HSA Carried In
                                            <CustomToolTip
                                                header="HSA Carried In"
                                                bodyContent="Amount carried in from previous benefit year. During runoff, remaining balances from the previous benefit year are subject to change and, if applicable, Credit Carry Forward amounts will show here after runoff is closed."
                                            />
                                        </th>: null}
                                    {showLSASelections ? 
                                        <th>
                                            LSA Selections
                                            <CustomToolTip
                                                header="LSA Selections"
                                                bodyContent="Amount allocated by employee."
                                            />
                                        </th>: null}
                                    {showLSASelections ? 
                                        <th>
                                            LSA Carried In
                                            <CustomToolTip
                                                header="LSA Carried In"
                                                bodyContent="Amount carried in from previous benefit year. During runoff, remaining balances from the previous benefit year are subject to change and, if applicable, Credit Carry Forward amounts will show here after runoff is closed."
                                            />
                                        </th>: null}
                                    {showRSPSelections ? 
                                        <th>
                                            RSP Selections
                                            <CustomToolTip
                                                header="RSP Selections"
                                                bodyContent="Amount allocated by employee."
                                            />
                                        </th>: null}
                                    <th className="table-text-content">
                                        Default
                                        <CustomToolTip
                                            header="Default"
                                            bodyContent="Allocations default to a predetermined split if no selection is made by the employee."
                                        />
                                    </th>
                                    <th className="table-text-content">Selection Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((item, index) => (
                                    <tr key={index} className="table-body-itme">
                                        <td className="table-text-content">{item.lastName}</td>
                                        <td className="table-text-content">{item.firstName}</td>
                                        <td>{item.totalLimit}</td>
                                        <td>{item.adjustedTotalLimit}</td>
                                        {showHSASelections ? <td>{item.hsaSelections}</td>: null}
                                        {showHSASelections ? <td>{item.hsaCarriedIn}</td>: null}
                                        {showLSASelections ? <td>{item.lsaSelections}</td>: null}
                                        {showLSASelections ? <td>{item.lsaCarriedIn}</td>: null}
                                        {showRSPSelections ? <td>{item.rspSelections}</td>: null}
                                        <td className="table-text-content">{item.isDefault}</td>
                                        <td className="table-text-content">{item.selectionDate}</td>
                                    </tr>
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2} className="table-footer-item total-amount">Total</td>
                                    <td className="table-footer-item">{totalLimitTotal}</td>
                                    <td className="table-footer-item">{adjustedTotalLimitTotal}</td>
                                    {showHSASelections ? <td className="table-footer-item">{this.state.totalHsaSelections}</td>: null}
                                    {showHSASelections ? <td className="table-footer-item">{this.state.totalHsaCarriedIn}</td>: null}
                                    {showLSASelections ? <td className="table-footer-item">{this.state.totalLsaSelections}</td>: null}
                                    {showLSASelections ? <td className="table-footer-item">{this.state.totalLsaCarriedIn}</td>: null}
                                    {showRSPSelections ? <td className="table-footer-item">{this.state.totalRspSelections}</td>: null}
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </Table>
                        <div className="pagination-container">
                            <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {data.length} entries</span>
                            </div>
                            <CustomPagination 
                                nPages={nPages} 
                                currentPage={currentPage} 
                                setCurrentPage={this.setCurrentPage} 
                            />
                        </div>
                    </>}
                </div>
            );
        }
        return (<>{contents}</>);
    }
}