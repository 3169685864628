import { Button, Modal } from "react-bootstrap";
import { CustomDropdown } from "./CustomDropdown";
import { useState } from "react";

const EditGeneralSettingModal = ({modalLabel, options, showEditModal, selectedOption, setSelectedOption, closeModal, dropdownLabel}) => {
    const [option, setOption] = useState(selectedOption);

    const handleSave = () => {
        setSelectedOption(option);
        closeModal();
    }

    return (
        <Modal  
            className="react-modal edit-general-setting-modal"
            show={showEditModal}
        >
            <Modal.Header>
                <Modal.Title  style={{fontSize:17}}>{modalLabel}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="RT-TextBox">
                    <label className="RT-Label">{dropdownLabel}</label>
                    <CustomDropdown options={options} selectedOption={option} setSelectedOption={setOption} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Close</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditGeneralSettingModal;