import React from 'react';

export const Column = class extends React.Component<{
    size?: any;
    textAlign?: string;
    verticalCenter?:boolean;
    divider?:boolean;
}> {
    render() {
        let children = this.props.children;
        let style: React.CSSProperties = {};
        if (this.props.size == "content") {
            style.flexBasis = "auto";
            style.flexGrow = 1;
        }
        else if (this.props.size){
            style.flexGrow = 0;
            style.flexShrink = 0;
            try {
                let size = parseInt(this.props.size);
                style.flexBasis = (size * 100)/12 + "%";
            }
            catch{
                style.flexBasis = "100%"
            }
        }
        else {
            style.flexBasis = 0;
            style.flexGrow = 1;
        }

        if (this.props.verticalCenter){
            style.alignSelf = "center";
        }
    
        let className = "RT-Column";
        if (this.props.textAlign) {
            className += " RT-Column--align-" + this.props.textAlign;
        }
        if (this.props.divider){
            className += " RT-Column--divider";
        }
    
     
        if (children){
            let decorate;
            return (
                <div
                    className={className}
                    style={style}
                    {...decorate}
                >
                    {children}
                </div>
            );
            }
        return null;
    }
}