import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  TabSet,
  Tab,
  Layout,
  RecordTabs,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Display, Button, EmptyState } from "ktm-ui";
import ProductNewScreen from "planadmin/product/New";
import ProductRecordScreen from "planadmin/product/Record";

interface IdsBenefits {
  Id: string;
  Name: string;
  Description: string;
  Policy_Id: string;
  Benefit: string;
}

export default KTMScreen(
  { name: "planadmin.policy.products" },
  class extends React.Component<{ policy_id: string }> {
    data: {
      dsBenefits: IdsBenefits[];
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefits";
      screen.title = "Plan";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
      });
      // if (this.data.dsBenefits || this.data.dsBenefits.length) {
      //   //  await screen.triggerAction(this.show_record);
      // }
      
    }

    main({ draw }: RenderEvent) {
      return <Layout>{draw(this.noTabView)}</Layout>;
    }

    tabset({ field, draw, action, collection, getValue }: RenderEvent) {
      return (
        <RecordTabs collection={collection("@dsBenefits")} template="tabContent" />
      );
    }

    noTabView({ getValue }: RenderEvent){
      return <ProductRecordScreen policyBenefit_id={getValue("Id")} policy_id={this.props.policy_id} />;
    }

    tabContent({ getValue }: RenderEvent) {
      return <ProductRecordScreen policyBenefit_id={getValue("Id")} policy_id={this.props.policy_id} />;
    }

    empty_state({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState label="No products have been added yet." icon="money" />
      );
    }

    async add({ screen }: ActionEvent) {
      await screen.openDialog(
        <ProductNewScreen policy_id={this.props.policy_id} />,
        { onRecordChanged: this.open_new }
      );
    }

    async open_new({ screen, eventValue }: ActionEvent) {
      screen.showLayout(this.main);
      await KTMApi.refreshRow(screen, {
        collection: "@dsBenefits",
        rowid: eventValue,
        appendNewRows: true,
      });
      screen.locate("@dsBenefits", eventValue);
    }
  }
);
