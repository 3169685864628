import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, RadioButtons} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Card,Column,Panel,Line} from 'ktm-ui';
import AdvisorLookup from 'planadmin/lookups/Advisor';
import AccountHolderLookup from 'planadmin/lookups/Policy_holder';


interface IdsPolicy {
    PolicyHolderName:string;
    PolicyHolder_Id:string;
    PolicyName:string;
    DistroAccount:string;
    PrimaryAdvisor_Id:string;
    Mode:string;
    Policy_Id:string;
};

interface IdsBenefitYear {
    Id:string;
    StartDate:string;
    EndDate:string;
    RunoffMonths:number;
};

export default KTMScreen({name:"planadmin.policy.new"}, class extends React.Component<{new_policy_id:string}> {

    data:{
        dsPolicy:IdsPolicy;
        dsBenefitYear:IdsBenefitYear;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsPolicy";
        await KTMApi.start(screen,{
            "@new_policy_id":this.props.new_policy_id,
        });
        screen.title = "New Enrollment";
        screen.setLookup("PrimaryAdvisor_Id",{template:this.advisor_lookup,autoLaunch:true});  
        screen.setLookup("PolicyHolder_Id",{template:this.account_holder_lookup,autoLaunch:true});  
        screen.setOptions("Mode",[
            {value:"NEW",label:"New policy holder"},
            {value:"ADD",label:"Add a policy to existing policy holder"}
        ])
        screen.setOptions("@dsBenefitYear.RunoffMonths",[
            {value:"1",label:"1 month"},
            {value:"2",label:"2 months"},
            {value:"3",label:"3 months"}
        ]);
        screen.setOnValueChanged("Mode",this.mode_onchange);
        screen.setOnValueChanged("PrimaryAdvisor_Id",this.advisor_onchange); 
    }

    advisor_lookup({getValue,eventValue}:RenderEvent){
        return <AdvisorLookup eventValue={eventValue}/>
    }

    account_holder_lookup({getValue,eventValue}:RenderEvent){
        return <AccountHolderLookup eventValue={eventValue}/>
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="full">
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="New Enrollment"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Create policy" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    new_account_holder({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("PolicyHolderName")}/>
            </Card>
        );
    }

    existing_account_holder({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display field={field("PolicyHolder_Id")}/>
                    <Display field={field("PolicyName")}/>
                </Line>
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.enrollment_type)}
                    {draw(this.new_account_holder)}
                    {draw(this.existing_account_holder)}
                    {draw(this.detail_panel)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card label="Primary Advisor">
                <Line>
                    <Display field={field("PrimaryAdvisor_Id")}/>
                    <Display field={field("DistroAccount")}/>
                </Line>
            </Card>
        );
    }

    enrollment_type({field,draw,action}:RenderEvent){
        return <RadioButtons field={field("@dsPolicy.Mode")} hideLabel direction="horizontal"/>
    }

    benefit_year({field,draw,action}:RenderEvent){
        return (
            <Card label="Benefit year">
                <Line>
                    <Display field={field("@dsBenefitYear.StartDate")}/>
                    <Display field={field("@dsBenefitYear.EndDate")} />
                    <Display field={field("@dsBenefitYear.RunoffMonths")}/>
                </Line>
            </Card>
        );
    }

    detail_panel({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
                <Column>
                    {draw(this.benefit_year)}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsPolicy.Mode":this.data.dsPolicy.Mode,
            "@dsPolicy.PolicyHolder_Id":this.data.dsPolicy.PolicyHolder_Id,
            "@dsPolicy.PolicyName":this.data.dsPolicy.PolicyName,
            "@dsPolicy.PolicyHolderName":this.data.dsPolicy.PolicyHolderName,
            "@dsPolicy.PrimaryAdvisor_Id":this.data.dsPolicy.PrimaryAdvisor_Id,
            "@dsBenefitYear.StartDate":this.data.dsBenefitYear.StartDate,
            "@dsBenefitYear.EndDate":this.data.dsBenefitYear.EndDate,
            "@dsBenefitYear.RunoffMonths":this.data.dsBenefitYear.RunoffMonths,
        });
        screen.close({"continue":true});
    }

    async mode_onchange({screen,eventValue}:ActionEvent){
        if (eventValue == 'ADD'){
            screen.setValue("@dsPolicy.PolicyHolderName",null);
            screen.hide(this.new_account_holder);
            screen.unhide(this.existing_account_holder);
        }
        else {
            screen.setValue("@dsPolicy.PolicyHolder_Id",null);
            screen.setValue("@dsPolicy.PolicyName",null);
            screen.hide(this.existing_account_holder);
            screen.unhide(this.new_account_holder);
        }
    }

    async advisor_onchange({screen,eventValue}:ActionEvent){
        await KTMApi.onValueChanged(screen,"@dsPolicy.PrimaryAdvisor_Id",{
            "@event-value":eventValue
        });
    }

});
