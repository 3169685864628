import { ScreenRef } from "../ktm-ui/core/ActionEvent";
import {Application, Request} from 'ktm-ui/core';
export class KTMApi {

    static async doPostRequest(path:string,body:any):Promise<any>{
        let app = Application.instance;
        let host = app.host || "";
        let url = host  + "/" + app.appName + path;
        let info:any =  {
            method:"POST",
            credentials:"include",
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(body)
        };
        let res = await fetch(url,info);
        let json = await res.json();
        return json;
    }


    static async start(screen:ScreenRef,params:any){
        await Request.start(screen,params);
    }
    static async action(screen:ScreenRef,action:string,params?:any):Promise<any>{
        await screen.runScript(action);
    }

    static async onValueChanged(screen:ScreenRef,field:string,params?:any):Promise<any>{
        let method = field + ":onValueChanged[0]";
        if (!params){
            params = Request.getParameters(screen,method);
        }
        await Request.callScript(screen,"batch",method,params);
    }

    static async onFieldClick(screen:ScreenRef,field:string,params?:any):Promise<any>{
        let method = field + ":onClick[0]";
        if (!params){
            params = Request.getParameters(screen,method);
        }
        await Request.callScript(screen,"batch",method,params);
    }

    static async refreshRow(screen:ScreenRef,params:{collection:string,rowid:string,appendNewRows?:boolean}){
        await Request.refreshRow(screen,params);
    }
    

    
}





