import React from 'react';
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from 'ktm-ui';
import { KTMApi } from 'api/KTMApi';
import { Layout, Dialog, Button, Display, Line, Column, Panel, Card } from 'ktm-ui';

interface IdsBenCat {
    Id: string;
    FlexSingleLimit: number;
    FlexCoupleLimit: number;
    FlexFamilyLimit: number;
    FlexSinglePercent: number;
    FlexCouplePercent: number;
    FlexFamilyPercent: number;
    CreditCalcMethod: string;
    Policy_Id: string;
}

export default KTMScreen({name:"planadmin.ben-limit.edit-flex-limits"}, 
    class extends React.Component<{limit_id: string, employeesCount: number, employeeHasClaims: boolean}> {

    data: {
        dsBenCat: IdsBenCat;
    }

    state = {
        originalLimits: {
            FlexSingleLimit: 0,
            FlexCoupleLimit: 0,
            FlexFamilyLimit: 0,
        },
        disableButton: false,
        showCampaignWarning: false,
    };

    render() {
        return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen, {
            "@limit_id": this.props.limit_id,
        });
        screen.title = "Edit flex limits";
        this.setState({
            originalLimits: {
                FlexSingleLimit: this.data.dsBenCat.FlexSingleLimit,
                FlexCoupleLimit: this.data.dsBenCat.FlexCoupleLimit,
                FlexFamilyLimit: this.data.dsBenCat.FlexFamilyLimit,
            }
        });
        await this.checkCampaignStatus();
        await screen.triggerAction(this.set_limit_visibility);
    }

    main({ field, draw, action }: RenderEvent) {
        return (
            <Layout>
                {draw(this.header)}
                {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                    <Card>
                        <Line>
                            <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                                This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                                <br />
                                If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                            </span>
                        </Line>
                    </Card>
                )}
                {draw(this.main_tab)}
                {this.isButtonDisabled() ? <p style={{color: "red"}}>Benefit category cannot be decreased after assigned employees have claimed amounts.</p> : ""}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Header label="Edit Flex Limits" />
        );
    }

    isButtonDisabled(){
        const { FlexSingleLimit, FlexCoupleLimit, FlexFamilyLimit } = this.data.dsBenCat;
        const { originalLimits } = this.state;
        const disableButton = this.props.employeesCount > 0 &&
            (FlexSingleLimit < originalLimits.FlexSingleLimit ||
                FlexCoupleLimit < originalLimits.FlexCoupleLimit ||
                FlexFamilyLimit < originalLimits.FlexFamilyLimit)
            && this.props.employeeHasClaims; // Disable the button if employees have claims and the user is reducing the limit

        return disableButton;
    }
    
    footer({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Footer>
                <Button 
                    action={action(this.submit)} 
                    label="Save" 
                    size="large" 
                    style="primary"
                    disable={this.isButtonDisabled()}
                />
            </Dialog.Footer>
        );
    }

    limits({ field, draw, action }: RenderEvent) {
        return (
            <Line>
                <Display field={field("FlexSingleLimit")} />
                <Display field={field("FlexCoupleLimit")} />
                <Display field={field("FlexFamilyLimit")} />
            </Line>
        );
    }

    percentages({ field, draw, action }: RenderEvent) {
        return (
            <Line>
                <Display field={field("FlexSinglePercent")} />
                <Display field={field("FlexCouplePercent")} />
                <Display field={field("FlexFamilyPercent")} />
            </Line>
        );
    }

    main_tab({ field, draw, action }: RenderEvent) {
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({ field, draw, action }: RenderEvent) {
        return (
            <Card>
                {draw(this.limits)}
                {draw(this.percentages)}
            </Card>
        );
    }

    async submit({ screen }: ActionEvent) {
        if (this.data.dsBenCat.FlexSingleLimit == null)
            this.data.dsBenCat.FlexSingleLimit = 0;
        if (this.data.dsBenCat.FlexCoupleLimit == null)
            this.data.dsBenCat.FlexCoupleLimit = 0;
        if (this.data.dsBenCat.FlexFamilyLimit == null)
            this.data.dsBenCat.FlexFamilyLimit = 0;
        if (this.data.dsBenCat.FlexSinglePercent == null)
            this.data.dsBenCat.FlexSinglePercent = 0;
        if (this.data.dsBenCat.FlexCouplePercent == null)
            this.data.dsBenCat.FlexCouplePercent = 0;
        if (this.data.dsBenCat.FlexFamilyPercent == null)
            this.data.dsBenCat.FlexFamilyPercent = 0;

        await KTMApi.action(screen, "submit", {
            "@dsBenCat.FlexSingleLimit": this.data.dsBenCat.FlexSingleLimit,
            "@dsBenCat.FlexCoupleLimit": this.data.dsBenCat.FlexCoupleLimit,
            "@dsBenCat.FlexFamilyLimit": this.data.dsBenCat.FlexFamilyLimit,
            "@dsBenCat.FlexSinglePercent": this.data.dsBenCat.FlexSinglePercent,
            "@dsBenCat.FlexCouplePercent": this.data.dsBenCat.FlexCouplePercent,
            "@dsBenCat.FlexFamilyPercent": this.data.dsBenCat.FlexFamilyPercent,
            "@limit_id": this.props.limit_id,
        });
        screen.close({ "continue": true });
    }

    async set_limit_visibility({ screen }: ActionEvent) {
        screen.hide(this.limits);
        screen.hide(this.percentages);
        if (screen.getValue("@dsBenCat.CreditCalcMethod") === "SALARY") {
            screen.unhide(this.percentages);
        } else {
            screen.unhide(this.limits);
        }
    }

    async checkCampaignStatus() {
        let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsBenCat.Policy_Id);
        let res = await response.json();
        if (res.isCampaignOpen) {
            this.setState({ showCampaignWarning: true });
        }
    }
});
