import React from "react";
import axios from "axios";
import { Collapse } from "react-bootstrap";
import moment from "moment";
import NotesReadOnlyModal from "./NotesReadOnlyModal";

interface Metadata {
  rotation?: number;
}

export default class SubmittedImages extends React.Component<any, any> {
  state = {
    imageUrls: [] as any,
    isModalOpen: false,
    modalImageId: null,
    modal: null,
    open: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.images.map((data, i) => {
      axios
        .get(`/api/claims/getimage?imageId=${data.imageId}`)
        .then((response) => {
          const mapping = {
            imageId: data.imageId,
            notes: data.note,
            src: response.data.src,
            imgNo: data.seqNo,
            expenseDate: response.data.expenseDate,
            timestamp: response.data.timestamp,
            source: response.data.source,
            metadata: response.data.metadata
          };
          const newSrc = this.state.imageUrls.slice();
          newSrc.push(mapping);
          var sortedImages = newSrc.sort(function (a, b) {
            return a.imgNo - b.imgNo;
          });
          this.setState({ imageUrls: sortedImages });
        });
    });
  }

  compareIndexFound(a, b) {
    return a.indexFound - b.indexFound;
  }

  componentDidUpdate(prevProps) {
    if (this.props.images !== prevProps.images) {
      this.setState({ imageUrls: [] });
      this.props.images.map((data, i) => {
        axios
          .get(`/api/claims/getimage?imageId=${data.imageId}`)
          .then((response) => {
            const mapping = {
              imageId: data.imageId,
              src: response.data.src,
              imgNo: data.seqNo,
              notes: data.note,
              timestamp: response.data.timestamp,
              metadata: response.data.metadata
            };
            const newSrc = this.state.imageUrls.slice();
            newSrc.push(mapping);
            var sortedImages = newSrc.sort(function (a, b) {
              return a.imgNo - b.imgNo;
            });
            this.setState({ imageUrls: sortedImages });
          });
      });
    }
  }

  displayModal = (imageId) => {
    this.setState({ isModalOpen: true, modalImageId: imageId });
  };

  renderOpenModal() {
    if (!this.state.isModalOpen) return null;
    return (
      <NotesReadOnlyModal
        imageId={this.state.modalImageId}
        close={() => this.setState({ isModalOpen: false })}
      ></NotesReadOnlyModal>
    );
  }

  displayPreview = (base64URL, rotationDegrees) => {
    var newTab = window.open(
      "",
      "Image Preview",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=500,top=100,left=100"
    );

    const rotationStyle = `rotate(${rotationDegrees}deg)`;

    newTab.document.body.innerHTML =
      '<img src="data:image/png;base64,' +
      base64URL +
      '" width="900" style="transform:' +
      rotationStyle +
      '">';
  };

  render() {
    let imageDiv = null;
    let imageContainer = null;
    if (this.state.imageUrls && this.state.imageUrls.length > 0) {
      imageDiv = this.state.imageUrls.map((data, i) => {
        let metadata: Metadata = {};
        if (data.metadata && typeof data.metadata === "string") {
          try {
            metadata = JSON.parse(data.metadata) as Metadata;
          } catch (e) {
            console.error("Failed to parse metadata JSON:", e);
          }
        }
      
        const rotationDegrees = metadata.rotation || 0;
        const rotationStyle = `rotate(${rotationDegrees}deg)`;

        return (
          <div key={data.imageId} className="col-3 mt-3" style={{ position: "relative" }}>
            <div className="circle">
              <p className="circle-content">{i + 1}</p>
            </div>
            <img
              onClick={() => this.displayPreview(data.src, rotationDegrees)}
              className="img-fluid"
              src={`data:image/png;base64,${data.src}`}
              width={300}
              style={{
                cursor: "pointer",
                boxShadow: "0 3px 8px rgba(0, 0, 0, 0.25)",
                transform: rotationStyle,
              }}
            />
            <h6
              style={{
                position: "relative",
                left: "5px",
                top: "15px",
                marginBottom: "0.5rem",
              }}
            >
              <strong>{moment(data.expenseDate).local().format("MMM DD, YYYY")}</strong>
              {" (Submitted " + data.source + " "}
              {moment(data.timestamp).local().format("MMM DD, YYYY h:mm A")}
              {" MST)"}
            </h6>
            {data.notes && (
              <button
                className="btn btn-warning pd-x-5 pd-y-1"
                onClick={() => this.displayModal(data.imageId)}
                style={{
                  position: "relative",
                  left: "5px",
                  top: "10px",
                  marginBottom: "10px",
                }}
              >
                <i className="fa fa-edit fa-1x mg-r-5" /> Read Notes
              </button>
            )}
          </div>
        );
      });

      imageContainer = (
        <div className="br-pagebody">
          <div className="br-section-wrapper no-padding">
            <div className="card">
              <div className="card-header tx-medium tx-uppercase bg-white">
                <button
                  onClick={() => {
                    this.setState({ open: !this.state.open });
                  }}
                  style={{color:"#4987ef", fontSize: 17, fontWeight: "bold"}}
                >
                  SUBMITTED IMAGES
                </button>
              </div>
              {this.renderOpenModal()}
              <Collapse in={this.state.open}>
                <div className="card-body">
                  <div
                    className="container-fluid"
                    style={{
                      maxHeight: 550,
                      overflowY: "auto",
                      marginBottom: 15,
                      marginTop: 15,
                    }}
                  >
                    <div className="row fluid">{imageDiv}</div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      );
    }

    return imageContainer;
  }
}
