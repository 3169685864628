import React, {Component} from "react";
import {KTMScreen} from "ktm-ui";
import toastr from "toastr";
import Moment from "react-moment";
import {
    Button,
    Table,
    Container,
    Row,
    Col,
    Form,
    Modal,
    Spinner,
    Card,
} from "react-bootstrap";
import axios from "axios";
import {RiBankCard2Line} from "react-icons/ri";
import {RiMailLine} from "react-icons/ri";

export default KTMScreen(
    {name: "eftreissue"},
    class extends React.Component<any, any> {
        state = {
            loading: true,
            success: false,
            searchInput: null,
            searchResult: null,
        };

        componentDidMount() {
        }

        setInput = (e) => {
            this.setState({searchInput: e.target.value});
        };
        handleKeyDown = (event) => {
            if (event.key === "Enter") {
                this.submitSearch();
            }
        };
        submitSearch = () => {
            if (this.state.searchInput != null && this.state.searchInput != "") {
                fetch("/api/payment/searchdisbursement/" + this.state.searchInput, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        this.setState(
                            {
                                searchResult: data,
                                loading: false,
                                success: data.success,
                            },
                            () => {
                                if (!this.state.success) {
                                    toastr.error("Disbursement not found", "Error");
                                }
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState({searchResult: null, success: false});
                        toastr.error("Search failed", "Error");
                    });
            }
        };

        reissueEft = () => {
            fetch("/api/payment/reissueeft", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: this.state.searchResult.disbursementId,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: {
                                ...this.state.searchResult,
                                status: "UNPAID",
                            },
                            loading: false,
                            success: data.success,
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            } else {
                                toastr.success("Disbursement reissued", "Success");
                            }
                        }
                    );
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");
                });
        };

        reissueChq = () => {
            fetch("/api/payment/reissuechq", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: this.state.searchResult.disbursementId,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: {
                                ...this.state.searchResult,
                                status: "UNPAID",
                            },
                            loading: false,
                            success: data.success,
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            } else {
                                toastr.success("Disbursement reissued", "Success");
                            }
                        }
                    );
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");
                });
        };

        render() {
            let responseContents = null;
            if (this.state.searchResult != null && this.state.success) {
                responseContents = (
                    <div>
                        <div className="ktm-funding-name">
                            Disbursement: {this.state.searchResult.claimNo} (
                            {this.state.searchResult.disbursementId})
                        </div>
                        <div>
                            <table className="table" style={{width: 600, marginTop: 25}}>
                                <tbody>
                                <tr>
                                    <td>
                                        <b>Last Name</b>
                                    </td>
                                    <td>{this.state.searchResult.lastName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>First Name</b>
                                    </td>
                                    <td>{this.state.searchResult.firstName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Status</b>
                                    </td>
                                    <td
                                        style={{
                                            color:
                                                this.state.searchResult.status == "PAID"
                                                    ? "green"
                                                    : "red",
                                        }}
                                    >
                                        {this.state.searchResult.status}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Amount</b>
                                    </td>
                                    <td>
                                        {" "}
                                        $
                                        {this.state.searchResult.amount.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Payment Batch</b>
                                    </td>
                                    <td>{this.state.searchResult.paymentBatch}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Banking Info</b>
                                    </td>
                                    <td>{this.state.searchResult.paymentInfo}</td>
                                </tr>
                                {this.state.searchResult.paymentDate != null ? (
                                    <tr>
                                        <td>
                                            <b>Payment Date</b>
                                        </td>
                                        <td>
                                            <Moment local format="YYYY-MM-DD">
                                                {this.state.searchResult.paymentDate}
                                            </Moment>
                                        </td>
                                    </tr>
                                ) : null}

                                <tr>
                                    <td>
                                        <b>Disbursement Date</b>
                                    </td>
                                    <td>
                                        <Moment local format="YYYY-MM-DD">
                                            {this.state.searchResult.disbursementDate}
                                        </Moment>
                                    </td>
                                </tr>
                                {/*{this.state.searchResult.status == "PAID" ? (*/}
                                {/*  <tr>*/}
                                {/*    <td></td>*/}
                                {/*    <td>*/}
                                {/*      <button*/}
                                {/*        onClick={() => this.reissueDisbursement()}*/}
                                {/*        className="btn btn-danger"*/}
                                {/*        type="button"*/}
                                {/*      >*/}
                                {/*        Reissue Payment*/}
                                {/*      </button>*/}
                                {/*    </td>*/}
                                {/*  </tr>*/}
                                {/*) : null}*/}
                                {this.state.searchResult.status == "PAID" && this.state.searchResult.employeePaymentMethod == "EFT" ? (
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className={"pb-2"}>
                                                Click ‘Reissue as EFT’ to automatically reissue a payment using the
                                                existing banking information of the employee.
                                            </div>
                                            <button
                                                onClick={() => this.reissueEft()}
                                                className="btn btn-danger d-flex align-items-center justify-content-center"
                                                type="button"
                                                style={{minWidth: 200, padding: '8px'}}
                                            >
                                                <RiBankCard2Line style={{marginRight: '8px'}}/>
                                                <span>Reissue as EFT</span>
                                            </button>
                                        </td>
                                    </tr>
                                ) : null}
                                {this.state.searchResult.status == "PAID" ? (
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className={"pb-2"}>
                                                Click ‘Reissue as CHEQUE’ to generate a new cheque payment using a new cheque number. This action will also automatically clear the existing banking information of the employee, if it exists.
                                            </div>
                                            <button
                                                onClick={() => this.reissueChq()}
                                                className="btn btn-danger d-flex align-items-center justify-content-center"
                                                type="button"
                                                style={{minWidth: 200, padding: '8px'}}
                                            >
                                                <RiMailLine style={{marginRight: '8px'}}/>
                                                <span>Reissue as CHEQUE</span>
                                            </button>
                                        </td>
                                    </tr>
                                ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            }
            return (
                <div>
                    <div className="ktm-financials-content">
                        <div className="input-group mb-3">
                            <input
                                onKeyDown={this.handleKeyDown}
                                type="text"
                                className="form-control"
                                name="searchInput"
                                onChange={(e) => this.setInput(e)}
                                value={this.state.searchInput}
                                placeholder={
                                    "Enter claim number (CA20000000) or disbursement ID (CLA00000001A2BC)"
                                }
                            />
                            <button
                                onClick={() => this.submitSearch()}
                                className="btn btn-outline-primary"
                                type="button"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    {responseContents}
                </div>
            );
        }
    }
);
