import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Button,DataGrid,IDataGridLayout,EmptyState} from 'ktm-ui';
import DivisionAddScreen from 'enroll/division/Add';
import DivisionRecordScreen from 'enroll/division/Record';

interface IdsData {
    Id:string;
    Policy_Id:string;
    Name:string;
    Description:string;
    IsActive:boolean;
};

export default KTMScreen({name:"enroll.policy.divisions"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsData:IdsData[];
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsData";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Divisions";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.default_view)}
            </Layout>
        );
    }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = (<><Button action={action(this.add)} label="Add Division" style="add"/>
</>);
        let layout:IDataGridLayout = {
            columns:[
                {name:"Name",label:"Name",type:"character"},
                {name:"Description",label:"Description",type:"character",width:400},
                {name:"IsActive",label:"Is Active",type:"logical"},
            ],
            sort:[{name:"Name"}]
        };
        return (
            <DataGrid collection={collection("@dsData")} layout={layout} onRowClick={action(this.open_row)} actions={actions}/>
        );
    }

    empty_state({ field, draw, action }: RenderEvent) {
        return (
          <EmptyState label="Welcome to divisions!" icon="building">
            <div style={{"width":"50%","textAlign":"center"}}>
            <div style={{"marginBottom":15}}>
              Typically a division is a part of a business entity. This means that a division, although it can often operate under a different name and have its own financial statements, is still a part of the business entity itself and not entirely incorporated.
            </div>
            <div style={{"marginBottom":25}}>
              If you want to tag and group employees by division in your reports, create your divisions on this page. Then when you add new employees, you can tag them with a division, as needed. For existing employees, you can edit their profile and tag them with a division after it has been created here.
            </div>
            </div>
            <Button
              action={action(this.add)}
              label="Add Division"
              style="primary"
            />
          </EmptyState>
        );
    }

    async search({screen}:ActionEvent){
        await KTMApi.action(screen,"search",{
            "@policy_id":this.props.policy_id,
        });
    }

    async add({screen}:ActionEvent){
        await screen.openDialog(<DivisionAddScreen policy_id={this.props.policy_id}/>
,{onRecordChanged:this.refresh_row});
    }

    async open_row({screen}:ActionEvent){
        screen.browse(<DivisionRecordScreen division_id={screen.getValue("Id")}/>,{onRecordChanged:this.refresh_row,label:"Division"});
    }

    async refresh_row({screen,eventValue}:ActionEvent){
        screen.showLayout("main");
        await screen.refreshRow("@dsData",eventValue);
    }

});
