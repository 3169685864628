import React from "react";
import axios from "axios";
import moment from "moment";
import Spinner from "components/Spinner";
import AddNewDependentModal from "./AddNewDependentModal";
import EditDependentModal from "./EditDependentModal";
import toastr from "toastr";
import NoAddressModal from "./NoAddressModal";

interface IdsDependent {
  id: string;
  employee_Id: string;
  firstName: string;
  lastName: string;
  maidenName: string;
  nickname: string;
  dateOfBirth: string;
  relation: string;
}

export default class ProfileScreen extends React.Component<any> {
  data: {
    dsDependent: IdsDependent;
  };

  state = {
    loaded: false,
    employee: null,
    dependents: null,
    allowEditAddress: false,
    allowEditBanking: false,
    allowDependentEdit: false,
    errors: null,
    confirmBank: {
      confirmBankTransitNo: "",
      confirmBankNo: "",
      confirmBankAccountNo: "",
    },
    company: null,
    isNoAddressModalOpen: false,
    dateRangeList: null,
    empYear: null,
    benefitCategory: null,
    twoFactor: false,
    showAddDependentModal: false,
    showEditDependentModal: false,
    updatedDependent: {
      id: "",
      employee_Id: "",
      firstName: "",
      lastName: "",
      maidenName: "",
      nickname: "",
      dateOfBirth: "",
      relation: "",
    },
    dynamicEmployeeStartDate: null,
    employeeId: null
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }

  async startLoading() {
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');
    this.setState({employeeId});

    axios
      .get(`/api/OpsEmployee/getemployee?employeeId=${employeeId}`)
      .then((response) => {
        this.setState({
          loaded: true,
          employee: response.data.employee,
          dependents: response.data.dependents,
          company: response.data.company,
          dateRangeList: response.data.dateRangeList,
          empYear: response.data.empYear,
          benefitCategory: response.data.benefitCategory,
          twoFactor: response.data.twoFactorEnabled,
          allowDependentEdit: response.data.allowDependentEdit,
          dynamicEmployeeStartDate: response.data.dynamicEmployeeStartDate
        });
      })
      .catch((error) => {
      });
  }

  renderNoAddressModal() {
    if (!this.state.isNoAddressModalOpen) return null;
    return (
      <NoAddressModal
        close={this.closeNoAddressModal}
        editAddress={this.handleEditAddress}
      ></NoAddressModal>
    );
  }

  renderAddNewDependentModal() {
    return (
      <AddNewDependentModal
        onHide={this.hideAddDependentModal}
        show={this.state.showAddDependentModal}
        employeeId={this.state.employee.id}
      ></AddNewDependentModal>
    );
  }

  renderEditDependentModal() {
    return (
      <EditDependentModal
        onHide={this.hideEditDependentModal}
        show={this.state.showEditDependentModal}
        dependent={this.state.updatedDependent}
        employeeId={this.state.employee.id}
        handleChange=""
      ></EditDependentModal>
    );
  }

  displayNoAddressModal = () => {
    this.setState({ isNoAddressModalOpen: true });
  };

  closeNoAddressModal = () => {
    this.setState({ isNoAddressModalOpen: false });
  };

  handleEditAddress = () => {
    this.setState({ allowEditAddress: !this.state.allowEditAddress });
  };

  handleEditBanking = () => {
    this.setState({ allowEditBanking: !this.state.allowEditBanking });
  };

  handleCheckBox = (e) => {
    var tfa = null;
    if (this.state.twoFactor) {
      this.setState({ twoFactor: false });
      tfa = false;
    } else {
      this.setState({ twoFactor: true });
      tfa = true;
    }

    axios
      .put("/api/OpsEmployee/updatetwofactor", {
        Tfa: tfa,
        EmployeeId: this.state.employeeId,
      })
      .then((response) => {
        toastr.success("Two Factor Updated", "Success");
      })
      .catch((error) => {
        toastr.error("Unable to Update Two Factor", "Error");
      });
  };

  handleChange = (event) => {
    let empObj = this.state.employee;
    if (event.target.value == "") {
      empObj[event.target.name] = null;
    } else {
      empObj[event.target.name] = event.target.value;
    }
    this.setState({ employee: empObj });
  };

  handleConfirmBank = (event) => {
    let bankObj = this.state.confirmBank;
    bankObj[event.target.name] = event.target.value;
    this.setState({ confirmBank: bankObj });
  };

  handleEditDependent = (event) => {};

  handleAddressSave = (event) => {
    let profileModel = {
      Id: this.state.employeeId,
      MailingStreet1: this.state.employee.mailingStreet1,
      MailingStreet2: this.state.employee.mailingStreet2,
      MailingCity: this.state.employee.mailingCity,
      MailingProv: this.state.employee.mailingProv,
      MailingPostalCode: this.state.employee.mailingPostalCode,
      HomePhone: this.state.employee.homePhone,
      MobilePhone: this.state.employee.mobilePhone,
    };

    axios
      .put("/api/OpsEmployee/updateaddress", profileModel)
      .then((response) => {
        this.setState({ allowEditAddress: false, errors: null });
        toastr.success("Address updated", "Success");
      })
      .catch((error) => {
        toastr.error("Failed to update address", "Error");
      });
  };

  showAddDependentModal = () => {
    this.setState({ showAddDependentModal: true });
  };

  showEditDependentModal = (item) => {
    let dependentObj = this.state.updatedDependent;
    dependentObj = {
      id: item.id,
      employee_Id: item.employeeId,
      firstName: item.firstName,
      lastName: item.lastName,
      maidenName: item.maidenName,
      nickname: item.nickname,
      dateOfBirth: item.dateOfBirth,
      relation: item.relation,
    };
    this.setState({
      updatedDependent: dependentObj,
      showEditDependentModal: true,
    });
  };

  hideAddDependentModal = () => {
    this.fetchDependent();
    this.setState({ showAddDependentModal: false });
  };

  hideEditDependentModal = () => {
    this.fetchDependent();
    this.setState({ showEditDependentModal: false });
  };

  dependentsTable() {
    var dependentList = null;
    dependentList = this.getDependentsList();

    if (dependentList.length === 0) {
      return (
        <div className="noteDependentBottom">
          There are currently no dependents added to this account. Click the
          "Add" button above to add a new dependent. <br/> Please note, by entering in
          your own dependent information you certify the information given is
          true and correct. This information may also be subject to review by
          your company plan administrator.
        </div>
      );
    }
    return (
      <div>
        <div className="dependentListTop no-padding" style={{ maxWidth: 1250, marginTop: 20 }}>
          <div className="br-section-wrapper mg-x-50 dependentListBottom no-padding">
            <div className="bd bd-gray-300 rounded table-responsive">
              <table
                className="table table-hover black"
                style={{ marginBottom: 0 }}
              >
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Maiden Name</th>
                    <th>Nickname</th>
                    <th>Relation</th>
                    <th>Date Of Birth</th>
                  </tr>
                </thead>
                <tbody>{dependentList}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="noteDependentBottom" style={{padding:20}}>
          To make additional edits to your dependents such as updating a Child
          Dependent to Student Status, or to request to remove a dependent,
          please contact your company plan administrator to make those changes.
          Please note, by entering in your own dependent information you certify
          the information given is true and correct. This information may also
          be subject to review by your company plan administrator.
        </div>
      </div>
    );
  }

  getDependentsList() {
    var dependentList = null;
    if (this.state.dependents) {
      dependentList = this.state.dependents.map((item, i) => {
        return (
          <tr key={item.id} style={{ cursor: "pointer" }}>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.maidenName}</td>
            <td>{item.nickname}</td>
            <td>{item.relation}</td>
            <td>{moment(item.dateOfBirth).utc().format("MMM DD, YYYY")}</td>
            <td className="text-right">
              <button
                className="btn btn-outline-primary pd-y-5"
                onClick={() => this.showEditDependentModal(item)}
              >
                Edit
              </button>
            </td>
          </tr>
        );
      });
    }
    return dependentList;
  }

  async fetchDependent() {
    axios
      .get(`/api/OpsEmployee/getalldependent?employeeId=${this.state.employeeId}`)
      .then((response) => {
        this.setState({
          dependents: response.data,
        });
      })
      .catch((error) => {
      });
  }

  handleBankingSave = (event) => {
    let profileModel = {
      Id: this.state.employeeId,
      BankTransitNo: this.state.employee.bankTransitNo,
      BankNo: this.state.employee.bankNo,
      BankAccountNo: this.state.employee.bankAccountNo,
      ConfirmBankTransitNo: this.state.confirmBank.confirmBankTransitNo,
      ConfirmBankNo: this.state.confirmBank.confirmBankNo,
      ConfirmBankAccountNo: this.state.confirmBank.confirmBankAccountNo,
    };

    axios
      .put("/api/OpsEmployee/updatebanking", profileModel)
      .then((response) => {
        this.setState({ allowEditBanking: false, errors: null });
        toastr.success("Banking updated", "Success");
      })
      .catch((error) => {
        toastr.error("Failed to update banking", "Error");
      });
  };

  handleCancel = () => {
    this.startLoading();
    this.setState({ allowEditAddress: false, errors: null });
    this.setState({ allowEditBanking: false, errors: null });
  };

  renderContent() {

    if (!this.state.loaded) {
      return <Spinner />;
    }

    let dependents = false;

    this.state.dateRangeList.forEach((dateRange) => {
      var dependent = dateRange.dependent;
      if (!dependent.isHidden) {
        dependents = true;
      }
    });

    let depList = this.state.dateRangeList.map((item, i) => {
      var dateRange = item.dateRange;
      var dependent = item.dependent;
      let dob = moment(dependent.dateOfBirth).format("LL");
      var display = true;
      if (dateRange != null) {
        if (dateRange.toDate != null) {
          if (new Date(Date.parse(dateRange.toDate)) < new Date()) {
            display = false;
          }
        }
      }
      if (dependent.isHidden == true) {
        display = false;
      }

      if (display) {
        return (
          <li key={dependent.id} className="pd-b-10">
            <div>
              {dependent.firstName} {dependent.maidenName} {dependent.lastName}
            </div>
            {dependent.nickname != null && <div className="tx-14">
              Nickname:{" "}
              <span className="text-capitalize">{dependent.nickname}</span>
            </div>}
            <div className="tx-14">
              Relation:{" "}
              <span className="text-capitalize">{dependent.relation}</span>
            </div>
            <div className="tx-14">Date of birth: {dob}</div>
          </li>
        );
      } else {
        return null;
      }
    });
    if (!dependents) {
      depList = <li>No dependents listed</li>;
    }

    let censoredTransitNo = null;
    let censoredBankNo = null;
    let censoredAccountNo = null;
    if (this.state.employee.bankTransitNo != null) {
      censoredTransitNo =
        "***" +
        this.state.employee.bankTransitNo.substr(
          3,
          this.state.employee.bankTransitNo.length
        );
    }
    if (this.state.employee.bankNo != null) {
      censoredBankNo =
        "**" +
        this.state.employee.bankNo.substr(2, this.state.employee.bankNo.length);
    }
    if (this.state.employee.bankAccountNo != null) {
      censoredAccountNo =
        "****" +
        this.state.employee.bankAccountNo.substr(
          4,
          this.state.employee.bankAccountNo.length
        );
    }

    return (
      <div className="br-mainpanel">
        <div className="br-pagebody" style={{ maxWidth: 1250 }}>
          <div style={{marginBottom: 100}}>
            <div className="mg-l-40 mg-b-0 black br-section-wrapper mg-b-30 no-padding" style={{marginBottom: 30}}>
              <div className="card mg-t-35">
                <div className="card-header tx-medium tx-uppercase bg-white">
                  Account Information
                </div>
                {this.renderNoAddressModal()}
                <div className="card-body" style={{ paddingBottom: "0.5rem" }}>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Company
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.company.name}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Username / Email
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.email}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Date of Birth
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {moment(this.state.employee.dateOfBirth).format("LL")}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        First Name
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.firstName}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Last Name
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.lastName}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Nickname
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.nickname ? (
                          <span>{this.state.employee.nickName}</span>
                        ) : (
                          <span> -- </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Maiden Name
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.maidenName ? (
                          <span>{this.state.employee.maidenName}</span>
                        ) : (
                          <span> -- </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.allowDependentEdit === false && (
                  <div className="card-body" style={{ paddingTop: 0 }}>
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          Dependents
                        </label>
                        <div className="tx-16-force">
                          <ul style={{listStyle:"inherit"}}>{depList}</ul>
                        </div>
                        <div className="tx-12 pd-l-10">
                          To update your dependent information, please contact
                          your Plan Admin.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mg-l-40 mg-b-0 black br-section-wrapper mg-b-30 no-padding" style={{marginBottom: 30}}>
              <div className="card mg-t-35">
                <div className="card-header tx-medium tx-uppercase bg-white">
                  General
                </div>
                <div className="card-body" style={{ paddingBottom: "0.5rem" }}>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Category
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.benefitCategory.name}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Coverage
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.empYear.coverage}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Hire Date
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.hireDate ? (
                          <span>
                            {moment(this.state.employee.hireDate).format("LL")}
                          </span>
                        ) : (
                          <span> -- </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Waiting Period
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.waitingPeriodMonths} months
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Pro-rating
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.empYear.hasProrating ? (
                          <span>Enabled</span>
                        ) : (
                          <span>Disabled</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Benefit Start Date
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.dynamicEmployeeStartDate ? (
                          <span>
                            {moment(
                              this.state.dynamicEmployeeStartDate
                            ).format("LL")}
                          </span>
                        ) : (
                          <span> -- </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Status
                      </label>
                      <div className="tx-16-force pd-l-10">
                        {this.state.employee.isActive ? (
                          <span>Active</span>
                        ) : (
                          <span>Inactive</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-flex p-2 mg-r-25">
                    <div className="form-group">
                      <label className="form-control-label tx-semibold">
                        Two Factor Authentication
                      </label>
                      <div className="tx-16-force pd-l-10">
                        <input
                          type="checkbox"
                          onChange={this.handleCheckBox.bind(this)}
                          checked={this.state.twoFactor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mg-l-40 mg-b-0 black br-section-wrapper mg-b-30 no-padding" style={{marginBottom: 30}}>
                <div className="card mg-t-35">
                  <div className="card-header tx-medium tx-uppercase bg-white">
                    Address Information
                    <span className="pd-l-25">
                      {this.state.allowEditAddress ? (
                        <span>
                          <button
                            className="btn btn-primary"
                            onClick={this.handleAddressSave}
                            style={{marginLeft:10}}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-light"
                            onClick={this.handleCancel}
                            style={{marginLeft:10}}
                          >
                            Cancel
                          </button>
                        </span>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={this.handleEditAddress}
                          style={{marginLeft:10}}
                        >
                          Edit
                        </button>
                      )}
                    </span>
                  </div>
                  <div
                    className="card-body"
                    style={{ paddingBottom: "0.5rem" }}
                  >
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          Mailing Street 1{" "}
                          {this.state.allowEditAddress ? (
                            <span className="tx-danger">*</span>
                          ) : null}
                        </label>
                        {this.state.errors != null ? (
                          <div className="text-danger pd-b-5">
                            {this.state.errors["MailingStreet1"] ? (
                              <div>{this.state.errors["MailingStreet1"]}</div>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.allowEditAddress ? (
                          <input
                            className="form-control"
                            type="text"
                            name="mailingStreet1"
                            value={this.state.employee.mailingStreet1 || ""}
                            onChange={this.handleChange.bind(this)}
                          />
                        ) : (
                          <div className="tx-16-force pd-l-10">
                            {this.state.employee.mailingStreet1}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          Mailing Street 2
                        </label>
                        {this.state.errors != null ? (
                          <div className="text-danger pd-b-5">
                            {this.state.errors["MailingStreet2"] ? (
                              <div>{this.state.errors["MailingStreet2"]}</div>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.allowEditAddress ? (
                          <input
                            className="form-control"
                            type="text"
                            name="mailingStreet2"
                            value={this.state.employee.mailingStreet2 || ""}
                            onChange={this.handleChange.bind(this)}
                          />
                        ) : (
                          <div className="tx-16-force pd-l-10">
                            {this.state.employee.mailingStreet2 ? (
                              <span>{this.state.employee.mailingStreet2}</span>
                            ) : (
                              <span> -- </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          City{" "}
                          {this.state.allowEditAddress ? (
                            <span className="tx-danger">*</span>
                          ) : null}
                        </label>
                        {this.state.errors != null ? (
                          <div className="text-danger pd-b-5">
                            {this.state.errors["MailingCity"] ? (
                              <div>{this.state.errors["MailingCity"]}</div>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.allowEditAddress ? (
                          <input
                            className="form-control"
                            type="text"
                            name="mailingCity"
                            value={this.state.employee.mailingCity || ""}
                            onChange={this.handleChange.bind(this)}
                          />
                        ) : (
                          <div className="tx-16-force pd-l-10">
                            {this.state.employee.mailingCity}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          Province{" "}
                          {this.state.allowEditAddress ? (
                            <span className="tx-danger">*</span>
                          ) : null}
                        </label>
                        {this.state.errors != null ? (
                          <div className="text-danger pd-b-5">
                            {this.state.errors["MailingProv"] ? (
                              <div>{this.state.errors["MailingProv"]}</div>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.allowEditAddress ? (
                          <select
                            className="form-control"
                            name="mailingProv"
                            value={this.state.employee.mailingProv}
                            onChange={this.handleChange.bind(this)}
                          >
                            <option selected>-Select-</option>
                            <option value="AB">Alberta</option>
                            <option value="BC">British Columbia</option>
                            <option value="MB">Manitoba</option>
                            <option value="NB">New Brunswick</option>
                            <option value="NL">
                              Newfoundland and Labrador
                            </option>
                            <option value="NS">Nova Scotia</option>
                            <option value="ON">Ontario</option>
                            <option value="PE">Prince Edward Island</option>
                            <option value="QC">Quebec</option>
                            <option value="SK">Saskatchewan</option>
                            <option value="NT">Northwest Territories</option>
                            <option value="NU">Nunavut</option>
                            <option value="YT">Yukon</option>
                          </select>
                        ) : (
                          <div className="tx-16-force pd-l-10">
                            {this.state.employee.mailingProv}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          Postal Code{" "}
                          {this.state.allowEditAddress ? (
                            <span className="tx-danger">*</span>
                          ) : null}
                        </label>
                        {this.state.errors != null ? (
                          <div className="text-danger pd-b-5">
                            {this.state.errors["MailingPostalCode"] ? (
                              <div>
                                {this.state.errors["MailingPostalCode"]}
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.allowEditAddress ? (
                          <input
                            className="form-control"
                            type="text"
                            name="mailingPostalCode"
                            placeholder="A1A 1A1"
                            value={this.state.employee.mailingPostalCode || ""}
                            onChange={this.handleChange.bind(this)}
                          />
                        ) : (
                          <div className="tx-16-force pd-l-10">
                            {this.state.employee.mailingPostalCode}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          Home Phone
                        </label>
                        {this.state.errors != null ? (
                          <div className="text-danger pd-b-5">
                            {this.state.errors["HomePhone"] ? (
                              <div>{this.state.errors["HomePhone"]}</div>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.allowEditAddress ? (
                          <input
                            className="form-control"
                            type="number"
                            name="homePhone"
                            value={this.state.employee.homePhone}
                            onChange={this.handleChange.bind(this)}
                          />
                        ) : (
                          <div className="tx-16-force pd-l-10">
                            {this.state.employee.homePhone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-inline-flex p-2 mg-r-25">
                      <div className="form-group">
                        <label className="form-control-label tx-semibold">
                          Mobile Phone
                        </label>
                        {this.state.errors != null ? (
                          <div className="text-danger pd-b-5">
                            {this.state.errors["MobilePhone"] ? (
                              <div>{this.state.errors["MobilePhone"]}</div>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.allowEditAddress ? (
                          <input
                            className="form-control"
                            type="number"
                            name="mobilePhone"
                            value={this.state.employee.mobilePhone}
                            onChange={this.handleChange.bind(this)}
                          />
                        ) : (
                          <div className="tx-16-force pd-l-10">
                            {this.state.employee.MobilePhone ? (
                              <span>{this.state.employee.mobilePhone}</span>
                            ) : (
                              <span> -- </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.allowDependentEdit === true && (
                <div className="mg-l-40 mg-b-0 black br-section-wrapper mg-b-30 no-padding" style={{marginBottom: 30}}>
                  <div className="card mg-t-35">
                    <div className="card-header tx-medium tx-uppercase bg-white">
                      Dependents
                      <span className="pd-l-25">
                        <button
                          className="btn btn-primary"
                          onClick={this.showAddDependentModal}
                          style={{marginLeft:10}}
                        >
                          Add
                        </button>
                      </span>
                    </div>
                    {this.dependentsTable()}
                    {this.renderAddNewDependentModal()}
                    {this.renderEditDependentModal()}
                  </div>
                </div>
              )}

              <div className="mg-l-40 mg-b-0 black br-section-wrapper mg-b-30 no-padding" style={{marginBottom: 30}}>
                <div className="card mg-t-35">
                  <div className="card-header tx-medium tx-uppercase bg-white">
                    Banking Information
                    <span className="pd-l-25">
                      {this.state.allowEditBanking ? (
                        <span>
                          <button
                            className="btn btn-primary"
                            onClick={this.handleBankingSave}
                            style={{marginLeft:10}}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-light"
                            onClick={this.handleCancel}
                            style={{marginLeft:10}}
                          >
                            Cancel
                          </button>
                        </span>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={this.handleEditBanking}
                          style={{marginLeft:10}}
                        >
                          Edit
                        </button>
                      )}
                    </span>
                  </div>

                  <div className="card-body" style={{ paddingTop: "0.5rem" }}>
                    <div
                      className="d-inline-flex p-2 mg-r-25"
                      style={{ maxWidth: 1000 }}
                    >
                      {this.state.errors != null ? (
                        <div className="text-danger pd-b-15">
                          {this.state.errors["BankTransitNo"] ? (
                            <div>{this.state.errors["BankTransitNo"]}</div>
                          ) : null}
                          {this.state.errors["BankNo"] ? (
                            <div>{this.state.errors["BankNo"]}</div>
                          ) : null}
                          {this.state.errors["BankAccountNo"] ? (
                            <div>{this.state.errors["BankAccountNo"]}</div>
                          ) : null}
                          {this.state.errors["ConfirmBankingInfo"] ? (
                            <div>{this.state.errors["ConfirmBankingInfo"]}</div>
                          ) : null}
                        </div>
                      ) : null}
                      <div>
                        <div className="mg-b-0 form-width-custom">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="form-control-label tx-semibold">
                                  Transit Number{" "}
                                  {this.state.allowEditBanking ? (
                                    <span className="tx-danger">*</span>
                                  ) : null}
                                </label>
                                {this.state.allowEditBanking ? (
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="bankTransitNo"
                                    value={
                                      this.state.employee.bankTransitNo || ""
                                    }
                                    onChange={this.handleChange.bind(this)}
                                  />
                                ) : (
                                  <div className="tx-16-force pd-l-10">
                                    {censoredTransitNo}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="form-control-label tx-semibold">
                                  Bank Number{" "}
                                  {this.state.allowEditBanking ? (
                                    <span className="tx-danger">*</span>
                                  ) : null}
                                </label>
                                {this.state.allowEditBanking ? (
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="bankNo"
                                    value={this.state.employee.bankNo || ""}
                                    onChange={this.handleChange.bind(this)}
                                  />
                                ) : (
                                  <div className="tx-16-force pd-l-10">
                                    {censoredBankNo}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="form-control-label tx-semibold">
                                  Account Number{" "}
                                  {this.state.allowEditBanking ? (
                                    <span className="tx-danger">*</span>
                                  ) : null}
                                </label>
                                {this.state.allowEditBanking ? (
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="bankAccountNo"
                                    value={
                                      this.state.employee.bankAccountNo || ""
                                    }
                                    onChange={this.handleChange.bind(this)}
                                  />
                                ) : (
                                  <div className="tx-16-force pd-l-10">
                                    {censoredAccountNo}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {this.state.allowEditBanking ? (
                            <div>
                              <div className="pd-y-10 tx-bold">
                                Confirm your banking information
                              </div>
                              {this.state.errors != null ? (
                                <div className="text-danger pd-b-15">
                                  {this.state.errors["ConfirmBankTransitNo"] ? (
                                    <div>
                                      {
                                        this.state.errors[
                                          "ConfirmBankTransitNo"
                                        ]
                                      }
                                    </div>
                                  ) : null}
                                  {this.state.errors["ConfirmBankNo"] ? (
                                    <div>
                                      {this.state.errors["ConfirmBankNo"]}
                                    </div>
                                  ) : null}
                                  {this.state.errors["ConfirmBankAccountNo"] ? (
                                    <div>
                                      {
                                        this.state.errors[
                                          "ConfirmBankAccountNo"
                                        ]
                                      }
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="form-control-label tx-semibold">
                                      Transit Number{" "}
                                      <span className="tx-danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="confirmBankTransitNo"
                                      value={
                                        this.state.confirmBank
                                          .confirmBankTransitNo
                                      }
                                      onChange={this.handleConfirmBank.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="form-control-label tx-semibold">
                                      Bank Number{" "}
                                      <span className="tx-danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="confirmBankNo"
                                      value={
                                        this.state.confirmBank.confirmBankNo
                                      }
                                      onChange={this.handleConfirmBank.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="form-control-label tx-semibold">
                                      Account Number{" "}
                                      <span className="tx-danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="confirmBankAccountNo"
                                      value={
                                        this.state.confirmBank
                                          .confirmBankAccountNo
                                      }
                                      onChange={this.handleConfirmBank.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
