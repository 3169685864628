import axios from "axios";
import React from "react";
import { Button, Table } from "react-bootstrap";
import toastr from "toastr";
import { CustomLoading } from "components/CustomLoading";
import "../../styles/BenefitCategory.css";
import BencatRecordScreen from "enroll/bencat/Record_New";

export default class Benefit_Categories_New extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      benefitCategories: [],
      expenseTypes: [],
      loadExpenseType: false,
      bencat_id: null,
    };

    this.fetchBenefitCategories = this.fetchBenefitCategories.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true, loadExpenseType: false });
    this.fetchBenefitCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loadExpenseType === this.props.reload) {
      this.setState({ loadExpenseType: false });
      this.fetchBenefitCategories();
    }
  }

  fetchBenefitCategories = () => {
    var apiString: string;
    apiString = `/api/BenefitCategories/getbenefitcategories?policyId=${this.props.policy_id}&benyear_id=${this.props.benyear_id}`;
    axios
      .get(apiString)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            benefitCategories: res.data.benefitCategories,
            loading: false,
          });
        } else {
          toastr.error(res.data.message, "Error");
        }
      })
      .catch((error) => {
        toastr.error(error, "Error");
      });
  };

  fetchExpenseTypes = (limit_id) => {
    var apiString: string;
    apiString = `/api/BenefitCategories/getexpensetypes?policyId=${this.props.policy_id}&limit_id=${limit_id}`;
    axios
      .get(apiString)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            expenseTypes: res.data.expenseTypes,
            loading: false,
          });
        } else {
          toastr.error(res.data.message, "Error");
        }
      })
      .catch((error) => {
        toastr.error(error, "Error");
      });
  };

  setSelectedOption = (option: any) => {
    this.setState({ selectedOption: option });
  };

  editBenCat = (id) => {
    this.setState({ loadExpenseType: true, bencat_id: id });
  };

  render() {
    let contents = null;
    if (this.state.loading) {
      contents = <CustomLoading />;
    } else if (this.state.loadExpenseType) {
      contents = (
        <BencatRecordScreen
          bencat_id={this.state.bencat_id}
          policy_id={this.props.policy_id}
          benyear_id={this.props.benyear_id}
        ></BencatRecordScreen>
      );
    } else {
      contents = (
        <div className="RT-DataTable RT-DataTable--pull-padding ">
          <Table className="benefit-category-table">
            <thead className="custome-thead">
              <tr className="benefit-category-table-container custom-tr">
                <th
                  style={{
                    width: "200px", 
                    inlineSize: "200px", 
                    overflow: "hidden"
                  }}
                >
                  Name
                </th>
                <th>CreditCalcMethod</th>
                <th>Has HSA</th>
                <th>Has LSA</th>
                <th>HSA Single Limit</th>
                <th>HSA Couple Limit</th>
                <th>HSA Family Limit</th>
                <th>LSA Single Limit</th>
                <th>LSA Couple Limit</th>
                <th>LSA Family Limit</th>
                <th>Allow LSA Dependent Claim</th>
                <th>Healthclaim Prorated</th>
                <th>Expense Types</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody className="RT-DataTable__body custom-tbody">
              {this.state.benefitCategories.map((item, index) => (
                <tr
                  key={index}
                  className="RT-DataTable__row benefit-category-column custom-tr"
                >
                  <td style={{ 
                    width: "200px",
                    inlineSize: "200px", 
                    whiteSpace: "normal",
                    }}
                  >
                    {item.name}
                  </td>
                  <td>{item.creditCalcMethod}</td>
                  <td>
                    {item.hasHSA === true ? this.renderCheckmark() : "No"}
                  </td>
                  <td>
                    {item.hasLSA === true ? this.renderCheckmark() : "No"}
                  </td>
                  <td>{item.hsaSingleLimit}</td>
                  <td>{item.hsaCoupleLimit}</td>
                  <td>{item.hsaFamilyLimit}</td>
                  <td>{item.lsaSingleLimit}</td>
                  <td>{item.lsaCoupleLimit}</td>
                  <td>{item.lsaFamilyLimit}</td>
                  <td>
                    {item.allowLSADependentClaims === true
                      ? this.renderCheckmark()
                      : "No"}
                  </td>
                  <td>
                    {item.hasProrating === true ? this.renderCheckmark() : "No"}
                  </td>
                  <td>
                    <button
                      className="RT-Button RT-Button--primary"
                      onClick={() => this.fetchExpenseTypes(item.limit_Id)}
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <button
                      className="RT-Button RT-Button--primary"
                      onClick={() => this.editBenCat(item.benCat_Id)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {this.state.expenseTypes && (
            <Table className="expense-type-table">
              <thead className="RT-DataTable__header benefit-category-table-container">
                <tr className="custom-tr">
                  <th>AccountType</th>
                  <th>Code</th>
                  <th>CoverageRate</th>
                  <th>ShortName</th>
                </tr>
              </thead>
              <tbody className="RT-DataTable__body custom-tbody">
                {this.state.expenseTypes.map((item, index) => (
                  <tr
                    key={index}
                    className="RT-DataTable__row benefit-category-column custom-tr"
                  >
                    <td>{item.accountType}</td>
                    <td>{item.code}</td>
                    <td>{item.coverageRate}</td>
                    <td>{item.shortName}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      );
    }

    return <>{contents}</>;
  }

  renderCheckmark() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        style={{
          display: "inline-block",
          width: 20,
          height: 20,
          fill: "var(--rt-primary-color)",
        }}
      >
        <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
      </svg>
    );
  }
}
