import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  Layout,
  TabSet,
  Tab,
  RecordTabs,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Display, Button, EmptyState } from "ktm-ui";
import Ben_limitRecordScreen from "enroll/ben_limit/Record";
import Ben_limitAddScreen from "enroll/ben_limit/Add";
import { Renderer } from "react-dom";

interface IdsBenefits {
  Id: string;
  Policy_Id: string;
  PolicyBenefit_Id: string;
  BenefitCategory_Id: string;
  BenefitYear_Id: string;
  Name: string;
}

export default KTMScreen(
  { name: "enroll.bencat.benefits" },
  class extends React.Component<{
    bencat_id: string;
    policy_id: string;
    benyear_id: string;
  }> {
    data: {
      dsBenefits: IdsBenefits;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefits";
      await KTMApi.start(screen, {
        "@bencat_id": this.props.bencat_id,
        "@policy_id": this.props.policy_id,
        "@benyear_id": this.props.benyear_id,
      });
      screen.title = "Benefits";
    }

    main({ field, draw, action, collection }: RenderEvent) {
      var collectionLength = collection("@dsBenefits").rows.length;
      return collectionLength ? (
        <Layout>{draw(this.tabset)}</Layout>
      ) : (
        <Layout>{draw(this.showEmpty)}</Layout>
      );
    }

    tabset({ action, collection }: RenderEvent) {
      return (
        <RecordTabs
          collection={collection("@dsBenefits")}
          template="tabContent"
        />
      );
    }

    showEmpty({ action, collection }: RenderEvent) {
      return (
        <EmptyState label="Benefit details" icon="bill">
          <div style={{ width: "50%", textAlign: "center" }}>
            <div style={{ marginBottom: 15 }}>
              No Benefit details (limits, expenses) created yet for this Benefit
              category
            </div>
          </div>
          <Button
            action={action(this.add)}
            label="Add Benefit"
            style="primary"
          />
        </EmptyState>
      );
    }

    tabContent({ getValue, action }: RenderEvent) {
      return (
        <Ben_limitRecordScreen
          policy_id={getValue("Policy_Id")}
          limit_id={getValue("Id")}
        />
      );
    }

    empty_state({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState icon="bill">
          <div style={{ width: "50%", textAlign: "center" }}>
            <div style={{ marginBottom: 15 }}>
              No Benefit details (limits, expenses) created yet for this Benefit
              category
            </div>
          </div>
          <Button
            action={action(this.add)}
            label="Add Benefit"
            style="primary"
          />
        </EmptyState>
      );
    }

    async add({ screen }: ActionEvent) {
      await screen.openDialog(
        <Ben_limitAddScreen
          policy_id={this.props.policy_id}
          bencat_id={this.props.bencat_id}
          benyear_id={this.props.benyear_id}
        />,
        { onRecordChanged: this.after_new }
      );
    }

    async after_new({ screen, eventValue }: ActionEvent) {
      await KTMApi.refreshRow(screen, {
        collection: "@dsBenefits",
        rowid: eventValue,
        appendNewRows: true,
      });
      screen.locate("@dsBenefits", eventValue);
    }
  }
);
