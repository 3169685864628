import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const DoughnutChart = ({ id , data}) => {
    const ref = useRef();
    const tooltipId = `chart-tooltip-${id}`;
    const [showDetails, setShowDetails] = useState(true);

    useEffect(() => {
        const width = 250;
        const height = 250;
        const radius = Math.min(width, height) / 2;

        d3.select(ref.current).selectAll("*").remove();

        const svg = d3.select(ref.current)
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const arc = d3.arc()
            .innerRadius(radius - 30)
            .outerRadius(radius);

        const totalUsage = data.reduce((acc, item) => acc + (item.limit - item.balance), 0).toFixed(2);
        const totalBalance = data.reduce((acc, item) => acc + item.balance, 0).toFixed(2);

        const transformedData = [
            ...data.map(item => ({ ...item, value: item.balance, type: 'balance' })),
            { name: 'Total Usage', value: totalUsage, type: 'used' }
        ];

        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("dy", "0em")
            .style("font-size", "20px")
            .style("font-weight", "bold") 
            .text(`$${totalBalance}`)
            .append("tspan")
            .attr("x", 0)
            .attr("dy", "1.2em")
            .style("font-size", "20px")
            .style("font-weight", "bold")
            .text("Available");

        const pie = d3.pie()
            .value(d => d.value)
            .sort(null)
            .startAngle(0)   // start from 3 o'clock
            .endAngle(2 * Math.PI);

        let tooltip = d3.select(`#${tooltipId}`);

        svg.selectAll('path')
            .data(pie(transformedData))
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', function(d) {
                if (d.data.type === 'balance') {
                    return d.data.backgroundColor;
                } else {
                    return '#cccccc';
                }
            })
            .on('mouseover', (event, d) => {
                const [x, y] = arc.centroid(d);

                if (d.data.type === 'used') {
                    const totalUsage = d.data.value;
                    tooltip
                        .style('visibility', 'visible')
                        .html(`Total Usage: ${totalUsage}`)
                        .style('left', `${x + width / 2}px`)
                        .style('top', `${y + height / 2}px`);
                }
                else
                {
                    const name = d.data.name;
                    const limit = d.data.limit;
                    const balance = d.data.balance;
    
                    tooltip
                        .style('visibility', 'visible')
                        .html(`
                            ${name}<br>
                            Limit: ${limit}<br>
                            Available: ${balance}<br>
                        `)
                        .style('left', `${x + width / 2}px`)
                        .style('top', `${y + height / 2}px`);
                }
            })
            .on('mouseout', () => {
                tooltip.style('visibility', 'hidden');
            });

    }, [data]);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const totalLimit = data.reduce((acc, item) => acc + item.limit, 0).toFixed(2);
    const totalBalance = data.reduce((acc, item) => acc + item.balance, 0).toFixed(2);

    return (
        <>
            <svg ref={ref} id={`doughnut-svg-${id}`}></svg>
            {data.length > 1 && (
                <>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <button 
                            className="btn" 
                            onClick={toggleDetails}
                            style={{
                                borderRadius: 45,
                                backgroundColor: "#ffffff",
                                borderColor:data[0].backgroundColor,
                                color:data[0].backgroundColor,
                                padding: "5px 10px 5px 10px"
                            }}
                        >
                            <span className="btn-label">{"Details"}</span>
                            <FontAwesomeIcon icon={showDetails ? faAngleUp : faAngleDown} className="ml-2 dropdown-icon"/>
                        </button>
                    </div>
                    <div id={tooltipId} 
                        style={{ 
                            position: 'absolute', 
                            visibility: 'hidden', 
                            backgroundColor: 'white', 
                            padding: '10px', 
                            border: '1px solid black', 
                            borderRadius: '4px', 
                            pointerEvents: 'none',
                            zIndex: 1,
                            color: "black"
                        }}
                    />
                </>
            )}
            {(showDetails && data.length > 1) && (
                <table 
                    style={{ 
                        marginTop: '30px',
                        marginBottom: '30px',
                        width: '100%', 
                        borderCollapse: 'collapse' 
                    }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ border: '1px solid black', color: 'black'}}>Limit</th>
                            <th style={{ border: '1px solid black', color: 'black' }}>Available</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td style={{ 
                                    color: item.backgroundColor, 
                                    textAlign: 'left',
                                    width: 120,
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    display: "block"
                                }}>{item.name}</td>
                                <td style={{ border: '1px solid black', backgroundColor: item.backgroundColor, color: item.textColor }}>${item.limit}</td>
                                <td style={{ border: '1px solid black', backgroundColor: item.backgroundColor, color: item.textColor }}>${item.balance}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ color: 'black', fontWeight: 500, textAlign: 'left'}}>Total</td>
                            <td style={{ border: '1px solid black', color: 'black', fontWeight: 500 }}>${totalLimit}</td>
                            <td style={{ border: '1px solid black', color: 'black', fontWeight: 500 }}>${totalBalance}</td>
                        </tr>
                    </tfoot>
                </table>
            )}
            
        </>
    );
};

export default DoughnutChart;
