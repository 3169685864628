import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsDependent {
  Id: string;
  Employee_Id: string;
  FirstName: string;
  LastName: string;
  MaidenName: string;
  Nickname: string;
  DateOfBirth: string;
  Relation: string;
  IsHidden: boolean;
}

export default KTMScreen(
  { name: "enroll.employee.add-dependent" },
  class extends React.Component<{ employee_id: string, dependents:any }> {
    data: {
      dsDependent: IdsDependent;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsDependent";
      await KTMApi.start(screen, {
        "@employee_id": this.props.employee_id,
      });
      screen.title = "Add dependent";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout dialogSize="medium">
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Add dependent" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      let dependentSubmitButton = null;
      const newDependent = this.data.dsDependent;

      const isDuplicate = this.props.dependents.some(dependent =>
        dependent.FirstName === newDependent.FirstName &&
        dependent.LastName === newDependent.LastName &&
        dependent.DateOfBirth === newDependent.DateOfBirth
      );

      if (
        newDependent.FirstName === undefined ||
        newDependent.FirstName === "" ||
        newDependent.LastName === undefined ||
        newDependent.LastName === "" ||
        newDependent.Relation === undefined ||
        newDependent.Relation === "-- Select --" ||
        newDependent.DateOfBirth === undefined ||
        newDependent.DateOfBirth === "" ||
        isDuplicate
      ) {
        dependentSubmitButton = (
          <button disabled className="btn btn-secondary">
            Save
          </button>
        );
      } else {
        dependentSubmitButton = (
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        );
      }

      return <Dialog.Footer>{dependentSubmitButton}</Dialog.Footer>;
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display required field={field("FirstName")} />
            <Display required field={field("LastName")} />
            <Display field={field("MaidenName")} />
            <Display field={field("Nickname")} />
          </Line>
          <Line>
            <Display
              required
              field={field("Relation")}
              toolTip={
                <CustomToolTip
                  header="Relation"
                  bodyContent="A Spouse has no age restrictions for coverage, while a Child is only eligible up to a maximum age determined by the plan setup. Unconditional dependents are non-Spouse dependents with no age restrictions. Follow CRA guidelines to determine if a dependent qualifies as Unconditional."
                />
              }
            />
            <Display required field={field("DateOfBirth")} />
          </Line>
          <Display
            field={field("IsHidden")}
            toolTip={
              <CustomToolTip
                header="Is Hidden"
                bodyContent="If checked, this dependent is no longer visible to the employee. The dependent entry must be kept for record keeping purposes, but only plan administrators and NHC can see a dependent who has been hidden"
              />
            }
          />
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@employee_id": this.props.employee_id,
        "@dsDependent.FirstName": this.data.dsDependent.FirstName,
        "@dsDependent.LastName": this.data.dsDependent.LastName,
        "@dsDependent.MaidenName": this.data.dsDependent.MaidenName,
        "@dsDependent.Nickname": this.data.dsDependent.Nickname,
        "@dsDependent.DateOfBirth": this.data.dsDependent.DateOfBirth,
        "@dsDependent.Relation": this.data.dsDependent.Relation,
        "@dsDependent.IsHidden": this.data.dsDependent.IsHidden,
      });
      screen.close({ continue: true });
    }
  }
);
