import axios from "axios";
import { Card, Line } from "ktm-ui";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import toastr from "toastr";
import { CustomDropdown } from "./CustomDropdown";
import "../styles/CustomComponents.css";

const EditPlanAdminSettingModal = ({policyId, paData, updatePA}) => {
    const [show, setShow] = useState(false);
    const [hideOnEE, setHideOnEE] = useState(paData.hideOnEE);
    const [receiveEmail, setReceiveEmail] = useState(paData.receiveEmail);
    const [groupPA, setGroupPA] = useState(paData.sendGroupPAEmail);
    const [individualPA, setIndividualPA] = useState(paData.sendIndividualPAEmail);
    const [role, setRole] = useState(paData.role);
    const [showCustomCatReport, setShowCustomCatReport] = useState(paData.showCustomCatReport)
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleSave = () => {
      const data = {
        PolicyId: policyId,
        UserId: paData.id,
        HideOnEE: hideOnEE,
        ReceiveEmail: receiveEmail,
        SendGroupPAEmail: groupPA,
        SendIndividualPAEmail: individualPA,
        Role: role,
        ShowCustomCatReport: showCustomCatReport
      };
      axios
        .put("/api/assignplanadmin/updatepasetting", data)
        .then((res) => {
          if (res.data.success) {
            toastr.success("Plan Admin Setting Updated");
            updatePA(res.data.data);
            handleClose();
          } else {
            toastr.error("Error");
          }
        })
        .catch((err) => {
          toastr.error("Error");
        });
    };

    const handleIndividualPAChange = (event) => {
      const { checked } = event.target;
      setIndividualPA(checked);
      if (checked) {
        setGroupPA(false);
      }
    };
    
    const handleGroupPAChange = (event) => {
      const { checked } = event.target;
      setGroupPA(checked);
      if (checked) {
        setIndividualPA(false);
      }
    };
    
    const handleShowCustomCatReport = (event) => {
        setShowCustomCatReport(!showCustomCatReport);
    }

    return (
      <>
        <a href="#" className="RT-Link" onClick={handleShow}>
          Edit
        </a>
        <Modal show={show} onHide={handleClose} className="edit-planadmin-setting-modal">
          <Modal.Header closeButton>
            <Modal.Title>Edit Plan Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Line>
                <div className="checkbox-container">
                  <input type="checkbox"
                    checked={hideOnEE}
                    onChange={() => setHideOnEE(!hideOnEE)}
                  />
                  <label>Hide on EE Portal</label>
                </div>
                <div className="checkbox-container">
                  <input type="checkbox" 
                    checked={receiveEmail}
                    onChange={() => setReceiveEmail(!receiveEmail)}
                  />
                  <label>Receive Email</label>
                </div>
              </Line>
              <Line>
                <div className="checkbox-container">
                  <input type="checkbox" 
                    checked={groupPA}
                    onChange={handleGroupPAChange}
                  />
                  <label>Group PA</label>
                </div>
                <div className="checkbox-container">
                  <input type="checkbox" 
                    checked={individualPA}
                    onChange={handleIndividualPAChange}
                  />
                  <label>Individual PA</label>
                </div>
              </Line>
              <Line>
                <div className="checkbox-container">
                  <input type="checkbox" 
                    checked={showCustomCatReport}
                    onChange={handleShowCustomCatReport}
                  />
                  <label>Show Enhanced Benefit Report</label>
                </div>
              </Line>
            </Card>
            <Card>
              <div className="pa-role-dropdown-container">
                <label>Role</label>
                <CustomDropdown 
                  options={["--Select--", "Primary", "Secondary"]} 
                  selectedOption={role == null ? "--Select--" : role} 
                  setSelectedOption={(val) => {
                    if (val === "--Select--") {
                      val = null;
                    }
                    setRole(val);
                  }}
                />
              </div>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  
  export default EditPlanAdminSettingModal;