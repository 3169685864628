import Record from "enroll/division/Record";
import { Canvas, RecordMeta } from ".";
import reportWebVitals from "../../reportWebVitals";
import { IRecordSchema, Schema } from "./Schema";


 export interface ICollectionSortField {
    name: string;
    descending?: boolean;
}

 type CollectionArray = any[] & {$$meta?:ICollectionMeta};


 export interface ICollectionMeta {
     version:number;
     schema:IRecordSchema;
 }

 export class Collection {

   
     public static incrementVersion(rows:CollectionArray){
         if (rows){
             if (rows.$$meta){
                rows.$$meta.version++;
             }
             else {
                 rows.$$meta = {version:1,schema:null};
             }
         }
     }

     public static getVersion(rows:CollectionArray):number{
         if (rows && rows.$$meta){
             return rows.$$meta.version;
         }
     }

     public static getSchema(rows:CollectionArray):IRecordSchema{
        if (rows && rows.$$meta){
            return rows.$$meta.schema;
        }
    }

     public static replace(current:CollectionArray,newArray:CollectionArray,schema:IRecordSchema):CollectionArray{
         if (current && current.$$meta){
             newArray.$$meta = current.$$meta;
             Collection.incrementVersion(newArray);
         }
         else {
             newArray.$$meta = {version:1,schema};
         }
         return newArray;
     }

     static sort(table:any[],schema:IRecordSchema,sortBy:ICollectionSortField[]){
        
        let instance = new CollectionSorter(table,schema,sortBy);
        instance.executeSort();
    }

    static isRowSelected(row:any):boolean {
        if (row && row.IsSelected) return true;
        return false;
    }

    static setRowSelected(row:any,value:boolean){
        if (row){
            row.IsSelected = value;
        }
    }

    static setCurrentRow(canvas:Canvas,collectionName:string,row:any){
        canvas.data[collectionName + "$current"] = row;
    }

    static getCurrentRow(canvas:Canvas,collectionName:string):any{
        return canvas.data[collectionName + "$current"];
    }
    static getRow(canvas:Canvas,collectionName:string,index:number):any {
        let col = canvas.data[collectionName.substr(1)];
        if (!col) return null;
        return col[index];
    }

    static bindSchema(schema:IRecordSchema,rows:any[]){
        if (!rows) return;
        for(let i =0 ; i < rows.length; i++){
            let row = rows[i];
            if (row){
                let meta:RecordMeta = row.$$meta;
                if (meta){
                    meta.schema = schema;
                }
                else {
                    meta = new RecordMeta();
                    meta.schema = schema;
                    row.$$meta = meta;
                }
            }
        }
    }

   



 }
 export class CollectionSorter {

    collection:any[];
    schema:IRecordSchema;
    sortBy:ICollectionSortField[];

   
    constructor(collection:any[],schema:IRecordSchema,sortBy:ICollectionSortField[]){
        this.collection = collection;
        this.schema = schema;
        this.sortBy = sortBy;
    }

    executeSort(){
        
        let comparers = this.buildComparers(this.schema,this.sortBy);
        let comparerCount = comparers.length;

        let compareRow = (rowA:any,rowB:any):number =>  {
            for (let i = 0; i < comparerCount;i++){
                let v = comparers[i].compare(rowA,rowB);
                if (v) return v;
            }
            return 0;
        }
        this.collection.sort(compareRow);
    }


    buildComparers(schema:IRecordSchema,sortBy:ICollectionSortField[]):IComparer[]{
        let comparers:IComparer[] = [];
        for(let i = 0; i < sortBy.length;i++){
            let sortItem = sortBy[i];
            let field = Schema.getFieldDef(schema,sortItem.name);
            if (field){
                if (field.type == "logical"){
                    comparers.push(new BooleanComparer(field.name,sortItem.descending));
                }
                else if (field.type == "decimal" || field.type == "integer" || field.type == "money"){
                    comparers.push(new NumberComparer(field.name,sortItem.descending));
                }
                else {
                    comparers.push(new StringComparer(field.name,sortItem.descending));
                }
            }
        }
        return comparers;
    }

}

interface IComparer {
    compare(rowA:any,rowB:any):number;
}

class StringComparer implements IComparer {
    public field:string;
    public descending:boolean;

    constructor(field:string,descending:boolean){
        this.field = field;
        this.descending = descending;
    }

    compare(rowA:any,rowB:any):number {
        let a:string;
        let b:string;
        if (this.descending){
            b = rowA[this.field];
            a = rowB[this.field];
        }
        else {
            a = rowA[this.field]
            b = rowB[this.field];
        }
        if (!a){
            if (!b) return 0;
            return -1
        }
        if (!b){
            return 1;
        }
        if (a.toLowerCase){
            a = a.toLowerCase();
        }
        if (b.toLowerCase){
            b = b.toLowerCase();
        }
       
        if (a > b) { return 1 }
        else if (a < b) { return -1 };
        return 0;
    
    }
}

class NumberComparer implements IComparer  {
    public field:string;
    public descending:boolean;

    constructor(field:string,descending:boolean){
        this.field = field;
        this.descending = descending;
    }


    compare(rowA:any,rowB:any):number {
        let a:number;
        let b:number;
        if (this.descending){
            b = rowA[this.field];
            a = rowB[this.field];
        }
        else {
            a = rowA[this.field];
            b = rowB[this.field];
        }
        if (!a){
            if (!b) return 0;
            return -1
        }
        if (!b){
            return 1;
        }

        if (a > b) { return 1 }
        else if (a < b) { return -1 };
        return 0;
    
    }
}

class BooleanComparer  implements IComparer {
    public field:string;
    public descending:boolean;

    constructor(field:string,descending:boolean){
        this.field = field;
        this.descending = descending;
    }


    compare(rowA:any,rowB:any):number {
        let a:boolean;
        let b:boolean;
        if (this.descending){
            b = rowA[this.field];
            a = rowB[this.field];
        }
        else {
            a = rowA[this.field];
            b = rowB[this.field];
        }
       
        if (a == b) return 0;
        if (a){
            return 1;
        }
        else {
            return -1;
        }
    
    }
}