import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, TimelineItem, Timeline} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Column,Panel,Display,Line,Card,Button} from 'ktm-ui';
import Create_next_yearScreen from './Create_next_year';
import Activate_next_yearScreen from './Activate_next_year';
import Close_previous_yearScreen from './Close_previous_year';

interface IdsPolicy {
    Id:string;
    PolicyNo:string;
    IsActive:boolean;
    PreviousBenYear_Id:string;
    CurrentBenYear_Id:string;
    NextBenYear_Id:string;
};

interface IdsCurrentYear {
    Id:string;
    Status:string;
    StartDate:string;
    EndDate:string;
    RunOffClosingDate:string;
    RunoffMonths:number;
};

interface IdsPreviousYear {
    Id:string;
    StartDate:string;
    EndDate:string;
    RunOffClosingDate:string;
    Status:string;
    RunoffMonths:number;
};

interface IdsNextYear {
    Id:string;
    StartDate:string;
    EndDate:string;
    RunOffClosingDate:string;
    Status:string;
    RunoffMonths:number;
};

export default KTMScreen({name:"planadmin.policy.year-dashboard"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsPolicy:IdsPolicy;
        dsCurrentYear:IdsCurrentYear;
        dsPreviousYear:IdsPreviousYear;
        dsNextYear:IdsNextYear;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsPolicy";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Details";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.timeline)}
                </Column>
            </Panel>
        );
    }

    current_year({field,draw,action}:RenderEvent){
        return (
            <Card label="Current year">
                <Line>
                    <Display field={field("@dsCurrentYear.StartDate")}/>
                    <Display field={field("@dsCurrentYear.EndDate")}/>
                    <Display field={field("@dsCurrentYear.RunOffClosingDate")}/>
                </Line>
            </Card>
        );
    }

    previous_year({field,draw,action}:RenderEvent){
        return (
            <Card label="Previous year">
                <Line>
                    <Display field={field("@dsPreviousYear.StartDate")}/>
                    <Display field={field("@dsPreviousYear.EndDate")}/>
                    <Display field={field("@dsPreviousYear.RunOffClosingDate")}/>
                </Line>
                <Display field={field("@dsPreviousYear.Status")} textStyle="bold"/>
                <Button action={action(this.close_previous_year)} label="Close" style="primary"/>
            </Card>
        );
    }

    next_year({field,draw,action}:RenderEvent){
        return (
            <Card label="Next year">
                <Line>
                    <Display field={field("@dsNextYear.StartDate")}/>
                    <Display field={field("@dsNextYear.EndDate")}/>
                    <Display field={field("@dsNextYear.RunoffMonths")}/>
                </Line>
                <Button action={action(this.activate_next_year)} label="Activate" style="primary"/>
            </Card>
        );
    }

    next_year_empty({field,draw,action}:RenderEvent){
        return (
            <Card label="Next year">
                <Button action={action(this.create_next_year)} label="Create next year" style="primary"/>
            </Card>
        );
    }

    timeline({field,draw,action,getValue}:RenderEvent){
        let previousYearIsActive = getValue("@dsPreviousYear.Status") == "OPEN";

        return (
           <Timeline>
               <TimelineItem isActive={previousYearIsActive} template={this.previous_year}/>
               <TimelineItem isActive={true} template={this.current_year}/>
               <TimelineItem isActive={false} template={this.next_year}/>
               <TimelineItem isActive={false} template={this.next_year_empty}/>
            </Timeline>
        );
    }

    async create_next_year({screen}:ActionEvent){
        await screen.openDialog(<Create_next_yearScreen policy_id={this.data.dsPolicy.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async activate_next_year({screen}:ActionEvent){
        await screen.openDialog(<Activate_next_yearScreen policy_id={this.data.dsPolicy.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async close_previous_year({screen}:ActionEvent){
        await screen.openDialog(<Close_previous_yearScreen policy_id={this.data.dsPolicy.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async refresh_data({screen}:ActionEvent){
        await screen.restart();
    }

});
