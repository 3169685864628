import {ScreenRef} from './ActionEvent';
import { Schema } from './Schema';

type commandHandler = (screen:ScreenRef,elem:any) => void | Promise<any>;
var map:{[name:string]:commandHandler} = {
    "LoadDropValues":loadDropValues,
    "Label":setLabel
}

type CommandElem = {$kind:string} & any;

export async function executeConfig(screen:ScreenRef,commands:CommandElem[]){
    if (!commands) return;
    let command:CommandElem;
    try {
        for(let i =0 ; i < commands.length;i++){
            command = commands[i];
            let func = map[command.$kind];
            if (func){
                let result = func(screen,command);
                if (result && result.then){
                    await result;
                }
            }
            else {
                throw "invalid command";
            }
        }
    }
    catch(e){
        throw "Error on command: " + command.$kind + " , " + e.toString();
    }
}


function loadDropValues(screen:ScreenRef,elem:any){
    let name = elem.name;
    let options = elem.data || [];

    let defaultOptionExists = options.some(option => option.value === null);
    if (!defaultOptionExists) {
        options.unshift({ value: null, label: "-- Select -- " });
    }

    let fieldDef = Schema.getQualifiedFieldDef(screen.canvas,name);
    if (fieldDef){
        fieldDef.options = options;
    }
}

function  setLabel(screen:ScreenRef,elem:any){
    let name = elem.name;
    let fieldDef = Schema.getQualifiedFieldDef(screen.canvas,name);
    if (fieldDef){
        fieldDef.label = elem.value;
    }
}



