import React from 'react';
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from 'ktm-ui';
import { KTMApi } from 'api/KTMApi';
import { Layout, DataGrid, IDataGridLayout, Display, Line, Column, Panel, Button, Card } from 'ktm-ui';
import AddScreen from './Add';
import RecordScreen from './Record';

interface IdsData {
    Id: string;
    Policy_Id: string;
    Employee_Id: string;
    PolicyBenefit_Id: string;
    BenefitCategory_Id: string;
    Coverage: string;
    HasProrating: boolean;
    BenefitName: string;
    CategoryName: string;
    BenefitYear: string;
};

export default KTMScreen({ name: "planadmin.employee-benefits.browse" }, class extends React.Component<{ employee_id: string }> {

    data: {
        dsData: IdsData[];
    }

    render() {
        return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
        screen.defaultRecord = "@dsData";
        await KTMApi.start(screen, {
            "@employee_id": this.props.employee_id,
        });
        screen.title = "Employee benefit limits";
    }

    main({ field, draw, action }: RenderEvent) {
        return (
            <Layout>
                {draw(this.default_view)}
            </Layout>
        );
    }

    new_default_view({ field, draw, action, collection }: RenderEvent) {
        return (
            <Card>
                <Line>
                    <Display field={field("Coverage")} />
                </Line>
            </Card>

        )
    };

    default_view({ field, draw, action, collection }: RenderEvent) {
        let actions = null;
        let layout: IDataGridLayout = {
            columns: [
                { name: "BenefitYear", label: "Benefit Year", type: "date", width: 100 },
                { name: "BenefitName", label: "Benefit", type: "character", width: 200 },
                { name: "CategoryName", label: "Category", type: "character", width: 300 },
                { name: "Coverage", label: "Coverage", type: "character" },
                { name: "HasProrating", label: "Prorate", type: "logical" },
            ],
            sort: [{ name: "BenefitYear", descending: true }, { name: "BenefitName" }]
        };
        return (
            <DataGrid collection={collection("@dsData")} layout={layout} onRowClick={action(this.open_row)} actions={actions} />
        );
    }

    async search({ screen }: ActionEvent) {
        await KTMApi.action(screen, "search", {
            "@employee_id": this.props.employee_id,
        });
    }

    async add({ screen }: ActionEvent) {
        await screen.openDialog(<AddScreen employee_id={this.props.employee_id} />
            , { onRecordChanged: this.refresh_row });
    }

    async open_row({ screen }: ActionEvent) {
        screen.browse(<RecordScreen employeeBenefit_id={screen.getValue("Id")} />, { label: "Employee benefit", onRecordChanged: this.refresh_row });
    }

    async refresh_row({ screen, eventValue }: ActionEvent) {
        await KTMApi.refreshRow(screen, {
            collection:
                "@dsData", rowid: eventValue
        });
    }

});
