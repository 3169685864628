const CustomDisplayElement = ({ label, toolTip, content }) => (
    <div className="RT-Static-Field">
        <label className="RT-Static-Field__label">
            {label}
            {toolTip}
        </label>
        <div className='RT-Static-Field__content'>
            {content}
        </div>
    </div>
);

export default CustomDisplayElement;
