import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button} from 'ktm-ui';

interface IdsBenefit {
    Id:string;
    Name:string;
};

export default KTMScreen({name:"enroll.product.remove-lsa"}, class extends React.Component<{policyBenefit_id:string}> {

    data:{
        dsBenefit:IdsBenefit;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenefit";
        await KTMApi.start(screen,{
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.title = "Dislable product LSA option";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                <div>LSA option will be removed for this product.</div>
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Dislable product LSA option"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Disable" style="destructive"/>
            </Dialog.Footer>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.close({"continue":true});
    }

});
