
import {Canvas} from './Canvas';
import React from 'react';
import ReactDOM from 'react-dom';
import {ComponentContextValue,ComponentContextType, ActionHandler} from './types';
import { ActionEvent } from './ActionEvent';
import { FieldRef } from './FieldRef';
import { RecordBrowse } from './RecordBrowse';
import { Application } from './Application';
import { ActionRef } from './ActionRef';
import { RenderEvent } from '.';

export type DialogResult = {continue:boolean,value?:any,label?:any};

export class Dialog {

    static nextKey = 1;

    static open(canvas:Canvas,dialog:any,onRecordChanged:ActionRef,layer:'dialog' | 'lookup'):Promise<DialogResult> {
        let zIndex = 9000;
        if (canvas.layer == "dialog" || canvas.layer == "lookup"){
            zIndex = canvas.layerZIndex + 10;
        }

        let elem = document.createElement("div");
        document.body.appendChild(elem);
        
        canvas.app.spinner.pause();
        let promise = new Promise<DialogResult>((resolve,reject) => {
            let onClose = (result:DialogResult)=> {
                ReactDOM.unmountComponentAtNode(elem);
                document.body.removeChild(elem);
               
                if (result.continue){
                    resolve(result);
                    canvas.app.spinner.resume();
                }
                else {
                    canvas.app.spinner.kill();
                    resolve(result);
                }
            }

            let $handleRecordChanged;
            if (onRecordChanged){
                $handleRecordChanged = async (action:string,id:any) => {
                    await canvas.triggerAction(onRecordChanged,{value:id});
                }
            }
            dialog = React.cloneElement(dialog,{key:Dialog.nextKey++,$layer:layer,$onClose:onClose,$layerZIndex:zIndex, $handleRecordChanged,$parent:canvas});
            let value:ComponentContextValue = {canvas: canvas};
            let content = <ComponentContextType.Provider value={value}>
                {dialog}
            </ComponentContextType.Provider>

            ReactDOM.render(
                content,
                elem
            );
        })
        return promise;
    }

    static async openLookup(field:FieldRef, eventValue:any, previous:any ,options:{isAutoLaunch?:boolean}):Promise<DialogResult>{
        let lookup = field.lookup;
        let lookupScreen:any;

        let props = field.canvas.getParamValues(lookup.params,{scope:field.scope}) || {};
        props.eventValue = eventValue;
        if (lookup.name){
            let ScreenComponent = Application.screens[lookup.name];
            if (!ScreenComponent) return;
            lookupScreen = React.createElement(ScreenComponent,props);
        }
        else if (lookup.template){
            let renderEvent = new RenderEvent(field.canvas,lookup.template);
            renderEvent.eventValue = eventValue;
            lookupScreen = renderEvent.draw(lookup.template,{scope:field.scope});
        }
        let result =  Dialog.open(field.canvas,lookupScreen,null,"lookup");
        return result;
    }
}

export class Stack {

    static nextKey = 1;
    static open(canvas:Canvas,stacked:any,onRecordChanged:ActionRef,options:{browse?:RecordBrowse,showStackContent?:(content:any) => void} = {}) {
       
        let $handleRecordChanged;
        if (onRecordChanged){
            $handleRecordChanged = async (action:string,id:any) => {
                await canvas.triggerAction(onRecordChanged,{value:id});
            }
        }
        stacked = React.cloneElement(stacked,{key:Stack.nextKey++,$layer:"stack",$handleRecordChanged,$parent:canvas});
       
    
        if (options.showStackContent){
            options.showStackContent(stacked);
            return;
        }

        let targetCanvas = Stack.getTargetCanvas(canvas);
        if (!targetCanvas) return;
        targetCanvas.stackChild = ()=> stacked;
        targetCanvas.recordBrowse = options.browse;
        if (targetCanvas != canvas){
            targetCanvas.update();
        }
    }

    private static getTargetCanvas(canvas:Canvas):Canvas {
        while (canvas){
            if (canvas.layer != "embedded") return canvas;
            canvas = canvas.parent;
        }
        return null;
    }


    static browse(canvas:Canvas,recordScreen:any,options:IBrowseOptions){

        let recordBrowse = new RecordBrowse();
        recordBrowse.triggerAction = options.triggerAction;
        recordBrowse.rows = options.rows;
        recordBrowse.currentIndex = options.rows.indexOf(options.current);
        recordBrowse.label = options.label;
        recordBrowse.onRecordChanged = options.onRecordChanged;
        Stack.open(canvas,recordScreen,options.onRecordChanged,{browse:recordBrowse});
    }
}

export interface IBrowseOptions {onRecordChanged?:ActionRef,label?:any,renderRecord?:(row:any)=> any,rows?:any[],current?:any,triggerAction?:ActionRef};