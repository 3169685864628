import React from 'react';
import { KTMScreen, ActionEvent, RenderEvent, renderScreen, Card } from 'ktm-ui';
import { KTMApi } from 'api/KTMApi';
import { Layout, Button, DataGrid, IDataGridLayout } from 'ktm-ui';
import EmployeeRecord from '../employee/Record';
import EmployeeBenefitsEdit from '../employee_benefits/Edit';
import ReactButton from "react-bootstrap/Button";
import { Modal } from 'react-bootstrap';

interface IdsData {
    Id: string;
    Policy_Id: string;
    FlexBase: number;
    HSABase: number;
    LSABase: number;
    Employee_Id: string;
    FirstName: string;
    LastName: string;
    WaitingPeriodMonths: number;
    BenefitStartDate: string;
    HireDate: string;
    HasProrating: boolean;
    Coverage: string;
    Benefit: string;
    Category: string;
    edit: string;
};

interface IdsErrors {
    Policy_Id: string;
    BatchType: string;
    Message: string;
    Severity: string;
};

export default KTMScreen({ name: "enroll.policy.limit-preview" }, class extends React.Component<{ policy_id: string }, { showAcknowledgmentModal: boolean }> {

    data: {
        dsData: IdsData[];
        dsErrors: IdsErrors;
    }

    state = {
        showAcknowledgmentModal: false,
    }

    render() {
        return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
        screen.defaultRecord = "@dsData";
        await KTMApi.start(screen, {
            "@policy_id": this.props.policy_id,
        });
        screen.title = "Limit Preview";
    }

    main({ field, draw, action }: RenderEvent) {
        return (
            <Layout>
                {draw(this.default_view)}
                {this.renderAcknowledgmentModal(action)}
            </Layout>
        );
    }

    default_view({ field, draw, action, collection }: RenderEvent) {
        let actions = (<ReactButton variant="primary" onClick={this.showAcknowledgmentModal}>Generate Limits</ReactButton>);

        let layout: IDataGridLayout = {
            columns: [
                { name: "FirstName", label: "First Name", type: "character", onClickAction: "show_employee" },
                { name: "LastName", label: "Last Name", type: "character", onClickAction: "show_employee" },
                { name: "HireDate", label: "Hire Date", type: "date", width: 90 },
                { name: "WaitingPeriodMonths", label: "Wait Period", type: "integer", width: 90 },
                { name: "BenefitStartDate", label: "Benefit Start Date", type: "date" },
                { name: "Benefit", label: "Benefit", type: "character" },
                { name: "Category", label: "Category", type: "character" },
                { name: "HasProrating", label: "Prorate", type: "logical", width: 60 },
                { name: "Coverage", label: "Coverage", type: "character", width: 80, align: "right" },
                { name: "HSABase", label: "HSA", type: "money", width: 100, align: "right", format: "$#,##0.00;" },
                { name: "LSABase", label: "LSA", type: "money", width: 100, align: "right", format: "$#,##0.00;" },
                { name: "FlexBase", label: "Flex", type: "money", width: 100, align: "right", format: "$#,##0.00;" },
                { action: "edit", label: "Edit", width: 80 },
            ],
            sort: [{ name: "LastName" }, { name: "FirstName" }]
        };
        return (
            <DataGrid collection={collection("@dsData")} layout={layout} actions={actions} />
        );
    }

    renderAcknowledgmentModal(action: any) {
        return (
            <Modal
                show={this.state.showAcknowledgmentModal}
                style={{ top: 200 }}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{ fontSize: 17, fontWeight: 500 }}>Acknowledgment</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 400, overflowY: "auto", color: "red", fontWeight: 'bold' }}>
                    <Card>
                        <p>
                            You are about to generate the limits. Any custom overrides applied to employees after the initial limits were generated may be lost and will need to be reapplied.
                        </p>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.hideAcknowledgmentModal} style={{background:"transparent", border:"none", color:"black"}}>
                        Cancel
                    </ReactButton>
                    <Button action={action(this.generate)} label="Generate limits" style="primary"/>
                </Modal.Footer>
            </Modal>
        );
    }

    async generate({ screen }: ActionEvent) {
        await KTMApi.action(screen, "generate", {
            "@policy_id": this.props.policy_id,
        });
        this.hideAcknowledgmentModal();
        await screen.restart();
    }

    async refresh_row({ screen, eventValue }: ActionEvent) {
        await KTMApi.refreshRow(screen, {
            collection: "@dsData", rowid: eventValue
        });
    }

    async show_employee({ screen }: ActionEvent) {
        screen.openOnStack(<EmployeeRecord employee_id={screen.getValue("@dsData.Employee_Id")} />)
    }

    async edit({ screen }: ActionEvent) {
        screen.openDialog(<EmployeeBenefitsEdit employeeBenefit_id={screen.getValue("@dsData.Id")} />)
    }

    showAcknowledgmentModal = () => {
        this.setState({ showAcknowledgmentModal: true });
    }

    hideAcknowledgmentModal = () => {
        this.setState({ showAcknowledgmentModal: false });
    }
});
