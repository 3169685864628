import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Card} from 'ktm-ui';

interface IdsData {
    Id:string;
    Policy_Id:string;
    Name:string;
    Description:string;
    IsActive:boolean;
};

export default KTMScreen({name:"enroll.division.add"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsData:IdsData;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsData";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Add Division";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.details)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add Division"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        var isDisabled = this.data.dsData.Name == null ||
        this.data.dsData.Name == "" ||
        this.data.dsData.Description == null ||
        this.data.dsData.Description == "";

        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Create" size="large" style="primary" disable={isDisabled}/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("Name")}/>
                <Display field={field("Description")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@policy_id":this.props.policy_id,
            "@dsData.Name":this.data.dsData.Name,
            "@dsData.Description":this.data.dsData.Description,
        });
        screen.close({"continue":true});
    }

});
