import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Card,DataGrid,IDataGridLayout} from 'ktm-ui';
import CustomToolTip from '../../components/CustomToolTip';

interface IdsExpenseTypes {
    isSelected:boolean;
    Id:string;
    AccountType:string;
    Code:string;
    ShortName:string;
    Description:string;
};

interface IdsForm {
    Coverage:number;
};

export default KTMScreen({name:"enroll.ben-limit.add-expense-types"}, class extends React.Component<{policy_id:string,limit_id:string, bencat_id:string}> {

    data:{
        dsExpenseTypes:IdsExpenseTypes;
        dsForm:IdsForm;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsExpenseTypes";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
            "@limit_id":this.props.limit_id,
            "@bencat_id":this.props.bencat_id,
        });
        screen.title = "Add expense types";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="large">
                {draw(this.header)}
                {draw(this.coverage)}
                {draw(this.expense_type_grid)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add expense types"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Add" style="primary"/>
            </Dialog.Footer>
        );
    }

    expense_type_grid({field,draw,action,collection}:RenderEvent){
        let layout:IDataGridLayout = {
            rowSelector:true,
            columns:[
                {name:"AccountType",label:"Account Type",type:"character"},
                {name:"Code",label:"Code",type:"character"},
                {name:"ShortName",label:"Short Name",type:"character",width:500}
            ],
            sort:[{name:"AccountType"},{name:"ShortName"}]
        };
        return (
            <DataGrid collection={collection("@dsExpenseTypes")} layout={layout}/>
        );
    }

    coverage({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display 
                field={field("@dsForm.Coverage")}
                toolTip={
                    <CustomToolTip
                    header="Coverage (%)"
                    bodyContent="Shortcut to apply the same Copay to every expense."
                    />
                }
            />
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit");
        screen.close({"continue":true});
    }

});
