import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';

interface IdsBenefit {
    Id:string;
    Policy_Id:string;
    CommissionRatioOurPortion:number;
    CommissionRatioAdvisorPortion:number;
};

export default KTMScreen({name:"planadmin.product.edit-commission-ratio"}, class extends React.Component<{policyBenefit_id:string}> {

    data:{
        dsBenefit:IdsBenefit;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenefit";
        await KTMApi.start(screen,{
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.title = "Edit product commission ratio";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit product commission ratio"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display required field={field("CommissionRatioOurPortion")}/>
                    <Display required field={field("CommissionRatioAdvisorPortion")}/>
                </Line>
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsBenefit.CommissionRatioOurPortion":this.data.dsBenefit.CommissionRatioOurPortion,
            "@dsBenefit.CommissionRatioAdvisorPortion":this.data.dsBenefit.CommissionRatioAdvisorPortion,
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.close({"continue":true});
    }

});
