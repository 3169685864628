import React, { Component } from "react";
import axios from "axios";
import {
    Layout,
    RecordHeader,
    TabSet,
    Tab,
    Display,
    Button,
    Line,
    Card,
} from "ktm-ui";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

export default KTMScreen(
    { name: "enrollmentforms" },
    class extends Component<any, any> {
        state = {
            loading: true,
            enrollments: [],
            enrollmentFiles: [],
            isModalOpen: false,
            selectedFiles: [],
            enrollmentStatus: "signed",
            currentPage: 1,
            itemsPerPage: 10,
            sortBy: "SignDate DESC",
            showStatusModal: false,
            selectedEnrollment: null,
            newStatus: "",
            totalCount: 0,
            filterBy: "Show All",
        };

        componentDidMount() {
            this.fetchEnrollments(this.state.currentPage);
        }

        fetchEnrollments = async (page) => {
            this.setState({ loading: true });
            const res = await axios.get(`/api/enrollment/getenrollments`, {
                params: {
                    enrollmentStatus: this.state.enrollmentStatus,
                    filterBy: this.state.filterBy,
                    pageNumber: page,
                    pageSize: this.state.itemsPerPage,
                    sortBy: this.state.sortBy
                }
            });
            this.setState({
                enrollments: res.data.items,
                totalCount: res.data.totalCount,
                loading: false,
                currentPage: page
            });
        }

        fetchEnrollmentFiles = async (id: string) => {
            const res = await axios.get(`/api/enrollment/getenrollmentfiles/${id}`);
            this.setState({ selectedFiles: res.data, isModalOpen: true });
        }

        closeModal = () => {
            this.setState({ isModalOpen: false });
        }

        handleFileClick = async (e, fileId, fileName) => {
            e.preventDefault();
            try {
                const response = await fetch(`/api/enrollment/downloadenrollmentfiles/${fileId}`);
                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Failed to download file:', error);
            }
        };

        handlePageChange = (page: number) => {
            this.setState({ loading: true, enrollments: [] }, () => this.fetchEnrollments(page));
        }

        handleStatusClick = (enrollment) => {
            this.setState({
                selectedEnrollment: enrollment,
                newStatus: enrollment.status,
                showStatusModal: true
            });
        };

        handleStatusChange = (status) => {
            this.setState({ newStatus: status });
        };

        handleSaveStatus = async () => {
            try {
                await axios.put('/api/enrollment/setenrollmentstatus', {
                    enrollmentId: this.state.selectedEnrollment.id,
                    enrollmentStatus: this.state.newStatus
                });
                this.setState({ showStatusModal: false });
                this.fetchEnrollments(this.state.currentPage);
            } catch (error) {
                console.error('Error updating enrollment status:', error);
            }
        };

        render() {
            let enrollmentTable = null;
            if (!this.state.loading && this.state.enrollments.length > 0) {
                enrollmentTable = <table className={"table table-hover table-striped"}>
                    <thead>
                    <tr>
                        <th>Status</th>
                        <th>Company Name</th>
                        <th>Client Name</th>
                        <th>Client Email</th>
                        <th>Advisor Name</th>
                        <th>Advisor Email</th>
                        {this.state.enrollmentStatus !== "pending" ? (
                            <>
                                <th>Sign Date</th>
                                <th>Files</th>
                            </>
                        ) : this.state.enrollmentStatus === "pending" ? (
                            <th>Last Sent</th>
                        ) : (
                            <></>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.enrollments.map((enrollment: any) => {
                        let companyInfo = "";
                        try {
                            companyInfo = JSON.parse(enrollment.enrollmentData).enrollmentData.companyInfo.companyName || "";
                        } catch (error) {
                            console.error("Error parsing enrollmentData:", error);
                        }
                        return <tr key={enrollment.id}>
                            <td>
                                <button className={"btn btn-outline-primary btn-sm"}
                                        onClick={() => this.handleStatusClick(enrollment)}>{enrollment.status == "pending" ? "unsigned" : enrollment.status}</button>
                            </td>
                            <td>{enrollment.policyName}</td>
                            <td>{enrollment.clientFirstName} {enrollment.clientLastName}</td>
                            <td>{enrollment.clientEmail}</td>
                            <td>{enrollment.advisorFirstName} {enrollment.advisorLastName}</td>
                            <td>{enrollment.advisorEmail}</td>
                            {this.state.enrollmentStatus !== "pending" ? (
                                <>
                                    <td>{moment.utc(enrollment.signDate).local().format('YYYY-MM-DD')}</td>
                                    <td>
                                        <button onClick={() => this.fetchEnrollmentFiles(enrollment.id)}
                                                className="btn btn-outline-primary btn-sm">View
                                        </button>
                                    </td>
                                </>
                            ) : this.state.enrollmentStatus === "pending" ? (
                                <td>{enrollment.lastSent ? moment.utc(enrollment.lastSent).local().format('YYYY-MM-DD') : 'Unsent'}</td>
                            ) : (
                                <></>
                            )}

                        </tr>;
                    })}
                    </tbody>
                </table>;
            }

            if (this.state.loading) {
                return <div className="d-flex justify-content-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                    </div>
                </div>
            }

            return <div style={{ width: "100%", padding: 30 }}>
                <Modal
                    show={this.state.isModalOpen}
                    onHide={this.closeModal}
                    style={{ top: 100 }}
                >
                    <Modal.Header>
                        <Modal.Title style={{ fontSize: "17px" }}>Enrollment Files</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            {this.state.selectedFiles.map((file: any) => {
                                const parts = file.fileName.split("_");
                                const trimmedFileName = parts.slice(1).join("_");
                                return (
                                    <li key={file.id} style={{ textAlign: 'left' }}>
                                        <button style={{ textAlign: "left" }}
                                                onClick={(e) => this.handleFileClick(e, file.id, trimmedFileName)}>
                                            {trimmedFileName}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.closeModal}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showStatusModal}
                    onHide={() => this.setState({ showStatusModal: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Enrollment Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    name="enrollmentStatus"
                                    value="signed"
                                    checked={this.state.newStatus === "signed"}
                                    onChange={() => this.handleStatusChange("signed")}
                                />
                                <label className="form-check-label" htmlFor="signed">
                                    <b>Signed</b> - Enrollment has been signed but not enrolled yet
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    name="enrollmentStatus"
                                    value="complete"
                                    checked={this.state.newStatus === "complete"}
                                    onChange={() => this.handleStatusChange("complete")}
                                />
                                <label className="form-check-label" htmlFor="complete">
                                    <b>Complete</b> - Policy has been enrolled
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    name="enrollmentStatus"
                                    value="cancelled"
                                    checked={this.state.newStatus === "cancelled"}
                                    onChange={() => this.handleStatusChange("cancelled")}
                                />
                                <label className="form-check-label" htmlFor="cancelled">
                                    <b>Cancelled</b> - Policy enrollment has been cancelled
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    name="enrollmentStatus"
                                    value="pending"
                                    checked={this.state.newStatus === "pending"}
                                    onChange={() => this.handleStatusChange("pending")}
                                />
                                <label className="form-check-label" htmlFor="pending">
                                    <b>Unsigned</b> - Policy enrollment is pending signature
                                </label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className={"btn btn-outline-primary"}
                                onClick={() => this.setState({showStatusModal: false})}>
                            Close
                        </button>
                        <button className={"btn btn-primary"} onClick={this.handleSaveStatus}>
                            Save
                        </button>
                    </Modal.Footer>
                </Modal>

                <div>
                    <b style={{fontSize: 18, marginBottom: 20}}>Enrollment Forms</b>
                </div>
                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem'}}>
                    <div className="btn-group" role="group">
                        <button
                            className={`btn ${this.state.enrollmentStatus === "signed" ? "btn-primary" : "btn-outline-primary"}`}
                            onClick={() => this.setState({
                                enrollmentStatus: "signed",
                                enrollments: [],
                                currentPage: 1
                            }, () => this.fetchEnrollments(1))}
                        >
                            Signed
                        </button>
                        <button
                            className={`btn ${this.state.enrollmentStatus === "complete" ? "btn-primary" : "btn-outline-primary"}`}
                            onClick={() => this.setState({
                                enrollmentStatus: "complete",
                                enrollments: [],
                                currentPage: 1
                            }, () => this.fetchEnrollments(1))}
                        >
                            Enrolled
                        </button>
                        <button
                            className={`btn ${this.state.enrollmentStatus === "cancelled" ? "btn-primary" : "btn-outline-primary"}`}
                            onClick={() => this.setState({
                                enrollmentStatus: "cancelled",
                                enrollments: [],
                                currentPage: 1
                            }, () => this.fetchEnrollments(1))}
                        >
                            Cancelled
                        </button>
                        <button
                            className={`btn ${this.state.enrollmentStatus === "pending" ? "btn-primary" : "btn-outline-primary"}`}
                            onClick={() => this.setState({
                                enrollmentStatus: "pending",
                                enrollments: [],
                                currentPage: 1
                            }, () => this.fetchEnrollments(1))}
                        >
                            Unsigned
                        </button>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                        Sort By
                        <select
                            value={this.state.sortBy}
                            onChange={(e) => this.setState({sortBy: e.target.value}, () => this.fetchEnrollments(this.state.currentPage))}
                            className="form-select"
                            style={{marginLeft: '8px', width: 'auto'}}
                        >
                            <option value="SignDate DESC">Sign Date DESC</option>
                            <option value="SignDate ASC">Sign Date ASC</option>
                            <option value="PolicyName ASC">Policy Name ASC</option>
                            <option value="PolicyName DESC">Policy Name DESC</option>
                        </select>

                        {this.state.enrollmentStatus === "pending" && (
                            <div style={{display: 'flex', alignItems: 'center', marginLeft: '16px'}}>
                                Filter
                                <select
                                    value={this.state.filterBy}
                                    onChange={(e) => this.setState({filterBy: e.target.value}, () => this.fetchEnrollments(this.state.currentPage))}
                                    className="form-select"
                                    style={{marginLeft: '8px', width: 'auto'}}
                                >
                                    <option value="Show All">Show All</option>
                                    <option value="Show Unsent">Show Unsent</option>
                                    <option value="Show Sent Awaiting Signature">Show Sent Awaiting Signature</option>
                                </select>
                            </div>
                        )}
                    </div>

                </div>
                <div style={{marginTop: 10}}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            {enrollmentTable}
                            <nav>
                                <ul className="pagination">
                                    <li className={`page-item ${this.state.currentPage === 1 ? "disabled" : ""}`}>
                                        <button className="page-link"
                                                onClick={() => this.handlePageChange(this.state.currentPage - 1)}>Previous
                                        </button>
                                    </li>
                                    <li className={`page-item ${this.state.currentPage * this.state.itemsPerPage >= this.state.totalCount ? "disabled" : ""}`}>
                                        <button className="page-link"
                                                onClick={() => this.handlePageChange(this.state.currentPage + 1)}>Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>;
        }
    }
);