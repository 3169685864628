import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Button,DataGrid,IDataGridLayout,EmptyState} from 'ktm-ui';
import BencatRecordScreen from 'enroll/bencat/Record';
import BencatNewScreen from 'enroll/bencat/New';

interface IdsBenefitCategories {
    Id:string;
    Name:string;
    Description:string;
    IsHidden:boolean;
    Policy_Id:string;
    FlexSingleLimit:number;
    FlexCoupleLimit:number;
    FlexFamilyLimit:number;
    ExcludeTeladoc: boolean;
    ExcludeCorehealth: boolean;
};

interface IdsBenefits {
    Id:string;
    HasFlex:boolean;
};
export default KTMScreen({name:"enroll.policy.benefit-categories"}, 
    class extends React.Component<{policy_id:string, hasTeladoc: boolean, hasCorehealth: boolean}> {

    data:{
        dsBenefitCategories:IdsBenefitCategories[];
        dsBenefits:IdsBenefits;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenefitCategories";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Benefit categories";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.default_view)}
            </Layout>
        );
    }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = (
            <Button 
                action={action(this.new_record)} 
                label="Add benefit category" 
                style="add"
            />
        );

        let layout:IDataGridLayout = {
            columns:[
                {name:"Name",label:"Name",type:"character", width:300},
                {name:"IsHidden",label:"Is Hidden",type:"logical"},
            ],
            sort:[{name:"Name"}]
        };
        
        if (this.props.hasTeladoc) {
           layout.columns.push(
               {name: "ExcludeTeladoc", label: "Exclude Teladoc", type: "logical"},
           ) 
        }

        if (this.props.hasCorehealth) {
            layout.columns.push(
                {name: "ExcludeCorehealth", label: "Exclude Corehealth", type: "logical"},
            )
        }

        layout.columns.push({name:"hasCustomCat",label:"Has Enhanced Benefit",type:"logical"});
        
        if (this.data.dsBenefits.HasFlex) {
            layout.columns.push(
                { name: "FlexSingleLimit", label: "Flex Single Limit", type: "decimal" },
                { name: "FlexCoupleLimit", label: "Flex Couple Limit", type: "decimal" },
                { name: "FlexFamilyLimit", label: "Flex Family Limit", type: "decimal" }
            );
        }
    
        return (
            <DataGrid 
                collection={collection("@dsBenefitCategories")} 
                layout={layout} 
                onRowClick={action(this.show_record)} 
                actions={actions}
            />
        );
    }

    empty_state({field,draw,action}:RenderEvent){
        return (
            <EmptyState label="No benefit categories created yet." icon="folder">
                <Button action={action(this.new_record)} label="Add Benefit Category" style="primary"/>
            </EmptyState>
        );
    }

    async show_record({screen}:ActionEvent){
        screen.browse(<BencatRecordScreen bencat_id={screen.getValue("Id")}/>,{label:"Benefit category"});
    }

    async refresh_row({screen,eventValue}:ActionEvent){
        await KTMApi.refreshRow(screen,{collection:
"@dsBenefitCategories",rowid:eventValue});
    }

    async new_record({screen}:ActionEvent){
        await screen.openDialog(<BencatNewScreen policy_id={this.props.policy_id}/>
,{onRecordChanged:this.after_new});
    }

    async after_new({screen,eventValue}:ActionEvent){
        screen.showLayout(this.main);
        await KTMApi.refreshRow(screen,{collection:
"@dsBenefitCategories",rowid:eventValue});
        screen.locate("@dsBenefitCategories",eventValue);
    }

});
