import React from 'react';
import {KTMScreen, ActionEvent, RenderEvent, renderScreen, TabSet, Tab, Layout, RecordTabs} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Display, Button, EmptyState} from 'ktm-ui';
import ProductRecordScreen from "../enroll/product/Record";
import ProductNewScreen from "../enroll/product/New";
import Edit_generalScreen from "../enroll/product/Edit_general";
import toastr from "toastr";

export default KTMScreen({name: "enroll.policy.billingcorehealth"}, class extends React.Component<{ policy_id: string }> {

    state = {
        loading: true,
        editingCorehealthDetails: false,
        editingCorehealthAdvisors: false,
        corehealth: {
            enabled: false,
            bank: {
                transitNo: "",
                bankNo: "",
                accountNo: ""
            },
            natPolicy: "NAT1001",
            corehealthNhcRate: 0
        },
        selectedAdvisor1: null,
        selectedAdvisor2: null,
        selectedAdvisor3: null,
        search1: '',
        search2: '',
        search3: '',
        advisors1: [],
        advisors2: [],
        advisors3: [],
        advisor1Rate: null,
        advisor2Rate: null,
        advisor3Rate: null,
        originalCorehealthDetails: null,
        originalAdvisors: null,
        originalAdvisorRates: null
    }

    constructor(props) {
        super(props);
        this.saveCorehealthDetails = this.saveCorehealthDetails.bind(this);
        this.saveCorehealthAdvisors = this.saveCorehealthAdvisors.bind(this);
        this.cancelEditCorehealthDetails = this.cancelEditCorehealthDetails.bind(this);
        this.cancelEditCorehealthAdvisors = this.cancelEditCorehealthAdvisors.bind(this);
    }

    componentDidMount() {
        this.startLoading();
    }

    async startLoading() {
        const policyId = this.props.policy_id;
        const responseDetails = await fetch(`/api/Corehealth/getdetails?policyId=${policyId}`);

        if (!responseDetails.ok) {
            console.error('Failed to load corehealth details');
            return;
        }

        // Load details
        const corehealthDetails = await responseDetails.json();
        this.setState({
            corehealth: {
                enabled: corehealthDetails.enabled,
                bank: {
                    transitNo: corehealthDetails.transitNo,
                    bankNo: corehealthDetails.bankNo,
                    accountNo: corehealthDetails.accountNo,
                },
                natPolicy: corehealthDetails.natPolicy,
                corehealthNhcRate: corehealthDetails.corehealthNhcRate
            },
            originalCorehealthDetails: {
                enabled: corehealthDetails.enabled,
                bank: {
                    transitNo: corehealthDetails.transitNo,
                    bankNo: corehealthDetails.bankNo,
                    accountNo: corehealthDetails.accountNo,
                },
                natPolicy: corehealthDetails.natPolicy,
                corehealthNhcRate: corehealthDetails.corehealthNhcRate
            }
        });

        // Load advisors
        const responseAdvisors = await fetch(`/api/Corehealth/getadvisors?policyId=${policyId}`);
        if (!responseAdvisors.ok) {
            console.error('Failed to load corehealth advisors');
            return;
        }

        const advisors = await responseAdvisors.json();

        this.setState({
            selectedAdvisor1: advisors.corehealthAdvisor1 ? { id: advisors.corehealthAdvisor1, name: advisors.corehealthAdvisor1Name } : null,
            advisor1Rate: advisors.corehealthAdvisor1Rate,
            selectedAdvisor2: advisors.corehealthAdvisor2 ? { id: advisors.corehealthAdvisor2, name: advisors.corehealthAdvisor2Name } : null,
            advisor2Rate: advisors.corehealthAdvisor2Rate,
            selectedAdvisor3: advisors.corehealthAdvisor3 ? { id: advisors.corehealthAdvisor3, name: advisors.corehealthAdvisor3Name } : null,
            advisor3Rate: advisors.corehealthAdvisor3Rate,
            loading: false,
        });
    }

    handleSearch = async (searchTerm, advisorNumber) => {
        const response = await fetch(`/api/corehealth/searchadvisors?search=${searchTerm}`);
        const advisors = await response.json();

        this.setState({[`advisors${advisorNumber}`]: advisors});

        if (advisors.length === 1) {
            this.handleSelectAdvisor(advisors[0], advisorNumber);
        }
    }

    handleSelectAdvisor = (advisor, advisorNumber) => {
        this.setState({
            [`selectedAdvisor${advisorNumber}`]: advisor,
            [`search${advisorNumber}`]: advisor.name,
        });
    }

    startEditingCorehealthDetails = () => {
        this.setState({
            editingCorehealthDetails: true,
            originalCorehealthDetails: {
                enabled: this.state.corehealth.enabled,
                bank: {
                    transitNo: this.state.corehealth.bank.transitNo,
                    bankNo: this.state.corehealth.bank.bankNo,
                    accountNo: this.state.corehealth.bank.accountNo,
                },
                natPolicy: this.state.corehealth.natPolicy,
                corehealthNhcRate: this.state.corehealth.corehealthNhcRate
            }
        });
    }

    startEditingCorehealthAdvisors = () => {
        this.setState({
            editingCorehealthAdvisors: true,
            originalAdvisors: {
                selectedAdvisor1: this.state.selectedAdvisor1,
                selectedAdvisor2: this.state.selectedAdvisor2,
                selectedAdvisor3: this.state.selectedAdvisor3,
            },
            originalAdvisorRates: {
                advisor1Rate: this.state.advisor1Rate,
                advisor2Rate: this.state.advisor2Rate,
                advisor3Rate: this.state.advisor3Rate,
            },
            search1: this.state.selectedAdvisor1 ? this.state.selectedAdvisor1.name : '',
            search2: this.state.selectedAdvisor2 ? this.state.selectedAdvisor2.name : '',
            search3: this.state.selectedAdvisor3 ? this.state.selectedAdvisor3.name : '',
        });
    }

    saveCorehealthDetails = async () => {
        const { transitNo, bankNo, accountNo } = this.state.corehealth.bank;
        const isAnyBankFieldFilled = transitNo?.trim() || bankNo?.trim() || accountNo?.trim();
        const areAllBankFieldsFilled = transitNo?.trim() && bankNo?.trim() && accountNo?.trim();
        if (this.state.corehealth.enabled && isAnyBankFieldFilled && !areAllBankFieldsFilled) {
            toastr.error("Please enter the complete bank information", "Error");
            return;
        }

        const corehealthDetails = {
            PolicyId: this.props.policy_id,
            Enabled: this.state.corehealth.enabled,
            BankNo: this.state.corehealth.bank.bankNo?.trim() || null,
            TransitNo: this.state.corehealth.bank.transitNo?.trim() || null,
            AccountNo: this.state.corehealth.bank.accountNo?.trim() || null,
            natPolicy: this.state.corehealth.natPolicy?.trim() || null,
            corehealthNhcRate: this.state.corehealth.corehealthNhcRate || 0,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(corehealthDetails),
        };

        try {
            const response = await fetch("/api/Corehealth/updatedetails", requestOptions);
            if (!response.ok) {
                throw new Error("Failed to save Corehealth details");
            }

            toastr.success("Corehealth details updated successfully");
            this.setState({
                editingCorehealthDetails: false,
                originalCorehealthDetails: null
            });
        } catch (error) {
            console.error("Error saving Corehealth details:", error);
            toastr.error("Failed to save Corehealth details. Please try again.", "Error");
        }
    }

    cancelEditCorehealthDetails = () => {
        if (this.state.originalCorehealthDetails) {
            this.setState({
                editingCorehealthDetails: false,
                corehealth: {
                    enabled: this.state.originalCorehealthDetails.enabled,
                    bank: {
                        transitNo: this.state.originalCorehealthDetails.bank.transitNo,
                        bankNo: this.state.originalCorehealthDetails.bank.bankNo,
                        accountNo: this.state.originalCorehealthDetails.bank.accountNo,
                    },
                    natPolicy: this.state.originalCorehealthDetails.natPolicy,
                    corehealthNhcRate: this.state.originalCorehealthDetails.corehealthNhcRate
                },
                originalCorehealthDetails: null
            });
        } else {
            this.setState({
                editingCorehealthDetails: false
            });
        }
    }

    cancelEditCorehealthAdvisors = () => {
        if (this.state.originalAdvisors && this.state.originalAdvisorRates) {
            this.setState({
                editingCorehealthAdvisors: false,
                selectedAdvisor1: this.state.originalAdvisors.selectedAdvisor1,
                selectedAdvisor2: this.state.originalAdvisors.selectedAdvisor2,
                selectedAdvisor3: this.state.originalAdvisors.selectedAdvisor3,
                advisor1Rate: this.state.originalAdvisorRates.advisor1Rate,
                advisor2Rate: this.state.originalAdvisorRates.advisor2Rate,
                advisor3Rate: this.state.originalAdvisorRates.advisor3Rate,
                originalAdvisors: null,
                originalAdvisorRates: null,
                search1: '',
                search2: '',
                search3: '',
                advisors1: [],
                advisors2: [],
                advisors3: [],
            });
        } else {
            this.setState({
                editingCorehealthAdvisors: false,
                search1: '',
                search2: '',
                search3: '',
                advisors1: [],
                advisors2: [],
                advisors3: [],
            });
        }
    }

    async saveCorehealthAdvisors() {
        if (this.state.selectedAdvisor1 && this.state.selectedAdvisor2 && this.state.selectedAdvisor1.id === this.state.selectedAdvisor2.id ||
            this.state.selectedAdvisor1 && this.state.selectedAdvisor3 && this.state.selectedAdvisor1.id === this.state.selectedAdvisor3.id ||
            this.state.selectedAdvisor2 && this.state.selectedAdvisor3 && this.state.selectedAdvisor2.id === this.state.selectedAdvisor3.id) {
            toastr.error("Advisor already assigned", "Error");
            return;
        }

        const corehealthAdvisors = {
            Id: this.props.policy_id,
            CorehealthAdvisor1: this.state.selectedAdvisor1 ? this.state.selectedAdvisor1.id : null,
            CorehealthAdvisor2: this.state.selectedAdvisor2 ? this.state.selectedAdvisor2.id : null,
            CorehealthAdvisor3: this.state.selectedAdvisor3 ? this.state.selectedAdvisor3.id : null,
            CorehealthAdvisor1Rate: parseFloat(this.state.advisor1Rate) || 0,
            CorehealthAdvisor2Rate: parseFloat(this.state.advisor2Rate) || 0,
            CorehealthAdvisor3Rate: parseFloat(this.state.advisor3Rate) || 0,
        };

        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(corehealthAdvisors),
        };

        await fetch("/api/corehealth/setadvisors", requestOptions);

        this.setState({editingCorehealthAdvisors: false});
    }

    removeAdvisor = (advisorNumber) => {
        if (this.state.corehealth.enabled && advisorNumber === 1) {
            toastr.error("At least one advisor must be assigned when Corehealth is enabled.", "Error");
            return;
        }

        this.setState({
            [`selectedAdvisor${advisorNumber}`]: null,
            [`advisor${advisorNumber}Rate`]: null,
            [`search${advisorNumber}`]: '',
            [`advisors${advisorNumber}`]: [],
        });
    }

    handleRateChange = (advisorNumber, rate) => {
        this.setState({ [`advisor${advisorNumber}Rate`]: rate });
    }

    render() {
        return renderScreen(this);
    }

    async start({screen}: ActionEvent) {
        screen.title = "Billing";
    }

    contents() {

        if (this.state.loading) {
            return <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    width: '65px',
                    height: '65px',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 50001
                }}>
                <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="spinner-path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                            cy="33" r="30"></circle>
                </svg>
            </div>
        }

        return (<div className={"RT-Layout"}>
            <div className={"RT-Layout__body"}>
                <div className={"RT-Row"}>
                    <div className={"RT-Column"} style={{flexBasis: 0, flexGrow: 1}}>
                        <div className={"RT-Section"}>
                            <div className={"RT-Section__header"}>
                                <div className={"RT-Section__title"}>
                                    Corehealth Details
                                </div>
                                <div className={"RT-Section__actions"}>
                                    {this.state.editingCorehealthDetails ? (

                                        <div style={{display: 'inline-block'}}>
                                            <button className="RT-Link" onClick={this.cancelEditCorehealthDetails}>Cancel
                                            </button>
                                            <button className="RT-Link" onClick={this.saveCorehealthDetails}>Save</button>
                                        </div>
                                    ) : (
                                        <a href="#" className="RT-Link"
                                           onClick={() => this.setState({editingCorehealthDetails: true})}>Edit</a>
                                    )}
                                </div>
                            </div>
                            <div className={"RT-Line"}>
                                <div className={"RT-Line__content RT-Line__content--space-evenly"}>
                                    <div className={"RT-Line__item"}>
                                        <div className={"RT-Static-Field"}>
                                            <label className={"RT-Static-Field__label"}>
                                                Corehealth Enabled
                                            </label>
                                            <div className={"RT-Static-Field__content"}>
                                                {this.state.editingCorehealthDetails ? (
                                                    <select className={"RT-TextBox__select"}
                                                            value={this.state.corehealth.enabled ? "true" : "false"}
                                                            onChange={(e) => this.setState({
                                                                corehealth: {
                                                                    ...this.state.corehealth,
                                                                    enabled: e.target.value === "true"
                                                                }
                                                            })}>
                                                        <option value="true">Enabled</option>
                                                        <option value="false">Disabled</option>
                                                    </select>
                                                ) : (
                                                    this.state.corehealth.enabled ? "Enabled" : "Disabled"
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"RT-Line__item"}>
                                        <div className={"RT-Static-Field"}>
                                            <label className={"RT-Static-Field__label"}>
                                                Nat Policy
                                            </label>
                                            <div className={"RT-Static-Field__content"}>
                                                {this.state.editingCorehealthDetails ? (
                                                    <select
                                                        className="form-select" 
                                                        value={this.state.corehealth.natPolicy || "NAT1001"} // Default to NAT1001
                                                        onChange={(e) => this.setState({
                                                            corehealth: {
                                                                ...this.state.corehealth,
                                                                natPolicy: e.target.value
                                                            }
                                                        })}
                                                    >
                                                        <option value="NAT1001">NAT1001 (Corehealth)</option>
                                                        <option value="NAT1002">NAT1002 (InsurPak)</option>
                                                    </select>
                                                ) : (
                                                    this.state.corehealth.natPolicy
                                                        ? `${this.state.corehealth.natPolicy} (${this.state.corehealth.natPolicy === 'NAT1001' ? 'Corehealth' : 'InsurPak'})`
                                                        : "NAT1001 (Corehealth)"
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"RT-Line__item"}>
                                        <div className={"RT-Static-Field"}>
                                            <label className={"RT-Static-Field__label"}>
                                                NHC Rate
                                            </label>
                                            <div className={"RT-Static-Field__content"}>
                                                {this.state.editingCorehealthDetails ? (
                                                    <input
                                                        type="number"
                                                        className={"RT-TextBox__input"}
                                                        value={this.state.corehealth.corehealthNhcRate}
                                                        onChange={(e) => this.setState({
                                                            corehealth: {
                                                                ...this.state.corehealth,
                                                                corehealthNhcRate: parseFloat(e.target.value)
                                                            }
                                                        })}
                                                        min="0"
                                                        step="0.01"
                                                        placeholder="Enter NHC Rate"
                                                    />
                                                ) : (
                                                    `${this.state.corehealth.corehealthNhcRate}%`
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"RT-Line"}>
                                <div className={"RT-Line__content RT-Line__content--space-evenly"}>
                                    <div className={"RT-Line__item"}>
                                        <div className={"RT-Static-Field"}>
                                            <label className={"RT-Static-Field__label"}>
                                                Bank Transit No.
                                            </label>
                                            <div className={"RT-Static-Field__content"}>
                                                {this.state.editingCorehealthDetails ? (
                                                    <input type="text" className={"RT-TextBox__input"}
                                                           value={this.state.corehealth.bank.transitNo}
                                                           onChange={(e) => this.setState({
                                                               corehealth: {
                                                                   ...this.state.corehealth,
                                                                   bank: {
                                                                       ...this.state.corehealth.bank,
                                                                       transitNo: e.target.value
                                                                   }
                                                               }
                                                           })}/>
                                                ) : (
                                                    this.state.corehealth.bank.transitNo
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"RT-Line__item"}>
                                        <div className={"RT-Static-Field"}>
                                            <label className={"RT-Static-Field__label"}>
                                                Bank No.
                                            </label>
                                            <div className={"RT-Static-Field__content"}>
                                                {this.state.editingCorehealthDetails ? (
                                                    <input type="text" className={"RT-TextBox__input"}
                                                           value={this.state.corehealth.bank.bankNo}
                                                           onChange={(e) => this.setState({
                                                               corehealth: {
                                                                   ...this.state.corehealth,
                                                                   bank: {
                                                                       ...this.state.corehealth.bank,
                                                                       bankNo: e.target.value
                                                                   }
                                                               }
                                                           })}/>
                                                ) : (
                                                    this.state.corehealth.bank.bankNo
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"RT-Line__item"}>
                                        <div className={"RT-Static-Field"}>
                                            <label className={"RT-Static-Field__label"}>
                                                Bank Account No.
                                            </label>
                                            <div className={"RT-Static-Field__content"}>
                                                {this.state.editingCorehealthDetails ? (
                                                    <input type="text" className={"RT-TextBox__input"}
                                                           value={this.state.corehealth.bank.accountNo}
                                                           onChange={(e) => this.setState({
                                                               corehealth: {
                                                                   ...this.state.corehealth,
                                                                   bank: {
                                                                       ...this.state.corehealth.bank,
                                                                       accountNo: e.target.value
                                                                   }
                                                               }
                                                           })}/>
                                                ) : (
                                                    this.state.corehealth.bank.accountNo
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"RT-Column"} style={{flexBasis: 0, flexGrow: 1}}>
                        <div className={"RT-Section"}>
                            <div className={"RT-Section__header"}>
                                <div className={"RT-Section__title"}>
                                    Corehealth Advisors
                                </div>
                                <div className={"RT-Section__actions"}>
                                    {this.state.editingCorehealthAdvisors ? (
                                        <div style={{display: 'inline-block'}}>
                                            <button className="RT-Link"
                                                    onClick={this.cancelEditCorehealthAdvisors}>Cancel
                                            </button>
                                            <button className="RT-Link" onClick={this.saveCorehealthAdvisors}>Save</button>
                                        </div>
                                    ) : (
                                        <a href="#" className="RT-Link"
                                           onClick={this.startEditingCorehealthAdvisors}>Edit</a>
                                    )}
                                </div>
                            </div>

                            {/*Advisor 1*/}
                            <div className={"RT-Line"}>
                                <div className={"RT-Line__content RT-Line__content--space-evenly"}>
                                    <div className={"RT-Line__item"}>
                                        <div className={"RT-Static-Field"}>
                                            <label className={"RT-Static-Field__label"}>
                                                Advisor 1 (Primary)
                                            </label>
                                            <div className={"RT-Static-Field__content"}>
                                                {this.state.editingCorehealthAdvisors ? (
                                                    <>
                                                        <input className={"RT-TextBox__input"}
                                                               type="text"
                                                               value={this.state.search1}
                                                               onChange={e => this.setState({search1: e.target.value})}
                                                               onBlur={() => this.handleSearch(this.state.search1, 1)}
                                                               onKeyPress={e => {
                                                                   if (e.key === 'Enter') {
                                                                       this.handleSearch(this.state.search1, 1);
                                                                   }
                                                               }}
                                                        />
                                                        {this.state.advisors1.length > 0 && (
                                                            <select className={"RT-TextBox__select"}
                                                                    onChange={e => this.handleSelectAdvisor(this.state.advisors1[e.target.selectedIndex - 1], 1)}>
                                                                <option>Select an advisor</option>
                                                                {this.state.advisors1.map((advisor, index) => (
                                                                    <option key={index}
                                                                            value={index + 1}>{advisor.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </>
                                                ) : (
                                                    <p>{this.state.selectedAdvisor1 ? this.state.selectedAdvisor1.name : 'No advisor selected'}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.selectedAdvisor1 && (
                                        <div className={"RT-Line__item"}>
                                            <div className={"RT-Static-Field"}>
                                                <label className={"RT-Static-Field__label"}>
                                                    Advisor 1 Rate
                                                </label>
                                                <div className={"RT-Static-Field__content"}>
                                                    {this.state.editingCorehealthAdvisors ? (
                                                        <input
                                                            type="number"
                                                            className={"RT-TextBox__input"}
                                                            value={this.state.advisor1Rate || '0'}
                                                            onChange={e => this.handleRateChange(1, e.target.value)}
                                                            min="0"
                                                            max="100"
                                                            step="0.01" 
                                                            placeholder="Enter % rate"
                                                        />
                                                    ) : (
                                                        <p>{this.state.advisor1Rate ? `${this.state.advisor1Rate}%` : 'No rate set'}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/*<div className={"RT-Line__item"}>*/}
                                {/*    {this.state.editingCorehealthAdvisors && (*/}
                                {/*        <button className="RT-Link"*/}
                                {/*                onClick={() => this.removeAdvisor(1)}>Remove</button>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                            </div>

                            {/*/!* Advisor 2 *!/*/}
                            {/*{this.state.selectedAdvisor1 && (*/}
                            {/*    <div className={"RT-Line"}>*/}
                            {/*        <div className={"RT-Line__content RT-Line__content--space-evenly"}>*/}
                            {/*            <div className={"RT-Line__item"}>*/}
                            {/*                <div className={"RT-Static-Field"}>*/}
                            {/*                    <label className={"RT-Static-Field__label"}>*/}
                            {/*                        Advisor 2*/}
                            {/*                    </label>*/}
                            {/*                    <div className={"RT-Static-Field__content"}>*/}
                            {/*                        {this.state.editingCorehealthAdvisors ? (*/}
                            {/*                            <>*/}
                            {/*                                <input*/}
                            {/*                                    className={"RT-TextBox__input"}*/}
                            {/*                                    type="text"*/}
                            {/*                                    value={this.state.search2}*/}
                            {/*                                    onChange={e => this.setState({ search2: e.target.value })}*/}
                            {/*                                    onBlur={() => this.handleSearch(this.state.search2, 2)}*/}
                            {/*                                    onKeyPress={e => {*/}
                            {/*                                        if (e.key === 'Enter') {*/}
                            {/*                                            this.handleSearch(this.state.search2, 2);*/}
                            {/*                                        }*/}
                            {/*                                    }}*/}
                            {/*                                />*/}
                            {/*                                {this.state.advisors2.length > 0 && (*/}
                            {/*                                    <select*/}
                            {/*                                        className={"RT-TextBox__select"}*/}
                            {/*                                        onChange={e => this.handleSelectAdvisor(this.state.advisors2[e.target.selectedIndex - 1], 2)}*/}
                            {/*                                    >*/}
                            {/*                                        <option>Select an advisor</option>*/}
                            {/*                                        {this.state.advisors2.map((advisor, index) => (*/}
                            {/*                                            <option key={index} value={index + 1}>{advisor.name}</option>*/}
                            {/*                                        ))}*/}
                            {/*                                    </select>*/}
                            {/*                                )}*/}
                            {/*                            </>*/}
                            {/*                        ) : (*/}
                            {/*                            <p>{this.state.selectedAdvisor2 ? this.state.selectedAdvisor2.name : 'No advisor selected'}</p>*/}
                            {/*                        )}*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            {this.state.selectedAdvisor2 && (*/}
                            {/*                <div className={"RT-Line__item"}>*/}
                            {/*                    <div className={"RT-Static-Field"}>*/}
                            {/*                        <label className={"RT-Static-Field__label"}>*/}
                            {/*                            Advisor 2 Rate*/}
                            {/*                        </label>*/}
                            {/*                        <div className={"RT-Static-Field__content"}>*/}
                            {/*                            {this.state.editingCorehealthAdvisors ? (*/}
                            {/*                                <input*/}
                            {/*                                    type="number"*/}
                            {/*                                    className={"RT-TextBox__input"}*/}
                            {/*                                    value={this.state.advisor2Rate || '0'}*/}
                            {/*                                    onChange={e => this.handleRateChange(2, e.target.value)}*/}
                            {/*                                    min="0"*/}
                            {/*                                    max="100"*/}
                            {/*                                    step="0.01"*/}
                            {/*                                    placeholder="Enter % rate"*/}
                            {/*                                />*/}
                            {/*                            ) : (*/}
                            {/*                                <p>{this.state.advisor2Rate ? `${this.state.advisor2Rate}%` : 'No rate set'}</p>*/}
                            {/*                            )}*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*        </div>*/}
                            {/*        <div className={"RT-Line__item"}>*/}
                            {/*            {this.state.editingCorehealthAdvisors && (*/}
                            {/*                <button className="RT-Link" onClick={() => this.removeAdvisor(2)}>Remove</button>*/}
                            {/*            )}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            
                            {/*/!* Advisor 3 *!/*/}
                            {/*{this.state.selectedAdvisor2 && (*/}
                            {/*    <div className={"RT-Line"}>*/}
                            {/*        <div className={"RT-Line__content RT-Line__content--space-evenly"}>*/}
                            {/*            <div className={"RT-Line__item"}>*/}
                            {/*                <div className={"RT-Static-Field"}>*/}
                            {/*                    <label className={"RT-Static-Field__label"}>*/}
                            {/*                        Advisor 3*/}
                            {/*                    </label>*/}
                            {/*                    <div className={"RT-Static-Field__content"}>*/}
                            {/*                        {this.state.editingCorehealthAdvisors ? (*/}
                            {/*                            <>*/}
                            {/*                                <input*/}
                            {/*                                    className={"RT-TextBox__input"}*/}
                            {/*                                    type="text"*/}
                            {/*                                    value={this.state.search3}*/}
                            {/*                                    onChange={e => this.setState({ search3: e.target.value })}*/}
                            {/*                                    onBlur={() => this.handleSearch(this.state.search3, 3)}*/}
                            {/*                                    onKeyPress={e => {*/}
                            {/*                                        if (e.key === 'Enter') {*/}
                            {/*                                            this.handleSearch(this.state.search3, 3);*/}
                            {/*                                        }*/}
                            {/*                                    }}*/}
                            {/*                                />*/}
                            {/*                                {this.state.advisors3.length > 0 && (*/}
                            {/*                                    <select*/}
                            {/*                                        className={"RT-TextBox__select"}*/}
                            {/*                                        onChange={e => this.handleSelectAdvisor(this.state.advisors3[e.target.selectedIndex - 1], 3)}*/}
                            {/*                                    >*/}
                            {/*                                        <option>Select an advisor</option>*/}
                            {/*                                        {this.state.advisors3.map((advisor, index) => (*/}
                            {/*                                            <option key={index} value={index + 1}>{advisor.name}</option>*/}
                            {/*                                        ))}*/}
                            {/*                                    </select>*/}
                            {/*                                )}*/}
                            {/*                            </>*/}
                            {/*                        ) : (*/}
                            {/*                            <p>{this.state.selectedAdvisor3 ? this.state.selectedAdvisor3.name : 'No advisor selected'}</p>*/}
                            {/*                        )}*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            {this.state.selectedAdvisor3 && (*/}
                            {/*                <div className={"RT-Line__item"}>*/}
                            {/*                    <div className={"RT-Static-Field"}>*/}
                            {/*                        <label className={"RT-Static-Field__label"}>*/}
                            {/*                            Advisor 3 Rate*/}
                            {/*                        </label>*/}
                            {/*                        <div className={"RT-Static-Field__content"}>*/}
                            {/*                            {this.state.editingCorehealthAdvisors ? (*/}
                            {/*                                <input*/}
                            {/*                                    type="number"*/}
                            {/*                                    className={"RT-TextBox__input"}*/}
                            {/*                                    value={this.state.advisor3Rate || '0'}*/}
                            {/*                                    onChange={e => this.handleRateChange(3, e.target.value)}*/}
                            {/*                                    min="0"*/}
                            {/*                                    max="100"*/}
                            {/*                                    step="0.01"*/}
                            {/*                                    placeholder="Enter % rate"*/}
                            {/*                                />*/}
                            {/*                            ) : (*/}
                            {/*                                <p>{this.state.advisor3Rate ? `${this.state.advisor3Rate}%` : 'No rate set'}</p>*/}
                            {/*                            )}*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*        </div>*/}
                            {/*        <div className={"RT-Line__item"}>*/}
                            {/*            {this.state.editingCorehealthAdvisors && (*/}
                            {/*                <button className="RT-Link" onClick={() => this.removeAdvisor(3)}>Remove</button>*/}
                            {/*            )}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    main({draw}: RenderEvent) {
        return <Layout>
            {draw(this.contents)}
        </Layout>
    }
});
