import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsEmployee {
  Id: string;
  Name: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MaidenName: string;
  Nickname: string;
  EmployeeNo: string;
  DateOfBirth: string;
  HireDate: string;
  BenefitStartDate: string;
  Division_Id: string;
  CostCentre_Id: string;
  WaitingPeriodMonths: number;
}

export default KTMScreen({name: "enroll.employee.edit"}, class extends React.Component<{ employee_id: string }> {

    data:{
      dsEmployee:IdsEmployee;
    }

    render(){
      return renderScreen(this);
    }

    async start({screen}:ActionEvent){
      screen.defaultRecord = "@dsEmployee";
      await KTMApi.start(screen,{
        "@employee_id":this.props.employee_id,
      });
      screen.title = "Edit employee";
    }

    main({field,draw,action}:RenderEvent){
      return (
        <Layout dialogSize="medium">
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit employee"/>
        );
    }

    footer({ field, draw, action }: RenderEvent) {
      var isDisabled =
        this.data.dsEmployee.FirstName === null ||
        this.data.dsEmployee.FirstName === undefined ||
        this.data.dsEmployee.FirstName === "" ||
        this.data.dsEmployee.LastName === null ||
        this.data.dsEmployee.LastName === undefined ||
        this.data.dsEmployee.LastName === "" ||
        this.data.dsEmployee.Email === null ||
        this.data.dsEmployee.Email === undefined ||
        this.data.dsEmployee.Email === "" ||
        this.data.dsEmployee.DateOfBirth === null ||
        this.data.dsEmployee.DateOfBirth === undefined ||
        this.data.dsEmployee.DateOfBirth === ""
          ? true
          : false;

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }


    main_tab({field,draw,action}:RenderEvent){
      return (
        <Panel>
          <Column>
              {draw(this.details)}
          </Column>
        </Panel>
      );
    }

    details({field,draw,action}:RenderEvent){
      return (
        <Card>
          <Line>
            <Display required field={field("FirstName")}/>
            <Display required field={field("LastName")}/>
          </Line>
          <Line>
            <Display field={field("MaidenName")}/>
            <Display field={field("Nickname")}/>
          </Line>
          <Line>
              <Display required field={field("Email")}/>
              <Display field={field("EmployeeNo")}/>
          </Line>
          <Line>
              <Display field={field("Division_Id")}/>
              <Display field={field("CostCentre_Id")}/>
          </Line>
          <Line>
              <Display required field={field("DateOfBirth")}/>
          </Line>
        </Card>
      );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsEmployee.FirstName":this.data.dsEmployee.FirstName,
            "@dsEmployee.LastName":this.data.dsEmployee.LastName,
            "@dsEmployee.Email":this.data.dsEmployee.Email,
            "@dsEmployee.MaidenName":this.data.dsEmployee.MaidenName,
            "@dsEmployee.Nickname":this.data.dsEmployee.Nickname,
            "@dsEmployee.EmployeeNo":this.data.dsEmployee.EmployeeNo,
            "@dsEmployee.DateOfBirth":this.data.dsEmployee.DateOfBirth,
            "@dsEmployee.Division_Id":this.data.dsEmployee.Division_Id,
            "@dsEmployee.CostCentre_Id":this.data.dsEmployee.CostCentre_Id,
            "@employee_id":this.props.employee_id,
      });

        screen.close({"continue":true});
    }

});
