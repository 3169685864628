import { Application } from 'ktm-ui/core';
import React from 'react';
export class StudioLaunch extends React.Component<{}>{

    componentDidMount(){
        let app = Application.instance;
        app.session.broadcast.connect(this);
    }

    componentWillUnmount(){
        let app = Application.instance;
        app.session.broadcast.disconnect(this);
    }

    render(){
        let app = Application.instance;
        if (app.studioSettings && app.studioSettings.enabled){
            return <button style={{position:"absolute",bottom:10,right:20,zIndex:100}} onClick={this.handleClick}>Launch Studio</button>
        }
        else {
            return null;
        }
    }
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let screen = window.screen;
        let width = screen.width / 2;
        if (width < 1600){
            width = 1600;
        }
        let height = screen.height - 140;
        let href = Application.instance.studioSettings.launchUrl;
        let domain = Application.instance.domain;
        Application.instance.studioWindow = window.open(href,"lisa.studio:" + domain,"width=" + width + ",height=" + height);
        

    }

    
}