import React from 'react';
import {  makeComponent } from '../../core';
import { ClickThrottle } from '../helpers/ClickThrottle';
import { InputControl } from '../../core';
import { DisplayProps } from '../Display';


export const ReadonlyField = makeComponent(class extends React.Component<DisplayProps>{

    
    render(){

        let field = this.props.field;
        let value;
        if (field){
            value = field.formattedValue;
        }

        let content;
       
        if((field.label === "HSA Carry Fwd" || field.label === "LSA Carry Fwd") &&  value === null){
            value = "No Carry Forward";
        }     
        let contentClasses = ['RT-Static-Field__content'];
        if (field.style) {
            switch (field.style) {
                case "danger":
                    contentClasses.push("text-danger");
                    break;
                case "warning":
                    contentClasses.push("text-warning");
                    break;
                case "info":
                    contentClasses.push("text-info");
                    break;
                case "success":
                    contentClasses.push("text-success");
                    break;
            }
        }
        if (this.props.textStyle){
            contentClasses.push("text-" + this.props.textStyle);
        }
        if (this.props.size){
            contentClasses.push("text-" + this.props.size);
        }
        let valueElem;
        if (field.lookup) {
            let lookupLabel = field.lookupLabel;
            valueElem = (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>{value}</div>
                    <div style={{ marginLeft: 8 }}>
                        {lookupLabel}
                    </div>
                </div>
            );
        } 
        else if (field.type == "logical"){
            if (field.value){
                valueElem = <div style={{display:"flex",alignItems:"center"}}>
                    {this.renderStaticCheckmark()}
                    <div>Yes</div>
                </div>
            }
            else {
                valueElem = "No";
            }
    
        }
        else {
            valueElem = value;
        }
        if (this.props.onClick) {
            content = (
                <a
                    style={{ display: "block" }}
                    href="#"
                    onClick={this.handleHyperlinkClick}
                >
                    {valueElem}
                </a>
            );
        } else {
            content = valueElem;
        }
        let labelElem;
        let label = this.props.label;
        if (label && !this.props.hideLabel) {
            labelElem = (
                <label className="RT-Static-Field__label">
                    {label} {this.props.toolTip}
                </label>
            );
        }
        if (!content && content !== 0){
            content = '\u00A0';
        }
        return (
            <div className="RT-Static-Field">
                {labelElem}
                <div className={contentClasses.join(' ')}>
                    {content}
                </div>
            </div>
        );
    }

    clickThrottle = new ClickThrottle();

    handleHyperlinkClick = (e: React.MouseEvent) => {
        this.clickThrottle.handle(e,()=> {
            e.preventDefault();
            e.stopPropagation();
            this.props.onClick.trigger();
        });
    };

    renderStaticCheckmark(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{display:"block",width:20,height:20,fill:"var(--rt-primary-color)"}}><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>);
    }

});