import { useState } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import toastr from 'toastr';
import { Line } from 'ktm-ui';
import DatePicker from 'react-date-picker';

const BulkEditEmployeeDeadlineCampaignModal = ({
  showBulkEditEmployeeDeadlineCampaignModal,
  policyId,
  employeeIds,
  campaignLaunchDate,
  selectionDeadline,
  closeBulkEditEmployeeDeadlineCampaignModal,
}) => {
  const [selectedDeadline, setSelectedDeadline] = useState(selectionDeadline);
  const [saveCompleted, setSaveCompleted] = useState(false);
  const [showEmailPrompt, setShowEmailPrompt] = useState(false);

  const handleSave = async () => {
    const body = {
      policyId,
      employeeIds,
      newSelectionDeadline: selectedDeadline.toLocaleDateString(),
    };

    try {
      const response = await axios.put('/api/Flex/updateemployeedeadlinecampaign', body);
      if (response.data.success) {
        toastr.success("Employee's campaign updated successfully", 'Success');
        setSaveCompleted(true);
        setShowEmailPrompt(true);
      } else {
        toastr.error(response.data.message, 'Error');
        closeBulkEditEmployeeDeadlineCampaignModal();
      }
    } catch (error) {
      toastr.error("Failed to update employee's campaign", 'Error');
    }
  };

  const handleSendEmail = async () => {
    const body = {
      PolicyId: policyId,
      RecipientIds: employeeIds,
    };

    try {
      const response = await axios.put('/api/Flex/sendselectionemails', body);
      if (response.data.success) {
        toastr.success('Email sent successfully', 'Success');
      } else {
        toastr.error(response.data.message, 'Error');
      }
    } catch (error) {
      toastr.error('Failed to send email', 'Error');
    }

    setSaveCompleted(false);
    closeBulkEditEmployeeDeadlineCampaignModal();
  };

  const handleCloseEmailModal = () => {
    setSaveCompleted(false);
    closeBulkEditEmployeeDeadlineCampaignModal();
  };

  const editSelectionDateModal = (
    <Modal
      className="edit-employee-campaign-modal-container"
      show={showBulkEditEmployeeDeadlineCampaignModal}
    >
      <Modal.Header className="campaign-edit-employee-modal-header">
        <Modal.Title>Edit Employee Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body className="campaign-edit-employee-modal-body">
        <Card>
          <Line>
            <div className="date-picker-container">
              <label className="RT-Label">Selection Deadline</label>
              <DatePicker
                onChange={(date) => setSelectedDeadline(date)}
                value={new Date(selectedDeadline)}
                format={'y-MM-dd'}
                minDate={new Date()}
                clearIcon={null}
              />
            </div>
          </Line>
        </Card>
      </Modal.Body>
      <Modal.Footer className="campaign-edit-employee-modal-footer">
        <Button variant="secondary" onClick={() => closeBulkEditEmployeeDeadlineCampaignModal()}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const sendEmailModal = (
    <Modal className="edit-employee-campaign-modal-container" show={showEmailPrompt}>
      <Modal.Header className="campaign-edit-employee-modal-header">
        <Modal.Title>Edit Employee Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body className="campaign-edit-employee-modal-body">
        <Card>
          <p style={{ fontWeight: 'bold', color: 'red' }}>
            Do you want to send a flex email to the employees?
          </p>
        </Card>
      </Modal.Body>
      <Modal.Footer className="campaign-edit-employee-modal-footer">
        <Button variant="secondary" onClick={handleCloseEmailModal}>
          No
        </Button>
        <Button variant="primary" onClick={handleSendEmail}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const today = new Date();
  const hasLaunched = new Date(campaignLaunchDate) <= today;

  return !saveCompleted ? editSelectionDateModal : hasLaunched ? sendEmailModal : null;
};

export default BulkEditEmployeeDeadlineCampaignModal;
