import axios from "axios";
import { Card } from "ktm-ui";
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import "../styles/FlexTemplates.css";
import toastr from "toastr";
import { CustomLoading } from "components/CustomLoading";

const defaultTemplate =  'Hello *|FNAME|*\n\nWelcome to your flex dollar program!\n\nYour employer has given you a yearly limit and wants you to choose where those dollars work best for you.\n\nComplete the process below to learn more about the different options available and make your selection.\n\nSincerely,\nNational HealthClaim\n\nWe administer your flex dollar program.';
export default class FlexSelectionTemplate extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedTemplate: "Default",
            customTemplateContent: "",
            isDisabled: true,
        };

        this.edit = this.edit.bind(this);
        this.fetchCustomTemplate = this.fetchCustomTemplate.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount(): void {
        this.fetchCustomTemplate();
    }

    fetchCustomTemplate(): void {
        axios
        .get("/api/Flex/getcustomflexselectiontemplate?policyId="+this.props.policyId)
        .then((response) => {
            if (response.data.success) {
                this.setState({
                    loading: false,
                    selectedTemplate: response.data.customFlexSelectionTemplate ? "Custom" : "Default",
                    customTemplateContent: response.data.customFlexSelectionTemplate || "",
                });
            }
            else
            {
                toastr.error("Error while fetching template","Error");
            }
        })
        .catch((error) => {
            toastr.error("Error while fetching template","Error");
        });
    }

    edit(): void {
        this.setState({ isDisabled: false });
    }

    handleSave(): void {
        this.setState({ isDisabled: true });
        axios
        .put("/api/Flex/updatecustomflexselectiontemplate", {
            policyId: this.props.policyId,
            customFlexSelectionTemplate: this.state.customTemplateContent,
            templateType: this.state.selectedTemplate
        })
        .then((response) => {
            if (response.data.success) {
                this.fetchCustomTemplate();
                toastr.success("Template saved successfully","Success");
            }
            else
            {
                toastr.error("Error while saving template","Error");
            }
        })
        .catch((error) => {
            toastr.error("Error while saving template","Error");
        });
    }

    handleCancel(): void {
        this.setState({ 
            selectedTemplate: "Default",
            isDisabled: true 
        });
    }

    toggleTemplateOption(e) {
        this.setState({ selectedTemplate: e.target.value });
    }

    render() {
        var content = null;
        if (this.state.loading) {
            content = (<CustomLoading />);
        } else {
            content = (
                <Card 
                    label="Flex Selection Template"
                    actions={<><a href="#" className="RT-Link" onClick={this.edit}>Edit</a></>}
                >
                    <div className="selection-options">
                        <label>
                            <input
                            className="default-checkbox"
                            type="checkbox"
                            value={"Default"}
                            checked={this.state.selectedTemplate == "Default"}
                            onChange={(e) => this.toggleTemplateOption(e)}
                            disabled={this.state.isDisabled}
                            />
                            Default
                        </label>
                        <label>
                            <input
                            className="custom-checkbox"
                            type="checkbox"
                            value={"Custom"}
                            checked={this.state.selectedTemplate == "Custom"}
                            onChange={(e) => this.toggleTemplateOption(e)}
                            disabled={this.state.isDisabled}
                            />
                            Custom
                        </label>
                    </div>
                    <div className="merge-tags">
                        <label>Merge Tags</label>
                        <Table className="merge-tags-table">
                            <thead>
                                <tr>
                                    <th>TAG</th>
                                    <th>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{`*|FNAME|*`}</td>
                                    <td>Employee's first name</td>
                                </tr>
                                <tr>
                                    <td>{`*|LNAME|*`}</td>
                                    <td>Employee's last name</td>
                                </tr>
                                <tr>
                                    <td>{`*|LIMIT|*`}</td>
                                    <td>Total assigned limit</td>
                                </tr>
                                <tr>
                                    <td>{`*|DEADLINE|*`}</td>
                                    <td>Selection deadline</td>
                                </tr>
                                <tr>
                                    <td>{`*|PAFIRST|*`}</td>
                                    <td>Plan admin first name</td>
                                </tr>
                                <tr>
                                    <td>{`*|PALAST|*`}</td>
                                    <td>Plan admin last name</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="selection-email-template-content">
                        <label>{this.state.selectedTemplate+" Template"}</label>
                        <textarea 
                            className="email-template-textarea"
                            value={this.state.selectedTemplate == "Default" ? defaultTemplate : this.state.customTemplateContent}
                            disabled={this.state.isDisabled}
                            onChange={(e) => this.setState({ customTemplateContent: e.target.value })}
                        >
                        </textarea>
                    </div>
                    <div className="flex-templates-buttons">
                        <button className="RT-Button" disabled={this.state.isDisabled} onClick={this.handleCancel}>Cancel</button>
                        <button className="RT-Button RT-Button--primary RT-Button--large RT-Button--large" disabled={this.state.isDisabled} onClick={this.handleSave}>Save</button>
                    </div>
                </Card>
            );
        }

        return (<div className="flex-selection-template-container">{content}</div>);
    }
}