import React from 'react';

export class DialogContainer extends React.Component<{size:string,zIndex:number,animate?:boolean,height?:string,isLookup?:boolean}>{


    animationComplete:boolean;

    constructor(props){
        super(props);
        if (!this.props.animate){
            this.animationComplete = true;
        }
    }

    componentDidMount(){

        setTimeout(()=>{
            this.animationComplete = true;
            this.forceUpdate();
        });
    }

    render(){
    
        let size = this.props.size || "small";
        let sizeClass = "RT-Modal--" + size;
        let outerClass = "RT-Modal " + sizeClass;
        
        if (this.props.isLookup){
            outerClass += " RT-Modal--lookup";
        }
        else {
            outerClass += " RT-Modal--dialog";
        }
        
        let outerStyle = {zIndex:this.props.zIndex + 1};
        let containerClass = "RT-Modal__container " + sizeClass;
        if (this.props.height){
            containerClass += " RT-Modal--height-" + this.props.height;
        }
        let containerStyle:React.CSSProperties;
        let backdropStyle:React.CSSProperties;
        if (this.animationComplete){
            containerStyle={transform:"none"};
            backdropStyle={opacity:1,zIndex:this.props.zIndex};
        }
        else {
            if (this.props.animate){
                containerStyle={transform:"translateY(-40px)"};
            }
            backdropStyle = {opacity:0,zIndex:this.props.zIndex};
        }
        let decorate;

        
        return (
            <div >
                <div
                    className={outerClass}
                    style={outerStyle}
                >
                    <div className={containerClass}  style={containerStyle}  {...decorate}>
                        {this.props.children}
                        <div className="RT-Modal__bottom-spacer" />
                    </div>
                </div>
                <div className="RT-Modal__backdrop" style={backdropStyle}/>
            </div>
        );
    }


}
