import React from "react";
import { Switch, Route } from "react-router-dom";
import HomeScreen from "./HomeScreen";
import ClaimHistory from "./ClaimHistory";
import ClaimEOB from "./ClaimEOB";
import AccountScreen from "./AccountScreen";
import EligibleExpensesScreen from "./EligibleExpensesScreen";
import LimitCalculationScreen from "./LimitCalculationScreen";
import ProfileScreen from "./ProfileScreen";

export default class Main extends React.Component<any, any> {
  render() {
    const { employeeId, claimId } = this.props;

    return (
      <main style={{flexGrow: 1, overflowY: "auto", padding: 20}}>
        <Switch>
          <Route path="/ops/employee/home" component={() => <HomeScreen employeeId={employeeId} />} />
          <Route path="/ops/employee/account" component={() => <AccountScreen employeeId={employeeId}/>} />
          <Route path="/ops/employee/claimhistory" component={() => <ClaimHistory employeeId={employeeId} />} />
          <Route path="/ops/employee/vieweob" component={() => <ClaimEOB employeeId={employeeId} claimId={claimId} />} />
          <Route path="/ops/employee/eligibleexpenses" component={() => <EligibleExpensesScreen employeeId={employeeId} claimId={claimId} />} />
          <Route path="/ops/employee/limitcalculation" component={() => <LimitCalculationScreen employeeId={employeeId} />} />
          <Route path="/ops/employee/profile" component={() => <ProfileScreen employeeId={employeeId} />} />
        </Switch>
      </main>
    );
  }
}
