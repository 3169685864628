import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card} from 'ktm-ui';
import TaxLookup from 'planadmin/lookups/Tax_jurisdiction';
import { isConstructorDeclaration } from 'typescript';

interface IdsPolicy {
    Id:string;
    Name:string;
    PolicyNo:string;
    EnrollmentNote:string;
    DependentAge:number;
    StudentAge:number;
    TaxJurisdiction_Id:string;
    Type:string;
    StartDate:string;
    UpdatedAt:string;
    UpdatedBy:string;
};

interface IdsTaxJurisdiction {
    Id:string;
    Name:string;
};

export default KTMScreen({name:"planadmin.policy.edit-details"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsPolicy:IdsPolicy;
        dsTaxJurisdiction:IdsTaxJurisdiction;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsPolicy";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Edit policy details";
    }

    tax_lookup({getValue,eventValue}:RenderEvent){
        return <TaxLookup eventValue={eventValue}/>
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.detail_card)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit Policy Details">
            </Dialog.Header>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    detail_card({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display required field={field("Type")} size="medium" textStyle="bold"/>
                <Line>
                    <Display required field={field("DependentAge")}/>
                    <Display required field={field("StudentAge")}/>
                </Line>
                <Display required field={field("TaxJurisdiction_Id")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsPolicy.Name":this.data.dsPolicy.Name,
            "@dsPolicy.DependentAge":this.data.dsPolicy.DependentAge,
            "@dsPolicy.StudentAge":this.data.dsPolicy.StudentAge,
            "@dsPolicy.TaxJurisdiction_Id":this.data.dsPolicy.TaxJurisdiction_Id,
            "@dsPolicy.Type":this.data.dsPolicy.Type,
            "@dsPolicy.StartDate":this.data.dsPolicy.StartDate,
            "@policy_id":this.props.policy_id,
        });
        screen.recordChanged("updated",this.props.policy_id);
        screen.close({"continue":true});
    }

});
