
import { ScreenRef } from "./ActionEvent";
import {executeCommands,processErrors} from './ActionRunner';
import { executeConfig } from "./ConfigurationRunner";
import { IRequestCallType } from "./Request";
import { IRecordSchema } from "./Schema";
import { IActionDefinition, IActionResult, ICanvasError, ICodeError, IScriptMethod, ITemplateDefinition } from "./types";

export interface IServiceResponse {
    actions:{[name:string]:IActionDefinition};
    batches:{[name:string]:IScriptMethod},
    schema:IRecordSchema;
    templates:{[name:string]:ITemplateDefinition}
    run:any[];
    configure:any[];
    errorTable:any[];
    success:boolean;
    retryAfterWarnings:boolean;
    initVariables:any;
    debug:any;
    error:any;
    code:any;
    codeErrors:ICodeError[];
    codeHTML:string;
    returnValue:any;
    screenId?:string;
}

export class Response {
    static async process(screen:ScreenRef,callType:IRequestCallType, res:IServiceResponse):Promise<IActionResult>{

        if (callType == "start"){
            await Response.loadStartValues(screen,res);
        }
        if (res.error){
            throw res.error.message || "Request failed";
        }
        if (res.errorTable && res.errorTable.length){
            let messages:string [] = [];
            for (let i =0 ; i < res.errorTable.length;i++){
                let item = res.errorTable[i];
                messages.push(item.message);
            }
            if (!messages.length){
                messages.push("Unknown API Error");
            }
            let error:ICanvasError = {
                messages,
                $$error:true,
                sourceCode:res.codeHTML,
                codeErrors:res.codeErrors
            }
            throw error;
        }
        let run = res.run;
        if (run){
        
            await executeCommands(screen,run);
            let error =  processErrors(screen,run);
            if (error){
                if (error.showDialog){
                    throw error;
                }
                throw "stopped";
            }
            
        }
        screen.returnValue = res.returnValue;
        return {continue:true};
    }

    static async loadStartValues(screen:ScreenRef,res:IServiceResponse){
        let canvas = screen.canvas;
        canvas.setSchema(res.schema);
        if (res.configure){
            await executeConfig(screen,res.configure);
        }
        if (res.initVariables){
            for(let key in res.initVariables){
                let field = canvas.getFieldRef(key,null);
                canvas.setRecordValueNoUpdate(field,res.initVariables[key]);
            }
        }
        canvas.scriptMethods = res.batches;
        canvas.actions = res.actions;
        canvas.templates = res.templates;
        canvas.screenId = res.screenId;
    }
}
