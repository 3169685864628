import React from "react";
import Spinner from "components/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTable, useSortBy, usePagination } from "react-table";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const ClaimsTable = ({ data, employeeId }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Claim Number",
        accessor: "claimNo",
      },
      {
        Header: "Submitted",
        accessor: "claimDate",
        Cell: ({ value }) => moment(value).utc().format("MMM DD, YYYY"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const statusMap = {
            SUBMITTED: "SUBMITTED",
            PENDING: "UNDER REVIEW",
            PAID: "PAID",
            ZEROPAID: "ZEROPAID",
            ADJUDICATED: "PENDING PAYMENT",
          };
          return statusMap[value] || value;
        },
      },
      {
        Header: "Type",
        accessor: "accountType",
      },
      {
        Header: "Claimed ($)",
        accessor: "totalClaimed",
        Cell: ({ row }) =>
          row.original.status === "SUBMITTED" ? "--" : row.original.totalClaimed.toFixed(2),
        className: "text-right",
      },
      {
        Header: "Approved ($)",
        accessor: "totalApproved",
        Cell: ({ row }) =>
          row.original.status === "SUBMITTED" ? "--" : row.original.totalApproved.toFixed(2),
        className: "text-right",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Link to={`/ops/employee/vieweob?claimId=${row.original.id}&employeeId=${employeeId}`} className="btn btn-outline-primary pd-y-5">
            View
          </Link>
        ),
        className: "text-right",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="table table-hover black">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown />
                      ) : (
                        <FaSortUp />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps({
                  // onClick: () => {
                  //   window.location.href = `/ops/employee/vieweob?claimId=${row.original.id}&employeeId=${employeeId}`;
                  // },
                  // style: {
                  //   cursor: "pointer",
                  // },
                })}
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination" style={{ float: "inline-end" }}>
        <button className="btn btn-primary btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <span style={{ fontSize: 17, paddingTop: 4, paddingLeft: 20, paddingRight: 20 }}>
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
        <button className="btn btn-primary btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
      </div>
    </>
  );
};

export default class ClaimHistory extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      claims: [],
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      currHsaLimit: 0,
      currHsaBalance: 0,
      currLsaLimit: 0,
      currLsaBalance: 0,
      error: null,
      buttonText: "Push New Claim",
      buttonDisplay: "none",
      showHSAButton: false,
      showLSAButton: false,
      pendingDisplay: "none",
      enableWellness: false,
    };

    this.startLoading = this.startLoading.bind(this);
    this.generateTestClaims = this.generateTestClaims.bind(this);
  }

  componentDidMount() {
    const { employeeId } = this.props;

    if (employeeId) {
      this.startLoading(employeeId);
    } else {
      console.error("Employee ID not found in query string");
      this.setState({ error: "Employee ID not found in query string" });
    }
  }

  async startLoading(employeeId) {
    try {
      const claimsResponse = await axios.get(`/api/claims/getclaims?employeeId=${employeeId}`);
      const { claims } = claimsResponse.data;

      const queuedImagesResponse = await axios.get(`/api/upload/getqueuedimagecount?employeeId=${employeeId}`);
      const response = queuedImagesResponse.data;

      let showHSAButton = false;
      let showLSAButton = false;
      let enableWellness = false;
      let pendingDisplay = "none";
      let buttonDisplay = "none";
      let buttonText = "Push New Claim";

      response.type.forEach((element) => {
        if (element.accountType === "HSA") {
          showHSAButton = true;
        } else if (element.accountType === "LSA") {
          showLSAButton = true;
          enableWellness = response.enableWellness;
        }
      });

      if (response.queuedImageCount > 0) {
        pendingDisplay = "";
      }

      if (response.queuedImageCount > 0) {
        buttonDisplay = "";
        if (response.pendingClaimCount > 0 || response.submittedClaimCount > 0) {
          buttonText = "Push Appended Images";
        }
      }

      this.setState({
        loaded: true,
        claims,
        error: null,
        showHSAButton,
        showLSAButton,
        enableWellness,
        pendingDisplay,
        buttonDisplay,
        buttonText,
      });
    } catch (error) {
      console.error("Error loading data: ", error);
      this.setState({
        loaded: true,
        error: "Failed to load claim data. Please try again later.",
      });
    }
  }

  generateTestClaims = async () => {
    const { employeeId } = this.props;
    await axios
      .post(`/api/upload/GenerateTestClaim?employeeId=${employeeId}`)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
      });
  };

  renderContent() {
    const {
      loaded,
      claims,
      error,
      buttonText,
      showHSAButton,
      showLSAButton,
      pendingDisplay,
      enableWellness,
    } = this.state;

    const { employeeId } = this.props;

    if (!loaded) {
      return <Spinner />;
    }

    if (error) {
      return <div className="error">{error}</div>;
    }

    if (claims.length === 0) {
      return <div style={{ paddingTop: 50, paddingLeft: 50, paddingBottom: 20, fontSize:17 }}>No claims found for this employee.</div>;
    }

    return (
      <div className="br-mainpanel pd-b-50 site-content">
        <div
          className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30"
          style={{ paddingTop: 50, paddingLeft: 50, paddingBottom: 20 }}
        >
          <button
            className="btn btn-danger"
            onClick={this.generateTestClaims}
            style={{ display: this.state.buttonDisplay }}
          >
            {buttonText}
          </button>
        </div>

        <div className="br-pagebody no-padding" style={{ maxWidth: 1250, display: pendingDisplay }}>
          <div className="br-section-wrapper mg-x-50 mg-b-30 no-padding">
            <div className="card mg-t-35">
              <div className="card-header tx-medium tx-uppercase bg-white" style={{ color: "black" }}>
                You have submitted image(s)
              </div>
              <div className="card-body" style={{ color: "black" }}>
                These images have been submitted. They will be assigned a claim number and placed in the
                queue for processing within 24 hours. You can still modify/delete your images until then.
                {showLSAButton && enableWellness ? (
                  <span style={{ float: "right" }}>
                    <Link to={`/ops/employee/account?employeeId=${employeeId}&type=LSA`} className="btn btn-outline-primary pd-y-5">
                      View WSA
                    </Link>
                  </span>
                ) : (
                  ""
                )}
                {showLSAButton && !enableWellness ? (
                  <span style={{ float: "right" }}>
                    <Link to={`/ops/employee/account?employeeId=${employeeId}&type=LSA`} className="btn btn-outline-primary pd-y-5">
                      View LSA
                    </Link>
                  </span>
                ) : (
                  ""
                )}

                {showHSAButton ? (
                  <span style={{ float: "right" }}>
                    <Link to={`/ops/employee/account?employeeId=${employeeId}&type=HSA`} className="btn btn-outline-primary pd-y-5 mg-r-10">
                      View HSA
                    </Link>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="br-pagebody no-padding" style={{ maxWidth: 1250 }}>
          <div className="br-section-wrapper mg-x-50 mg-b-30 no-padding">
            <div
              className="bd bd-gray-300 rounded table-responsive br-mainpanel pd-b-50 site-content"
              style={{
                backgroundColor: "white",
                padding: 20,
              }}
            >
              <div
                style={{
                  fontSize: 17,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <span style={{ fontSize: 20, fontWeight: "bold", flexGrow: 1 }}>Claim History</span>
              </div>
              <ClaimsTable data={claims} employeeId={employeeId}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
