import { Button, Modal } from "react-bootstrap";
import { CustomDropdown } from "./CustomDropdown";
import { useState, useEffect } from "react";
import { Card, Line } from "ktm-ui";
import DatePicker from "react-date-picker";

const EditCompanyStatusModal = ({ showEditModal, selectedOption, setSelectedOption, closeModal }) => {
    const terminationNoteOptions = ["--Select--", "No longer operating", "Switched providers", "Other"];
    const initialTerminationNote = terminationNoteOptions.includes(selectedOption.note) ? selectedOption.note : "Other";
    const initialOtherTerminationNote = terminationNoteOptions.includes(selectedOption.note) ? "" : selectedOption.note;

    const [status, setStatus] = useState(selectedOption.status);
    const [terminationDate, setTerminationDate] = useState(selectedOption.date ? new Date(selectedOption.date) : null);
    const [terminationNote, setTerminationNote] = useState(initialTerminationNote);
    const [otherTerminationNote, setOtherTerminationNote] = useState(initialOtherTerminationNote);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        validateForm();
    }, [status, terminationDate, terminationNote, otherTerminationNote]);

    const handleSave = () => {
        const finalTerminationNote = terminationNote === "Other" ? otherTerminationNote : terminationNote;
        setSelectedOption({
            status: status,
            date: terminationDate,
            note: finalTerminationNote
        });
        closeModal();
    };

    const handleDateChange = (date) => {
        setTerminationDate(date);
    };

    const handleNoteChange = (e) => {
        setOtherTerminationNote(e.target.value);
    };

    const validateForm = () => {
        if (status === "Pending Cancellation" && !terminationDate) {
            setIsSaveDisabled(true);
        } else {
            setIsSaveDisabled(false);
        }
    };

    return (
        <Modal
            className="react-modal edit-company-status-modal"
            show={showEditModal}
        >
            <Modal.Header>
                <Modal.Title>Edit Company Status</Modal.Title>
            </Modal.Header>
            <Modal.Body className="edit-company-status-modal-body">
                <Card>
                    <Line>
                        <div className="RT-TextBox">
                            <label className="RT-Label">Company Status</label>
                            <CustomDropdown
                                options={["Active", "Pending Cancellation", "Cancelled"]}
                                selectedOption={status}
                                setSelectedOption={(value) => {
                                    setStatus(value);
                                    validateForm();
                                }}
                            />
                        </div>
                        <div className="date-picker-container">
                            <label className="RT-Label">Termination Date</label>
                            <DatePicker
                                onChange={handleDateChange}
                                value={terminationDate}
                                format={"y-MM-dd"}
                                minDate={new Date()}
                                clearIcon={null}
                                disabled={status !== "Pending Cancellation"}
                            />
                        </div>
                    </Line>
                    <Line>
                        <div className="RT-TextBox Termination-Note">
                            <label className="RT-Label">Reasons For Cancelling</label>
                            <div className="RT-TextBox__container">
                                <CustomDropdown
                                    options={terminationNoteOptions}
                                    selectedOption={terminationNote}
                                    setSelectedOption={(value) => {
                                        setTerminationNote(value);
                                        if (value !== "Other") {
                                            setIsSaveDisabled(false);
                                        }
                                        validateForm();
                                    }}
                                    disabled={status === "Active"}
                                />
                                {terminationNote === "Other" && status !== "Active" && (
                                    <div className="RT-TextBox Termination-Note" style={{marginTop:10}}>
                                        <label className="RT-Label">Termination Note</label>
                                        <input
                                            className="RT-TextBox__input"
                                            type="text"
                                            placeholder="Type here..."
                                            value={otherTerminationNote}
                                            onChange={handleNoteChange}
                                            style={{ marginTop: 10 }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Line>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Close</Button>
                <Button variant="primary" onClick={handleSave} disabled={isSaveDisabled}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCompanyStatusModal;
