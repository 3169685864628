import { makeComponent, RenderEvent, Template } from 'ktm-ui/core';
import React from 'react';


export const Timeline = makeComponent(class extends React.Component<{event?:RenderEvent}>{
    render(){
        let children = this.props.children;
        let event = this.props.event;
        if (!event) return null;
        return <div>
            {React.Children.map(children,(child:any,index) => {
                if (!child) return child;
                let template = child.props.template;
                if (template){
                    let className = "RT-Timeline__item";
                    let isActive = child.props.isActive;
                    if (!isActive){
                        className += " RT--info";
                    }
                    let content = event.draw(template);
                    if (content){
                        return <div className={className}>{content}</div>
                    }
                }
                return null;
            })}
        </div>
    }
});

export const TimelineItem = makeComponent(class extends React.Component<{isActive:boolean,template:string | Template}>{
    render(){
        return null;
    }
});

