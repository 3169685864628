import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Button,DataGrid,IDataGridLayout} from 'ktm-ui';
import Add_overpaymentScreen from './Add_overpayment';

interface Ioverpayments {
    AccountType:string;
    TransType:string;
    TransDate:string;
    Claim_Id:string;
    RefNo:string;
    TransAmount:number;
    SeqNo:string;
    Employee_Id:string;
    ClaimNo:string;
};

export default KTMScreen({name:"enroll.employee.overpayment-list"}, class extends React.Component<{employee_id:string}> {

    data:{
        overpayments:Ioverpayments;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@overpayments";
        await KTMApi.start(screen,{
            "@employee_id":this.props.employee_id,
        });
        screen.title = "Overpayments";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.data_grid)}
            </Layout>
        );
    }

    data_grid({field,draw,action,collection}:RenderEvent){
        let actions = (<><Button action={action(this.new)} label="Add overpayment" style="add"/>
</>);
        let layout:IDataGridLayout = {
            columns:[
                {name:"AccountType",label:"Account Type",type:"character"},
                {name:"TransType",label:"Transaction Type",type:"character",width:160},
                {name:"TransDate",label:"Transaction Date",type:"date",width:166},
                {name:"ClaimNo",label:"Claim No",type:"character",width:200},
                {name:"RefNo",label:"Ref No",type:"character"},
                {name:"TransAmount",label:"Amount",type:"money",align:"right"},
            ],
            sort:[{name:"TransDate",descending:true}]
        };
        return (
            <DataGrid collection={collection("@overpayments")} layout={layout} actions={actions}/>
        );
    }

    async new({screen}:ActionEvent){
        await screen.openDialog(<Add_overpaymentScreen employee_id={this.props.employee_id}/>
,{onRecordChanged:this.refresh_row});
    }

    async refresh_row({screen}:ActionEvent){
        await screen.restart();
    }

});
