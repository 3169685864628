import axios from "axios";
import React from "react";
import { Button, Table } from "react-bootstrap";
import toastr from "toastr";
import { CSVLink } from "react-csv"; 
import { CustomLoading } from "components/CustomLoading";
import "../../styles/Reports.css";
import { EmptyStateTemplate } from "./Empty_state_Template";
import CustomPagination from "components/CustomPagination";
import { DateDropdownWithCalendarPicker } from "./DateDropdownWithCalendarPicker";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class LsaCustomCategoriesReport extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            selectedOption: this.props.benefitYearOptions[0],
            currentPage: 1,
            itemsPerPage: 12,
            totalLimit: 0,
            totalBalance: 0,
            percentageUtilization: 0,
            totalBalanceUsed: 0,
            showInactiveEmployees: false,
        };

        this.setSelectedOption = this.setSelectedOption.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.getTotals = this.getTotals.bind(this);
        this.toggleInactiveEmployees = this.toggleInactiveEmployees.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOption } = this.state;
        if (prevState.selectedOption !== selectedOption) 
        {
            this.setState({loading: true});
            this.fetchData();
        }
    }

    fetchData = () => {
        const { selectedOption } = this.state;
        var apiString: string;
        if (selectedOption.id === null) {
            apiString = `/api/Reports/customcategoriesutilization?policyId=${this.props.policyId}&AccountType=LSA&fromDate=${selectedOption.startDate.toLocaleString()}&toDate=${selectedOption.endDate.toLocaleString()}`;
        } else {
            apiString = `/api/Reports/customcategoriesutilization?policyId=${this.props.policyId}&AccountType=LSA&benefitYearId=${selectedOption.id}`;
        }
        axios.get(apiString)
            .then(response => {
                if (response.data.success) {
                    const resultToShow = this.state.showInactiveEmployees ? response.data.allResult : response.data.activeOnlyResult;
                    this.getTotals(resultToShow);
                } else {
                    toastr.error(response.data.message, "Error");
                }
                this.setState({loading: false});
            })
            .catch(error => {
                toastr.error(error, "Error");
            });
    }

    setSelectedOption = (option) => {
        this.setState({selectedOption: option});
    }
    
    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    getTotals = (data) => {
        const {
            selectedOption,
        } = this.state;

        let dataToExport = [];
        let totalLimit = 0;
        let totalBalance = 0;
        let totalBalanceUsed = 0;

        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let formattedItem = [
                item.benefit_category_name,
                item.custom_category_name,
            ];

            formattedItem.push(
                item.total_limit, 
                item.total_balance, 
                item.utilization_percentage+"%", 
                item.total_usage, 
            );

            totalLimit += parseFloat(item.total_limit);
            totalBalance += parseFloat(item.total_balance);
            totalBalanceUsed += parseFloat(item.total_usage);
            dataToExport.push(formattedItem);
        }

        const headerRow = [
            "Benefit Category", 
            "Enhanced Benefit"
        ];

        headerRow.push("Total Limit", "Total Balance", "Utilization %", "Total Amount Used");

        dataToExport.unshift(headerRow);

        let totalItem = ["Total", ""];

        let percentageUtilization = ((totalBalanceUsed / totalLimit) * 100).toFixed(2);

        totalItem.push(totalLimit.toFixed(2), totalBalance.toFixed(2), percentageUtilization+"%", totalBalanceUsed.toFixed(2));

        dataToExport.push(totalItem);

        const dateRangeRow = [
            "Date Range",
            `${selectedOption.dateRange}`
        ];

        dataToExport.unshift(dateRangeRow);
        
        this.setState({
            data: data,
            dataToExport: dataToExport,
            totalLimit: totalLimit.toFixed(2),
            totalBalance: totalBalance.toFixed(2),
            totalBalanceUsed: totalBalanceUsed.toFixed(2),
            percentageUtilization: percentageUtilization,
        });
    }

    setSelectedCostCentre = (option) => {
        let findCostCentre = this.state.costCentres.find(cc => cc.name === option);
        this.setState({
            selectedCostCentre: findCostCentre,
        });
    }

    setSelectedDivsion = (option) => {
        let findDivision = this.state.divisions.find(div => div.name === option);
        this.setState({
            selectedDivision: findDivision,
        });
    }

    exportToPdf = () => {
        const { dataToExport } = this.state;
    
        const header = dataToExport[1];
        const bodyRows = dataToExport.slice(2, dataToExport.length - 1);
        const total = dataToExport[dataToExport.length - 1];
      
        const headerData = this.formatRow(header, 'tableHeader');
        const bodyData = this.formatTableBody(bodyRows);
        bodyData.push(this.formatRow(total, 'tableFooter'));
    
        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            content: [
                {
                    columns: [
                        this.createLogo(),
                        this.createDateRange(dataToExport[0][1]),
                    ],
                },
                this.createTitle(),
                this.createTable(headerData, bodyData)
            ],
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download("LSA_Custom_Categories_Utilization.pdf");
    };
    
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    createTitle = () => ({
        text: 'LSA Enhanced Benefit Utilization Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map(row => this.formatRow(row, 'tableBody'));
    
    createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('auto'),
            body: [header, ...body]
        }
    });
    
    getPdfStyles = () => ({
        table: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 10,
            color: 'black'
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    toggleInactiveEmployees = () => {
        this.setState(
            prevState => ({ showInactiveEmployees: !prevState.showInactiveEmployees }),
            () => this.fetchData()
        );
    }
           
    render() {
        const { 
            data,
            selectedOption,
            currentPage,
            itemsPerPage,
            totalLimit,
            totalBalance,
            percentageUtilization,
            totalBalanceUsed,
            loading,
            dataToExport,
            showInactiveEmployees
        } = this.state;

        const {
            benefitYearOptions
        } = this.props;

        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(data.length / itemsPerPage);

        let contents = null;
        if (loading) {
            contents = (<CustomLoading />);
        } else {
            
            contents = (
                <div className="HSA-Benefit-Utilization-Table report-table-container">
                    <div className="report-toolbar"
                        style={{display: "flex", alignItems: "center", flexDirection: "row", flexWrap: "wrap"}}
                    >
                        <DateDropdownWithCalendarPicker
                            options={[{id:null, dateRange:"Custom", status: "", startDate: "", endDate: ""}, ...benefitYearOptions]}
                            selectedOption={selectedOption}
                            setSelectedOption={this.setSelectedOption}
                        />
                        <div className="benefit-year-status">
                            <span className="benefit-year-status-text">{selectedOption.status}</span>
                        </div>
                        <label className="utilization-deactivated-employees">
                            <input
                                type="checkbox"
                                checked={showInactiveEmployees}
                                onChange={this.toggleInactiveEmployees}
                                style={{marginRight:"10px"}}
                            />
                            Include deactivated employees
                        </label>
                        {dataToExport.length !== 0?
                        <div className="export-button-container">
                            <Button variant="primary" className="export-to-pdf" onClick={this.exportToPdf}>
                                <span className="pdf-text">Export to PDF</span>
                            </Button>
                            <CSVLink data={dataToExport} filename={"LSA_Enhanced_Benefits_Report.csv"} className="export-button">
                                <Button variant="primary" className="csv-button export-to-csv">
                                    <span className="csv-text">Export to CSV</span>
                                </Button>
                            </CSVLink>
                        </div>:null}
                    </div>
                    {data.length === 0 ? <EmptyStateTemplate />:
                    <>
                        <Table hover className="report-table">
                            <thead>
                                <tr>
                                    <th className="table-text-content">Benefit Category</th>
                                    <th className="table-text-content">Enhanced Benefit</th>
                                    <th>Total Limit</th>
                                    <th>Total Balance</th>
                                    <th>Utilization %</th>
                                    <th>Amount Used</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((item, index) => (
                                    <tr key={index} className="table-body-itme">
                                        <td className="table-text-content">{item.benefit_category_name}</td>
                                        <td className="table-text-content">{item.custom_category_name}</td>
                                        <td>{item.total_limit}</td>
                                        <td>{item.total_balance}</td>
                                        <td>{item.utilization_percentage}%</td>
                                        <td>{item.total_usage}</td>
                                    </tr>
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2} className="table-footer-item total-amount">Total</td>
                                    <td className="table-footer-item">{totalLimit}</td>
                                    <td className="table-footer-item">{totalBalance}</td>
                                    <td className="table-footer-item">{percentageUtilization}%</td>
                                    <td className="table-footer-item">{totalBalanceUsed}</td>
                                </tr>
                            </tfoot>
                        </Table>
                        <div className="pagination-container">
                            <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {data.length} entries</span>
                            </div>
                            <CustomPagination 
                                nPages={nPages} 
                                currentPage={currentPage} 
                                setCurrentPage={this.setCurrentPage} 
                            />
                        </div>
                    </>}
                </div>
            );
        }
        
        return (<>{contents}</>);
    }
}