import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  Layout,
  TabSet,
  Tab,
  RecordTabs,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Display, Button, EmptyState } from "ktm-ui";
import Ben_limitRecordScreen from "planadmin/ben_limit/Record";
import Ben_limitAddScreen from "planadmin/ben_limit/Add";
import { Renderer } from "react-dom";

interface IdsBenefits {
  Id: string;
  Policy_Id: string;
  PolicyBenefit_Id: string;
  BenefitCategory_Id: string;
  BenefitYear_Id: string;
  Name: string;
}

export default KTMScreen(
  { name: "planadmin.bencat.benefits" },
  class extends React.Component<{
    bencat_id: string;
    policy_id: string;
    benyear_id: string;
  }> {
    data: {
      dsBenefits: IdsBenefits;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefits";
      await KTMApi.start(screen, {
        "@bencat_id": this.props.bencat_id,
        "@policy_id": this.props.policy_id,
        "@benyear_id": this.props.benyear_id,
      });
      screen.title = "Benefits";
    }

    main({ field, draw, action }: RenderEvent) {
      return <Layout>{draw(this.tabset)}</Layout>;
    }

    tabset({ action, collection }: RenderEvent) {
      return (
        <RecordTabs
          collection={collection("@dsBenefits")}
          template="tabContent"
        //   actions={
        //     <Button
        //       label="+ Add benefit"
        //       action={action(this.add)}
        //       style="link"
        //     />
        //   }
        />
      );
    }

    tabContent({ getValue, action }: RenderEvent) {
      return (
        <Ben_limitRecordScreen
          policy_id={getValue("Policy_Id")}
          limit_id={getValue("Id")}
        />
      );
    }

    empty_state({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState label="No benefits have been created yet." icon="money">
          {/* <Button action={action(this.add)} label="+ Add benefit" style="primary"/> */}
        </EmptyState>
      );
    }

    async add({ screen }: ActionEvent) {
      await screen.openDialog(
        <Ben_limitAddScreen
          policy_id={this.props.policy_id}
          bencat_id={this.props.bencat_id}
          benyear_id={this.props.benyear_id}
        />,
        { onRecordChanged: this.after_new }
      );
    }

    async after_new({ screen, eventValue }: ActionEvent) {
      await KTMApi.refreshRow(screen, {
        collection: "@dsBenefits",
        rowid: eventValue,
        appendNewRows: true,
      });
      screen.locate("@dsBenefits", eventValue);
    }
  }
);
