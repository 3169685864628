import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Dialog, Button, Display, Line, Card } from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsBenefitYear {
  Id: string;
  StartDate: string;
  EndDate: string;
  RunoffMonths: number;
}

export default KTMScreen(
  { name: "planadmin.policy.edit-first-year" },
  class extends React.Component<{ year_id: string }> {
    data: {
      dsBenefitYear: IdsBenefitYear;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefitYear";
      await KTMApi.start(screen, {
        "@year_id": this.props.year_id,
      });
      screen.setOptions("RunoffMonths", [
        { value: "1", label: "1 month" },
        { value: "2", label: "2 months" },
        { value: "3", label: "3 months" },
      ]);
      screen.title = "Edit benefit year";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.acknowledgement)}
          {draw(this.details)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit Benefit Year" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      const isDisabled =
        this.data.dsBenefitYear.StartDate === null ||
        this.data.dsBenefitYear.EndDate === null ||
        this.data.dsBenefitYear.RunoffMonths === 0;

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display
              required
              field={field("StartDate")}
              toolTip={
                <CustomToolTip
                  header="Start Date"
                  bodyContent="A policy can only be backdated up to a maximum of 2 years prior to the Benefit End Date."
                />
              }
            />
            <Display required field={field("EndDate")} />
            <Display
              required
              field={field("RunoffMonths")}
              toolTip={
                <CustomToolTip
                  header="RunOff Months"
                  bodyContent="Instead of days, employees have run-off month(s) to get their previous year receipts submitted."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    acknowledgement({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <div style={{color: 'red', fontWeight: 'bold' }}>
            If you update the policy dates, the employees' start dates need to be manually updated as they won’t be automatically updated.
          </div>
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@dsBenefitYear.StartDate": this.data.dsBenefitYear.StartDate,
        "@dsBenefitYear.EndDate": this.data.dsBenefitYear.EndDate,
        "@dsBenefitYear.RunoffMonths": this.data.dsBenefitYear.RunoffMonths,
        "@year_id": this.props.year_id,
      });
      screen.close({ continue: true });
    }
  }
);
