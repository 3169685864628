import React from "react";
import {
    KTMScreen,
    ActionEvent,
    RenderEvent,
    renderScreen,
    Confirm,
    RecordHeader,
} from "ktm-ui";
import {KTMApi} from "api/KTMApi";
import {Layout, Button, DataGrid, IDataGridLayout} from "ktm-ui";
import RecordScreen from "./Record";
import NewScreen from "./New";
import AdvisorNewScreen from "enroll/advisor/New";

interface IdsResults {
    Id: string;
    Name: string;
    PolicyNo: string;
    PolicyHolder_Id: string;
    PrimaryAdvisor_Id: string;
    EnrollmentStatus: string;
    EnrollmentNote: string;
    PolicyHolder: string;
    Advisor: string;
    CreatedAt: string;
}

interface IdsNextPolicyNumber {
    PolicyNo: string;
}

var PolicyNumbers = [];
export default KTMScreen(
    {name: "enroll.policy.browse"},
    class extends React.Component<{}> {
        data: {
            dsResults: IdsResults;
            dsNextPolicyNumber: IdsNextPolicyNumber;
        };

        state: {
            newPolicyNumber: string;
        };

        render() {
            return renderScreen(this);
        }

        async start({screen}: ActionEvent) {
            screen.defaultRecord = "@dsResults";
            await KTMApi.start(screen, {});
            screen.title = "Enrollments";
        }

        main({field, draw, action}: RenderEvent) {
            return (
                <Layout>
                    {draw(this.header)}
                    {draw(this.pending_enrollments)}
                </Layout>
            );
        }

        header() {
            return (
                <RecordHeader label="Pending Enrollments"/>
            );
        }

        pending_enrollments({field, draw, action, collection}: RenderEvent) {
            let actions = (
                <>
                    <Button
                        action={action(this.new_enrollment)}
                        label="Start New Enrollment"
                        style="add"
                    />
                    <Button
                        action={action(this.view_enrollment_forms)}
                        label="View Enrollment Forms"
                        style="add"
                    />
                    <Button
                        action={action(this.search)}
                        label="Refresh list"
                        style="link"
                    />
                </>
            );
            let layout: IDataGridLayout = {
                columns: [
                    {
                        name: "PolicyNo",
                        label: "Policy No",
                        type: "character",
                        width: 145,
                    },
                    {
                        name: "PolicyHolder",
                        label: "Policy Holder",
                        type: "character",
                        width: 400,
                    },
                    {name: "Advisor", label: "Advisor", type: "character", width: 250},
                    {
                        name: "EnrollmentStatus",
                        label: "Status",
                        type: "character",
                        width: 100,
                    },
                    {
                        name: "EnrollmentNote",
                        label: "Note",
                        type: "longchar",
                        width: 275,
                    },
                    {name: "Name", label: "Name", type: "character"},
                ],
                sort: [{name: "PolicyNo", descending: true}],
            };
            return (
                <DataGrid
                    collection={collection("@dsResults")}
                    layout={layout}
                    actions={actions}
                    onRowClick={action(this.show_record)}
                />
            );
        }

        async search({screen}: ActionEvent) {
            await KTMApi.action(screen, "search", {});
        }

        confirmDialog() {
            return <Confirm message="Are you sure you want to refresh?"/>;
        }

        async show_record({screen}: ActionEvent) {
            await screen.browse(<RecordScreen policy_id={screen.getValue("Id")}/>, {
                onRecordChanged: this.refresh_row,
                label: "Policy",
            });
        }

        async refresh_row({screen, eventValue}: ActionEvent) {
            await KTMApi.refreshRow(screen, {
                collection: "@dsResults",
                rowid: eventValue,
            });
        }

        async view_enrollment_forms({screen}: ActionEvent) {
            window.open('/ops/enrollmentforms', '_blank');
        }
        
        async new_enrollment({screen}: ActionEvent) {
            await screen.openDialog(
                <NewScreen
                    new_policy_id={this.sortPolicyNumbers()}
                    policyNumbers={PolicyNumbers}
                />,
                {
                    onRecordChanged: this.on_create_enrollment,
                }
            );
        }

        sortPolicyNumbers() {
            var newPolicyNumber = null;
            PolicyNumbers = [];
            if (this.data.dsNextPolicyNumber) {
                let i = 0;
                for (; this.data.dsNextPolicyNumber[i] != null; i++) {
                    PolicyNumbers.push(this.data.dsNextPolicyNumber[i].PolicyNo);
                }
                PolicyNumbers.sort(function (a, b) {
                    return a - b;
                });
                i = i - 1;
                //grab the highest PolicyNo from Policies table, then increment
                newPolicyNumber = parseInt(PolicyNumbers[i]) + 1;
            }
            return newPolicyNumber.toString();
        }

        async enroll_advisor({screen}: ActionEvent) {
            await screen.openDialog(<AdvisorNewScreen/>);
        }

        async on_create_enrollment({screen, eventValue}: ActionEvent) {
            await screen.openOnStack(<RecordScreen policy_id={eventValue}/>);
            await KTMApi.refreshRow(screen, {
                collection: "@dsResults",
                rowid: eventValue,
            });
        }

        async run_payment({screen, eventValue}: ActionEvent) {
            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({}),
            };

            await fetch("/api/Payment/run", requestOptions);
        }

        async run_paymentbatch({screen, eventValue}: ActionEvent) {
            const requestOptions = {
                method: "GET",
            };

            await fetch("/api/Payment/paymentbatch", requestOptions);
        }

        async generate_payment_report({screen, eventValue}: ActionEvent) {
            // const requestOptions = {
            //     method: 'GET',
            //     headers: {'Content-Type': 'application/json'},
            //     body: JSON.stringify({
            //     })
            // }

            await fetch("/api/Payment/report")
                .then((response) => response.json())
                .then((data) => {
                    let a = document.createElement("a");
                    a.href = "data:application/octet-stream;base64," + data.base64;
                    a.download = "paymentreport.pdf";
                    a.click();
                });
        }

        async run_corehealth({screen, eventValue}: ActionEvent) {
        }

        async run_edis({screen, eventValue}: ActionEvent) {
        }
    }
);
