import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Column,Panel,Card} from 'ktm-ui';
import CustomToolTip from "../../components/CustomToolTip";

interface IdsBenCat {
  Id: string;
  LSALabel: string;
  AllowLSADependentClaims: boolean;
  Policy_Id: string;
}

export default KTMScreen({name:"planadmin.ben-limit.edit-lsa-settings"}, class extends React.Component<{limit_id:string, employeesCount:number}> {

    data:{
        dsBenCat:IdsBenCat;
    }

    state = {
        showCampaignWarning: false,
    };

    render() {
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen,{
            "@limit_id":this.props.limit_id,
        });
        screen.title = "Edit LSA options";
        await this.checkCampaignStatus();
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                    <Card>
                        <Line>
                            <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                                This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                                <br />
                                If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                            </span>
                        </Line>
                    </Card>
                )}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Header label="Edit LSA Options"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button 
                    action={action(this.submit)} 
                    label="Save" 
                    size="large" 
                    style="primary"
                />
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Display field={field("LSALabel")} />
          <Display
            field={field("AllowLSADependentClaims")}
            toolTip={
              <CustomToolTip
                header="Allow LSA Dependent Claims"
                bodyContent="This option determines if employees can reimburse LSA expenses for their dependents or if the LSA is an Employee Only benefit."
              />
            }
          />
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@dsBenCat.LSALabel": this.data.dsBenCat.LSALabel,
        "@dsBenCat.AllowLSADependentClaims":
          this.data.dsBenCat.AllowLSADependentClaims,
        "@limit_id": this.props.limit_id,
      });
      screen.close({ continue: true });
    }

    async checkCampaignStatus() {
        let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsBenCat.Policy_Id);
        let res = await response.json();
        if (res.isCampaignOpen) {
            this.setState({ showCampaignWarning: true });
        }
    }
});
