import React from "react";
import { ScreenRef } from "./ActionEvent";
import { AppSession, ISessionResult } from "./AppSession";
import { Broadcast } from "./Broadcast";
import { IServiceResponse } from "./Response";
import { IRequestCallType, IServiceRequest} from './Request';
import ScreenContainer from "./ScreenContainer";
import { IUpdateSpinnerState, SpinnerManager } from "./SpinnerManager";
import { ICanvasError, ISigninSettings,IStudioSettings } from "./types";
import { Url } from "./Url";


export type ISigninProps = {app:Application,done:(result:ISessionResult)=> void,token:string};

export class Application {
    notifications:Broadcast = new Broadcast();
    updateSpinnerState:IUpdateSpinnerState;
    spinner:SpinnerManager = new SpinnerManager(this);
    getMessageBox:() => React.ComponentType<{message:string,style:string}>;
    getConfirmDialog:() => React.ComponentType<{message:string,style:string}>;
    doPostRequest:(path:string,body:any) => Promise<any>;
    renderError:(error:ICanvasError) => any;
    renderSignin:(props:ISigninProps) => React.ReactElement;
    static components:{[name:string]:React.ComponentClass} = {};
   
    static instance:Application;
    static screens:{[name:string]:React.ComponentType} = {};

    currentScreen:{name:string,props?:any}
    screenKey:number = 1;
    navigator:React.Component;
    studioWindow:Window;
    host:string;
    activeScreens:ScreenContainer[] = [];
    domain:string;
    branch:string;
    appName:string;
    studioOpen:boolean;
    session:AppSession;
    signinSettings: ISigninSettings;
    studioSettings:IStudioSettings;

    constructor(){
        this.session = new AppSession(this);
    }

    navigate(screen:string,props?:any){
        var url = Url.stringify(this,{screen,props});
        if (this.navigator){
            (this.navigator as any).navigate(url);
        }
        /*
        this.currentScreen = {name:screen,props};
        this.screenKey++;
        if (this.navigator){
            this.navigator.forceUpdate();
        }
        */
    }

    registerScreen(screen:ScreenContainer){
        this.activeScreens.push(screen);
    }

    unregisterScreen(screen:ScreenContainer){
        let i = this.activeScreens.indexOf(screen);
        if (i != -1){
            this.activeScreens.splice(i,i);
        }
    }

    reloadScreen(screenName:string){
        for(let i = 0; i < this.activeScreens.length;i++){
            let activeScreen = this.activeScreens[i];
            if (activeScreen.canvas.launchParams.screen == screenName){
                activeScreen.reload();
            }
        }
    }

    static addComponents(namespace:string,manifest:any){
        if (manifest){
            for(let key in manifest){
                if (namespace){
                    Application.components[namespace + "." + key] = manifest[key];
                }
                else {
                    Application.components[key] = manifest[key];
                }
            }
        }
    }
    static getKind(name:string):any{
        return Application.components[name];
    }
   
}

Application.instance = new Application();