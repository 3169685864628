import React from "react";
import ReactButton from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class EditMinimumBalance extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      policyMinimumBalance: "Select",
      customValue: "",
      isCustom: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e) {
    e.preventDefault();
    e.stopPropagation();
    const selectedValue = e.target.value;
    this.setState({
      policyMinimumBalance: selectedValue,
      isCustom: selectedValue === "Custom",
      customValue: "",
    });
  }

  handleSave() {
    if (this.state.isCustom) {
      this.props.updateMinimumBalance(this.state.customValue);
    } else {
      this.props.updateMinimumBalance(this.state.policyMinimumBalance);
    }
  }

  render() {
    return (
      <Modal
        className="react-modal"
        show={this.props.showModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="react-modal-title">
          <Modal.Title>Minimum Balance Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label"> Minimum Balance </label>
          <div className="d-flex">
            <select
              className="form-select"
              value={this.state.policyMinimumBalance}
              onChange={(e) => this.handleOnChange(e)}
              style={{ width: "300px" }}
            >
              <option value={"Select"}>--Select--</option>
              <option value={0}>No minimum balance notification</option>
              <option value={"Custom"}>Custom Amount</option>
              <option value={1000}>$1000</option>
              <option value={2000}>$2000</option>
              <option value={5000}>$5000</option>
              <option value={10000}>$10000</option>
            </select>
            {this.state.isCustom && (
              <div className="input-group" style={{ marginLeft: "10px" }}>
                <span className="input-group-text">$</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter custom amount"
                  value={this.state.customValue}
                  onChange={(e) => this.setState({ customValue: e.target.value })}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ReactButton
            variant="light"
            onClick={this.props.toggleModal}
            style={{ width: "70px" }}
          >
            Cancel
          </ReactButton>
          <ReactButton
            className="RT-Button RT-Button--primary"
            onClick={() => this.handleSave()}
            style={{ width: "70px" }}
          >
            Save
          </ReactButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditMinimumBalance;