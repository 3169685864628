import React from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import ClaimSubmittedModal from "../modals/ClaimSubmittedModal";
import ClaimSubmissionFailedModal from "../modals/ClaimSubmissionFailedModal";
import ClaimSubmittedPendingModal from "../modals/ClaimSubmittedPendingModal";
import DoughnutChart from "./AccountDetail";
import Spinner from "components/Spinner";
import QueuedImages from "./QueuedImages";
import toastr from "toastr";

export default class AccountScreen extends React.Component<any, any> {
  state = {
    loaded: false,
    plan: null,
    files: null,
    empId: null,
    polBenId: null,
    queuedImages: null,
    isModalOpen: false,
    isFailedModalOpen: false,
    isPendingModalOpen: false,
    modal: null,
    loadedImages: "none",
    loadedSpinner: "",
    load: 4,
    loadedPictures: null,
    run: true,
    enableWellness: false,
    type: null
  };
  private myRef;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }

  async startLoading() {
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');
    const type = params.get('type');

    this.setState({empId : employeeId, type: type});

    axios
      .get(
        `/api/OpsEmployee/getaccountinfo?employeeId=${employeeId}&type=${type}`
      )
      .then((response) => {
        this.setState({
          loaded: true,
          plan: response.data.spendingAccounts,
          polBenId: response.data.polBenId,
          queuedImages: response.data.queuedImages,
          enableWellness: response.data.enableWellness,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    if (localStorage.getItem("intro2") == "completed") {
      this.setState({ run: false });
    }
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
    this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  closeFailedModal = () => {
    this.setState({ isFailedModalOpen: false });
  };

  closePendingModal = () => {
    this.setState({ isPendingModalOpen: false });
  };

  displayModal = () => {
    this.setState({ isModalOpen: true });
  };

  displayFailedModal = () => {
    this.setState({ isFailedModalOpen: true });
  };

  displayPendingModal = () => {
    this.setState({ isPendingModalOpen: true });
  };

  renderOpenModal() {
    if (!this.state.isModalOpen) return null;
    return <ClaimSubmittedModal close={this.closeModal}></ClaimSubmittedModal>;
  }

  renderFailedModal() {
    if (!this.state.isFailedModalOpen) return null;
    return (
      <ClaimSubmissionFailedModal
        close={this.closeFailedModal}
      ></ClaimSubmissionFailedModal>
    );
  }

  renderPendingModal() {
    if (!this.state.isPendingModalOpen) return null;
    return (
      <ClaimSubmittedPendingModal
        close={this.closeFailedModal}
      ></ClaimSubmittedPendingModal>
    );
  }

  setLoaded() {
    this.setState({ loadedImages: "", loadedSpinner: "none" });
  }

  setUnloaded() {
    this.setState({ loadedImages: "none", loadedSpinner: "" });
  }

  onDrop = (acceptedFiles) => {
    this.setState({ files: acceptedFiles });
  };

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  uploadPdf = async (pdfArray) => {
    const formData = new FormData();
    pdfArray.forEach((file, i) => {
      formData.append("pdffile" + i, file);
    });

    await axios
      .post(
        "/api/upload/UploadPDFs/" +
          this.state.empId +
          "/" +
          this.state.polBenId +
          "/" +
          this.state.plan.type,
        formData
      )
      .then((response) => {
        this.closePendingModal();
        if (response.data.success) {
          this.setState({ queuedImages: response.data.queuedImages });
          this.displayModal();
          toastr.success("Upload success", "Success");
        } else {
          toastr.error("Failed to upload files", "Error");
          this.displayFailedModal();
        }
      })
      .catch((error) => {
        this.displayFailedModal();
      });
  };

  uploadImage = async (imagesArray) => {
    const formData = new FormData();
    imagesArray.forEach((file, i) => {
      formData.append("imgfile" + i, file);
    });

    await axios
      .post(
        "/api/upload/UploadImages/" +
          this.state.empId +
          "/" +
          this.state.polBenId +
          "/" +
          this.state.plan.type,
        formData
      )
      .then((response) => {
        this.closePendingModal();
        if (response.data.success) {
          this.setState({ queuedImages: response.data.queuedImages });
          this.displayModal();
          toastr.success("Upload success", "Success");
        } else {
          toastr.error("Failed to upload files", "Error");
          this.displayFailedModal();
        }
      })
      .catch((error) => {
        this.displayFailedModal();
      });
  };

  onSubmit = (e) => {
    let self = this;
    this.setUnloaded();
    e.preventDefault();
    this.displayPendingModal();
    let imagesToUpload = [];
    let pdfToUpload = [];
    let hasPdf = false;

    this.state.files.map((file, i) => {
      if (file.type == "application/pdf") {
        pdfToUpload.push(file);
        hasPdf = true;
      } else {
        imagesToUpload.push(file);
      }
    });

    if (hasPdf) {
      self.uploadPdf(pdfToUpload);
    } else {
      self.uploadImage(imagesToUpload);
    }

    this.setState({ files: null });
  };

  removeFile = (index) => (e) => {
    this.setState({ files: this.state.files.filter((_, i) => i !== index) });
  };

  renderContent() {

    if (!this.state.loaded) {
      return <Spinner />;
    }

    let accountBox = null;
    let plan = this.state.plan;
    let currPercent = Math.round(
      (plan.currYear.available / plan.currYear.limit) * 100
    );
    let prevPercent = null;
    if (plan.prevYear != null) {
      prevPercent = Math.round(
        (plan.prevYear.Available / plan.prevYear.limit) * 100
      );
    }
    if (currPercent > 100) {
      currPercent = 100;
    }
    if (prevPercent > 100) {
      prevPercent = 100;
    }

    let disableSubmit = true;
    if (this.state.files) {
      disableSubmit = false;
    }

    let { enableWellness, empId } = this.state;
    let { type, label } = plan;

    let accountName = "";

    if (type === "LSA") {
      accountName = enableWellness ? "Wellness Spending Account" : "Lifestyle Spending Account";
    } else if (label) {
      accountName = label;
    } else {
      accountName = "Health Spending Account";
    }

    let prevYearDiv = null;
    if (plan.prevYear != null) {

      const prevYearData = [
        {name: accountName, 
         limit: plan.prevYear.limit, 
         balance: plan.prevYear.available,
         backgroundColor: plan.backgroundColor, 
         textColor: plan.textColor
        },
        ...plan.prevYearCustomCategories
      ];

      const totalLimit = prevYearData.reduce((acc, item) => acc + item.limit, 0).toFixed(2);

      prevYearDiv = (
        <div className="text-center">
          <DoughnutChart id={`prev-year-doughnut-chart-${plan.type}`} data={prevYearData} />
          <div className="text-center">
            <div style={{ margin: "auto", textAlign: "center" }}>
              <div className="h6 black pd-t-20">
                {plan.prevYear.startDate} to {plan.prevEndDate}
              </div>
              <div className="h6 black">
                Previous year limit: ${totalLimit}
              </div>
              <div className="h6 black pd-b-15">
                <span style={{ backgroundColor: "yellow" }}>
                  Deadline: {plan.prevYear.runoff} at 11:59 PM MT
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const currentYearData = [
      {name: accountName, 
       limit: plan.currYear.limit, 
       balance: plan.currYear.available, 
       backgroundColor: plan.backgroundColor, 
       textColor: plan.textColor 
      },
      ...plan.currYearCustomCategories
    ];

    const totalLimit = currentYearData.reduce((acc, item) => acc + item.limit, 0).toFixed(2);

    accountBox = (
      <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
        <div className="text-center">
          <DoughnutChart id={`curr-year-doughnut-chart-${plan.type}`} data={currentYearData} />
          <div className="text-center">
            <div style={{ margin: "auto", textAlign: "center" }}>
              <div className="h6 black pd-t-20">
                {plan.currYear.startDate} to {plan.currYear.endDate}
              </div>
              <div className="h6 black">
                Current year limit: ${totalLimit}
              </div>
              <div className="h6 black pd-b-15">
                Deadline: {plan.currYear.runoff} at 11:59 PM MT
              </div>
            </div>
          </div>
        </div>
        {prevYearDiv}
      </div>
    );

    let fileToUpload = null;
    if (this.state.files) {
      fileToUpload = (
        <div className="pd-t-10">
          <div>Files ({this.state.files.length})</div>
          <div className="tx-12 pd-l-5">
            {this.state.files.map((data, i) => {
              return (
                <div key={data.name}>
                  {data.name}{" "}
                  <a
                    onClick={this.removeFile(i)}
                    href="#"
                    className="mg-l-25 tx-18 mg-r-25"
                    style={{ color: "red" }}
                  >
                    <i className="fa fa-remove" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let queuedImageDiv = null;

    if (this.state.queuedImages != null && this.state.queuedImages.length > 0) {
      queuedImageDiv =
        <div>
          <div className="br-pagebody no-padding mg-t-0 mg-x-50" style={{ display: this.state.loadedSpinner }}>
            <div
              className="br-section-wrapper no-padding"
              style={{ maxWidth: 1250 }}
            >
              <div className="card">
                <div className="card-header tx-medium tx-uppercase bg-white">
                  Submitted Images
                </div>
                {this.renderOpenModal()}
                {this.renderFailedModal()}
                {this.renderPendingModal()}
                <div className="card-body no-padding" style={{ minHeight: "694px" }}>
                  <div className="pd-10 clearfix">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        margin: 15,
                        padding: 10,
                        textAlign: "center",
                        border: "1px solid #ffb75d",
                      }}
                    >
                      <div style={{ width: 780, margin: "0 auto" }}>
                        These image(s) have been submitted and will be removed from this page when our team is reviewing them. 
                        In the meantime, you may enter notes, delete or add more images. You will receive an email notification 
                        once your claim is processed.
                      </div>
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{ maxHeight: 550, overflowY: "auto", marginBottom: 30 }}
                  >
                    <div className="row" style={{ display: this.state.loadedSpinner }}>
                      <div className="col-12">
                        <Spinner /> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: this.state.loadedImages }} ref={this.myRef}>
            <QueuedImages 
              loaded={this.setLoaded.bind(this)} 
              unloaded={this.setUnloaded.bind(this)} 
              type={this.state.type} 
              images={this.state.queuedImages} 
              showModal={this.displayModal} 
            />
          </div>
        </div>
    }

    plan.label = this.state.enableWellness && plan.type === 'LSA'? 'Wellness Spending Account' : plan.label;
    return (
      <div className="br-mainpanel black min-width-custom" >
        {this.renderOpenModal()}
        {this.renderFailedModal()}
        {this.renderPendingModal()}
        <div
          className="br-pagebody no-padding mg-t-0 mg-x-50"
          style={{ maxWidth: 1250 }}
        >
          <div className="br-section-wrapper no-padding">
            <div className="step4">
              <div className="card">
                <div className="card-header tx-medium tx-uppercase bg-white">
                  Make a Claim: Upload your {plan.label}  receipts
                </div>
                <div className="card-body" style={{ padding: 10 }}>
                  <div className="row">
                    <div className="col-sm-1 col-md-8 col-lg-8">
                      <div
                        className="br-pagebody mg-t-10"
                        style={{ paddingLeft: 15 }}
                      >
                        <div className="pd-b-80" style={{ maxWidth: 1250 }}>
                          <div style={{marginBottom:25}}>
                              <p style={{marginBottom:5}}>
                                STEP 1: Review your <a style={{"color":"#0d6efd"}} href={`eligibleexpenses?employeeId=${empId}&type=${plan.type}`}>Eligible Expenses</a> to see if
                                your expense qualifies for reimbursement.
                              </p>
                              <p style={{marginBottom:5}}>
                                STEP 2: Have all your {plan.label} receipts/images
                                ready to go on your computer.
                              </p>
                              <p style={{marginBottom:5}}>
                                STEP 3: Drag all the receipts/images into the box
                                below to upload them onto your account.
                              </p>
                              <p style={{marginBottom:5}}>
                                STEP 4: Press the 'Submit' button when you are
                                ready to make your claim.
                              </p>
                          </div>
                          <div
                            style={{ position: "fixed", top: 0, left: -9999 }}
                            id="canvas-container"
                          ></div>
                          <div className="card">
                            <div className="text-center">
                              <Dropzone
                                onDrop={this.onDrop}
                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                                minSize={0}
                                maxSize={15728640}
                                maxFiles={10}
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                  isDragActive,
                                  isDragReject,
                                  fileRejections
                                }) => {
                                  const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > 15728640;
                                  return(
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div style={{ height: 160, paddingTop: 40 }}>
                                      <div>
                                        {!isDragActive &&
                                          "Drag and drop your files here"}
                                        {isDragActive &&
                                          !isDragReject &&
                                          "Drag and drop your files here"}
                                        {isDragReject && "File type not accepted"}
                                        {isFileTooLarge && (
                                        <div className="text-danger mt-2">
                                          Invalid file size
                                        </div>
                                      )}
                                      </div>
                                      <div
                                        style={{ fontSize: 10, color: "grey" }}
                                      >
                                        Maximum file upload size: 15MB
                                        Upload up to 10 images at a time.
                                      </div>
                                      <div
                                        style={{ fontSize: 10, color: "grey" }}
                                      >
                                        These are the accepted file types for upload: .jpg, .png, .pdf.
                                      </div>
                                      <div style={{marginTop:15}}>
                                        <button
                                          className="btn btn-primary btn-sm"
                                        >
                                          Browse Files
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  )
                                }}
                              </Dropzone>
                            </div>
                          </div>
                          {fileToUpload}
                          <div className="mg-t-20 float-left">
                            <button
                              className="btn btn-primary btn-submit"
                              onClick={this.onSubmit}
                              disabled={disableSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-1 col-md-4 col-lg-4">
                      <div className="mg-b-50">
                        <div>{accountBox}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {queuedImageDiv}
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
