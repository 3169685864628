import React from 'react';

class CustomIntegerInput extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0
    };
  }

  handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    this.setState({ value: newValue });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  render() {
    const { label } = this.props;
    const { value } = this.state;

    return (
      <div className='RT-TextBox'>
        <label>
            {label}
            {this.props.toolTip}
        </label>
        <div className='RT-TextBox__container'>
            <input
                className='RT-TextBox__input'
                type="number"
                value={value}
                onChange={this.handleChange}
                min="0"
            />
        </div>
      </div>
    );
  }
}

export default CustomIntegerInput;
