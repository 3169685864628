import React from 'react';
import { ActionRef, FieldRef, FieldType, InputControl, makeComponent } from "../core";
import {TextBox} from './display/TextBox';
import { ReadonlyField } from './display/ReadonlyField';
import { Checkbox } from './display/Checkbox';
import { Select } from './display/Select';
import { TextArea } from './display/TextArea';

export type DisplayProps = {
    field?:FieldRef,
    label?:string,
    span?:string;
    required?:boolean,
    placeholder?:string,
    hideLabel?:boolean,
    format?:string,
    help?:string,
    style?:string,
    onValueChanged?:ActionRef,
    type?:FieldType,
    size?:null | 'small' | 'medium' | 'large',
    textStyle?:null | 'normal' | 'bold' | 'italic',
    lookupLabel?:string,
    onClick?:ActionRef,
    noTabStop?:boolean;
    input?:InputControl;
    toolTip?:any;
    defaultValue?:any;
    disabled?:boolean;
}

export const Display = makeComponent((props:DisplayProps) => {
    
    let field = props.field;
    if (!field || field.hidden) return null;
    let type = props.type || field.type;
    let label = props.label || field.label;
    let placeholder = props.placeholder || field.placeholder;
    let format = props.format || field.format;
    let help = props.help || field.help;
    let style = props.style || field.style;
    let required = props.required || field.required;
    let disabled = props.disabled || field.disabled;
    
    let componentProps = {...props,key:props.field.name,type,label,placeholder,format,help,style,required,disabled};
    if (field.readonly) {
        return <ReadonlyField {...componentProps}/>
    }
    if (field.options){
        return <Select {...componentProps}/>
    }

    switch (type){
        case "logical":return <Checkbox {...componentProps}/>
        case "longchar":return <TextArea {...componentProps}/>
        case "date":
        case "character":
        default:return <TextBox {...componentProps} />
    }
    return null;
});