import React, {Component} from "react";
import {KTMScreen} from "ktm-ui";
import toastr from "toastr";
import Moment from "react-moment";
import {
    Button,
    Table,
    Container,
    Row,
    Col,
    Form,
    Modal,
    Spinner,
    Card,
} from "react-bootstrap";
import axios from "axios";


export default KTMScreen(
    {name: "fundingrefund"},
    class extends React.Component<any, any> {
        state = {
            loading: true,
            success: false,
            searchInput: null,
            searchResult: null,
            note: null,
            chqNo: null,
            amount: 0,
            ledger: [],
            showRefundModal: false
        };

        componentDidMount() {
        }

        submitSearch = () => {
            if (this.state.searchInput != null && this.state.searchInput != "") {
                fetch("/api/payment/searchrefund/" + this.state.searchInput, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        this.setState(
                            {
                                searchResult: data,
                                loading: false,
                                success: data.success,
                                amount: 0,
                            },
                            () => {
                                if (!this.state.success) {
                                    toastr.error("Policy not found", "Error");
                                }
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState({searchResult: null, success: false});
                        toastr.error("Search failed", "Error");
                    });
            }
        };

        handleCloseRefundModal = () => {
            this.setState({showRefundModal: false});
        };

        handleSubmitRefundModal = () => {
            this.submitRefund();
            this.setState({showRefundModal: false});
        };

        verifyFunding = () => {
            this.setState({showRefundModal: true});
        };
        submitRefund = () => {
            fetch("/api/payment/setrefund", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    policyId: this.state.searchResult.policyId,
                    amount: this.state.amount,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: data,
                            loading: false,
                            success: data.success,
                            amount: 0,
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            }
                        }
                    );
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");
                });
        };

        setInput = (e) => {
            this.setState({searchInput: e.target.value});
        };
        setNote = (e) => {
            this.setState({note: e.target.value});
        };
        setChqNo = (e) => {
            this.setState({chqNo: e.target.value}, () => {
                var newNote = "Cheque " + this.state.chqNo;
                this.setState({note: newNote})
            });
        };
        setAmount = (e) => {
            this.setState({amount: e.target.value});
        };

        handleKeyDown = (event) => {
            if (event.key === "Enter") {
                this.submitSearch();
            }
        };

        downloadCheque = (refundId) => {
            fetch(`/api/payment/getchqrefund?refundId=${refundId}`, {
                method: "GET",
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `CHQ_${new Date().toLocaleDateString()}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        render() {
            let responseContents = null;
            let ledgerTable = null;

            let fundingModal = <Modal show={this.state.showRefundModal} onHide={this.handleCloseRefundModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Refund</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to submit this funding refund?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseRefundModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmitRefundModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            if (this.state.searchResult != null && this.state.success) {
                if (
                    this.state.searchResult.ledgerEntries != null &&
                    this.state.searchResult.ledgerEntries.length > 0
                ) {
                    ledgerTable = (
                        <table className="table">
                            <thead>
                            <tr>
                                <td>Type</td>
                                <td style={{textAlign: "right"}}>Amount</td>
                                <td>Date</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.searchResult.ledgerEntries.map((data, key) => {
                                return (
                                    <tr>
                                        <td>Cheque</td>
                                        <td style={{textAlign: "right"}}>
                                            {data.amount.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </td>
                                        <td>
                                            <Moment local format="YYYY-MM-DD">
                                                {data.transDate}
                                            </Moment>
                                        </td>
                                        <td>
                                            <button className={"btn btn-primary me-2"} onClick={() => this.downloadCheque(data.id)}>
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    );
                }

                responseContents = (
                    <div>
                        <div className="ktm-funding-name">
                            {this.state.searchResult.policyName}
                        </div>
                        <div className="ktm-funding-balance-container">
                            <div className="ktm-funding-balance-label">
                                Current Balance:{" "}
                                <div className="ktm-funding-balance">
                                    $
                                    {this.state.searchResult.currentBalance.toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </div>
                            </div>
                            <div className="ktm-funding-balance-label ktm-funding-balance-label-right">
                                <div style={{paddingRight: 15}}>Submit Refund</div>
                                <div>
                                    <div>

                                        <div>
                                            <input
                                                className="form-control"
                                                onChange={(e) => this.setAmount(e)}
                                                value={this.state.amount}
                                                placeholder="Amount"
                                                onKeyPress={(e) => {
                                                    // Allow only digits and decimal point
                                                    const keyCode = e.keyCode || e.which;
                                                    const keyValue = String.fromCharCode(keyCode);
                                                    const validCharRegex = /[0-9.]/;
                                                    if (!validCharRegex.test(keyValue)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => this.verifyFunding()}
                                                className="btn btn-primary"
                                                type="button"
                                                style={{width: "100%", marginTop: 15}}
                                                disabled={
                                                    this.state.amount <= 0
                                                }
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>Recent Refunds (Last 5)</div>
                        <div>{ledgerTable}</div>
                    </div>
                );
            }
            return (
                <div>
                    <div className="ktm-financials-content">
                        <div className="input-group mb-3">
                            <input
                                onKeyDown={this.handleKeyDown}
                                type="text"
                                className="form-control"
                                name="searchInput"
                                onChange={(e) => this.setInput(e)}
                                value={this.state.searchInput}
                                placeholder={
                                    "Enter policy name, policy number, or account number"
                                }
                            />
                            <button
                                onClick={() => this.submitSearch()}
                                className="btn btn-outline-primary"
                                type="button"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    {responseContents}
                    {fundingModal}
                </div>
            );
        }
    }
);