import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Dialog, Button, Column, Panel, Display, Card } from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  BenefitType_Id: string;
  Name: string;
  Description: string;
  EffectiveDate: string;
  FirstYearProrateMonths: number;
  BankNo: string;
  BankAccountNo: string;
  BankTransitNo: string;
}

export default KTMScreen(
  { name: "enroll.product.edit-general" },
  class extends React.Component<{ policyBenefit_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.title = "Edit policy product";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.acknowledgement)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit Policy Product" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      var isDisabled =
        this.data.dsBenefit.BenefitType_Id === null ||
        this.data.dsBenefit.Name?.length === 0 ||
        this.data.dsBenefit.EffectiveDate === null
          ? true
          : false;

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      this.data.dsBenefit.Name = "Spending Account";
      return (
        <Card>
          <Display
            required
            field={field("BenefitType_Id")}
            toolTip={
              <CustomToolTip
                header="Benefit Type"
                bodyContent="The type of benefit you are setting up. Spending Account is the only option until premium products (CH+, Insurpak) become available to offer on our new system."
              />
            }
          />
          <Display required field={field("Name")} />
          <Display
            required
            field={field("EffectiveDate")}
            toolTip={
              <CustomToolTip
                header="Effective Date"
                bodyContent="A policy can only be backdated up to a maximum of 2 years prior to the Benefit End Date."
              />
            }
          />
        </Card>
      );
    }

    acknowledgement({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <div style={{color: 'red', fontWeight: 'bold' }}>
            If you update the Effective Date, the employees' start dates need to be manually updated as they won’t be automatically updated.
          </div>
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@dsBenefit.BenefitType_Id": this.data.dsBenefit.BenefitType_Id,
        "@dsBenefit.Name": this.data.dsBenefit.Name,
        "@dsBenefit.Description": this.data.dsBenefit.Description,
        "@dsBenefit.EffectiveDate": this.data.dsBenefit.EffectiveDate,
        "@dsBenefit.FirstYearProrateMonths":
          this.data.dsBenefit.FirstYearProrateMonths,
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.close({ continue: true });
    }
  }
);
