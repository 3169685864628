import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';
import toastr from "toastr";

interface IdsBenCat {
    Id:string;
    CreditCalcMethod:string;
    AllowRSPOption:boolean;
    AllowStockOption:boolean;
    HasHSA:boolean;
    HasLSA:boolean;
    HSALabel:string;
    LSALabel:string;
    RSPLabel:string;
    Stocklabel:string;
    DefaultRspAllocation:number;
    DefaultStockAllocation:number;
    DefaultHSAAllocation:number;
    DefaultLSAAllocation:number;
    AllowLSADependentClaims:boolean;
    HSACap:number;
    LSACap:number;
    RSPCap:number;
    StockCap:number;
    Policy_Id: string;
};

export default KTMScreen({name:"enroll.ben-limit.edit-flex-settings"}, class extends React.Component<{limit_id:string, employeesCount:number}> {

    data:{
        dsBenCat:IdsBenCat;
    }

    state = {
        showCampaignWarning: false,
    };

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen,{
            "@limit_id":this.props.limit_id,
        });
        screen.title = "Edit Flex Settings";
        await this.checkCampaignStatus();
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit Flex Settings"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    hsa({field,draw,action}:RenderEvent){
        if (!this.data.dsBenCat.HasHSA) {
            this.data.dsBenCat.HSALabel = "";
            this.data.dsBenCat.DefaultHSAAllocation = 0;
        }
        return (
            <Card label="HSA">
                <Display field={field("HasHSA")}/>
                <Line>
                    <Display field={field("HSALabel")} disabled={!this.data.dsBenCat.HasHSA}/>
                    <Display field={field("DefaultHSAAllocation")} disabled={!this.data.dsBenCat.HasHSA}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" && (
                <Line>
                    <Display field={field("HSACap")} disabled={!this.data.dsBenCat.HasHSA}/>
                </Line>
                )}
            </Card>
        );
    }

    lsa({field,draw,action}:RenderEvent){
        if (!this.data.dsBenCat.HasLSA) {
            this.data.dsBenCat.AllowLSADependentClaims = false;
            this.data.dsBenCat.LSALabel = "";
            this.data.dsBenCat.DefaultLSAAllocation = 0;
        }
        return (
            <Card label="LSA">
                <Line>
                    <Display field={field("HasLSA")}/>
                    <Display field={field("AllowLSADependentClaims")} disabled={!this.data.dsBenCat.HasLSA}/>
                </Line>
                <Line>
                    <Display field={field("LSALabel")} disabled={!this.data.dsBenCat.HasLSA}/>
                    <Display field={field("DefaultLSAAllocation")} disabled={!this.data.dsBenCat.HasLSA}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" && (
                <Line>
                    <Display field={field("LSACap")} disabled={!this.data.dsBenCat.HasLSA}/>
                </Line>
                )}
            </Card>
        );
    }

    rsp({field,draw,action}:RenderEvent){
        if (!this.data.dsBenCat.AllowRSPOption) {
            this.data.dsBenCat.RSPLabel = "";
            this.data.dsBenCat.DefaultRspAllocation = 0;
        }
        return (
            <Card label="RSP">
                <Display field={field("AllowRSPOption")}/>
                <Line>
                    <Display field={field("RSPLabel")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                    <Display field={field("DefaultRspAllocation")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" && (
                <Line>
                    <Display field={field("RSPCap")} disabled={!this.data.dsBenCat.AllowRSPOption}/>
                </Line>
                )}
            </Card>
        );
    }

    stock({field,draw,action}:RenderEvent){
        return (
            <Card label="Stock">
                <Display field={field("AllowStockOption")}/>
                <Line>
                    <Display field={field("Stocklabel")}/>
                    <Display field={field("DefaultStockAllocation")}/>
                </Line>
                {this.data.dsBenCat.CreditCalcMethod === "FLAT" && (
                <Line>
                    <Display field={field("StockCap")}/>
                </Line>
                )}
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                        <Card>
                            <Line>
                                <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                                    This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                                    <br />
                                    If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                                </span>
                            </Line>
                        </Card>
                    )}
                    {draw(this.hsa)}
                    {draw(this.lsa)}
                    {draw(this.rsp)}
                    {/* {draw(this.stock)} */}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        this.data.dsBenCat.DefaultHSAAllocation = Math.round(this.data.dsBenCat.DefaultHSAAllocation);
        this.data.dsBenCat.DefaultLSAAllocation = Math.round(this.data.dsBenCat.DefaultLSAAllocation);
        this.data.dsBenCat.DefaultRspAllocation = Math.round(this.data.dsBenCat.DefaultRspAllocation);
        let totalAllocation = this.data.dsBenCat.DefaultHSAAllocation + this.data.dsBenCat.DefaultLSAAllocation + this.data.dsBenCat.DefaultRspAllocation;
        if(totalAllocation > 100){
            toastr.error("Total allocation cannot exceed 100%.");
            return;
        } else if(totalAllocation < 100){
            toastr.error("Total allocation must equal 100%.");
            return;
        }
        await KTMApi.action(screen,"submit",{
            "@dsBenCat.AllowRSPOption":this.data.dsBenCat.AllowRSPOption,
            "@dsBenCat.AllowStockOption":this.data.dsBenCat.AllowStockOption,
            "@dsBenCat.HasHSA":this.data.dsBenCat.HasHSA,
            "@dsBenCat.HasLSA":this.data.dsBenCat.HasLSA,
            "@dsBenCat.HSALabel":this.data.dsBenCat.HSALabel,
            "@dsBenCat.LSALabel":this.data.dsBenCat.LSALabel,
            "@dsBenCat.RSPLabel":this.data.dsBenCat.RSPLabel,
            "@dsBenCat.Stocklabel":this.data.dsBenCat.Stocklabel,
            "@dsBenCat.DefaultRspAllocation":this.data.dsBenCat.DefaultRspAllocation,
            "@dsBenCat.DefaultStockAllocation":this.data.dsBenCat.DefaultStockAllocation,
            "@dsBenCat.DefaultHSAAllocation":this.data.dsBenCat.DefaultHSAAllocation,
            "@dsBenCat.DefaultLSAAllocation":this.data.dsBenCat.DefaultLSAAllocation,
            "@dsBenCat.AllowLSADependentClaims":this.data.dsBenCat.AllowLSADependentClaims,
            "@limit_id":this.props.limit_id,
        });
        screen.close({"continue":true});
    }

    async checkCampaignStatus() {
        let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsBenCat.Policy_Id);
        let res = await response.json();
        if (res.isCampaignOpen) {
            this.setState({ showCampaignWarning: true });
        }
    }
});
