import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Card, Line} from 'ktm-ui';
import toastr from "toastr";
import axios from "axios";

const AdvisorDetailsModal = ({selectedItem, showModal, onHide, onUpdate, rmdList, bddList }) => {
    const [allowEdit, setAllowEdit] = useState(false);
    const [a1Number, setA1Number] = useState(null);
    const [rmd1, setRmd1] = useState(null);
    const [bdd1, setBdd1] = useState(null);
    const [bdd2, setBdd2] = useState(null);

    useEffect(() => {
        setA1Number(selectedItem?.a1number ?? null);
        setRmd1(selectedItem?.rmd1DistroId ?? '');
        setBdd1(selectedItem?.bdd1DistroId ?? '');
        setBdd2(selectedItem?.bdd2DistroId ?? '');
    }, [selectedItem]);
    
    let saveAdvisor = async () => {
        try {
            const response = await axios.put('/api/advisors/updateadvisor', {
                id: selectedItem.id,
                a1Number: a1Number,
                rmd1: rmd1 || '',
                bdd1: bdd1 || '',
                bdd2: bdd2 || ''
            });

            if (response.status === 200) {
                toastr.success('Advisor Updated');
                setA1Number(a1Number);
                onUpdate(a1Number, rmd1, bdd1, bdd2);
                setAllowEdit(false);
                onHide();
            } else {
                toastr.error(`Update failed: ${response.statusText}`);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toastr.error('An unexpected error occurred');
        }
    };

    if (!selectedItem) return null;
    return (
        <Modal
            show={showModal}
            onHide={onHide}
            className="Account-Details-Modal"
        >
            <Modal.Header>
                <Modal.Title style={{fontSize: "17px", fontWeight: 500}}>Advisor Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedItem && (
                    <Card>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Health Claim Account ID</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.healthClaimAccountId == null ? "N/A" : selectedItem.healthClaimAccountId}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Health Claim Advisor ID</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.healthClaimDistroId == null ? "N/A" : selectedItem.healthClaimAdvisorId}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">A1 Number (JG Advisor Only)</label>
                                <div className="RT-Static-Field__content">
                                    {allowEdit ? (
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={a1Number || ''}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, '').slice(0, 6);
                                                setA1Number(value);
                                            }}
                                            placeholder="Enter A1 Number"
                                        />
                                    ) : (
                                        a1Number == null || a1Number === '' ? "N/A" : a1Number
                                    )}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">RMD (JG Advisor Only)</label>
                                <div className="RT-Static-Field__content">
                                    <div className="RT-Static-Field__content">
                                        {allowEdit ? (
                                            <select
                                                className="form-control"
                                                value={rmd1 || ''}
                                                onChange={(e) => {
                                                    const newValue = e.target.value || '';
                                                    setRmd1(newValue);
                                                }}
                                            >
                                                <option value="">None</option>
                                                {rmdList.map((rmd) => (
                                                    <option key={rmd.id} value={rmd.id}>
                                                        {rmd.name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            rmd1 == null || rmd1 === ''
                                                ? "None"
                                                : rmdList.find((rmd) => rmd.id === rmd1)?.name || "N/A"
                                        )}
                                    </div>

                                </div>
                            </div>

                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">BDD1 (JG Advisor Only)</label>
                                <div className="RT-Static-Field__content">
                                    {allowEdit ? (
                                        <select
                                            className="form-control"
                                            value={bdd1 || ''}
                                            onChange={(e) => setBdd1(e.target.value || '')}
                                        >
                                            <option value="">None</option>
                                            {bddList.map((bdd) => (
                                                <option key={bdd.id} value={bdd.id}>
                                                    {bdd.name}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        bdd1 == null || bdd1 === '' ? 'None' : bddList.find((bdd) => bdd.id === bdd1)?.name || 'N/A'
                                    )}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">BDD2 (JG Advisor Only)</label>
                                <div className="RT-Static-Field__content">
                                    {allowEdit ? (
                                        <select
                                            className="form-control"
                                            value={bdd2 || ''}
                                            onChange={(e) => setBdd2(e.target.value || '')}
                                        >
                                            <option value="">None</option>
                                            {bddList.map((bdd) => (
                                                <option key={bdd.id} value={bdd.id}>
                                                    {bdd.name}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        bdd2 == null || bdd2 === '' ? 'None' : bddList.find((bdd) => bdd.id === bdd2)?.name || 'N/A'
                                    )}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Health Claim Relationship</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.healthClaimRelationship == null ? "N/A" : selectedItem.healthClaimRelationship}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Health Claim Description</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.healthClaimDescription == null ? "N/A" : selectedItem.healthClaimDescription}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Last Name</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.lastName == null ? "N/A" : selectedItem.lastName}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">First Name</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.firstName == null ? "N/A" : selectedItem.firstName}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Mailing Street 1</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.mailingStreet1 == null ? "N/A" : selectedItem.mailingStreet1}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Mailing Street 2</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.mailingStreet2 == null ? "N/A" : selectedItem.mailingStreet2}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Mailing City</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.mailingCity == null ? "N/A" : selectedItem.mailingCity}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Mailing Province</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.mailingProv == null ? "N/A" : selectedItem.mailingProv}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Mailing Country</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.mailingCountry == null ? "N/A" : selectedItem.mailingCountry}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Mailing Postal Code</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.mailingPostalCode == null ? "N/A" : selectedItem.mailingPostalCode}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Phone</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.phone == null ? "N/A" : selectedItem.phone}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Email</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.email == null ? "N/A" : selectedItem.email}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Status</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.status == null ? "N/A" : selectedItem.status}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Is Owner</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.isActive ? "Yes" : "No"}
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">Is Active</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.isActive ? "Yes" : "No"}
                                </div>
                            </div>
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">User ID</label>
                                <div className="RT-Static-Field__content">
                                    {selectedItem.user_Id == null ? "N/A" : selectedItem.user_Id}
                                </div>
                            </div>
                        </Line>
                    </Card>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={onHide}
                    style={{marginRight: 'auto', width: '100px'}}
                >
                    Close
                </Button>
                <Button
                    variant={allowEdit ? "danger" : "primary"}
                    onClick={() => setAllowEdit(!allowEdit)}
                    style={{width: '100px'}}
                >
                    {allowEdit ? 'Cancel' : 'Edit'}
                </Button>
                {allowEdit && (
                    <Button
                        variant="success"
                        onClick={saveAdvisor}
                        style={{width: '100px'}}
                    >
                        Save
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AdvisorDetailsModal;
